/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Input, Space, Form, Select, Checkbox, Divider } from 'antd';
import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  ANONYMOUS_END_CHAT_STATUS,
  EFilterResponseStatusIncidents,
  IFiltersIncidentsFormValues,
  IFiltersIncidentsProps,
  incidentCols,
} from 'pages/incidents/interfaces';
import { useAppSelector } from 'app/hooks';
import { RO } from 'utils';
import debounce from 'lodash/debounce';
import { renderImageResponseStatus } from 'pages/incidents/helpers';
import { translate } from 'libs';
import { ExpandIcon, FilterIcon } from './images';
import useTriggerOutside from 'components/common/useTriggerOutside';
import { useDispatch } from 'react-redux';
import { updateIncidentFilterAction } from 'actions/incidents';
import { get } from 'lodash';

const { Option } = Select;

const Filters = React.forwardRef(
  ({ onFilter, handleVisibleCreate, onFilterCols, columns }: IFiltersIncidentsProps, ref: any) => {
    const [form] = Form.useForm();

    const role: string = useAppSelector((state) => state.auth.role);
    const data_org_root = useAppSelector((state) => state.organizations.root_org_data);

    const [optionsStatus, setOptionsStatus] = React.useState<any[]>([]);
    const [isShowFilterModal, setIsShowFilterModal] = React.useState<boolean>(false);

    const listFilterStatus = useAppSelector((state) => state.incidents.listFilterStatus);
    const isReadOnly: boolean = role === RO;
    const dispatch = useDispatch();
    const filters = useAppSelector((state) => state.incidentsFilters);

    const wrapperRef = React.useRef<any>(null);
    useTriggerOutside(wrapperRef, () => {
      if (isShowFilterModal) {
        setIsShowFilterModal(false);
      }
    });

    React.useEffect(() => {
      form.setFieldsValue({ status: undefined });
      const newOptionsStatus: any[] = listFilterStatus
        .map((item: any) => item?.name)
        .filter((e: string | null) => !!e);

      newOptionsStatus.unshift(translate('incidents.list.all'));
      setOptionsStatus(newOptionsStatus);
      form.setFieldsValue({
        status: filters.status || translate('incidents.list.all'),
      });
    }, [listFilterStatus, filters]);

    const updateColumns = () => {
      dispatch(
        updateIncidentFilterAction({
          incident_filter: {
            ...filters,
            columns: columns.map((c) => c.label),
          },
        })
      );
    };

    const getFilterQuery = (values: IFiltersIncidentsFormValues) => {
      const newQuery: IFiltersIncidentsFormValues = { ...values };
      const valuesAll: string[] = ['すべて', 'ALL', 'INCIDENTS.LIST.ALL'];
      if (valuesAll.includes(newQuery.status?.toUpperCase?.())) {
        newQuery.status = '';
      }
      if (valuesAll.includes(newQuery.responseStatus?.toUpperCase?.())) {
        newQuery.responseStatus = '';
      }
      if (valuesAll.includes(newQuery.anonymous_end_chat_status?.toUpperCase?.())) {
        newQuery.anonymous_end_chat_status = '';
      }

      return newQuery;
    };

    const onFinishFilter = (values: IFiltersIncidentsFormValues) => {
      const query: IFiltersIncidentsFormValues = getFilterQuery(values);

      onFilter(query);
    };

    const submitFormFilter = () => {
      return form.submit();
    };

    const debounceInputChange = debounce(submitFormFilter, 800);

    React.useImperativeHandle(ref, () => ({
      getFilterValues: () => {
        const formValues: IFiltersIncidentsFormValues = form.getFieldsValue();

        const query: IFiltersIncidentsFormValues = getFilterQuery(formValues);

        return query;
      },
      showFilterCols: () => {
        setIsShowFilterModal(true);
      },
      updateColumns: () => {
        updateColumns();
      },
    }));

    const responseStatus = [
      {
        value: EFilterResponseStatusIncidents.ALL,
        label: translate('incidents.list.all'),
      },
      { value: EFilterResponseStatusIncidents.UNREAD, label: translate('incidents.list.unread') },
      {
        value: EFilterResponseStatusIncidents.READ_UNANSWERED,
        label: translate('incidents.list.readAndUnanwered'),
      },
      {
        value: EFilterResponseStatusIncidents.READ_ANSWERED,
        label: translate('incidents.list.readAndAnwered'),
      },
    ];


    const onchangeFilterCols = (e, colKey: string) => {
      onFilterCols(colKey, e.target.checked);
    };

    const showFilterCols = (e) => {
      setIsShowFilterModal(!isShowFilterModal);
    };

    const isChecked = (label: any) => {
      return columns.map((c) => c.label).includes(label.key);
    };

    const handleChangeColumns = () => {
      updateColumns();
      setIsShowFilterModal(false);
    };

    return (
      <div className="filters-incidents">
        <Form
          form={form}
          onFinish={onFinishFilter}
          initialValues={{
            assigned: filters.assigned_to_me,
            status: filters.status || translate('incidents.list.all'),
            responseStatus: filters.response_status || EFilterResponseStatusIncidents.ALL,
            anonymous_end_chat_status:
              filters.anonymous_end_chat_status || ANONYMOUS_END_CHAT_STATUS.ALL,
            search: filters.search,
          }}
        >
          <Space size={12}>
            <Form.Item name="search" noStyle>
              <Input
                placeholder={translate('incidents.list.searchPlaceholder')}
                prefix={<SearchOutlined />}
                className="search-input"
                size="middle"
                allowClear
                onChange={debounceInputChange}
                maxLength={256}
                style={{ height: 32, marginTop: 1 }}
              />
            </Form.Item>
            <Form.Item noStyle name="status">
              <Select
                className={`select-status-test`}
                size="middle"
                onChange={submitFormFilter}
                dropdownClassName="dropdown-select-status"
                placeholder={translate('incidents.list.status')}
                optionLabelProp="label"
                style={{ maxWidth: 300, borderRadius: '4 !important' }}
              >
                {optionsStatus.map((status, index: number) => (
                  <Option
                    value={status}
                    key={`${status}-${index}`}
                    label={`${translate('incidents.list.status')}: ${status}`}
                  >
                    {status}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item noStyle name="responseStatus">
              <Select
                className={`select-response-status`}
                size="middle"
                onChange={submitFormFilter}
                dropdownClassName="dropdown-select-response-status"
                placeholder={translate('incidents.list.responseStatus')}
                optionLabelProp="label"
                style={{ marginTop: 0, height: 32 }}
              >
                {responseStatus.map(({ value, label }, index: number) => {
                  const { src } = renderImageResponseStatus(value, 1);
                  return (
                    <Option
                      value={value}
                      key={`${value}-${index}`}
                      label={`${translate('incidents.list.responseStatus')}: ${label}`}
                    >
                      <React.Fragment>
                        {value === EFilterResponseStatusIncidents.ALL ? null : (
                          <img src={src} alt="" />
                        )}
                        &nbsp;
                        {label}
                      </React.Fragment>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>


            <Form.Item noStyle name="assigned" valuePropName="checked">
              <Checkbox className="cb-assigned" onChange={submitFormFilter}>
                {translate('incidents.list.assignedToMe')}
              </Checkbox>
            </Form.Item>
            <Form.Item noStyle name="filterCols">
              <div style={{ position: 'relative' }}>
                <div className="filter-cols-icon" onClick={showFilterCols}>
                  <FilterIcon />
                  <ExpandIcon />
                </div>
                {isShowFilterModal && (
                  <div className="filter-cols-modal" ref={wrapperRef}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '5px',
                        margin: 'auto',
                        alignItems: 'center',
                      }}
                    >
                      {translate('incident_list.filter_text')}
                      <Button
                        type="primary"
                        style={{ width: '76px' }}
                        onClick={handleChangeColumns}
                      >
                        {translate('incident_list.filter_btn')}
                      </Button>
                    </div>
                    <Divider style={{ margin: '5px 0px' }} />
                    <div className="filter-cols-options">
                      {[...incidentCols()]
                        .filter((c) => {
                          if (!get(data_org_root, 'is_allow_servey', false)) {
                            return !['anonymous_end_chat_status', 'is_end_chat'].includes(c.key);
                          } else {
                            return c;
                          }
                        })
                        .map((option, index) => {
                          return (
                            <div key={index} className="filter-cols-item">
                              <Checkbox
                                className="cb-assigned"
                                style={{ marginLeft: '10px' }}
                                onChange={(ev) => onchangeFilterCols(ev, option.key)}
                                checked={isChecked(option)}
                              >
                                {option.label}
                              </Checkbox>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </Form.Item>
          </Space>
        </Form>

        {!isReadOnly && (
          <Button
            size="large"
            type="primary"
            className="btn-add-incident"
            icon={<PlusOutlined />}
            onClick={handleVisibleCreate}
          >
            {translate('incidents.list.addIncidentBtn')}
          </Button>
        )}
      </div>
    );
  }
);

export default React.memo(Filters);
