const FilterIcon = ({ color = 'black', fillOpacity = '0.54' }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="white" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21 4H3C2.45 4 2 4.45 2 5V19C2 19.55 2.45 20 3 20H21C21.55 20 22 19.55 22 19V5C22 4.45 21.55 4 21 4ZM8 18H4V6H8V18ZM14 18H10V6H14V18ZM20 18H16V6H20V18Z"
        fill={color}
        fillOpacity={fillOpacity}
      />
    </svg>
  );
};

export { FilterIcon };
