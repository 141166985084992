import API from 'libs/axios';
import { IPayloadGetListIP } from 'pages/setting/interfaces/security';

const PREFIX: string = 'admin/organizations/security';

export enum ESecurity {
  IP_LIMITER = 'IP_LIMITER',
  AUTH_EMAIL = 'AUTH_EMAIL',
  LOGIN_LOCKOUT = 'LOGIN_LOCKOUT',
}

export const apiGetIP = () => API.get(`${PREFIX}`);

export const apiGetIpById = (query: IPayloadGetListIP['query']) =>
  API.get(`${PREFIX}/ip`, { params: query });

export const apiGetStatusSecurity = (query: IPayloadGetListIP['query']) =>
  API.get(`${PREFIX}/settings`, { params: query });

export const apiCRUDIP = (data) => API.put(`${PREFIX}/ip/action`, data);

export const apiChangeStatus = (data) => API.post(`${PREFIX}/type`, data);
