import {
  IActionCRUDIP,
  IActionGetIp,
  IActionUpdateStatus,
  IPayloadActionCRUDIP,
  IPayloadActionUpdateStatus,
  IPayloadGetListIP,
} from 'pages/setting/interfaces/security';

export const LIST_SECURITY_IP = 'LIST_SECURITY_IP';
export const SECURITY_LOADED = 'SECURITY_LOADED';
export const LIST_IP_LOADING = 'LIST_IP_LOADING';
export const LIST_IP_CRUD_ACTION_LOADING = 'LIST_IP_CRUD_ACTION_LOADING';
export const LIST_STATUS_SECURITY_ACTION_LOADING = 'LIST_STATUS_SECURITY_ACTION_LOADING';
export const UPDATE_STATUS_SECURITY_ACTION_LOADING = 'UPDATE_STATUS_SECURITY_ACTION_LOADING';

export const getSecuritySetingByIdAction = (payload: IPayloadGetListIP): IActionGetIp => ({
  type: LIST_STATUS_SECURITY_ACTION_LOADING,
  payload,
});

export const getIpByIdAction = (payload: IPayloadGetListIP): IActionGetIp => ({
  type: LIST_IP_LOADING,
  payload,
});

export const curdIpAction = (payload: IPayloadActionCRUDIP): IActionCRUDIP => ({
  type: LIST_IP_CRUD_ACTION_LOADING,
  payload,
});

export const statusAction = (payload: IPayloadActionUpdateStatus): IActionUpdateStatus => ({
  type: UPDATE_STATUS_SECURITY_ACTION_LOADING,
  payload,
});
