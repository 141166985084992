import { EFilterResponseStatusIncidents } from './interfaces';

export const renderImageResponseStatus = (
  response_status: EFilterResponseStatusIncidents,
  totalMessage: number
) => {
  switch (response_status) {
    case EFilterResponseStatusIncidents.READ_ANSWERED:
      return {
        src: '/images/ic-read-anwered.png',
        tooltip: 'readAndAnwered',
      };
    case EFilterResponseStatusIncidents.READ_UNANSWERED:
      return {
        src: '/images/ic-read-unanwered.png',
        tooltip: 'readAndUnanwered',
      };
    case EFilterResponseStatusIncidents.UNREAD:
      if (totalMessage > 0) {
        return {
          src: '/images/ic-unread-dot.png',
          tooltip: 'unread',
        };
      } else {
        return {
          src: '/images/ic-unread.png',
          tooltip: 'unread',
        };
      }
    default:
      return {
        src: '',
        tooltip: '',
      };
  }
};
