/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Space,
  Typography,
  Tooltip,
  Divider,
  Radio,
  Table,
  Checkbox,
  Switch,
  Spin,
} from 'antd';
import {
  ICheckRoles,
  IFormUserProps,
  IPayloadGetDetailUser,
  ICallbackInitCheckRole,
  IPayloadChangeStatusUser,
  IPayloadUnlockUser,
} from 'pages/setting/interfaces';
import {
  DownOutlined,
  InfoCircleFilled,
  LockOutlined,
  RightOutlined,
  StepBackwardOutlined,
} from '@ant-design/icons';
import { AA, EMAIL_EXIST, OA, OS, RM, RO, isEmail } from 'utils';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { changeStatusUserAction, getDetailUserAction, unlockUserAction } from 'actions/users';
import DeactiveUser from './DeactiveUser';
import UnLockUser from './UnLockUser';
import { showSuccess } from 'components/standby-notice';
import { translate } from 'libs';

const FormUser = React.forwardRef(
  (
    {
      isVisible,
      onCancel,
      onFinish,
      handleCheckRoles,
      checkRoles,
      handleCheckLimiter,
      handleChangeTypeRole,
      formRef,
      organizations,
      selectedItem,
      handleInitCheckRoles,
    }: IFormUserProps,
    ref: any
  ) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const roleRef = React.useRef<string>('');

    const isLoading: boolean = useAppSelector((state) => state.users.isLoading);
    const userRole: string = useAppSelector((state) => state.auth.role);
    const profile: any = useAppSelector((state) => state.auth.profile);

    const isEdited: boolean = React.useMemo(() => !!selectedItem, [selectedItem]);
    const isOA: boolean = React.useMemo(() => userRole === OA, [userRole]);
    const isAAOS: boolean = React.useMemo(() => [AA, OS].includes(userRole), [userRole]);
    const rootOrgId: string = React.useMemo(() => organizations?.[0]?._id, [organizations]);
    const isHaveRoleRoot: boolean = React.useMemo(
      () => organizations?.[0]?.role?.length > 0,
      [organizations]
    );

    const [isVisibleDeactive, setIsVisibleDeactive] = React.useState<boolean>(false);
    const [isVisibleUnLock, setIsVisibleUnLock] = React.useState<boolean>(false);
    const [isShowLock, setIsShowLock] = React.useState<boolean>(false);
    const [isLoadingModal, setIsLoadingModal] = React.useState<boolean>(false);
    const [isUserActive, setIsUserActive] = React.useState<boolean>(false);
    const [actionHandlerChange, setActionHandlerChange] = useState<string>('');

    const getUser = React.useCallback(
      (userId: string) => {
        const payload: IPayloadGetDetailUser = {
          query: {
            user_id: userId,
          },
          onSuccess: (data: any) => {
            handleInitCheckRoles(
              data?.organizations,
              ({ isSelectRoleOS, isSelectRoleAA }: ICallbackInitCheckRole) => {
                if (isSelectRoleOS) {
                  roleRef.current = OS;
                  form.setFieldsValue({ role: OS });
                }

                if (isSelectRoleAA) {
                  roleRef.current = AA;
                  form.setFieldsValue({ role: AA });
                }
              }
            );

            const newActiveUser: boolean = !!data?.is_active;

            form.setFieldsValue({
              first_name: data?.first_name,
              last_name: data?.last_name,
              is_active: newActiveUser,
              email: data?.email,
              phone: data?.phone,
              country_code: data?.country_code || '81',
            });

            setIsUserActive(newActiveUser);

            setIsShowLock(!!data?.isBlock);
          },
          onError: () => {
            onCancel();
          },
        };

        return dispatch(getDetailUserAction(payload));
      },
      [selectedItem]
    );

    React.useEffect(() => {
      if (selectedItem && isVisible) {
        getUser(selectedItem?._id);
      } else {
        if (isVisible) form.resetFields();
      }

      return () => {
        if (isVisible) {
          form.resetFields();
        }

        setIsUserActive(false);
      };
    }, [selectedItem, isVisible]);

    const renderTitleModal = () => {
      if (!isEdited) return translate('settingPage.adminUser.addNewUser');

      return (
        <div className="form-user-title">
          <Typography.Text>{translate('settingPage.adminUser.updateUser')}</Typography.Text>
          {isShowLock && isAAOS && (
            <Button
              size="large"
              icon={<LockOutlined />}
              className="btn-lock"
              onClick={handleVisibleUnLock}
            >
              {translate('settingPage.adminUser.locked')}
            </Button>
          )}
        </div>
      );
    };

    const renderTitleTable = (role: string) => {
      return (
        <Space>
          <Typography.Text>{translate(`settingPage.adminUser.${role}`)}</Typography.Text>
          <Tooltip title={translate(`settingPage.adminUser.${role}.description`)}>
            <InfoCircleFilled className="ic-info" />
          </Tooltip>
        </Space>
      );
    };

    const handleSelectUserRole = (event: any) => {
      const { value } = event.target;

      roleRef.current = value;
      return handleChangeTypeRole(value);
    };

    const handleClickRole = (event: any) => {
      const { value } = event.target;

      if (roleRef.current === value) {
        form.setFieldsValue({ role: '' });
        return handleChangeTypeRole(OS);
      }
    };

    const handleChangeCheckBox = (event: any, key: string, item: any) => {
      const { checked } = event.target;

      const isCheckROParent: boolean = key === RO && item._id === item.key;
      handleCheckRoles(checked, key, item._id, isCheckROParent);
    };

    const handleChangeLimiter = (checked: boolean, id: string) => {
      handleCheckLimiter(checked, id);
    };

    const handleVisibleUnLock = () => {
      setIsVisibleUnLock(true);
    };

    const handleCancelUnLock = () => {
      setIsVisibleUnLock(false);
      setIsLoadingModal(false);
    };

    const handleVisibleDeactive = () => {
      setIsVisibleDeactive(true);
    };

    const handleCancelDeactive = () => {
      setIsVisibleDeactive(false);
      setIsLoadingModal(false);
    };

    const handleChangeStatus = (checked: boolean) => {
      form.setFieldsValue({ is_active: !checked });
      if (!checked) {
        if (!isOA) {
          setActionHandlerChange('deactive');
          return handleVisibleDeactive();
        }
      } else {
        setActionHandlerChange('active');
        return handleVisibleDeactive();
      }
    };

    const onActive = () => {
      setIsLoadingModal(true);
      const payload: IPayloadChangeStatusUser = {
        values: {
          user_id: selectedItem?._id,
          is_active: true,
        },
        onSuccess: () => {
          handleCancelDeactive();
          form.setFieldsValue({ is_active: true });
          setIsUserActive(true);
        },
        onError: () => {
          handleCancelDeactive();
        },
      };
      return dispatch(changeStatusUserAction(payload));
    };

    const onDeactive = () => {
      setIsLoadingModal(true);
      const payload: IPayloadChangeStatusUser = {
        values: {
          user_id: selectedItem?._id,
          is_active: false,
        },
        onSuccess: () => {
          handleCancelDeactive();
          form.setFieldsValue({ is_active: false });
          onCancel();
        },
        onError: () => {
          handleCancelDeactive();
        },
      };

      return dispatch(changeStatusUserAction(payload));
    };

    const onUnLock = () => {
      setIsLoadingModal(true);

      const payload: IPayloadUnlockUser = {
        values: {
          user_id: selectedItem?._id,
        },
        onSuccess: () => {
          setIsShowLock(false);
          handleCancelUnLock();
          showSuccess(translate('settingPage.adminUser.unlockSuccess'));
        },
        onError: (errorCode: string) => {
          console.log('🚀 ~ errorCode', errorCode);
          setIsShowLock(false);
        },
      };

      return dispatch(unlockUserAction(payload));
    };

    const getValueChecked = React.useCallback(
      (id: string, role: string): boolean => {
        const isChecked: boolean = checkRoles.some(
          (item: ICheckRoles) => item.organization_id === id && item.role_name === role
        );

        return isChecked;
      },
      [checkRoles]
    );

    const getValueDisabled = React.useCallback(
      (id: string, role: string): boolean => {
        if (isOA && id === rootOrgId) return true;

        if (!isUserActive && isEdited) return true;

        const typeRole: string = form.getFieldValue('role');

        if ([AA, OS].includes(typeRole)) return true;

        const isRootChecked: boolean = checkRoles.some(
          (item: ICheckRoles) => item.organization_id === rootOrgId
        );

        if (id !== rootOrgId && isRootChecked) return true;

        const isDisabledRoleGradeRelative: boolean = checkRoles.some(
          (item: ICheckRoles) => item.organization_id === id && item.role_name !== role
        );

        if (isDisabledRoleGradeRelative) return true;

        const isDisabled: boolean = checkRoles.some(
          (item: ICheckRoles) =>
            item.organization_id === id && item.role_name === role && item.disabled
        );

        return isDisabled;
      },
      [checkRoles, rootOrgId, isOA, isUserActive]
    );

    const renderAccessLimiter = React.useCallback(
      (id: string) => {
        const typeRole: string = form.getFieldValue('role');

        if ([AA, OS].includes(typeRole)) return null;

        const isDisabledSwitch: boolean = checkRoles.some(
          (item: ICheckRoles) => item.organization_id === id && item.disabled
        );

        const limited: boolean = checkRoles.some(
          (item: ICheckRoles) => item.organization_id === id && item.limited
        );
        const render = () => (
          <Space>
            <Switch
              checked={limited}
              size="small"
              className="switch-al"
              disabled={isDisabledSwitch}
              onChange={(checked: boolean) => handleChangeLimiter(checked, id)}
            />
            <Typography.Text className="access-limiter">
              {translate('settingPage.adminUser.accessLimiter')}
            </Typography.Text>
          </Space>
        );
        const isRootChecked: boolean = checkRoles.some(
          (item: ICheckRoles) => item.organization_id === rootOrgId && item.role_name === RO
        );

        const isChecked: boolean = checkRoles.some(
          (item: ICheckRoles) => item.organization_id === id
        );
        const isCheckedOtherRO: boolean = checkRoles.some(
          (item: ICheckRoles) => item.organization_id === id && item.role_name !== RO
        );

        if (isRootChecked) {
          if (isChecked) {
            if (id === rootOrgId || isCheckedOtherRO) {
              return render();
            }

            return null;
          }

          return null;
        } else {
          if (isChecked) {
            return render();
          }
        }

        return null;
      },
      [checkRoles, rootOrgId]
    );

    const columns: any[] = [
      {
        title: translate('settingPage.ogSetup.ogName'),
        dataIndex: 'name',
        key: 'name',
        width: 430,
        ellipsis: {
          showTitle: false,
        },
        render: (name: string, record: any) => {
          return (
            <Tooltip placement="bottomLeft" title={name}>
              <Typography.Text>{name}</Typography.Text>
            </Tooltip>
          );
        },
      },
      {
        title: renderTitleTable(OA),
        dataIndex: OA,
        key: OA,
        render: (_: any, record: any) => {
          if (record?._id === record?.key) return null;

          if (!isHaveRoleRoot && record?.isSchool && record?.role?.length === 0) return null;

          return (
            <Checkbox
              checked={getValueChecked(record?._id, OA)}
              disabled={getValueDisabled(record?._id, OA)}
              onChange={(event) => handleChangeCheckBox(event, OA, record)}
            />
          );
        },
        align: 'center',
      },
      {
        title: renderTitleTable(RM),
        dataIndex: RM,
        key: RM,
        render: (_: any, record: any) => {
          if (record?._id === record?.key) return null;

          if (!isHaveRoleRoot && record?.isSchool && record?.role?.length === 0) return null;

          return (
            <Checkbox
              checked={getValueChecked(record?._id, RM)}
              disabled={getValueDisabled(record?._id, RM)}
              onChange={(event) => handleChangeCheckBox(event, RM, record)}
            />
          );
        },
        align: 'center',
      },
      {
        title: renderTitleTable(RO),
        dataIndex: RO,
        key: RO,
        render: (_: any, record: any) => {
          if (isOA && record?._id === rootOrgId) return null;

          if (!isHaveRoleRoot && record?.isSchool && record?.role?.length === 0) return null;

          return (
            <Checkbox
              checked={getValueChecked(record?._id, RO)}
              disabled={getValueDisabled(record?._id, RO)}
              onChange={(event) => handleChangeCheckBox(event, RO, record)}
            />
          );
        },
        align: 'center',
      },
      {
        title: '',
        dataIndex: 'action',
        key: 'action',
        render: (_: any, record: any) => {
          if (!isHaveRoleRoot && record?.isSchool && record?.role?.length === 0) return null;

          return renderAccessLimiter(record?._id);
        },
      },
    ];

    React.useImperativeHandle(ref, () => ({
      onFormError: (errorCode: string) => {
        switch (errorCode) {
          case EMAIL_EXIST:
            form.setFields([
              {
                name: 'email',
                errors: [translate('settingPage.adminUser.validate.email.exist')],
              },
            ]);
            form.scrollToField('email');
            break;

          default:
            break;
        }
      },
    }));

    return (
      <Modal
        title={renderTitleModal()}
        centered
        visible={isVisible}
        onCancel={onCancel}
        maskClosable={false}
        footer={[
          <Button
            key="large"
            type="primary"
            className="btn-add"
            size="large"
            onClick={() => formRef.current?.submit()}
            loading={isLoading}
          >
            {isEdited
              ? translate('settingPage.adminUser.saveBtn')
              : translate('settingPage.adminUser.addMember')}
          </Button>,
          <Button key="cancel" className="btn-cancel" size="large" onClick={onCancel}>
            {translate('settingPage.adminUser.addUser.cancelBtn')}
          </Button>,
        ]}
        className="modal md-form-user"
      >
        <Spin spinning={isLoading}>
          <Form
            onFinish={onFinish}
            layout="vertical"
            scrollToFirstError
            ref={formRef}
            form={form}
            autoComplete="off"
            initialValues={{ country_code: '81', phone: '' }}
          >
            <Row gutter={24}>
              <Col span={12}>
                <Form.Item
                  label={translate('settingPage.myInfo.lastName')}
                  name="last_name"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: translate('settingPage.myInfo.lastName.required'),
                    },
                  ]}
                >
                  <Input
                    placeholder={translate('settingPage.myInfo.lastNameRequired')}
                    size="large"
                    className="input-info"
                    maxLength={256}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={translate('settingPage.myInfo.firstName')}
                  name="first_name"
                  rules={[
                    {
                      required: true,
                      whitespace: true,
                      message: translate('settingPage.myInfo.firstName.required'),
                    },
                  ]}
                >
                  <Input
                    placeholder={translate('settingPage.myInfo.firstNameRequired')}
                    size="large"
                    className="input-info"
                    maxLength={256}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={translate('settingPage.myInfo.email')}
                  name="email"
                  rules={[
                    { required: true, message: translate('loginPage.emailRequired') },
                    () => ({
                      validator(_rule, value) {
                        if (!value) return Promise.reject();

                        const checkEmail: boolean = isEmail(value);

                        if (checkEmail) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error(translate('loginPage.emailFormat')));
                      },
                    }),
                  ]}
                >
                  <Input
                    placeholder={translate('loginPage.emailPlaceholder')}
                    size="large"
                    disabled={isEdited}
                    className="input-info"
                    maxLength={256}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  rules={[
                    () => ({
                      validator() {
                        const regexNumber = /^[0-9]+$/;

                        const isNumber = (value: string): boolean => {
                          if (!value) return true;

                          return regexNumber.test(value.toLowerCase());
                        };

                        const codeValue: string = form.getFieldValue('country_code');
                        const phoneValue: string = form.getFieldValue('phone');

                        const checkCode: boolean = isNumber(codeValue);
                        const checkPhone: boolean = isNumber(phoneValue);

                        if (!checkCode || !checkPhone) {
                          return Promise.reject(
                            new Error(translate('settingPage.myInfo.phoneFormat'))
                          );
                        }

                        return Promise.resolve();
                      },
                    }),
                  ]}
                  name="hidden"
                >
                  <Space className="space-input-phone">
                    <Form.Item
                      name="country_code"
                      label={translate('settingPage.myInfo.phone.country_code')}
                    >
                      <Input size="large" maxLength={256} />
                    </Form.Item>
                    <Form.Item name="phone" label={translate('settingPage.myInfo.phone')}>
                      <Input
                        placeholder={translate('settingPage.myInfo.phoneRequired')}
                        size="large"
                        maxLength={12}
                        className="input-info"
                      />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
            </Row>

            {isEdited && (
              <Form.Item className="form-active item-status">
                <Typography.Title className="title-status">
                  {translate('settingPage.adminUser.status.title')}
                </Typography.Title>
                <Space size={8} className="space-status">
                  <Form.Item name="is_active" valuePropName="checked">
                    <Switch
                      className="switch-al"
                      size="small"
                      onChange={handleChangeStatus}
                      disabled={selectedItem?._id === profile?._id || isOA}
                    />
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate={(p, n) => p.is_active !== n.is_active}>
                    {({ getFieldValue }) => {
                      const status: boolean = getFieldValue('is_active');

                      return (
                        <div className="wrap-txt">
                          <Typography.Text className="txt-status">
                            {status
                              ? translate('settingPage.adminUser.status.active')
                              : translate('settingPage.adminUser.status.deactive')}
                          </Typography.Text>
                        </div>
                      );
                    }}
                  </Form.Item>
                </Space>
              </Form.Item>
            )}

            <Space className="label-info">
              <Typography.Text className="txt customize-required-class">
                {translate('settingPage.adminUser.selectRole')}
              </Typography.Text>
              <Tooltip title={translate('settingPage.adminUser.selectRoleDescription')}>
                <InfoCircleFilled className="ic-info" />
              </Tooltip>
            </Space>

            <Divider className="div-label-info" />

            {!isOA ? (
              <Form.Item noStyle shouldUpdate={(p, n) => p.is_active !== n.is_active}>
                {({ getFieldValue }) => {
                  const isActive: boolean = getFieldValue('is_active');
                  const isUserDeactive: boolean = !isActive && isEdited;

                  return (
                    <Form.Item
                      name="role"
                      rules={[
                        () => ({
                          validator(_rule, value) {
                            if (value || checkRoles.length > 0 || isUserDeactive) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error(translate('settingPage.adminUser.selectRoleRequired'))
                            );
                          },
                        }),
                      ]}
                    >
                      <Radio.Group className="radio-role" onChange={handleSelectUserRole}>
                        <Space direction="vertical" size={16}>
                          <Radio onClick={handleClickRole} value={AA} disabled={isUserDeactive}>
                            {translate('settingPage.adminUser.asignAA')}
                          </Radio>
                          <Radio onClick={handleClickRole} value={OS} disabled={isUserDeactive}>
                            {translate('settingPage.adminUser.asignOS')}
                          </Radio>
                        </Space>
                      </Radio.Group>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            ) : (
              <Form.Item
                name="role_oa"
                className="role-oa"
                rules={[
                  () => ({
                    validator(_rule) {
                      if (checkRoles.length > 0) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(translate('settingPage.adminUser.selectRoleRequired'))
                      );
                    },
                  }),
                ]}
              >
                <Input type="hidden" />
              </Form.Item>
            )}

            <Table
              columns={columns}
              dataSource={organizations}
              className="board"
              rowKey="_id"
              expandable={{
                expandIcon: ({ expanded, onExpand, record }) => {
                  if (Array.isArray(record?.children) && record.children.length > 0) {
                    return expanded ? (
                      <DownOutlined className="ic-expand" onClick={(e) => onExpand(record, e)} />
                    ) : (
                      <RightOutlined className="ic-expand" onClick={(e) => onExpand(record, e)} />
                    );
                  }

                  return <StepBackwardOutlined className="fake-expand" />;
                },
                defaultExpandAllRows: true,
              }}
              pagination={false}
              locale={{
                emptyText: translate('no_data', { name: translate('list.empty.permission') }),
              }}
            />
          </Form>

          <DeactiveUser
            isVisible={isVisibleDeactive}
            onCancel={handleCancelDeactive}
            isLoading={isLoadingModal}
            onDeactive={onDeactive}
            onActive={onActive}
            actionHandleChange={actionHandlerChange}
          />

          <UnLockUser
            isVisible={isVisibleUnLock}
            onCancel={handleCancelUnLock}
            isLoading={isLoadingModal}
            onUnLock={onUnLock}
          />
        </Spin>
      </Modal>
    );
  }
);

export default React.memo(FormUser);
