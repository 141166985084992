import { UPDATE_PERMISSION_ORG } from 'actions/auth';
import {
  CHANGE_SEARCH_USERS,
  CHANGE_SEARCH_USERS_SUCCESS,
  CHANGE_STATUS_USER,
  CREATE_USER,
  CREATE_USER_FAILED,
  CREATE_USER_SUCCESS,
  GET_DETAIL_USER,
  GET_DETAIL_USER_FAILED,
  GET_DETAIL_USER_SUCCESS,
  GET_OPT_INS_USER,
  LOADING_USERS,
  LOADING_USERS_FAILED,
  LOADING_USERS_SUCCESS,
  LOADING_USER_ROLE_FAILED,
  LOADING_USER_ROLE,
  LOADING_USER_ROLE_SUCCESS,
  SELECTED_ORGANIZATION_ID,
  SELECTED_ORGANIZATION_ID_SUCCESS,
  UNLOCK_USER,
  UPDATE_OPT_INS_USER,
  UPDATE_OPT_INS_USER_FAILED,
  UPDATE_OPT_INS_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  LOADING_DEACTIVE_USERS_SUCCESS,
  LOADING_DEACTIVE_USERS_FAILED,
  LOADING_DEACTIVE_USERS,
  REACTIVE_USERS,
  GET_OPT_INS_USER_SUCCESS,
  GET_OPT_INS_USER_FAILED,
  CHANGE_STATUS_USER_SUCCESS,
  UNLOCK_USER_SUCCESS,
  SELECTED_ORGANIZATION_SUCCESS,
  SELECTED_ORGANIZATION,
} from 'actions/users';
import { notification } from 'antd';
import {
  getDeactiverUsers,
  getDetailUser,
  getListUsers,
  getOptInsUser,
  getUserRole,
  postChangeStatusUser,
  postCreateUser,
  postUnlockUser,
  postUpdateOptInsUser,
  postUpdateUser,
  putReactiveUser,
} from 'api/users';
import { history, translate } from 'libs';
import {
  IActionChangeStatusUser,
  IActionCreateUser,
  IActionGetDetailUser,
  IActionGetOptInsUser,
  IActionLoadingDeactiveUsers,
  IActionLoadingUserRole,
  IActionLoadingUsers,
  IActionReactiveUsers,
  IActionSearchUsers,
  IActionSelectedOrganization,
  IActionSelectedOrganizationID,
  IActionUnlockUser,
  IActionUpdateOptInsUser,
  IActionUpdateUser,
} from 'pages/setting/interfaces';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  ADMIN_NOT_SAME_ORG_USER,
  FORBIDDEN,
  OK,
  RO,
  TARGET_USER_NOT_PERMISS,
  USER_NOT_PERMISS,
  USER_NOT_SAME_ORG,
} from 'utils';
import { get } from 'lodash';

function* loadingUsers({ payload }: IActionLoadingUsers) {
  try {
    const { showHidden } = yield select((state) => state.auth);
    const get = yield call(getListUsers, { ...payload?.query, show_hidden: showHidden });

    if (get?.status === OK) {
      const { data } = get;
      yield put({ type: LOADING_USERS_SUCCESS, payload: data });
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      yield put({ type: LOADING_USERS_FAILED });
      if (payload?.onError) yield payload.onError(get?.error_code);

      switch (get?.error_code) {
        case FORBIDDEN:
          return history.push({
            pathname: '/admin/no-permission',
          });
        case USER_NOT_PERMISS:
          yield notification.error({ message: translate('settingPage.adminUser.userNotPermiss') });
          break;
        default:
          break;
      }
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_USERS_FAILED });
  }
}

function* selectedOrgID({ payload }: IActionSelectedOrganizationID) {
  yield put({ type: SELECTED_ORGANIZATION_ID_SUCCESS, payload: payload.selectedOrgId });
  yield payload?.onSuccess?.();
}

function* selectedOrg({ payload }: IActionSelectedOrganization) {
  const { roles } = yield select((state) => state.auth);
  let currentSelectedOrgRole = '';
  for (const role of roles) {
    if (
      get(payload, 'selectedOrg._id') === role.org_id ||
      get(payload, 'selectedOrg.path', '').includes(role.org_id)
    ) {
      currentSelectedOrgRole = role.role_name;
      break;
    }
  }
  if (currentSelectedOrgRole) {
    yield put({
      type: SELECTED_ORGANIZATION_SUCCESS,
      payload: {
        ...(payload.selectedOrg as any),
        role_name: currentSelectedOrgRole,
      },
    });
  } else {
    yield put({
      type: SELECTED_ORGANIZATION_SUCCESS,
      payload: payload.selectedOrg,
    });
  }
  yield payload?.onSuccess?.();
}

function* changeSearchUsers({ payload }: IActionSearchUsers) {
  yield put({ type: CHANGE_SEARCH_USERS_SUCCESS, payload: payload.keyword });
  yield payload?.onSuccess?.();
}

export function* loadUserRole({ payload }: IActionLoadingUserRole) {
  try {
    const role = yield call(getUserRole, payload.query);
    const roles: any = [];
    if (role?.status === OK) {
      const { data } = role;
      let myRole: string[] = [RO]; // init with role Read Only (RO)
      let newPermissionOrganization: any[] = [];

      if (Array.isArray(data?.organizations)) {
        for (const organization of data.organizations) {
          if (Array.isArray(organization?.role)) {
            const checkRoleOrganization: string | null = organization?.role?.[0]?.role_name;

            if (!!checkRoleOrganization) {
              myRole = [checkRoleOrganization];
              roles.push(organization?.role?.[0]);
              break;
            } else {
              /**
               * Have not role at root organization, check role in school
               */
              if (Array.isArray(organization?.childrens)) {
                for (const school of organization.childrens) {
                  if (Array.isArray(school?.role)) {
                    const checkRoleSchool: string | null = school?.role?.[0]?.role_name;

                    if (!!checkRoleSchool) {
                      myRole = [...myRole, checkRoleSchool];
                      roles.push(school?.role?.[0]);
                      continue;
                    } else {
                      /**
                       * Have not role at root school, check role in grade
                       */
                      if (Array.isArray(school?.childrens)) {
                        for (const grade of school.childrens) {
                          if (Array.isArray(grade?.role)) {
                            const checkRoleGrade: string | null = grade?.role?.[0]?.role_name;

                            if (!!checkRoleGrade) {
                              myRole = myRole = [...myRole, checkRoleGrade];
                              roles.push(grade?.role?.[0]);
                              continue;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        newPermissionOrganization = data.organizations
          .map((organization: any) => {
            if (organization?._id === data.organizations?.[0]._id) {
              /**
               * Just pick first root
               */
              let newSchools: any = [];

              if (organization.childrens?.length) {
                newSchools = organization.childrens.map((school: any) => {
                  let newGrades: any = [];

                  if (school.childrens?.length) {
                    newGrades = school.childrens.map((grade: any) => {
                      return {
                        ...grade,
                        isGrade: true,
                        title: grade?.name,
                        key: `${organization?._id}-${school?._id}-${grade?._id}`,
                      };
                    });
                  }

                  return {
                    ...school,
                    title: school?.name,
                    children: newGrades,
                    key: `${organization?._id}-${school?._id}`,
                    isSchool: true,
                  };
                });
              }

              return {
                ...organization,
                title: organization?.name,
                children: newSchools,
                key: organization?._id,
                disableCheckbox: true,
                isOrg: true,
              };
            }
            return null;
          })
          .filter((e: any) => !!e);
      }
      const highestRole = myRole.sort()[0];
      if (!payload?.ignoreDispatchReducer) {
        if (!payload.query.name) {
          /**
           * Not change role with case search
           * only change role when first loaded
           */
          yield put({
            type: LOADING_USER_ROLE_SUCCESS,
            payload: { highestRole, roles },
          });
        }

        yield put({
          type: UPDATE_PERMISSION_ORG,
          payload: newPermissionOrganization,
        });
      }

      if (payload?.onSuccess) yield payload.onSuccess(newPermissionOrganization);

      return myRole;
    } else {
      yield put({ type: LOADING_USER_ROLE_FAILED });
      if (payload?.onError) yield payload.onError(role?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_USER_ROLE_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* createUser({ payload }: IActionCreateUser) {
  try {
    const create = yield call(postCreateUser, payload.values);

    if (create?.status === OK) {
      yield put({ type: CREATE_USER_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: CREATE_USER_FAILED });
      if (payload?.onError) yield payload.onError(create?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: CREATE_USER_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* getUser({ payload }: IActionGetDetailUser) {
  try {
    const get = yield call(getDetailUser, payload.query);

    if (get?.status === OK) {
      const { data } = get;
      yield put({ type: GET_DETAIL_USER_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      yield put({ type: GET_DETAIL_USER_FAILED });
      if (payload?.onError) yield payload.onError(get?.error_code);

      let message: string = translate('error.common');

      switch (get?.error_code) {
        case USER_NOT_SAME_ORG:
          message = translate('settingPage.adminUser.userNotSameOrg');
          break;
        case ADMIN_NOT_SAME_ORG_USER:
          message = translate('settingPage.adminUser.adminNotSameOrg');
          break;
        case USER_NOT_PERMISS:
          message = translate('settingPage.adminUser.userNotPermiss');
          break;
        default:
          break;
      }

      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: GET_DETAIL_USER_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* updateUser({ payload }: IActionUpdateUser) {
  try {
    const update = yield call(postUpdateUser, payload.values);
    const { profile } = yield select((state) => state.auth);
    if (update?.status === OK) {
      yield put({ type: UPDATE_USER_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
      if (payload.values.user_id === profile._id) {
        // update self, then need to reload permission tree
        yield put({ type: LOADING_USER_ROLE, payload: { query: {} } });
      }
    } else {
      yield put({ type: UPDATE_USER_FAILED });
      if (payload?.onError) yield payload.onError(update?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: UPDATE_USER_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* changeStatusUser({ payload }: IActionChangeStatusUser) {
  try {
    const change = yield call(postChangeStatusUser, payload.values);

    if (change?.status === OK) {
      yield put({ type: CHANGE_STATUS_USER_SUCCESS, payload: payload.values });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      if (payload?.onError) yield payload.onError(change?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload.onError('');
  }
}

function* getOptInUser({ payload }: IActionGetOptInsUser) {
  try {
    const get = yield call(getOptInsUser, payload.query);

    if (get?.status === OK) {
      const { data } = get;
      yield put({ type: GET_OPT_INS_USER_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      yield put({ type: GET_OPT_INS_USER_FAILED });
      if (payload?.onError) yield payload.onError(get?.error_code);

      let message: string = translate('error.common');

      switch (get?.error_code) {
        case USER_NOT_SAME_ORG:
          message = translate('settingPage.adminUser.userNotSameOrg');
          break;
        case ADMIN_NOT_SAME_ORG_USER:
          message = translate('settingPage.adminUser.adminNotSameOrg');
          break;
        case USER_NOT_PERMISS:
          message = translate('settingPage.adminUser.userNotPermiss');
          break;
        default:
          break;
      }

      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: GET_OPT_INS_USER_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* updateOptInsUser({ payload }: IActionUpdateOptInsUser) {
  try {
    const update = yield call(postUpdateOptInsUser, payload.values);

    if (update?.status === OK) {
      yield put({ type: UPDATE_OPT_INS_USER_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: UPDATE_OPT_INS_USER_FAILED });
      if (payload?.onError) yield payload.onError(update?.error_code);

      let message: string = translate('error.common');

      switch (update?.error_code) {
        case USER_NOT_SAME_ORG:
          message = translate('settingPage.adminUser.userNotSameOrg');
          break;
        case ADMIN_NOT_SAME_ORG_USER:
          message = translate('settingPage.adminUser.adminNotSameOrg');
          break;
        case TARGET_USER_NOT_PERMISS:
          message = translate('settingPage.adminUser.targetUserNotPermiss');
          break;
        case FORBIDDEN:
          return history.push({
            pathname: '/admin/no-permission',
          });
        default:
          break;
      }

      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: UPDATE_OPT_INS_USER_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* unlockUser({ payload }: IActionUnlockUser) {
  try {
    const unlock = yield call(postUnlockUser, payload.values);

    if (unlock?.status === OK) {
      yield put({ type: UNLOCK_USER_SUCCESS, payload: payload.values });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      if (payload?.onError) yield payload.onError(unlock?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload.onError('');
  }
}

/**
 * Deactive users
 */

function* loadingDeactiveUsers({ payload }: IActionLoadingDeactiveUsers) {
  try {
    const { showHidden } = yield select((state) => state.auth);
    const get = yield call(getDeactiverUsers, { ...payload.query, show_hidden: showHidden });

    if (get?.status === OK) {
      const { data } = get;
      yield put({ type: LOADING_DEACTIVE_USERS_SUCCESS, payload: data });
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      yield put({ type: LOADING_DEACTIVE_USERS_FAILED });
      if (payload?.onError) yield payload.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_DEACTIVE_USERS_FAILED });
    if (payload?.onError) yield payload.onError('');
  }
}

function* reactiveUser({ payload }: IActionReactiveUsers) {
  try {
    const reactive = yield call(putReactiveUser, payload.userIds);

    if (reactive?.status === OK) {
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      if (payload?.onError) yield payload.onError(reactive?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload.onError('');
  }
}

export default function* usersSaga() {
  yield takeLatest(LOADING_USERS, loadingUsers);
  yield takeLatest(SELECTED_ORGANIZATION_ID, selectedOrgID);
  yield takeLatest(SELECTED_ORGANIZATION, selectedOrg);
  yield takeLatest(CHANGE_SEARCH_USERS, changeSearchUsers);
  yield takeLatest(LOADING_USER_ROLE, loadUserRole);
  yield takeLatest(CREATE_USER, createUser);
  yield takeLatest(GET_DETAIL_USER, getUser);
  yield takeLatest(UPDATE_USER, updateUser);
  yield takeLatest(CHANGE_STATUS_USER, changeStatusUser);
  yield takeLatest(GET_OPT_INS_USER, getOptInUser);
  yield takeLatest(UPDATE_OPT_INS_USER, updateOptInsUser);
  yield takeLatest(UNLOCK_USER, unlockUser);
  yield takeLatest(LOADING_DEACTIVE_USERS, loadingDeactiveUsers);
  yield takeLatest(REACTIVE_USERS, reactiveUser);
}
