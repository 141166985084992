import { Button, Typography } from 'antd';
import Wrapper from './Wrapper';
import { useAppDispatch } from 'app/hooks';
import { logoutAction } from 'actions/auth';
import { translate } from 'libs';

const BlockIpPage = () => {
  const dispatch = useAppDispatch();

  const handleOk = () => dispatch(logoutAction());

  return (
    <Wrapper>
      <div className="lock-block">
        <div>
          <div>
            <div className="block-ip-icon">
              <img src="/images/ic-no-permission.png" alt="Block Ip" />
            </div>
            <div style={{ display: 'flex' }}>
              <Typography.Text style={{ textAlign: 'center' }} className="txt-title">
                {translate('loginPage.ipNotAcceptTitle')}
              </Typography.Text>
            </div>
            <div className="description">
              <Typography.Text className="txt-description">
                {translate('loginPage.ipNotAcceptDescription')}
              </Typography.Text>
            </div>
            <Button type="primary" block size="large" className="btn-ok" onClick={handleOk}>
              {translate('common.ok2')}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default BlockIpPage;
