import {
  LOADING_ACTIVE_USER,
  LOADING_ACTIVE_USER_FAILED,
  LOADING_ACTIVE_USER_SUCCESS,
  LOADING_ACTIVE_TIMELINE,
  LOADING_ACTIVE_TIMELINE_SUCCESS,
  LOADING_ACTIVE_TIMELINE_FAILED,
} from 'actions/reports';
import { IReportsSelector } from 'pages/reports/interfaces';
import { ActionProps } from 'reducers';

const initialState: IReportsSelector = {
  isLoading: false,
};

export const ReportsReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case LOADING_ACTIVE_USER:
    case LOADING_ACTIVE_TIMELINE:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_ACTIVE_USER_SUCCESS:
    case LOADING_ACTIVE_TIMELINE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case LOADING_ACTIVE_USER_FAILED:
    case LOADING_ACTIVE_TIMELINE_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
