import {
  IActionChangeVisibilityIncidentQuestion,
  IActionCreateIncidentQuestion,
  IActionDeleteIncidentQuestion,
  IActionDragIncidentQuestion,
  IActionEditIncidentQuestion,
  IActionLoadingIncidentQs,
  IPayloadChangeVisibilityIncidentQuestion,
  IPayloadCreateIncidentQuestion,
  IPayloadDeleteIncidentQuestion,
  IPayloadDragIncidentQuestion,
  IPayloadEditIncidentQuestion,
  IPayloadLoadingIncidentQs,
} from 'pages/setting/interfaces';

export const LOADING_INCIDENT_QS = 'LOADING_INCIDENT_QS';
export const LOADING_INCIDENT_QS_SUCCESS = 'LOADING_INCIDENT_QS_SUCCESS';
export const LOADING_INCIDENT_QS_FAILED = 'LOADING_INCIDENT_QS_FAILED';

export const DRAG_QUESTION = 'DRAG_QUESTION';
export const DRAG_QUESTION_SUCCESS = 'DRAG_QUESTION_SUCCESS';
export const DRAG_QUESTION_FAILED = 'DRAG_QUESTION_FAILED';

export const CREATE_QUESTION = 'CREATE_QUESTION';
export const CREATE_QUESTION_SUCCESS = 'CREATE_QUESTION_SUCCESS';
export const CREATE_QUESTION_FAILED = 'CREATE_QUESTION_FAILED';

export const EDIT_QUESTION = 'EDIT_QUESTION';
export const EDIT_QUESTION_SUCCESS = 'EDIT_QUESTION_SUCCESS';
export const EDIT_QUESTION_FAILED = 'EDIT_QUESTION_FAILED';

export const DELETE_QUESTION = 'DELETE_QUESTION';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAILED = 'DELETE_QUESTION_FAILED';

export const CHANGE_VISIBILITY = 'CHANGE_VISIBILITY';
export const CHANGE_VISIBILITY_SUCCESS = 'CHANGE_VISIBILITY_SUCCESS';
export const CHANGE_VISIBILITY_FAILED = 'CHANGE_VISIBILITY_FAILED';

export const loadingIncidentQuestionAction = (
  payload: IPayloadLoadingIncidentQs
): IActionLoadingIncidentQs => ({
  type: LOADING_INCIDENT_QS,
  payload,
});

export const dragIncidentQuestionAction = (
  payload: IPayloadDragIncidentQuestion
): IActionDragIncidentQuestion => ({
  type: DRAG_QUESTION,
  payload,
});

export const createIncidentQuestionAction = (
  payload: IPayloadCreateIncidentQuestion
): IActionCreateIncidentQuestion => ({
  type: CREATE_QUESTION,
  payload,
});

export const editIncidentQuestionAction = (
  payload: IPayloadEditIncidentQuestion
): IActionEditIncidentQuestion => ({
  type: EDIT_QUESTION,
  payload,
});

export const deleteIncidentQuestionAction = (
  payload: IPayloadDeleteIncidentQuestion
): IActionDeleteIncidentQuestion => ({
  type: DELETE_QUESTION,
  payload,
});

export const changeVisibilityIncidentQuestionAction = (
  payload: IPayloadChangeVisibilityIncidentQuestion
): IActionChangeVisibilityIncidentQuestion => ({
  type: CHANGE_VISIBILITY,
  payload,
});
