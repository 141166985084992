import Card from 'antd/es/card';
import React from 'react';
import '../login-page.scss';

const Wrapper = ({ children }: any) => {
  return (
    <div
      className="login-page"
      style={{
        backgroundImage: 'url(/images/background.png)',
      }}
    >
      <Card className="card-form">{children}</Card>
    </div>
  );
};

export default React.memo(Wrapper);
