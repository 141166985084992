const CloseIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6666 4.27331L11.7266 3.33331L7.99992 7.05998L4.27325 3.33331L3.33325 4.27331L7.05992 7.99998L3.33325 11.7266L4.27325 12.6666L7.99992 8.93998L11.7266 12.6666L12.6666 11.7266L8.93992 7.99998L12.6666 4.27331Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};

export default CloseIcon;
