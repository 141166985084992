import { DeleteOutlined, InfoCircleFilled } from '@ant-design/icons';
import {
  checkAccessCodeOrganizationAction,
  getDetailOrganizationAction,
  multiActionOrganization,
} from 'actions/organization';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  notification,
  Radio,
  Row,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { showSuccess } from 'components/standby-notice';
import { translate } from 'libs';
import {
  EActionAccessCode,
  EBrandingType,
  EMultiActionOrganization,
  EStatusMdHideDelete,
  IOGLevelACOGProps,
  IPayloadCheckAccessCode,
  IPayloadDetailOrganization,
  IPayloadMultiActionOrganization,
  IStatusMdHideDelete,
} from 'pages/setting/interfaces';
import { memo, useCallback, useEffect, useState, useMemo, useRef } from 'react';
import {
  AA,
  ACCESS_CODE_ALREADY_EXIST,
  ACCESS_CODE_EXIST,
  OS,
  validateAccessCodeRegex,
} from 'utils';
import DeactiveCode from './DeactiveCode';
import GenQrCode from './GenQrCode';
import ModalHideAndDelete from './ModalHideAndDelete';

const maxLengthAccessCode = 20;

const OGLevel = ({
  formRef,
  onFinish,
  isVisible,
  onCancel,
  selectedItem,
  loadingOrganizations,
}: IOGLevelACOGProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const isLoading: boolean = useAppSelector((state) => state.organizations.isLoading);
  const role: string = useAppSelector((state) => state.auth.role);
  const isAAOS: boolean = useMemo(() => [AA, OS].includes(role), [role]);
  const [isReadOnlyBranding, setIsReadOnlyBranding] = useState<boolean>(false);
  const [isVisibleGenQrCode, setIsVisibleGenQrCode] = useState<boolean>(false);
  const [isVisibleDeactiveCode, setIsVisibleDeactiveCode] = useState<boolean>(false);
  const [isVisibleMdHideDelete, setIsVisibleMdHideDelete] = useState<boolean>(false);
  const [isLoadingConfirmUseCode, setIsLoadingConfirmUseCode] = useState<boolean>(false);
  const [isLoadingCheckAvailableCode, setIsLoadingCheckAvailableCode] = useState<boolean>(false);
  const [isLoadingDeactive, setIsLoadingDeactive] = useState<boolean>(false);
  const messagesEndRef = useRef<any>(null);

  const [statusMdHideDelete, setStatusMdHideDelete] = useState<IStatusMdHideDelete>({
    isDelete: false,
    isError: false,
    isHide: false,
    isLoading: false,
    isUnHide: false,
    errorCode: '',
  });
  const [qrCode, setQrCode] = useState<string>('');
  const getDetailOG = useCallback((organization_id: string) => {
    const payload: IPayloadDetailOrganization = {
      organization_id,
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
      onSuccess: (data: any) => {
        const newAccessCodes: any[] = Array.isArray(data?.access_codes)
          ? data.access_codes
              .map((item: any) => {
                return {
                  access_code: item?.code,
                  isChecked: true,
                  isVerified: true,
                  isDeactived: !item?.is_active,
                  isOld: true, // add to ignore validate with new code
                };
              })
              .filter((e: any) => !!e)
          : [];

        newAccessCodes.push({});

        form.setFieldsValue({
          name: data?.name,
          branding_type: data?.branding_type,
          home_message: data?.home_message || '',
          incident_message: data?.incident_message || '',
          working_time: data?.working_time || '',
          access_codes: newAccessCodes,
        });

        const checkReadOnly: boolean =
          data?.branding_type === EBrandingType.READ_ONLY && data?.parent_id;

        setIsReadOnlyBranding(checkReadOnly);
      },
    };

    return dispatch(getDetailOrganizationAction(payload));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedItem?._id && isVisible) {
      form.resetFields();
      getDetailOG(selectedItem._id);
    }
    if (messagesEndRef) {
      messagesEndRef.current?.addEventListener('DOMNodeInserted', (event) => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem, isVisible]);

  // const handleModalGenQrCode = (code: string) => {
  //   setQrCode(code);
  //   setIsVisibleGenQrCode(true);
  // };

  const handleCancelModalQrCode = () => {
    setIsVisibleGenQrCode(false);
  };

  const handleModalHideDeleteOG = (type: EStatusMdHideDelete) => {
    const isDelete: boolean = EStatusMdHideDelete.DELETE === type;
    const isHide: boolean = EStatusMdHideDelete.HIDE === type;

    const newStatusMdHD: IStatusMdHideDelete = { ...statusMdHideDelete, isError: false };
    newStatusMdHD.isDelete = isDelete;
    newStatusMdHD.isHide = isHide;

    setStatusMdHideDelete(newStatusMdHD);
    setIsVisibleMdHideDelete(true);
  };

  const handleCancelModalHideDeleteOG = () => {
    setIsVisibleMdHideDelete(false);
  };

  const onConfirmHideDeleteOG = () => {
    setStatusMdHideDelete({ ...statusMdHideDelete, isLoading: true });
    const { isDelete, isHide, isError }: IStatusMdHideDelete = statusMdHideDelete;

    if (isHide && isError) {
      setStatusMdHideDelete({ ...statusMdHideDelete, isLoading: false });
      return handleCancelModalHideDeleteOG();
    }

    let action: EMultiActionOrganization = EMultiActionOrganization.SHOW;
    let messageSuccess: string;

    if (isDelete) {
      action = EMultiActionOrganization.DELETE;
      messageSuccess = translate('settingPage.ogSetup.deleteOgSuccess');
    }

    if (isHide) {
      action = EMultiActionOrganization.HIDE;
      messageSuccess = translate('settingPage.ogSetup.hideOgSuccess');
    }

    const payload: IPayloadMultiActionOrganization = {
      values: {
        organization_id: selectedItem?._id,
        action,
      },
      onSuccess: () => {
        handleCancelModalHideDeleteOG();
        onCancel();
        setStatusMdHideDelete({ ...statusMdHideDelete, isLoading: false });
        loadingOrganizations?.();

        showSuccess(messageSuccess);
      },
      onError: (errorCode) => {
        setStatusMdHideDelete({
          ...statusMdHideDelete,
          isLoading: false,
          isError: true,
          errorCode: errorCode,
        });
      },
    };

    return dispatch(multiActionOrganization(payload));
  };

  const handleDeactiveCode = (code: string) => {
    setIsLoadingDeactive(true);
    const fields = form.getFieldsValue();
    const newAccessCodes = [...fields?.access_codes];

    const findIndex = newAccessCodes.findIndex(
      (item) => item?.isVerified && item?.access_code === code
    );

    if (findIndex >= 0) {
      const accessCodeItem = newAccessCodes[findIndex];

      const payload: IPayloadCheckAccessCode = {
        values: {
          access_code: accessCodeItem?.access_code,
          action: EActionAccessCode.DEACTIVE,
          organization_id: selectedItem?._id,
        },
        onSuccess: () => {
          newAccessCodes.splice(findIndex, 1, { ...accessCodeItem, isDeactived: true });

          form.setFieldsValue({ access_codes: newAccessCodes });

          handleCancelDeactiveCode();
          setIsLoadingDeactive(false);

          showSuccess(translate('settingPage.ogSetup.deactivatedSuccess', { code }));
        },
        onError: (errorCode: string) => {
          console.log('🚀 ~ errorCode', errorCode);
          setIsLoadingDeactive(false);
        },
      };

      return dispatch(checkAccessCodeOrganizationAction(payload));
    }
  };

  const handleModalDeactiveCode = (code: string) => {
    setQrCode(code);
    setIsVisibleDeactiveCode(true);
  };

  const handleCancelDeactiveCode = () => {
    setIsVisibleDeactiveCode(false);
  };

  const onErrorAccessCode = (code: string) => {
    let message: string = translate('error.common');

    switch (code) {
      case ACCESS_CODE_ALREADY_EXIST:
      case ACCESS_CODE_EXIST:
        message = translate('settingPage.ogSetup.accessCodeExist');
        break;

      default:
        break;
    }

    return notification.error({ message });
  };

  const handleCheckAvailable = (index: number, isChecked = true) => {
    setIsLoadingCheckAvailableCode(true);
    const fields = form.getFieldsValue();
    const newAccessCodes = [...fields?.access_codes];
    const accessCodeItem = newAccessCodes[index];

    const onError = (errorCode: string) => {
      console.log('🚀 ~ errorCode', errorCode);
      onErrorAccessCode(errorCode);
      setIsLoadingCheckAvailableCode(false);
    };

    const onSuccess = (data?: any) => {
      if (data) {
        if (!data?.available) {
          return onError(ACCESS_CODE_ALREADY_EXIST);
        }
      }

      newAccessCodes.splice(index, 1, { ...accessCodeItem, isChecked });
      form.setFieldsValue({ access_codes: newAccessCodes });
      setIsLoadingCheckAvailableCode(false);
    };

    if (!isChecked) {
      return onSuccess();
    }

    const payload: IPayloadCheckAccessCode = {
      values: {
        access_code: accessCodeItem?.access_code,
        action: EActionAccessCode.VALIDATE,
        organization_id: '',
      },
      onSuccess,
      onError,
    };

    return dispatch(checkAccessCodeOrganizationAction(payload));
  };

  const handleVerifiedCode = (index: number, callback: () => void) => {
    setIsLoadingConfirmUseCode(true);
    const fields = form.getFieldsValue();
    const newAccessCodes = [...fields?.access_codes];
    const accessCodeItem = newAccessCodes[index];

    const onSuccess = () => {
      newAccessCodes.splice(index, 1, { ...accessCodeItem, isVerified: true, isOld: true });
      form.setFieldsValue({ access_codes: newAccessCodes });
      setIsLoadingConfirmUseCode(false);

      callback();
    };

    const payload: IPayloadCheckAccessCode = {
      values: {
        access_code: accessCodeItem?.access_code,
        action: EActionAccessCode.SAVE,
        organization_id: selectedItem?._id,
      },
      onSuccess,
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
        onErrorAccessCode(errorCode);
        setIsLoadingConfirmUseCode(false);
      },
    };

    return dispatch(checkAccessCodeOrganizationAction(payload));
  };

  const renderTitleModal = () => {
    return (
      <div className="og-level-title">
        <Typography.Text>{translate('settingPage.ogSetup.accountOgLevel')}</Typography.Text>
        {isAAOS && selectedItem?.parent_id && (
          <Space size={16}>
            <Button
              danger
              size="large"
              icon={<DeleteOutlined />}
              className="btn-header"
              onClick={() => handleModalHideDeleteOG(EStatusMdHideDelete.DELETE)}
            >
              {translate('settingPage.ogSetup.deleteOg')}
            </Button>
            {selectedItem?.parent_id && selectedItem?.is_active && (
              <Button
                danger
                size="large"
                className="btn-header"
                onClick={() => handleModalHideDeleteOG(EStatusMdHideDelete.HIDE)}
              >
                {translate('settingPage.ogSetup.hideOg')}
              </Button>
            )}
          </Space>
        )}
      </div>
    );
  };

  return (
    <Modal
      title={renderTitleModal()}
      centered
      visible={isVisible}
      maskClosable={false}
      onCancel={onCancel}
      footer={[
        <Button
          key="back"
          type="primary"
          className="btn-ok"
          size="large"
          onClick={() => formRef.current?.submit()}
          loading={isLoading}
        >
          {translate('settingPage.ogSetup.saveBtn')}
        </Button>,
        <Button key="close" onClick={onCancel} className="btn-close" size="large">
          {translate('settingPage.ogSetup.closeBtn')}
        </Button>,
      ]}
      className="md-og md-og-lg"
    >
      <Spin spinning={isLoading}>
        <Form
          layout="vertical"
          ref={formRef}
          onFinish={onFinish}
          requiredMark={false}
          initialValues={{
            branding_type: EBrandingType.READ_ONLY,
            access_codes: [{}],
          }}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="name"
            label={translate('settingPage.ogSetup.ogName')}
            rules={[
              {
                required: true,
                whitespace: true,
                message: translate('settingPage.ogSetup.ogNameRequired'),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={translate('settingPage.ogSetup.ogNamePlaceholder')}
              className="input-og-name"
              maxLength={70}
            />
          </Form.Item>

          <Space className="label-info">
            <Typography.Text className="txt">
              {translate('settingPage.ogSetup.accessCode')}
            </Typography.Text>
            <Tooltip title={translate('settingPage.ogSetup.accessCodeDesciption')}>
              <InfoCircleFilled className="ic-info" />
            </Tooltip>
          </Space>

          <Divider className="div-label-info" />

          <div className="wrap-access-codes" ref={messagesEndRef}>
            <Form.List name="access_codes">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, fieldKey, ...restField }) => {
                    return (
                      <Row gutter={[16, 16]} key={key}>
                        <Col span={12}>
                          <Form.Item
                            noStyle
                            shouldUpdate={(p, n) => {
                              const prevState: any = p?.access_codes?.[name];
                              const nextState: any = n?.access_codes?.[name];

                              const prevChecked: boolean = !!prevState?.isChecked;
                              const nextChecked: boolean = !!nextState?.isChecked;

                              const prevDeactived: boolean = !!prevState?.isDeactived;
                              const nextDeactived: boolean = !!nextState?.isDeactived;

                              const shouldUpdate: boolean =
                                prevChecked !== nextChecked || prevDeactived !== nextDeactived;

                              return shouldUpdate;
                            }}
                          >
                            {({ getFieldValue }) => {
                              const isChecked: boolean = !!getFieldValue('access_codes')?.[name]
                                ?.isChecked;
                              const isDeactived: boolean = !!getFieldValue('access_codes')?.[name]
                                ?.isDeactived;
                              const isVerified: boolean = !!getFieldValue('access_codes')?.[name]
                                ?.isVerified;

                              const isPendingConfirm: boolean = isChecked && !isVerified;

                              const className = (): string => {
                                if (isPendingConfirm) {
                                  if (isChecked) return 'input-code-checked';
                                }

                                if (isDeactived) {
                                  return 'input-code-checked-deactive';
                                }

                                return 'input-code-checked-disabled';
                              };

                              const disabled = (): boolean => {
                                if (!selectedItem?.is_active) return true;
                                if (isDeactived || isVerified) return true;

                                return isPendingConfirm;
                              };

                              const suffix = () => {
                                if (isDeactived) {
                                  return (
                                    <Typography.Text className="txt-deactivated">
                                      {translate('settingPage.ogSetup.accessCode.status.deactived')}
                                    </Typography.Text>
                                  );
                                }

                                return null;
                              };

                              const hasFeedback = (): boolean => {
                                if (isPendingConfirm) return true;

                                return false;
                              };

                              return (
                                <Form.Item
                                  {...restField}
                                  name={[name, 'access_code']}
                                  fieldKey={[fieldKey, 'access_code']}
                                  label={translate(
                                    `settingPage.ogSetup.${
                                      !isVerified ? 'createAccessCode' : 'accessCode'
                                    }`
                                  )}
                                  hasFeedback={hasFeedback()}
                                  rules={[
                                    () => ({
                                      validator(rule, value = '') {
                                        const accessCodes: any[] = form.getFieldValue(
                                          'access_codes'
                                        );
                                        console.log(accessCodes);

                                        const itemCode: any = accessCodes.find(
                                          (item: any) =>
                                            item?.access_code?.toLowerCase?.() ===
                                            value.toLowerCase()
                                        );
                                        const checkExist: boolean = accessCodes.some(
                                          (item: any) =>
                                            item?.access_code?.toLowerCase?.() ===
                                              value.toLowerCase() &&
                                            item?.isChecked &&
                                            !item?.isOld &&
                                            item?.isVerified
                                        );

                                        if (checkExist) {
                                          return Promise.reject(
                                            translate('settingPage.ogSetup.accessCodeExist')
                                          );
                                        }

                                        if (itemCode?.isOld || !value) return Promise.resolve();

                                        const regex = validateAccessCodeRegex;
                                        const checkValidCode: boolean = regex.test(
                                          value.toLowerCase?.()
                                        );

                                        if (!checkValidCode) {
                                          return Promise.reject(
                                            translate('settingPage.ogSetup.accessCodeFormat')
                                          );
                                        }
                                        return Promise.resolve();
                                      },
                                    }),
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder={translate(
                                      'settingPage.ogSetup.accessCodePlaceholder'
                                    )}
                                    className={`input-access-code ${className()}`}
                                    disabled={disabled()}
                                    suffix={suffix()}
                                    maxLength={maxLengthAccessCode}
                                  />
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'isChecked']}
                            fieldKey={[fieldKey, 'isChecked']}
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'isVerified']}
                            fieldKey={[fieldKey, 'isVerified']}
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'isDeactived']}
                            fieldKey={[fieldKey, 'isDeactived']}
                            noStyle
                          >
                            <Input type="hidden" />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              const isChecked: boolean = !!getFieldValue('access_codes')?.[name]
                                ?.isChecked;

                              const isDeactived: boolean = !!getFieldValue('access_codes')?.[name]
                                ?.isDeactived;

                              const accessCode: string = getFieldValue('access_codes')?.[name]
                                ?.access_code;

                              const allAccessCodes: any[] = getFieldValue('access_codes');

                              const checkExist: boolean = allAccessCodes.some(
                                (item: any) => item?.access_code === accessCode && item?.isChecked
                              );

                              const isValidCode: boolean = validateAccessCodeRegex.test(accessCode);

                              const isDisabledAvailable: boolean =
                                isValidCode && accessCode?.length >= 8;

                              const isVerified: boolean = !!getFieldValue('access_codes')?.[name]
                                ?.isVerified;

                              if (isDeactived) return null;

                              if (isVerified) {
                                return (
                                  <Form.Item label=" ">
                                    <Space size={12}>
                                      <Button
                                        size="large"
                                        className="btn-cancel"
                                        onClick={() => handleModalDeactiveCode(accessCode)}
                                      >
                                        {translate('settingPage.ogSetup.deactiveBtn')}
                                      </Button>
                                    </Space>
                                  </Form.Item>
                                );
                              }

                              if (isChecked) {
                                return (
                                  <Form.Item label=" ">
                                    <Space size={12}>
                                      <Button
                                        type="primary"
                                        size="large"
                                        onClick={() => {
                                          handleVerifiedCode(name, add);
                                        }}
                                        className="btn-confirm-use"
                                        loading={isLoadingConfirmUseCode}
                                      >
                                        {translate('settingPage.ogSetup.confirmUse')}
                                      </Button>
                                      <Button
                                        size="large"
                                        onClick={() => {
                                          handleCheckAvailable(name, false);
                                          remove(name + 1);
                                        }}
                                        className="btn-cancel"
                                      >
                                        {translate('common.cancelBtn')}
                                      </Button>
                                    </Space>
                                  </Form.Item>
                                );
                              }

                              return (
                                <Form.Item label=" ">
                                  <Button
                                    type="primary"
                                    size="large"
                                    onClick={() => handleCheckAvailable(name)}
                                    disabled={checkExist || !isDisabledAvailable}
                                    className="btn-check-availability"
                                    loading={isLoadingCheckAvailableCode}
                                  >
                                    {translate('settingPage.ogSetup.checkAvailability')}
                                  </Button>
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              )}
            </Form.List>
          </div>

          <Space className="label-info">
            <Typography.Text className="txt">
              {translate('settingPage.ogSetup.branding')}
            </Typography.Text>
            <Tooltip title={translate('settingPage.ogSetup.brandingDesciption')}>
              <InfoCircleFilled className="ic-info" />
            </Tooltip>
          </Space>

          <Divider className="div-label-info" />

          {!selectedItem?.parent_id && (
            <Form.Item
              name="branding_type"
              rules={[
                { required: true, message: translate('settingPage.ogSetup.brandingRequired') },
              ]}
            >
              <Radio.Group className="radio-branding">
                <Space direction="vertical" size={16}>
                  <Radio value={EBrandingType.READ_ONLY}>
                    {translate('settingPage.ogSetup.applyAllSubOg')}
                  </Radio>
                  <Radio value={EBrandingType.EDITABLE}>
                    {translate('settingPage.ogSetup.allowSubOg')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
          )}
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="home_message"
                label={translate('settingPage.ogSetup.homeScreenAppMesseger')}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: translate('settingPage.ogSetup.homeScreenAppMessegerRequired'),
                  },
                ]}
              >
                <Input.TextArea
                  size="large"
                  placeholder={translate('settingPage.ogSetup.homeScreenAppMessegerPlaceholder')}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="input-home-message"
                  maxLength={70}
                  disabled={isReadOnlyBranding}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="incident_message"
                label={translate('settingPage.ogSetup.disclaimer')}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: translate('settingPage.ogSetup.disclaimerRequired'),
                  },
                ]}
              >
                <Input.TextArea
                  size="large"
                  placeholder={translate('settingPage.ogSetup.disclaimerPlaceholder')}
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  className="input-incident-message"
                  maxLength={70}
                  disabled={isReadOnlyBranding}
                />
              </Form.Item>
            </Col>
          </Row>

          <Space className="label-info">
            <Typography.Text className="txt">
              {translate('settingPage.ogSetup.businessHour')}
            </Typography.Text>
            <Tooltip title={translate('settingPage.ogSetup.businessHourDescription')}>
              <InfoCircleFilled className="ic-info" />
            </Tooltip>
          </Space>

          <Divider className="div-label-info" />

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item name="working_time" label={translate('settingPage.ogSetup.businessHour')}>
                <Input
                  size="large"
                  placeholder={translate('settingPage.ogSetup.businessHourDescription')}
                  className="input-working-time"
                  maxLength={30}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>

        {/* Modal Gen QRCode */}
        <GenQrCode
          isVisible={isVisibleGenQrCode}
          onCancel={handleCancelModalQrCode}
          code={qrCode}
        />

        {/* Modal Deactive Code */}
        <DeactiveCode
          isVisible={isVisibleDeactiveCode}
          onCancel={handleCancelDeactiveCode}
          code={qrCode}
          handleDeactive={handleDeactiveCode}
          selectedItem={selectedItem}
          isLoading={isLoadingDeactive}
        />

        {/* Modal hide and delete organization */}
        <ModalHideAndDelete
          isVisible={isVisibleMdHideDelete}
          isDelete={statusMdHideDelete.isDelete}
          isHide={statusMdHideDelete.isHide}
          isError={statusMdHideDelete.isError}
          isLoading={statusMdHideDelete.isLoading}
          onCancel={handleCancelModalHideDeleteOG}
          onConfirm={onConfirmHideDeleteOG}
          errorCode={statusMdHideDelete.errorCode}
        />
      </Spin>
    </Modal>
  );
};

export default memo(OGLevel);
