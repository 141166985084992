import { Button, Result } from 'antd';
import { translate } from 'libs';
import { memo } from 'react';
import { useHistory } from 'react-router';

const NotFound = () => {
  const history = useHistory();

  return (
    <Result
      status="404"
      title="404"
      subTitle={translate('error.notFoundPage')}
      className="not-found-page"
      extra={
        <Button type="primary" onClick={() => history.goBack()} size="large" className="btn-back">
          {translate('common.ok')}
        </Button>
      }
    />
  );
};

export default memo(NotFound);
