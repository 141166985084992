import {
  IActionLoadingReportActiveTimeline,
  IActionLoadingReportActiveUsers,
  IPayloadLoadingReportActiveTimeline,
  IPayloadLoadingReportActiveUsers,
  IPayloadCreateReportBulkIncident,
  IActionCreateReportBulkIncident,
  IPayloadGetReportBulkIncident,
  IActionGetReportBulkIncident,
} from 'pages/reports/interfaces';

export const LOADING_ACTIVE_USER = 'LOADING_ACTIVE_USER';
export const LOADING_ACTIVE_USER_SUCCESS = 'LOADING_ACTIVE_USER_SUCCESS';
export const LOADING_ACTIVE_USER_FAILED = 'LOADING_ACTIVE_USER_FAILED';

export const LOADING_ACTIVE_TIMELINE = 'LOADING_ACTIVE_TIMELINE';
export const LOADING_ACTIVE_TIMELINE_SUCCESS = 'LOADING_ACTIVE_TIMELINE_SUCCESS';
export const LOADING_ACTIVE_TIMELINE_FAILED = 'LOADING_ACTIVE_TIMELINE_FAILED';
export const CREATE_BULK_INCIDENT = 'CREATE_BULK_INCIDENT';
export const CREATE_BULK_INCIDENT_FAILED = 'CREATE_BULK_INCIDENT_FAILED';
export const CREATE_BULK_INCIDENT_SUCCESS = 'CREATE_BULK_INCIDENT_SUCCESS';
export const LOADING_BULK_INCIDENT = 'LOADING_BULK_INCIDENT';
export const LOADING_ACTIVE_TIMELINE_TEST = 'LOADING_ACTIVE_TIMELINE_TEST';
export const LOADING_ACTIVE_USER_INCIDENTTEST = 'LOADING_ACTIVE_USER_INCIDENTTEST';

export const loadingActiveUsersAction = (
  payload: IPayloadLoadingReportActiveUsers
): IActionLoadingReportActiveUsers => ({
  type: LOADING_ACTIVE_USER,
  payload,
});

export const loadingActiveUsersActionIncidentTest = (
  payload: IPayloadLoadingReportActiveUsers
): IActionLoadingReportActiveUsers => ({
  type: LOADING_ACTIVE_USER_INCIDENTTEST,
  payload,
});

export const loadingActiveTimelineAction = (
  payload: IPayloadLoadingReportActiveTimeline
): IActionLoadingReportActiveTimeline => ({
  type: LOADING_ACTIVE_TIMELINE,
  payload,
});

export const loadingActiveTimelineActionTest = (
  payload: IPayloadLoadingReportActiveTimeline
): IActionLoadingReportActiveTimeline => ({
  type: LOADING_ACTIVE_TIMELINE_TEST,
  payload,
});

export const createBulkIncident = (
  payload: IPayloadCreateReportBulkIncident
): IActionCreateReportBulkIncident => ({
  type: CREATE_BULK_INCIDENT,
  payload,
});

export const loadingBulkIncident = (
  payload: IPayloadGetReportBulkIncident
): IActionGetReportBulkIncident => ({
  type: LOADING_BULK_INCIDENT,
  payload,
});
