/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import moment, { Moment } from 'moment';
import {
  Input,
  Modal,
  Select,
  Form,
  Button,
  Space,
  Typography,
  Dropdown,
  DatePicker,
  Spin,
} from 'antd';
import axios from 'axios';
import { Organizations } from 'components/common';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { showError, showSuccess } from 'components/standby-notice';
import { ICreateBroadcastProps } from '../interfaces/create-broadcast';
import { IFile, IPayloadUrlUpload } from 'pages/incidents/interfaces';
import { postUrlUploadOther } from 'api/incidents';
import nProgress from 'nprogress';
import {
  ICreateBroadcastFormValues,
  IPayloadCreateBroadcast,
} from 'pages/setting/interfaces/broadcast';
import { createBroadcastAction } from 'actions/broadcast';
import { EActionBroadcast, formatSchedule } from '../interfaces/index';
import { ESelectOrganizationValues } from 'pages/setting/interfaces';
import trim from 'lodash/trim';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { CloseCircleFilled } from '@ant-design/icons';
import { getFileType } from 'utils/common';
import Translate, { translate } from 'libs';

const { Option } = Select;
const { TextArea } = Input;
const limitFile = 10;

const ModalCreate = ({ isVisible, onCancel, loadingListBroadcast }: ICreateBroadcastProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const inputFileRef: any = React.useRef();

  const [isVisibleSelectOrg, setIsVisibleSelectOrg] = React.useState<boolean>(false);
  const [optionsOrganizations, setOptionsOrganizations] = React.useState<any[]>([]);
  const [isUploadingFile, setIsUploadingFile] = React.useState<boolean>(false);
  const [isHasSchedule, setIsHasSchedule] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<any[]>([]);

  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );
  const isLoading: boolean = useAppSelector((state) => state.broadcasts.isLoading);

  const format = formatSchedule.FORMAT;

  React.useEffect(() => {
    setIsHasSchedule(false);
    if (isVisible) {
      form.resetFields();
    }

    return () => {
      if (isVisible) {
        setFiles([]);
      }
    };
  }, [isVisible]);

  const onSelectedOrganization = React.useCallback(
    (selectedItem: any) => {
      handleVisibleOrg(false);

      if (selectedItem) {
        const { key } = selectedItem;

        let organizationName: string = '';
        let organizationValue: string = '';

        if (Array.isArray(permissionOrganizations)) {
          const [orgId, schoolId, gradeId] = key.split('-');

          if (orgId) {
            const findOrg: any = permissionOrganizations.find((item: any) => item._id === orgId);
            if (findOrg) {
              organizationName = findOrg.name;
              organizationValue = findOrg._id;

              if (schoolId && Array.isArray(findOrg?.children)) {
                const findSchool = findOrg.children.find((item: any) => item._id === schoolId);

                if (findSchool) {
                  organizationName = `${findOrg.name} > ${findSchool.name}`;
                  organizationValue = findSchool._id;

                  if (gradeId && Array.isArray(findSchool?.children)) {
                    const findGrade = findSchool.children.find((item: any) => item._id === gradeId);

                    if (findGrade) {
                      organizationName = `${findOrg.name} > ${findSchool.name} > ${findGrade.name}`;
                      organizationValue = findGrade._id;
                    }
                  }
                }
              }
            }
          }
        }

        setOptionsOrganizations([{ label: organizationName, value: organizationValue }]);

        form.setFieldsValue({ organization_id: organizationValue });
      } else {
        /**
         * When user select to All organization Am in charge
         */
        setOptionsOrganizations([
          {
            label: translate('settingPage.adminUser.allCharge'),
            value: ESelectOrganizationValues.ALL_IN_CHARGE,
          },
        ]);

        form.setFieldsValue({ organization_id: ESelectOrganizationValues.ALL_IN_CHARGE });
      }
    },
    [permissionOrganizations]
  );

  const handleVisibleOrg = (visible: boolean) => {
    setIsVisibleSelectOrg(visible);
  };

  const onFinish = ({
    organization_id,
    subject,
    message,
    schedule,
  }: ICreateBroadcastFormValues) => {
    const newFiles: IFile[] = files
      .map((file: any) => {
        delete file?.type;
        delete file?.url;

        return file;
      })
      .filter((file: IFile | null) => !!file);

    const payload: IPayloadCreateBroadcast = {
      values: {
        action: EActionBroadcast.ADD,
        organization_id:
          organization_id === ESelectOrganizationValues.ALL_IN_CHARGE ? '' : organization_id,
        subject: trim(subject),
        message: trim(message),
        files: newFiles,
        schedule: schedule ? moment(schedule).format(format) : '',
      },
      onSuccess: () => {
        loadingListBroadcast();

        onCancel();
        showSuccess(translate(`broadcast.create.${schedule ? 'schedule' : 'send'}.success`));
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(createBroadcastAction(payload));
  };

  const customRequest = async (fileList: any[]) => {
    const myOrgId: string = form.getFieldValue('organization_id');

    if (!myOrgId) {
      return form.setFields([
        { name: 'organization_id', errors: [translate('broadcast.create.reciptientsRequired')] },
      ]);
    }

    setIsUploadingFile(true);

    const isAllowed: string[] = ['image/jpeg', 'image/png', 'application/pdf'];
    const fileType: boolean[] = fileList?.map((e) => isAllowed.includes(e?.type.toLowerCase()));

    const isFileTobig = fileList?.find((e) => e.size > 100 * 1024 * 1024);

    const filesSameName: string[] = [];

    fileList.forEach((e) => {
      const isSameName: boolean = files.some((item: any) => item?.original_name === e?.name);

      if (isSameName) filesSameName.push(e.name);
    });

    if (filesSameName.length > 0) {
      setIsUploadingFile(false);
      inputFileRef.current.value = '';
      filesSameName.forEach((name: string) =>
        showError(translate('fileExist', { name: decodeURIComponent(name) }))
      );

      return;
    }

    if (fileList.length > 10) {
      setIsUploadingFile(false);
      inputFileRef.current.value = '';
      return showError(translate('fileToMany'));
    }

    if (fileType.includes(false)) {
      setIsUploadingFile(false);
      inputFileRef.current.value = '';
      return showError(translate('broadcast.modal.attachment.fileNotAllowed'));
    }

    if (isFileTobig) {
      setIsUploadingFile(false);
      inputFileRef.current.value = '';
      return showError(translate('fileToLarge', { size: 100 }));
    }
    const isFileToSmall = fileList?.find((e) => e.size <= 0);

    if (isFileToSmall) {
      setIsUploadingFile(false);
      inputFileRef.current.value = '';
      return showError(translate('fileToSmall'));
    }

    try {
      let jsonKey: any = {};
      for (let i = 0; i < fileList.length; i++) {
        jsonKey[fileList[i].name] = {
          file: fileList[i].file,
          type:
            getFileType(fileList[i].name) === 'heic' || getFileType(fileList[i].name) === 'HEIC'
              ? 'heic'
              : fileList[i].type,
        };
      }
      const payload: IPayloadUrlUpload = {
        organization_id: myOrgId,
        files: [...fileList].map((eel) => eel.name),
      };

      setIsUploadingFile(true);
      const { data } = await postUrlUploadOther(payload);

      // re-map file with url upload
      const dataRemap = (data || []).map((ele) => {
        return {
          url: ele.url,
          ...jsonKey[ele.original_name],
          fileKey: ele.fileKey,
          original_name: ele.original_name,
        };
      });

      Promise.all(
        dataRemap.map((item: any) => {
          const config = {
            headers: {
              'Content-Type': item.type,
              'Access-Control-Allow-Origin': '*',
            },
          };

          return axios.put(item.url, item.file, config);
        })
      )
        .then(() => {
          inputFileRef.current.value = '';

          const newFiles: any[] = dataRemap.map((item: any) => {
            const fileType: any = jsonKey[item.original_name]?.type;

            return { ...item, type: fileType };
          });

          setFiles([...files, ...newFiles]);
        })
        .finally(() => {
          nProgress.done();
          setIsUploadingFile(false);
        });
    } catch (error) {
      inputFileRef.current.value = '';
      console.log(error);
      setIsUploadingFile(false);
    } finally {
      // setIsUploadingFile(false);
    }
  };

  const handleClickUpload = () => {
    if (files.length >= limitFile) {
      return;
    }
    const valueOrg = form.getFieldValue('organization_id');

    if (!valueOrg) {
      form.validateFields();
    } else {
      inputFileRef.current?.click?.();
    }
  };

  const renderIconFileType = (fileKey) => {
    let text = '';
    text = fileKey?.split('.').pop().toUpperCase();
    return (
      <div className="wrap-fileType">
        <span>{text}</span>
      </div>
    );
  };

  const itemUploadRender = (file: any) => {
    return (
      <Space key={file.fileKey} className="space-file" size={10}>
        {renderIconFileType(file.original_name)}
        <div className="box-file-name">
          <Typography.Text className="file-name">
            {decodeURIComponent(file.original_name).length < 60
              ? decodeURIComponent(file.original_name)
              : decodeURIComponent(file.original_name).substring(0, 40) + '...'}
          </Typography.Text>
        </div>
        <img
          alt=""
          src="/images/ic-cancel.png"
          className="ic-cancel"
          onClick={() => handleRemove(file)}
        />
      </Space>
    );
  };

  const handleRemove = (file: any) => {
    const newFiles: any[] = files.filter((item: any) => file.fileKey !== item.fileKey);

    setFiles(newFiles);
  };

  const handlePreventDefault = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const element = document.querySelector('#form-upload-file');
    if (element) {
      element.className = 'preview-file-upload';
    }
  };
  //a
  const handleDragLeave = (event: any) => {
    event.preventDefault();

    if (event.currentTarget.contains(event.target) && event.target.id !== 'form-upload-file') {
      return;
    }

    const element = document.querySelector('#form-upload-file');
    if (element) {
      element.className = 'preview-file-upload preview-file-upload-hidden';
    }
  };

  const handleDropFile = (event: any) => {
    event.preventDefault();

    handleUploadFile(event.dataTransfer.files);

    const element = document.querySelector('#form-upload-file');
    if (element) {
      element.className = 'preview-file-upload preview-file-upload-hidden';
    }
  };

  const handleUploadFile = (fileList: any[]) => {
    let arrayKey: any = [];
    if (files.concat(fileList).length > limitFile) {
      return console.log('file length > 10');
    }

    if (fileList?.length > 0) {
      Object.keys(fileList).forEach(function (key) {
        arrayKey = arrayKey.concat(fileList[key]);
      });
      arrayKey = arrayKey.map((e) => {
        return {
          name: encodeURIComponent(e.name),
          type: e.type,
          size: e.size,
          file: e,
        };
      });
    }
    customRequest(arrayKey);
  };

  const handleChangeSchedule = (date: Moment | null) => {
    setIsHasSchedule(!!date);
  };

  return (
    <Modal
      className="modal md-form-broadcast"
      title={translate('broadcast.modal.create.title')}
      visible={isVisible}
      centered
      onCancel={onCancel}
      width={800}
      footer={[
        <Button
          key="large"
          type="primary"
          onClick={() => form.submit()}
          className="btn-save"
          size="large"
          loading={isLoading}
          disabled={isUploadingFile}
        >
          {translate(
            `${isHasSchedule ? 'broadcast.create.create_btn' : 'broadcast.create.sent_btn'}`
          )}
        </Button>,
        <Button
          key="cancel"
          className="btn-cancel"
          size="large"
          onClick={onCancel}
          disabled={isUploadingFile}
        >
          {translate('broadcast.create.cancel_btn')}
        </Button>,
      ]}
      destroyOnClose
      maskClosable={false}
    >
      <Spin spinning={isUploadingFile}>
        <div
          onDragStart={handlePreventDefault}
          onDragOver={handlePreventDefault}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDropFile}
        >
          <div id="form-upload-file" className="preview-file-upload preview-file-upload-hidden" />

          <Form
            layout="vertical"
            onFinish={onFinish}
            form={form}
            requiredMark={false}
            scrollToFirstError
          >
            <div>
              <Typography.Text id="text" className="txt-drop customize-required-class">
                {translate('broadcast.create.reciptients')}
              </Typography.Text>
              <Dropdown
                overlay={
                  <Organizations onSelected={onSelectedOrganization} isVisibleShowHidden={false} />
                }
                trigger={['click']}
                overlayClassName="dropdown-organizations"
                onVisibleChange={handleVisibleOrg}
                visible={isVisibleSelectOrg}
              >
                <Form.Item
                  name="organization_id"
                  rules={[
                    { required: true, message: translate('broadcast.create.reciptientsRequired') },
                  ]}
                >
                  <Select
                    notFoundContent={translate('no_data', {
                      name: translate('list.empty.organization'),
                    })}
                    size="large"
                    className="select-organization"
                    onClick={() => handleVisibleOrg(true)}
                    placeholder={translate('broadcast.create.selectOrg')}
                    dropdownRender={() => <div />}
                  >
                    {optionsOrganizations.map((org) => (
                      <Option key={org?.value} value={org.value}>
                        {org?.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Dropdown>
            </div>

            <Form.Item
              name="subject"
              label={translate('broadcast.create.subject')}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: translate('broadcast.create.subjectRequired'),
                },
              ]}
            >
              <Input
                className="inp-subject"
                placeholder={translate('broadcast.create.subjectInput')}
                maxLength={256}
              />
            </Form.Item>
            <Form.Item
              name="message"
              label={translate('broadcast.create.message')}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: translate('broadcast.create.messageRequired'),
                },
              ]}
            >
              <TextArea
                className="inp-message"
                autoSize={{ minRows: 5, maxRows: 7 }}
                placeholder={translate('broadcast.create.messageInput')}
                maxLength={12000}
              />
            </Form.Item>
            <Form.Item name="schedule">
              <DatePicker
                dropdownClassName="calendar"
                size="large"
                suffixIcon={
                  <React.Fragment>
                    <div className="schedule-clock-container">
                      <img
                        className="schedule-clock-icon"
                        alt=""
                        src="/images/clock-icon.svg"
                      ></img>
                      <span className="schedule-clock-text">
                        {translate('broadcast.detail.schedule')}:
                      </span>
                    </div>
                  </React.Fragment>
                }
                showNow={false}
                showTime
                format={format}
                clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
                allowClear
                className={`picker-schedule`}
                disabledDate={(current) => {
                  return moment().subtract(0, 'days').startOf('day') >= current;
                }}
                onChange={handleChangeSchedule}
                placeholder={''}
                locale={locale}
              />
            </Form.Item>
          </Form>
          <div className="upload-broadcast">
            <img id="img" alt="" src="/images/ic-upload.png" />
            <Translate
              i18nKey="common.browse2"
              components={{
                blue: (
                  <span
                    onClick={handleClickUpload}
                    style={{ display: 'inline-block', color: 'blue', textDecoration: 'underline' }}
                  />
                ),
              }}
            />
            <input
              onChange={(event: any) => handleUploadFile(event.target.files)}
              multiple
              type="file"
              style={{ display: 'none' }}
              ref={inputFileRef}
            />
          </div>

          <Space direction="vertical" size={16} className="all-files">
            {files.map((file: any) => itemUploadRender(file))}
          </Space>
        </div>
      </Spin>
    </Modal>
  );
};

export default React.memo(ModalCreate);
