import { Moment } from 'moment';

export enum ETimeFilterActivation {
  MONTH = 'month',
  WEEK = 'week',
}

// loading active timeline

export interface IValuesFilterActiveTimeline {
  type: ETimeFilterActivation;
  range: Moment[];
}

export interface IQueryLoadingReportActiveTimeline {
  org_id?: string;
  show_hidden?: boolean;
  lang?: string;
  type: ETimeFilterActivation;
  to_date: string;
  format: EFormatReport;
  from_date: string;
}

export interface IPayloadLoadingReportActiveTimeline {
  query: IQueryLoadingReportActiveTimeline;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionLoadingReportActiveTimeline {
  type: string;
  payload: IPayloadLoadingReportActiveTimeline;
}

// loading active users
export interface IValuesFilterActiveUser {
  search: string;
  date: Moment;
  compare_date?: Moment | null;
}

export enum EFormatReport {
  JSON = 'JSON',
  XLS = 'XLS',
}
export interface IQueryLoadingReportActiveUsers {
  org_id?: string;
  show_hidden?: boolean;
  lang?: string;
  compare_date?: string;
  format: EFormatReport;
  search: string;
  date: string;
}

export interface IPayloadLoadingReportActiveUsers {
  query: IQueryLoadingReportActiveUsers;
  onSuccess?: (data: any) => void;
  onError?: () => void;
}
export interface IActionLoadingReportActiveUsers {
  type: string;
  payload: IPayloadLoadingReportActiveUsers;
}

// export bulk incidents
export interface IExportBulkIncidentsFormValues {
  range: Moment[];
  org_id: string;
  status: string;
}

export interface IQueryBulkIncidents {
  org_id: string;
  status: string;
  show_hidden: boolean;
  from_date: string;
  to_date: string;
  lang: string;
  standby_token: string;
}

export interface IQueryGetBulkIncidents {
  page: number;
  page_size: number;
}
export interface IPayloadGetReportBulkIncident {
  query: IQueryGetBulkIncidents;
  onSuccess?: (data: any) => void;
  onError?: () => void;
}

export interface IActionGetReportBulkIncident {
  type: string;
  payload: IPayloadGetReportBulkIncident;
}

export interface IPayloadCreateReportBulkIncident {
  query: IQueryBulkIncidents;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: any) => void;
}

export interface IActionCreateReportBulkIncident {
  type: string;
  payload: IPayloadCreateReportBulkIncident;
}

export interface IActiveVolumeProps {}

export interface ITimelineProps {
  activeKey: string;
}

export interface IActiveByOrganizationProps {
  activeKey: string;
}
