import {
  IActionCRUDIncidentAttrByOrg,
  IActionGetListAttrByOrg,
  IActionOrderIncidentAttr,
  IPayloadActionCRUDIncidentAttrByOrg,
  IPayloadGetListIncidentAttrByOrg,
  IPayloadOrderIncidentAttr,
} from 'pages/setting/interfaces';

export const INCIDENATATTR_FAMILY_LOADING = 'INCIDENATATTR_FAMILY_LOADING';
export const INCIDENATATTR_FAMILY_LOADED = 'INCIDENATATTR_FAMILY_LOADED';

export const INCIDENATATTR_LIST_LOADING = 'INCIDENATATTR_LIST_LOADING';
export const INCIDENATATTR_CRUD_ACTION_LOADING = 'INCIDENATATTR_CRUD_ACTION_LOADING';
export const INCIDENATATTR_ORDER_LOADING = 'INCIDENATATTR_ORDER_LOADING';

export const getIncidentAttrFamily = () => ({
  type: INCIDENATATTR_FAMILY_LOADING,
});

export const getListIncidentAttrByOrg = (
  payload: IPayloadGetListIncidentAttrByOrg
): IActionGetListAttrByOrg => ({
  type: INCIDENATATTR_LIST_LOADING,
  payload,
});

export const actionCRUDListIncidentAttr = (
  payload: IPayloadActionCRUDIncidentAttrByOrg
): IActionCRUDIncidentAttrByOrg => ({
  type: INCIDENATATTR_CRUD_ACTION_LOADING,
  payload,
});

export const ordersIncidentAttr = (
  payload: IPayloadOrderIncidentAttr
): IActionOrderIncidentAttr => ({
  type: INCIDENATATTR_ORDER_LOADING,
  payload,
});
