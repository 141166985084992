import { Space } from 'antd';
import { Component } from 'react';
import ReactDOM from 'react-dom';

interface IProps {
  isMinimize: boolean;
  onClick: any;
  header: any;
  onClickClose: any;
  onMinimizeChat: any;
}

interface IState {}

const minBottom = 50;

class Drag extends Component<IProps, IState> {
  divRef: HTMLDivElement;
  startDrag: boolean;
  mouseMove: boolean;
  thefirst: boolean;
  mouseXNow: number;
  mouseYNow: number;
  positionRight: number;
  positionBottom: number;
  refDiv?: HTMLDivElement | null;
  constructor(props: IProps) {
    super(props);
    this.divRef = document.createElement('div');
    this.divRef.id = 'div-root-custom';
    const body = document.querySelector('body');
    if (body) {
      body.appendChild?.(this.divRef);
      body.onmousemove = this.handleMouseMove;
      body.onmouseup = this.handleMouseUp;
      body.onmouseleave = this.handleMouseUp;
    }
    this.startDrag = false;
    this.mouseMove = false;
    this.thefirst = false;
    this.mouseXNow = 0;
    this.mouseYNow = 0;
    this.positionBottom = minBottom;
    this.positionRight = 0;
  }

  componentWillUnmount() {
    document.querySelector('body')?.removeChild?.(this.divRef);
  }

  UNSAFE_componentWillReceiveProps(nextProps: IProps) {
    const { isMinimize } = this.props;
    if (isMinimize !== nextProps.isMinimize) {
      if (nextProps.isMinimize) {
        this.thefirst = true;
      } else {
        this.thefirst = false;
      }
    }
  }

  handleMouseMove = (e) => {
    if (!this.startDrag) return;
    if (this.thefirst) {
      this.positionBottom = minBottom;
      this.positionRight = 0;
      this.thefirst = false;
    }
    const { isMinimize } = this.props;
    const heightSub = isMinimize ? 60 : 576;
    this.mouseMove = true;
    const yPosition = this.positionBottom + (this.mouseYNow - e.pageY);
    const xPosition = this.positionRight + (this.mouseXNow - e.pageX);
    this.positionBottom =
      yPosition < minBottom
        ? minBottom
        : yPosition > window.innerHeight - heightSub
        ? window.innerHeight - heightSub
        : yPosition;
    this.positionRight =
      xPosition < 0 ? 0 : xPosition > window.innerWidth - 560 ? window.innerWidth - 560 : xPosition;
    if (this.refDiv) {
      this.refDiv.style.right = this.positionRight + 'px';
      this.refDiv.style.bottom = this.positionBottom + 'px';
    }
    this.mouseXNow = e.pageX;
    this.mouseYNow = e.pageY;
  };

  resetPosition = () => {
    if (this.refDiv) {
      this.refDiv.style.right = '0px';
      this.refDiv.style.bottom = minBottom + 'px';
    }
  };

  handleOpen = () => {
    this.positionBottom -= 516;
    if (this.positionBottom < minBottom) {
      this.positionBottom = minBottom;
    }
    if (this.refDiv) {
      this.refDiv.style.bottom = this.positionBottom + 'px';
    }
  };

  handleMouseUp = () => {
    this.startDrag = false;
    this.mouseMove = false;
  };

  render() {
    const { children, isMinimize, onClick, header, onClickClose, onMinimizeChat } = this.props;
    return ReactDOM.createPortal(
      <div
        ref={(ref) => (this.refDiv = ref)}
        onMouseUp={this.handleMouseUp}
        className="chat-container"
        style={{
          bottom: isMinimize ? minBottom : this.positionBottom,
          right: isMinimize ? 0 : this.positionRight,
          height: isMinimize ? 60 : undefined,
        }}
        // onMouseMove={this.handleMouseMove}
      >
        <div
          style={{ userSelect: 'none' }}
          onMouseDown={(e) => {
            this.startDrag = true;
            this.mouseXNow = e.pageX;
            this.mouseYNow = e.pageY;
          }}
          onMouseUp={() => {
            if (!this.mouseMove) {
              this.handleOpen();
              onClick?.();
            }
          }}
        >
          <strong className={`chat-header ${isMinimize && 'minimize'}`}>
            {header}
            {!isMinimize && (
              <Space>
                <img
                  alt=""
                  src="/images/ic-minimize.png"
                  className="ic-minimize"
                  onClick={() => {
                    this.resetPosition();
                    onMinimizeChat?.();
                  }}
                />
                <img
                  alt=""
                  src="/images/ic-close.png"
                  className="ic-close"
                  onClick={onClickClose}
                />
              </Space>
            )}
          </strong>
        </div>
        {children}
      </div>,
      this.divRef
    );
  }
}

export default Drag;
