import { LIMIT_MESSAGE_LOADED } from 'actions/limit-message';
import { ActionProps } from 'reducers';

const initialState = {
  listLimitMessage: [],
};

const LimitMessageReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case LIMIT_MESSAGE_LOADED:
      return { ...state, listLimitMessage: action.payload };
    default:
      return state;
  }
};

export { LimitMessageReducer };
