/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './index.scss';
import { Descriptions, Divider, notification, Space, Spin, Typography } from 'antd';
import { useHistory, useLocation } from 'react-router';
import axios from 'axios';
import { LINK_EXPIRED, OK } from 'utils';
import moment from 'moment';
import { API_URL } from 'config';
import { translate } from 'libs';

const { Text } = Typography;

const ShareIncident = () => {
  const { search } = useLocation();
  const history = useHistory();

  const [incident, setIncident] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const queryString = new URLSearchParams(search);
  const type_business: any = queryString.get('type_business');

  const getDataIncidentByToken = React.useCallback(async () => {
    const token: string | null = queryString.get('token');

    if (!token) {
      return history.push('/auth/login');
    }

    try {
      const getIncident = await axios.get(
        `${API_URL}/admin/share-incident/share-fields/detail?token=${token}`
      );
      if (getIncident.data) {
        const { data, status } = getIncident.data;

        if (status === OK) {
          setIncident(data);
        }
      }
    } catch (error: any) {
      if (error) {
        const { response } = error;
        if (response?.status === 400) {
          if (response?.data?.error_code === LINK_EXPIRED) {
            return history.push({
              pathname: '/admin/link-expired',
            });
          }
        }
      } else {
        notification.error({
          message: 'Internal Server Error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  const getDataIncidentByIncidentId = React.useCallback(async () => {
    const incident_id: string | null = queryString.get('incident_id');
    const share_fields: string | null = queryString.get('share_fields');

    let url = `${API_URL}/admin/share-incident/export-incident/detail?incident_id=${incident_id}`;

    if (share_fields) {
      url += `&share_fields=${share_fields}`;
    }

    try {
      const getIncident = await axios.get(url);
      if (getIncident.data) {
        const { data, status } = getIncident.data;

        if (status === OK) {
          setIncident(data);
        }
      }
    } catch (error: any) {
      if (error) {
        const { response } = error;
        if (response?.status === 400) {
          if (response?.data?.error_code === LINK_EXPIRED) {
            return history.push({
              pathname: '/admin/link-expired',
            });
          }
        }
      } else {
        notification.error({
          message: 'Internal Server Error',
        });
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  React.useEffect(() => {
    const token: string | null = queryString.get('token');
    const incident_id: string | null = queryString.get('incident_id');
    if (token) {
      getDataIncidentByToken();
    }

    if (incident_id) {
      getDataIncidentByIncidentId();
    }
  }, []);

  const labelWithSub = (item: any, isNote = false) => {
    console.log('labelWithSub');
    return (
      <Space className="label-with-sub" direction="vertical">
        <Text>
          {isNote
            ? `${item?.creator_id?.last_name || ''} ${item?.creator_id?.first_name || ''}`
            : item?.name
            ? item?.name
            : item?.is_admin
            ? translate('title.chat.automatedResponse')
            : translate('platform.typeAnonymous', {}, type_business)}
        </Text>
        <Text className="label-sub">{`${
          item?.is_admin ? translate('platform.type', {}, type_business) : ''
        } ${moment(isNote ? item?.createdAt : item?.date).format('YYYY/MM/DD HH:mm:ss')}`}</Text>
      </Space>
    );
  };

  const buildLabel = (labels) => labels.map((ele) => ele.name).join(', ');
  const buildActionTaken = (taken) => taken.map((ele) => ele.name).join(', ');

  const buildRenderAnswer = (question: any) => {
    if (!question) return null;
    if (question.type === 'CHECKBOXLIST') {
      if (Array.isArray(question.answer)) {
        return (
          <ul>
            {question.answer.map((q) => {
              return <li style={{ margin: '20px 0' }}>{q}</li>;
            })}
          </ul>
        );
      }
      return question.answer;
    }

    if (!question.answer) {
      return translate('incident.export.there_is_not_answer', {}, type_business);
    }

    return question.answer;
  };

  return (
    <Spin spinning={isLoading}>
      <div className="share-incident">
        <div className="share-incident-header">
          <div className="logo" style={{ backgroundImage: 'url("/images/logo-txt-black.png")' }} />

          <Text className="txt-incident">
            {translate('incidents.detail.header.infoTitleRight', {}, type_business)}
            <strong>{incident?.incident_info?.incident_number}</strong>
          </Text>
        </div>
        <Space direction="vertical" className="page-header">
          <Text className="txt-head">
            {translate('incidents.detail.share.incidentInfo', {}, type_business)}
          </Text>
          <div className="line" />
        </Space>
        <Descriptions className="share-incident-description">
          <Descriptions.Item
            label={translate('incident.pdf.information.account', {}, type_business)}
            span={3}
          >
            {incident?.incident_info?.root_org_name}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.information.org_name', {}, type_business)}
            span={3}
          >
            {incident?.incident_info?.org_name}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.information.chat', {}, type_business)}
            span={3}
          >
            {translate('incidents.detail.share.seeBelow', {}, type_business)}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.information.note', {}, type_business)}
            span={3}
          >
            {translate('incidents.detail.share.seeBelow', {}, type_business)}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incidents.detail.share.dateOfIncident', {}, type_business)}
            span={3}
          >
            {incident?.incident_info?.date_created &&
              moment(incident.incident_info.date_created).format('YYYY/MM/DD HH:mm A')}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.information.user_id', {}, type_business)}
            span={3}
          >
            {incident?.incident_info?.app_id}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.information.files', {}, type_business)}
            span={3}
          >
            {incident?.incident_info?.media || 0}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.information.description', {}, type_business)}
            span={3}
          >
            {incident?.incident_info?.description}
          </Descriptions.Item>
        </Descriptions>
        <Space direction="vertical" className="page-header">
          <Text className="txt-head">
            {translate('incident.pdf.question.title', {}, type_business)}
          </Text>
          <div className="line" />
        </Space>
        {Array.isArray(incident?.questions) && incident.questions.length > 0 ? (
          <React.Fragment>
            {incident.questions.map((question: any, index: number) => {
              return (
                <div className="question" key={`question-${index}`}>
                  <Descriptions className="share-incident-description">
                    <Descriptions.Item
                      style={{ wordBreak: 'break-all' }}
                      label={`${question?.name} (${translate(
                        `incident.pdf.question.name.${question?.type?.toLocaleLowerCase?.()}`,
                        {},
                        type_business
                      )})`}
                      span={3}
                    >
                      <div style={{ wordBreak: 'break-all' }}>{buildRenderAnswer(question)}</div>
                    </Descriptions.Item>
                  </Descriptions>
                  {index !== incident.questions.length - 1 && <Divider />}
                </div>
              );
            })}
          </React.Fragment>
        ) : (
          <Text className="txt-no-data">
            {translate('incidents.detail.share.noData', {}, type_business)}
          </Text>
        )}
        <Space direction="vertical" className="page-header">
          <Text className="txt-head">
            {translate('incidents.detail.share.adminInfo', {}, type_business)}
          </Text>
          <div className="line" />
        </Space>
        <Descriptions className="share-incident-description">
          <Descriptions.Item
            label={translate('incident.pdf.question.admin.status', {}, type_business)}
            span={3}
          >
            {incident?.admin_info?.status?.name}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.question.admin.flag', {}, type_business)}
            span={3}
          >
            {incident?.admin_info?.priority}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.question.admin.type', {}, type_business)}
            span={3}
          >
            {incident?.admin_info?.type?.name}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.question.admin.location', {}, type_business)}
            span={3}
          >
            {incident?.admin_info?.location}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.question.admin.assign_to', {}, type_business)}
            span={3}
          >
            {incident?.admin_info?.assigned}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.question.admin.label', {}, type_business)}
            span={3}
          >
            {Array.isArray(incident?.admin_info?.incident_label) && (
              <Text>{buildLabel(incident.admin_info.incident_label)}</Text>
            )}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.question.admin.action', {}, type_business)}
            span={3}
          >
            {Array.isArray(incident?.admin_info?.action_taken) && (
              <Text>{buildActionTaken(incident.admin_info.action_taken)}</Text>
            )}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.question.admin.perpetrator', {}, type_business)}
            span={3}
          >
            {Array.isArray(incident?.admin_info?.perpetrators) &&
              incident.admin_info.perpetrators.join(', ')}
          </Descriptions.Item>
          <Descriptions.Item
            label={translate('incident.pdf.question.admin.vicim', {}, type_business)}
            span={3}
          >
            {Array.isArray(incident?.admin_info?.targets) && incident.admin_info.targets.join(', ')}
          </Descriptions.Item>
        </Descriptions>
        <Space direction="vertical" className="page-header">
          <Text className="txt-head">{`${translate(
            'incident.pdf.question.note.title',
            {
              number: incident?.notes?.length || 0,
            },
            type_business
          )}`}</Text>
          <div className="line" />
        </Space>
        {Array.isArray(incident?.notes) && incident.notes.length > 0 ? (
          <React.Fragment>
            <Descriptions className="share-incident-description with-title">
              {incident.notes.map((note: any, index: number) => (
                <Descriptions.Item key={`note-${index}`} label={labelWithSub(note, true)} span={3}>
                  {note?.content}
                </Descriptions.Item>
              ))}
            </Descriptions>
          </React.Fragment>
        ) : (
          <Text className="txt-no-data">
            {translate('incidents.detail.share.noData', {}, type_business)}
          </Text>
        )}

        <Space direction="vertical" className="page-header">
          <Text className="txt-head">{`${translate(
            'incident.pdf.question.chat.title',
            {
              number: incident?.chats?.length || 0,
            },
            type_business
          )}`}</Text>
          <div className="line" />
        </Space>
        {Array.isArray(incident?.chats) && incident.chats.length > 0 ? (
          <React.Fragment>
            <Descriptions className="share-incident-description">
              {incident.chats.map((chat: any, index: number) => {
                return (
                  <Descriptions.Item key={`chat-${index}`} label={labelWithSub(chat)} span={3}>
                    {chat?.file
                      ? translate(
                          'incidents.detail.chat.attachment',
                          {
                            number: chat?.file,
                          },
                          type_business
                        )
                      : ''}
                    {chat?.is_deleted
                      ? translate('incidents.detail.chat.deletedText')
                      : chat?.message}
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          </React.Fragment>
        ) : (
          <Text className="txt-no-data">
            {translate('incidents.detail.share.noData', {}, type_business)}
          </Text>
        )}
      </div>
    </Spin>
  );
};

export default React.memo(ShareIncident);
