import API from 'libs/axios';
import { IPayloadGetListIncidentAttrByOrg } from 'pages/setting/interfaces';

const PREFIX: string = 'admin/incident/attr';

export enum INCIDENT_ATTR_TYPES {
  TYPE = 'TYPE',
  LABEL = 'LABEL',
  ACTION = 'ACTION',
  STATUS = 'STATUS',
  PRIORITY = 'PRIORITY',
}

export const apiGetListIncidentAttrByOrg = (query: IPayloadGetListIncidentAttrByOrg['query']) =>
  API.get(`${PREFIX}/list`, { params: query });

export const apiGetListIncidentAttrFamily = () => API.get(`${PREFIX}/family`);
export const apiIncidentAttrAction = (data) => API.post(`${PREFIX}/action`, data);
export const apiOrdersIncidentAttr = (data) => API.post(`${PREFIX}/order`, data);
