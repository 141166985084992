export enum EBusiness {
  OFF_BUSINESS = 'OFF',
  HOURS_OPERATIONS_24H = 'TWENTY_FOUR',
  NORMAL_BUSINESS_HOURS = 'NORMAL',
}

export interface IFormHolidayProps {
  isVisible: boolean;
  onCancel: () => void;
  isLoading: boolean;
  onFinish: (values: any) => void;
  selectedItem: any;
}

export interface IDeleteHolidayProps {
  isVisible: boolean;
  onCancel: () => void;
  isLoading: boolean;
  onDelete: () => void;
}

export interface IAutomatedResponseSelector {
  holidays: any[];
  isLoading: boolean;
}

// GET Automated Response by oraganization id
export interface IPayloadGetAutomatedResponse {
  organization_id: string;
  onSuccess: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionGetAutomatedResponse {
  type: string;
  payload: IPayloadGetAutomatedResponse;
}

// POST Change Automated Response by oraganization id
export interface IDetailWorkingTime {
  type: EBusiness;
  start_time?: string;
  end_time?: string;
}
export interface IWorkingTimes {
  Monday: IDetailWorkingTime;
  Tuesday: IDetailWorkingTime;
  Wednesday: IDetailWorkingTime;
  Thursday: IDetailWorkingTime;
  Friday: IDetailWorkingTime;
  Saturday: IDetailWorkingTime;
  Sunday: IDetailWorkingTime;
}
export interface IChangeAutomatedResponseFormValues {
  organization_id: string;
  allow_editting_sub_org: boolean;
  working_times: IWorkingTimes;
  on_off: boolean;
  reporting_message: string;
  messager_message: string;
}
export interface IPayloadChangeAutomatedResponse {
  values: IChangeAutomatedResponseFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionChangeAutomatedResponse {
  type: string;
  payload: IPayloadChangeAutomatedResponse;
}

// List holiday
export interface IPayloadLoadingHoliday {
  organization_id: string;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionLoadingHoliday {
  type: string;
  payload: IPayloadLoadingHoliday;
}

// Create holiday
export enum ERepeatHoliday {
  NONE = 'NONE',
  YEARLY = 'YEARLY',
}
export interface ICreateHolidayFormValues {
  type: ERepeatHoliday;
  title: string;
  start_date: string;
  end_date: string;
  org_id: string;
}
export interface IPayloadCreateHoliday {
  values: ICreateHolidayFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionCreateHoliday {
  type: string;
  payload: IPayloadCreateHoliday;
}

// Update holiday
export interface IEditHolidayFormValues {
  type: ERepeatHoliday;
  title: string;
  start_date: string;
  end_date: string;
}
export interface IPayloadEditHoliday {
  values: IEditHolidayFormValues;
  holiday_id: string;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionEditHoliday {
  type: string;
  payload: IPayloadEditHoliday;
}

// Delete Holiday
export interface IPayloadDeleteHoliday {
  holiday_id: string;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionDeleteHoliday {
  type: string;
  payload: IPayloadDeleteHoliday;
}
