/* eslint-disable react-hooks/exhaustive-deps */
import { forgotPasswordAction, getProfileAction, updateProfileAction } from 'actions/auth';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Typography,
  Spin,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ModalConfirm } from 'components/modal-confirm';
import { showSuccess } from 'components/standby-notice';
import {
  IPayloadForgotPassword,
  IPayLoadUpdateUserProfile,
  IUpdateUserProfileFormValue,
  ELanguage,
} from 'pages/auth/interfaces';
import { memo, useEffect, useState } from 'react';
import { isEmail, updateLanguage } from 'utils';
import trim from 'lodash/trim';
import { translate } from 'libs';

const { Option } = Select;

const MyInformation = () => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const profile: any = useAppSelector((state) => state.auth.profile);
  const loading = useAppSelector((state) => state.auth.isLoading);
  const [visibleModalChangePassword, setVisibleModalChangePassword] = useState<boolean>(false);
  const [isVisibleConfirm, setIsVisibleConfirm] = useState<boolean>(false);

  const loadingProfile = () => {
    return dispatch(getProfileAction({}));
  };

  useEffect(() => {
    loadingProfile();
  }, []);

  useEffect(() => {
    form.setFieldsValue({ ...profile });
  }, [profile]);

  const handleVisibleConfirm = () => {
    if (form.getFieldsError().filter(({ errors }) => errors.length).length > 0) {
      return false;
    }
    setIsVisibleConfirm(true);
  };

  const handleCancelConfirm = () => setIsVisibleConfirm(false);

  const handleSave = () => form.submit();

  const onFinish = ({
    first_name,
    last_name,
    language,
    secondary_email,
    phone,
    country_code,
  }: IUpdateUserProfileFormValue) => {
    const payload: IPayLoadUpdateUserProfile = {
      values: {
        first_name: trim(first_name),
        last_name: trim(last_name),
        language,
        secondary_email,
        phone,
        country_code,
      },
      onSuccess: () => {
        loadingProfile();
        updateLanguage(language);

        handleCancelConfirm();

        showSuccess(translate('settingPage.myInfo.update.success'));
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(updateProfileAction(payload));
  };

  const onClickChangePassword = () => {
    setVisibleModalChangePassword(true);
  };

  const handleOkChangePassword = () => {
    const params: IPayloadForgotPassword = {
      values: {
        email: profile?.email,
      },
      onSuccess: () => {
        showSuccess(translate('settingPage.myInfo.message.success'));

        handleCancelChangePassword();
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(forgotPasswordAction(params));
  };

  const handleCancelChangePassword = () => {
    setVisibleModalChangePassword(false);
  };

  if (loading) {
    return <Spin spinning={loading} size="small" />;
  }

  return (
    <div className="my-info">
      <Form
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          ...profile,
          language: ELanguage.JAPANESE,
          country_code: profile?.country_code || '81',
          secondary_email: profile?.secondary_email || '',
        }}
        requiredMark={false}
        form={form}
      >
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label={translate('settingPage.myInfo.lastName')}
              name="last_name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: translate('settingPage.myInfo.lastName.required'),
                },
                () => ({
                  validator(_rule, value) {
                    if (!value) return Promise.resolve();

                    if (value.length < 257) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(translate('common.error.maxLength', { number: 256 }))
                    );
                  },
                }),
              ]}
            >
              <Input
                placeholder={translate('settingPage.myInfo.lastNameRequired')}
                size="large"
                maxLength={256}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={translate('settingPage.myInfo.firstName')}
              name="first_name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: translate('settingPage.myInfo.firstName.required'),
                },
                () => ({
                  validator(_rule, value) {
                    if (!value) return Promise.resolve();

                    if (value.length < 257) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(translate('common.error.maxLength', { number: 256 }))
                    );
                  },
                }),
              ]}
            >
              <Input
                placeholder={translate('settingPage.myInfo.firstNameRequired')}
                size="large"
                maxLength={256}
              />
            </Form.Item>
          </Col>

          <Col span={8} />

          <Col span={8}>
            <Form.Item
              label={translate('settingPage.myInfo.email')}
              name="email"
              rules={[
                { required: true, message: translate('loginPage.emailRequired') },
                () => ({
                  validator(_rule, value) {
                    if (!value) return Promise.reject();

                    const checkEmail: boolean = isEmail(value);

                    if (checkEmail) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(translate('loginPage.emailFormat')));
                  },
                }),
              ]}
            >
              <Input
                disabled
                placeholder={translate('loginPage.emailPlaceholder')}
                size="large"
                maxLength={256}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={translate('settingPage.myInfo.secondaryEmail')}
              name="secondary_email"
              rules={[
                () => ({
                  validator(_rule, value) {
                    if (!value) return Promise.resolve();

                    const checkEmail: boolean = isEmail(value);

                    if (checkEmail) {
                      return Promise.resolve();
                    }

                    return Promise.reject(new Error(translate('loginPage.emailFormat')));
                  },
                }),
              ]}
            >
              <Input
                placeholder={translate('loginPage.emailPlaceholder')}
                size="large"
                maxLength={256}
              />
            </Form.Item>
          </Col>

          <Col span={8} />

          <Col span={8}>
            <Form.Item
              rules={[
                () => ({
                  validator() {
                    const regexNumber = /^[0-9]+$/;

                    const isNumber = (value: string): boolean => {
                      if (!value) return true;

                      return regexNumber.test(value.toLowerCase());
                    };

                    const codeValue: string = form.getFieldValue('country_code');
                    const phoneValue: string = form.getFieldValue('phone');

                    const checkCode: boolean = isNumber(codeValue);
                    const checkPhone: boolean = isNumber(phoneValue);

                    if (!checkCode || !checkPhone) {
                      return Promise.reject(new Error(translate('settingPage.myInfo.phoneFormat')));
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
              name="hidden"
            >
              <Space className="space-input-phone">
                <Form.Item
                  name="country_code"
                  label={translate('settingPage.myInfo.phone.country_code')}
                >
                  <Input size="large" maxLength={256} />
                </Form.Item>
                <Form.Item name="phone" label={translate('settingPage.myInfo.phone')}>
                  <Input
                    placeholder={translate('settingPage.myInfo.phoneRequired')}
                    size="large"
                    maxLength={12}
                  />
                </Form.Item>
              </Space>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label={translate('settingPage.myInfo.language')}
              name="language"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: translate('settingPage.myInfo.languageRequired'),
                },
              ]}
            >
              <Select size="large" className="sl-language">
                {/* <Option value={ELanguage.ENGLISH}>
                  {t('settingPage.myInfo.language.english')}
                </Option> */}
                <Option value={ELanguage.JAPANESE}>
                  {translate('settingPage.myInfo.language.japanese')}
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Button className="btn-change-pw" size="large" onClick={onClickChangePassword}>
          {translate('settingPage.changePw.title')}
        </Button>

        <Divider />

        <Button type="primary" size="large" className="btn-save" onClick={handleVisibleConfirm}>
          {translate('settingPage.myInfo.saveBtn')}
        </Button>
      </Form>

      {/* Modal Change Password */}
      <Modal
        maskClosable={false}
        title={translate('settingPage.myInfo.changePw')}
        centered
        visible={visibleModalChangePassword}
        className="modal-change-pw"
        onCancel={handleCancelChangePassword}
        footer={[
          <Button
            key="back"
            type="primary"
            onClick={handleOkChangePassword}
            className="btn-ok"
            size="large"
          >
            {translate('common.ok')}
          </Button>,
          <Button
            key="close"
            onClick={handleCancelChangePassword}
            className="btn-close"
            size="large"
          >
            {translate('common.close')}
          </Button>,
        ]}
      >
        <Space size={20} direction="vertical">
          <Typography.Text className="txt-info-change-pw">
            {translate('settingPage.myInfo.changePwTitle')}
          </Typography.Text>
          <Typography.Text className="txt-info-change-pw">
            {translate('settingPage.myInfo.changePwConfirm')}
          </Typography.Text>
        </Space>
      </Modal>

      {/* Modal confirm save profile */}
      <ModalConfirm
        visible={isVisibleConfirm}
        handleOk={handleSave}
        width={625}
        handleCancel={handleCancelConfirm}
        title={translate('settingPage.myInfo.saveProfile.title')}
        textConfirm={translate('settingPage.myInfo.saveProfile.body')}
        textConfirmBtn={translate('settingPage.myInfo.saveProfile.okBtn')}
      />
    </div>
  );
};

export default memo(MyInformation);
