/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Layout, Space, Typography } from 'antd';
import { getTerms } from 'api/home';
import ViewPDF from 'components/view-pdf';
import { translate } from 'libs';

export enum ETypeMenuFooter {
  TERM = 'TERM',
  PRIVACY = 'PRIVACY',
  SUPPORT = 'URL_SUPPORT',
}

let modalTermVisible: any;

const Footer = () => {
  const handleClickMenu = (type: ETypeMenuFooter) => {
    switch (type) {
      case ETypeMenuFooter.TERM:
      case ETypeMenuFooter.PRIVACY:
        getTerms(type)
          .then((response: any) => {
            modalTermVisible?.({ visible: true, type, url: response?.data[0]?.url });
          })
          .catch((error: any) => {
            console.log('🚀 ~ error', error);
          });
        break;
      case ETypeMenuFooter.SUPPORT:
        getTerms(type)
          .then((response: any) => {
            window.open(response?.data, '_blank');
          })
          .catch((error: any) => {
            console.log('🚀 ~ error', error);
          });
        break;
      default:
        break;
    }
  };

  return (
    <Layout.Footer className="footer">
      <Space size={32}>
        <Typography.Text onClick={() => handleClickMenu(ETypeMenuFooter.TERM)}>
          {translate('menu.term_use')}
        </Typography.Text>
        <Typography.Text onClick={() => handleClickMenu(ETypeMenuFooter.PRIVACY)}>
          {translate('menu.privacy')}
        </Typography.Text>
        <Typography.Text onClick={() => handleClickMenu(ETypeMenuFooter.SUPPORT)}>
          {translate('menu.support')}
        </Typography.Text>
      </Space>
      <Space size={12}>
        <Typography.Text className="txt-version">
          © 2021 STANDBY. All rights reserved. v2.0.5
        </Typography.Text>
      </Space>

      {/* Modal View File PDF */}
      <ViewPDF onRef={(ref: any) => (modalTermVisible = ref)} />
    </Layout.Footer>
  );
};

export default React.memo(Footer);
