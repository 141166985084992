import API from 'libs/axios';
import { IPayloadGetListMessage } from 'pages/setting/interfaces/limit-message';

const PREFIX: string = '/admin/organizations/message';

export enum INCIDENT_ATTR_TYPES {
  BY_TOTAL = 'BY_TOTAL',
  BY_DATE = 'BY_DATE',
}
export const getSelectOption = () => API.get(`${PREFIX}/selected-option`);

export const UpdateLimited = (data) => API.put(`${PREFIX}/limit/settings`, data);

export const getLimited = (payload: IPayloadGetListMessage['query']) =>
  API.get(`${PREFIX}/limit/settings`, { params: payload });
  

