import API from 'libs/axios';
import {
  IChangeVisibilityFormValues,
  ICreateIncidentQuestionFormValues,
  IDeleteIncidentQuestionFormValues,
  IDragIncidentQuestionFormValues,
  IEditIncidentQuestionFormValues,
  IQueryLoadingIncidentQs,
} from 'pages/setting/interfaces';
import { queryString } from 'utils';

const PATH: string = 'admin/incident/questions';

export const getListIncidentQuestion = (query?: IQueryLoadingIncidentQs) => {
  const params: IQueryLoadingIncidentQs = {};

  if (query?.organization_id) {
    params.organization_id = query.organization_id;
  }
  params['iv'] = Date.now();
  const qs: string = queryString(params);

  return API.get(`${PATH}/list?${qs}`);
};

export const postDragIncidentQuestion = (payload: IDragIncidentQuestionFormValues) =>
  API.post(`${PATH}/order`, payload);
export const postCreateIncidentQuestion = (payload: ICreateIncidentQuestionFormValues) =>
  API.post(`${PATH}/action`, payload);
export const postEditIncidentQuestion = (payload: IEditIncidentQuestionFormValues) =>
  API.post(`${PATH}/action`, payload);
export const postDeleteIncidentQuestion = (payload: IDeleteIncidentQuestionFormValues) =>
  API.post(`${PATH}/action`, payload);
export const putVisibilityIncidentQuestion = (payload: IChangeVisibilityFormValues) =>
  API.put(`${PATH}/incident-question/visibility`, payload);
