import { CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { translate } from 'libs';
import './style.scss';

const showSuccess = (msg: string, duration = 1.5) => {
  message.success({
    content: (
      <div className="content">
        <div className="msg">{msg}</div>
        <div className="close-icon" onClick={() => message.destroy()}>
          <CloseOutlined style={{ color: '#70796c' }} />
        </div>
      </div>
    ),
    className: 'standby-notice-success',
    duration,
    onClick: () => message.destroy(),
  });
};

const showError = (msg: string, duration = 1.5) => {
  let errorMessage = msg;
  if (msg.includes('errors.code')) {
    errorMessage = translate(`error.common`);
  }
  message.error({
    content: (
      <div className="content">
        <div className="msg">{errorMessage}</div>
        <div className="close-icon" onClick={() => message.destroy()}>
          <CloseOutlined style={{ color: '#70796c' }} />
        </div>
      </div>
    ),
    className: 'standby-notice-error',
    duration,
    onClick: () => message.destroy(),
  });
};

export { showSuccess, showError };
