/* eslint-disable react-hooks/exhaustive-deps */
import { useAppDispatch, useAppSelector } from 'app/hooks';
import React, { useCallback, useEffect, useState, createRef, useMemo } from 'react';
import {
  EMultiActionOrganization,
  ETypeOrganization,
  IChangeNameOrg,
  ICreateOrganizationFormValues,
  IPayloadCreateOrganization,
  IPayloadHideMultiOrganization,
  IPayloadLoadingOrganization,
  IPayloadMultiActionOrganization,
  IPayloadStructureOrganization,
  IPayloadUpdateOrganization,
  IStatusMdHideDelete,
  IUpdateOrganizationFormValues,
} from '../../interfaces';
import {
  loadingOrganizationAction,
  updateOrganizationAction,
  createOrganizationAction,
  structureOrganizationAction,
  multiActionOrganization,
  changeSearchOrganizationAction,
  hiddenMultiOrganizationAction,
} from 'actions/organization';
import { loadingUserRoleAction } from 'actions/users';
import { Checkbox, Col, Input, Row, Typography, Spin } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import Tree from './components/tree/TreeComponent';
import CheckableAction from './components/CheckableAction';
import OGLevel from './components/OGLevel';
import FormCreate from './components/FormCreate';
import MoveOG from './components/Move';
import ModalHideAndDelete from './components/ModalHideAndDelete';
import trim from 'lodash/trim';
import { showError, showSuccess } from 'components/standby-notice';
import { getDetailOrganization } from 'api/organization';
import { INVALID_PARENT_OG } from 'utils';
import { translate } from 'libs';

const initStatusModalHide: IStatusMdHideDelete = {
  isDelete: false,
  isError: false,
  isHide: false,
  isLoading: false,
  isUnHide: false,
  errorCode: '',
};

const OrganizationSetupV2 = () => {
  const inputSearchRef: any = React.useRef();

  const dispatch = useAppDispatch();
  const organizations: any[] = useAppSelector((state) => state.organizations.organizations);
  const rootOrgId: string = React.useMemo(() => organizations?.[0]?._id, [organizations]);
  const keySearch: string = useAppSelector((state) => state.organizations.keySearch);
  const isShowHiddenOrganization: boolean = useAppSelector(
    (state) => state.organizations.isShowHiddenOrganization
  );
  const [isExpand, setExpand] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [isVisibleOGLevel, setIsVisibleOGLevel] = useState<boolean>(false);
  const [checkedKeys, setCheckedKeys] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]); // many items
  const [isVisibleFormCreate, setIsVisibleFormCreate] = useState<boolean>(false);
  const [isVisibleMovOG, setIsVisibleMovOG] = useState<boolean>(false);
  const [isVisibleModalHide, setIsVisibleModalHide] = useState<boolean>(false);

  const [statusMdHideDelete, setStatusMdHideDelete] =
    useState<IStatusMdHideDelete>(initStatusModalHide);

  const formRefOGLevel: any = createRef();
  const formRefFormCreate: any = createRef();

  const loadingOrganizations = useCallback(
    (searchName?: string) => {
      const payload: IPayloadLoadingOrganization = {
        query: {
          show_hidden: isShowHiddenOrganization,
        },
        onSuccess: () => {
          setLoading(false);
          setCheckedKeys([]);
        },
        onError: () => {
          setLoading(false);
        },
      };

      if (searchName && payload.query) {
        payload.query.name = searchName || '';
      }
      dispatch(loadingUserRoleAction({ query: {} }));
      return dispatch(loadingOrganizationAction(payload));
    },
    [isShowHiddenOrganization]
  );

  useEffect(() => {
    loadingOrganizations();
  }, []);

  const onSearch = (value: string) => {
    const valueSearch: string = trim(value) || '';

    dispatch(changeSearchOrganizationAction(valueSearch));
    loadingOrganizations(valueSearch);
  };

  const handleClickSearch = () => {
    const value: string = inputSearchRef?.current?.state?.value;

    if (!value) return;

    onSearch(value);
  };

  const handleChangeSeachOG = (event: any, isEnter = false) => {
    const { value } = event.target;
    if (!isEnter && value) return;

    onSearch(value);
  };

  const handleCheckExpand = () => {
    setExpand((pre) => !pre);
  };

  const handleMoveMultiple = () => {
    const newOrganizations = [...organizations];
    const newSelectedItems: any[] = [];

    newOrganizations.forEach((organization: any) => {
      if (Array.isArray(organization?.children)) {
        organization.children.forEach((school: any) => {
          if (Array.isArray(school?.children)) {
            school.children.forEach((grade: any) => {
              const selected = checkedKeys.map((item) => item._id).find((ele) => ele === grade._id);
              if (selected) {
                const arr = grade.path.split('/');
                arr.push(grade._id);

                newSelectedItems.push({
                  key: arr.filter((el) => !!el).join('-'),
                  _id: grade?._id,
                  name: grade?.name,
                  path: grade?.path,
                });
              }
            });
          }
        });
      }
    });

    setSelectedItems(newSelectedItems);
    setIsVisibleMovOG(true);
  };

  const handleHideMultiple = () => {
    setLoading(true);
    const payload: IPayloadHideMultiOrganization = {
      orgIds: checkedKeys.map((item) => item._id),
      onSuccess: () => {
        setLoading(true);
        setCheckedKeys([]);
        loadingOrganizations();
        showSuccess(
          `${checkedKeys.length > 1 ? checkedKeys.length : ''} ${translate(
            'settingPage.ogSetup.hideOgSuccess'
          )}`
        );
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
        setLoading(false);
        if (errorCode) {
          setStatusMdHideDelete({
            ...statusMdHideDelete,
            isUnHide: false,
            isError: true,
            isHide: true,
            errorCode: errorCode,
          });
          setIsVisibleModalHide(true);
        }
      },
    };

    return dispatch(hiddenMultiOrganizationAction(payload));
  };

  const showDetail = (item: any) => {
    setSelectedItem(item);
    setIsVisibleOGLevel(true);
  };

  const handleVisibleFormCreate = (item: any) => {
    setLoading(true);
    getDetailOrganization(rootOrgId)
      .then((res: any) => {
        setSelectedItem({ ...item, root: res?.data });

        setIsVisibleFormCreate(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClickSwap = (item: any) => {
    if (!item) return;
    setSelectedItems([]);
    setSelectedItem(item);
    setCheckedKeys([item]);
    setIsVisibleMovOG(true);
  };

  const onCancelOGLevel = () => {
    setIsVisibleOGLevel(false);
    setSelectedItem(null);
  };

  const onCancelFormCreate = () => {
    setIsVisibleFormCreate(false);
    setSelectedItem(null);
  };

  const handleCancelMoveOG = () => {
    setSelectedItems([]);
    setSelectedItem(null);
    setIsVisibleMovOG(false);
  };

  const handleCancelModalUnHide = () => {
    setLoading(false);
    setStatusMdHideDelete(initStatusModalHide);
    setIsVisibleModalHide(false);
    setSelectedItem(null);
  };

  const onFinishOGLevel = ({
    access_codes,
    name,
    home_message,
    incident_message,
    working_time,
    ...values
  }: IUpdateOrganizationFormValues) => {
    const payload: IPayloadUpdateOrganization = {
      values: {
        ...values,
        name: trim(name),
        home_message: trim(home_message),
        incident_message: trim(incident_message),
        working_time: trim(working_time),
        organization_id: selectedItem?._id,
      },
      onSuccess: () => {
        setLoading(true);
        loadingOrganizations();
        onCancelOGLevel();

        showSuccess(translate('settingPage.ogSetup.editOgSuccess'));
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(updateOrganizationAction(payload));
  };

  const onFinishFormCreate = ({
    access_codes,
    position,
    name,
    incident_message,
    working_time,
    home_message,
    ...values
  }: ICreateOrganizationFormValues) => {
    const newAccessCodes: string[] = [];

    if (Array.isArray(access_codes)) {
      access_codes.forEach((code: any) => {
        if (code?.isChecked) {
          newAccessCodes.push(code?.access_code);
        }
      });
    }

    const payload: IPayloadCreateOrganization = {
      values: {
        ...values,
        name: trim(name),
        home_message: trim(home_message),
        incident_message: trim(incident_message),
        working_time: trim(working_time),
        access_codes: newAccessCodes,
        parent_id: selectedItem?._id,
        type: values?.type ? values.type : ETypeOrganization.SINGLE,
      },
      onSuccess: () => {
        setLoading(true);
        loadingOrganizations();

        onCancelFormCreate();
        showSuccess(translate('settingPage.ogSetup.addOgSuccess'));
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(createOrganizationAction(payload));
  };

  const handleOptionsCascaderOG = useCallback(() => {
    if (Array.isArray(organizations)) {
      const options: any[] = organizations.map((organization) => {
        let newSchools: any[] = [];

        if (Array.isArray(organization?.childrens)) {
          newSchools = organization.childrens.map((child) => {
            return {
              value: `${organization?._id}-${child?._id}`,
              label: child?.name,
              type: child?.type,
              is_active: child?.is_active,
            };
          });
        }

        return {
          value: organization?._id,
          label: organization?.name,
          children: newSchools,
        };
      });

      return options;
    }

    return [];
  }, [organizations]);
  const optionsCascaderOG = useMemo(() => handleOptionsCascaderOG(), [organizations]);

  const handleMove = (to: string[]) => {
    setLoading(true);
    setIsVisibleMovOG(false);
    const newOrganizations = [...organizations];
    const targetFolder = to[1];

    const childrendIds: string[] = [];
    const parentId: string = targetFolder.split('-').pop() || '';

    newOrganizations.forEach((organization: any) => {
      if (organization.childrens && organization.childrens.length > 0) {
        organization.childrens.forEach((shool) => {
          if (shool.childrens && shool.childrens.length > 0) {
            shool.childrens.forEach((grade) => {
              const selected = checkedKeys.map((item) => item._id).find((id) => id === grade._id);
              if (selected) {
                childrendIds.push(grade._id);
              }
            });
          }
        });
      }
    });

    if (childrendIds.length === 0) return;
    const uniqueChildrendIds: string[] = Array.from(new Set(childrendIds));

    const payload: IPayloadStructureOrganization = {
      values: {
        children_ids: uniqueChildrendIds,
        parent_id: parentId,
      },
      onSuccess: () => {
        loadingOrganizations();
        setCheckedKeys([]);
        setSelectedItem(null);
        handleCancelMoveOG();
        showSuccess(translate('settingPage.ogSetup.moveOgSuccess'));
      },
      onError: (errorCode: string) => {
        switch (errorCode) {
          case INVALID_PARENT_OG:
            showError(translate('settingPage.ogSetup.invalidParentOg'));
            break;

          default:
            break;
        }
      },
    };

    return dispatch(structureOrganizationAction(payload));
  };

  const onConfirmModalHide = () => {
    const { isUnHide }: IStatusMdHideDelete = statusMdHideDelete;

    if (isUnHide) {
      setLoading(true);
      const payload: IPayloadMultiActionOrganization = {
        values: {
          organization_id: selectedItem?._id,
          action: EMultiActionOrganization.SHOW,
        },
        onSuccess: () => {
          setLoading(true);
          handleCancelModalUnHide();
          loadingOrganizations();

          showSuccess(translate('settingPage.ogSetup.unhideOgSuccess'));
        },
        onError: (errorCode: string) => {
          console.log('🚀 ~ errorCode', errorCode);
          handleCancelModalUnHide();
        },
      };

      return dispatch(multiActionOrganization(payload));
    }

    return handleCancelModalUnHide();
  };

  const handleChangeNameOG = ({ new_name, old_name, org_id }: IChangeNameOrg) => {
    if (new_name && new_name.trim() && new_name.trim() !== old_name.trim()) {
      const payload: IPayloadUpdateOrganization = {
        values: {
          organization_id: org_id || '',
          name: trim(new_name),
        },
        onSuccess: () => {
          setLoading(true);
          loadingOrganizations();

          showSuccess(translate('settingPage.ogSetup.renameOgSuccess'));
        },
        onError: (errorCode: string) => {
          console.log('🚀 ~ errorCode', errorCode);
        },
      };

      return dispatch(updateOrganizationAction(payload));
    }
  };

  const handleStructure = (children_ids = [], parent_id) => {
    if (!parent_id || children_ids.length === 0) return null;
    const payload: IPayloadStructureOrganization = {
      values: {
        children_ids: children_ids,
        parent_id: parent_id,
      },
      onSuccess: () => {
        setLoading(true);
        loadingOrganizations();
      },
    };

    return dispatch(structureOrganizationAction(payload));
  };

  const handleVisibleUnHide = (item) => {
    setSelectedItem(item);
    setStatusMdHideDelete({ ...statusMdHideDelete, isUnHide: true });
    setIsVisibleModalHide(true);
  };

  return (
    <Spin size="small" spinning={isLoading}>
      <div className="standby-org">
        <Row gutter={[16, 16]} align="middle" className="head">
          <Col span={8}>
            <Input
              placeholder={translate('settingPage.ogSetup.searchPlaceholder')}
              prefix={<SearchOutlined onClick={handleClickSearch} />}
              className="search-input"
              size="large"
              allowClear
              defaultValue={keySearch}
              maxLength={256}
              onChange={(event: any) => handleChangeSeachOG(event)}
              onPressEnter={(event: any) => handleChangeSeachOG(event, true)}
              ref={inputSearchRef}
            />
          </Col>
          <Col span={10}>
            <Checkbox onClick={handleCheckExpand} checked={isExpand} className="cb-expand">
              {translate('settingPage.ogSetup.expandOg')}
            </Checkbox>
          </Col>
          <Col span={6}>
            <Typography.Text
              className="txt-access-code txt-access-code-ct"
              style={{ marginLeft: '6.2vw' }}
            >
              {translate('settingPage.ogSetup.accessCode')}
            </Typography.Text>
          </Col>
        </Row>
        {checkedKeys.length > 0 && (
          <CheckableAction
            selected={checkedKeys.length}
            handleMove={handleMoveMultiple}
            handleHide={handleHideMultiple}
            isLoading={isLoading}
            disabledMove={
              checkedKeys.length > 0 && !checkedKeys.find((item) => item.path.split('/').length > 2)
            }
          />
        )}
        {organizations[0] ? (
          <Tree
            isExpand={isExpand}
            rootOrgData={organizations[0]}
            showDetail={showDetail}
            handleVisibleFormCreate={handleVisibleFormCreate}
            handleClickSwap={handleClickSwap}
            selectedTree={setCheckedKeys}
            handleChangeNameOG={handleChangeNameOG}
            handleStructure={handleStructure}
            handleVisibleUnHide={handleVisibleUnHide}
          />
        ) : (
          <div style={{ textAlign: 'center', padding: 15 }}>
            {translate('no_data', { name: translate('settingPage.ogSetup.searchPlaceholder') })}
          </div>
        )}
      </div>

      {/* Modal Form OG Level And Modal Form Detail OG */}

      <OGLevel
        formRef={formRefOGLevel}
        onFinish={onFinishOGLevel}
        isVisible={isVisibleOGLevel}
        selectedItem={selectedItem}
        onCancel={onCancelOGLevel}
        loadingOrganizations={loadingOrganizations}
      />

      {/* Modal Form Create OG */}

      <FormCreate
        formRef={formRefFormCreate}
        onFinish={onFinishFormCreate}
        isVisible={isVisibleFormCreate}
        onCancel={onCancelFormCreate}
        selectedItem={selectedItem}
        optionsCascaderOG={optionsCascaderOG}
      />

      {/* Modal Move OG */}
      <MoveOG
        isVisible={isVisibleMovOG}
        onCancel={handleCancelMoveOG}
        optionsCascaderOG={optionsCascaderOG}
        handleMove={handleMove}
        selectedItem={selectedItem}
        isLoading={isLoading}
        selectedItems={selectedItems}
      />

      {/* Modal unhide organization */}
      <ModalHideAndDelete
        isVisible={isVisibleModalHide}
        onConfirm={onConfirmModalHide}
        onCancel={handleCancelModalUnHide}
        isLoading={isLoading}
        isUnHide={statusMdHideDelete.isUnHide}
        isHide={statusMdHideDelete.isHide}
        isError={statusMdHideDelete.isError}
        errorCode={statusMdHideDelete.errorCode}
      />
    </Spin>
  );
};

export default OrganizationSetupV2;
