function insertUrlParam(key: string, value: string) {
  if (window.history.replaceState) {
    let searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, value);
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();

    window.history.replaceState({ path: newurl }, '', newurl);
  }
}

function replaceNewUrl(searchParams: URLSearchParams) {
  if (window.history.replaceState) {
    let newurl =
      window.location.protocol +
      '//' +
      window.location.host +
      window.location.pathname +
      '?' +
      searchParams.toString();
    window.history.replaceState({ path: newurl }, '', newurl);
  }
}

function removeUrlParameter(paramKey: string) {
  if (window.history.replaceState) {
    const url = window.location.href;
    let r = new URL(url);
    r.searchParams.delete(paramKey);
    const newUrl = r.href;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  }
}

function getUrlParameter(key: string) {
  const url = new URL(window.location.href);
  return url.searchParams.get(key);
}

export { insertUrlParam, removeUrlParameter, getUrlParameter, replaceNewUrl };
