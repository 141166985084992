import { IOptInData } from 'pages/setting/interfaces';

export interface ILoginFormValues {
  email: string;
  password: string;

  session?: string;
  challenger_name?: string;
  challenger_answer?: string;
}

export interface ITwoFactorFormValues {
  code: string;
}

export interface IPopupOk {
  title: string;
  description?: string | string[];
  icon: string;
  classIcon?: string;
  isReturnLogin?: boolean;
  isDeactiveForm?: boolean;
  handleOk?: () => void;
}

export interface IForgotPasswordProps {
  isCreateNewPassword?: boolean;
}

export interface IForgotPasswordFormValues {
  email?: string;
  token?: string;
  new_password?: string;
  confirm_password?: string;
  is_create?: boolean;
}

export interface ILock60MinsProps {
  returnToLogin: () => void;
  mins: number;
}

/**
 * Action
 */

export interface IPayloadLogin {
  values: ILoginFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string, data: any) => void;
}

export interface IActionLogin {
  payload: IPayloadLogin;
  type: string;
}

export interface IPayloadForgotPassword {
  values: IForgotPasswordFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}
export interface IActionForgotPassword {
  payload: IPayloadForgotPassword;
  type: string;
}

export interface IPayloadResendCodeTfa {
  session: string;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}

export interface IActionResendCodeTfa {
  payload: IPayloadResendCodeTfa;
  type: string;
}

export interface IAuthSelector {
  isLoggedIn: boolean;
  isLoading: boolean;
  profile: any;
  role: string;
  permissionOrganizations: any[];
  showHidden: boolean;
  isLoadingPermission: boolean;
  roles: any[];
}

export interface IIncidentAttrSelector {
  listAttrs: any;
}

export enum EPopUpTypeLogin {
  LOCK = 'LOCK',
  DEACTIVE = 'DEACTIVE',
  BLOCK_IP = 'BLOCK_IP',
  NO_PERMISSION = 'NO_PERMISSION',
  EXPIRED = 'EXPIRED',
  MAINTANCE = 'MAINTANCE',
}

// Refresh token

export interface IPayloadRefreshToken {
  refresh_token: string;
}
export interface IActionRefreshToken {
  type: string;
  payload: IPayloadRefreshToken;
}

// Get User Profile

export interface IPayloadGetProfile {
  onSuccess?: (res?: any) => any;
  onError?: (errorCode: string) => void;
}

export interface IActionGetProfile {
  payload: IPayloadGetProfile;
  type: string;
}

// Get OPT in

export interface IPayloadGetOptIns {
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}

export interface IActionGetOptIns {
  payload: IPayloadGetOptIns;
  type: string;
}
// Update My Opt Ins
export interface IMyOptInsFormValues {
  admin_user_id?: string;
  optin_data: IOptInData[];
}
export interface IPayloadUpdateMyOptIns {
  values: IMyOptInsFormValues;
  onSuccess?: () => void;
  onError?: (errorCode: string) => void;
}

export interface IActionUpdateMyOptIns {
  type: string;
  payload: IPayloadUpdateMyOptIns;
}

// Update User Profile
export enum ELanguage {
  ENGLISH = 'en',
  JAPANESE = 'ja',
}

export interface IUpdateUserProfileFormValue {
  first_name: string;
  last_name: string;
  language: ELanguage;
  secondary_email: string;
  country_code: string;
  phone: string;

  // optional
  hidden?: any;
}

export interface IPayLoadUpdateUserProfile {
  values: IUpdateUserProfileFormValue;
  onSuccess?: () => void;
  onError?: (errorCode: string, data?: any) => void;
}

export interface IActionUpdateUserProfile {
  type: string;
  payload: IPayLoadUpdateUserProfile;
}

// Get User Role Info

export interface IPayloadGetUserRole {
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}

export interface IActionGetUserRole {
  payload: IPayloadGetUserRole;
  type: string;
}
