import {
  LOADDING_SOS_LIST,
  LOADDING_SOS_LIST_ERROR,
  LOADDING_SOS_LIST_SUCCESS,
  CREATE_SOS,
  CREATE_SOS_SUCCESS,
  CREATE_SOS_FAILED,
  DELETE_SOS,
  DELETE_SOS_SUCCESS,
  DELETE_SOS_FAILED,
  UPDATE_SOS,
  UPDATE_SOS_SUCCESS,
  UPDATE_SOS_FAILED,
  ORDER_SOS,
  ORDER_SOS_SUCCESS,
} from 'actions/sos';
import { ISosSelector } from 'pages/setting/interfaces';
import { ActionProps } from 'reducers';

const initialState: ISosSelector = {
  listSOS: [],
  isLoading: false,
};

export const SosReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case LOADDING_SOS_LIST:
    case CREATE_SOS:
    case DELETE_SOS:
    case UPDATE_SOS:
    case ORDER_SOS:
      return {
        ...state,
        isLoading: true,
      };
    case LOADDING_SOS_LIST_ERROR:
      return {
        ...state,
        listSOS: [],
        isLoading: false,
      };
    case LOADDING_SOS_LIST_SUCCESS: {
      return {
        ...state,
        listSOS: action.payload,
        isLoading: false,
      };
    }
    case DELETE_SOS_SUCCESS:
    case DELETE_SOS_FAILED:
    case UPDATE_SOS_SUCCESS:
    case UPDATE_SOS_FAILED:
    case CREATE_SOS_SUCCESS:
    case CREATE_SOS_FAILED:
      return {
        ...state,
        isLoading: false,
      };

    case ORDER_SOS_SUCCESS: {
      const dataSOS: any[] = Array.isArray(action.payload)
        ? action.payload.map((SOS, index) => {
            return {
              ...SOS,
              index: index,
              key: `${index}`,
            };
          })
        : [];
      return {
        ...state,
        listSOS: dataSOS,
        isLoading: false,
      };
    }
    default:
      return state;
  }
};
