/* eslint-disable react-hooks/exhaustive-deps */
import { createNotesIncidentsAction, loadingNotesIncidentsAction } from 'actions/incidents';
import { Form, Input, Button, Typography, Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  ICreateNotesIncidentsFormValues,
  IPayloadCreateNotesIncidents,
  IPayloadLoadingNotesIncidents,
  IResponseIncidentDetail,
} from 'pages/incidents/interfaces';
import React from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroll-component';
import { translate } from 'libs';

export enum EStatus {
  UNREAD = 1,
  UNANSWERED = 2,
  CLOSED = 3,
}
interface INoteProps {
  data: IResponseIncidentDetail | null;
  currentRole: string;
}
const Note = ({ data, currentRole }: INoteProps) => {
  const { id }: any = useParams();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const isLoading: boolean = useAppSelector((state) => state.incidents.isLoading);
  const [notes, setNotes] = React.useState<any[]>([]);

  const loadingNotes = React.useCallback(() => {
    const payload: IPayloadLoadingNotesIncidents = {
      incident_id: id,
      onSuccess: (data: any) => {
        if (Array.isArray(data)) {
          setNotes(data.reverse());
        }
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(loadingNotesIncidentsAction(payload));
  }, []);

  React.useEffect(() => {
    loadingNotes();
  }, []);

  const onFinish = ({ content }: ICreateNotesIncidentsFormValues) => {
    const payload: IPayloadCreateNotesIncidents = {
      values: {
        content,
        incident_id: id,
      },
      onSuccess: () => {
        form.resetFields();
        loadingNotes();
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(createNotesIncidentsAction(payload));
  };
  const fetchMoreData = () => {};
  return (
    <Spin spinning={isLoading}>
      <div className="note">
        <div className="note-list">
          <InfiniteScroll
            dataLength={notes?.length}
            next={fetchMoreData}
            hasMore={false}
            loader={<Spin />}
            scrollableTarget="scrollableDiv"
            inverse
            className="inifinite-scroll space"
          >
            {/* <Space size={16} className="space"> */}
            {Array.isArray(notes) &&
              notes.length > 0 &&
              notes.map((note) => {
                return (
                  <div className="note-list-item" key={note?._id}>
                    <div className="header-item">
                      <Typography.Title className="title" style={{ wordBreak: 'break-all' }}>
                        {`${note?.creator_id?.last_name || ''} ${
                          note?.creator_id?.first_name || ''
                        }`}
                      </Typography.Title>
                      <Typography.Text className="text" style={{ minWidth: 110 }}>
                        {moment(note?.createdAt).format('YYYY/MM/DD HH:mm')}
                      </Typography.Text>
                    </div>
                    <div className="question-text">{note?.content}</div>
                  </div>
                );
              })}
            {/* </Space> */}
          </InfiniteScroll>
        </div>
        {data?.status?.code !== EStatus.CLOSED && currentRole !== 'RO' && (
          <Form
            className="form-chat"
            onFinish={onFinish}
            initialValues={{ content: '' }}
            form={form}
          >
            <Form.Item name="content">
              <Input.TextArea
                className="item-textarea"
                placeholder={translate('incidents.detail.note.writeNote')}
                autoSize={{ minRows: 3, maxRows: 5 }}
                maxLength={12000}
              />
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(p, n) => p.content !== n.content}>
              {({ getFieldValue }) => {
                const content: string = getFieldValue('content');

                return (
                  <Form.Item className="item-btn">
                    <Button
                      className="btn-send"
                      disabled={!content}
                      size="large"
                      type="primary"
                      onClick={() => form.submit()}
                      loading={isLoading}
                    >
                      {translate('incidents.detail.note.saveBtn')}
                    </Button>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Form>
        )}
      </div>
    </Spin>
  );
};

export default React.memo(Note);
