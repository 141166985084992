import { useAppSelector } from 'app/hooks';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

const PrivateRouter = (props: RouteProps) => {
  const isLoggedIn: boolean = useAppSelector((state) => state.auth.isLoggedIn);

  const { pathname } = useLocation();

  /**
   * For page share incident without layout header and footer
   */
  if (pathname === '/admin/share-incident') {
    return <Route {...props} />;
  }

  /**
   * For private layout with header and footer
   */

  const ignorePath: string[] = [
    '/auth/two-factor',
    '/auth/new-password',
    '/admin/block-ip',
    '/admin/user-inactive',
    '/admin/no-permission',
    '/system/maintenance',
    '/admin/link-expired',
  ];

  const canGoRouter: boolean = !isLoggedIn && ignorePath.includes(pathname);

  if (canGoRouter) return <Route {...props} />;

  if (!isLoggedIn) {
    return <Redirect to="/auth/login" />;
  }

  return <Route {...props} />;
};

export { PrivateRouter };
