import i18n from 'config/i18n';
import { ELanguage } from 'pages/auth/interfaces';
import { storage } from 'utils';

const updateLanguage = (lang: ELanguage) => {
  i18n.changeLanguage(lang as string);
  storage.setLocale(lang);
};

export { updateLanguage };
