export enum INCIDENT_ATTR_TYPES {
  TYPE = 'TYPE',
  LABEL = 'LABEL',
  ACTION = 'ACTION',
  STATUS = 'STATUS',
  PRIORITY = 'PRIORITY',
  TARGETS = 'targets',
  PERPETRATORS = 'perpetrators',
}

export enum IIncidentAttrType {
  TYPE = 'TYPE',
  LABEL = 'LABEL',
  ACTION = 'ACTION',
  STATUS = 'STATUS',
  PRIORITY = 'PRIORITY',
}

export interface IElementIncidentType {
  editable?: boolean;
  family?: {
    code: string;
    title: string;
  };
  name?: string;
  type?: IIncidentAttrType;
  _id?: string;
  color?: string;
  org_id?: string;
  action?: string | null;
  id?: string | null;
  organization_id?: string | null;
}

export interface IDataBodyOrder {
  type: INCIDENT_ATTR_TYPES;
  organization_id?: string;
  orders: (string | undefined)[];
}

// list attr by org
export interface IPayloadGetListIncidentAttrByOrg {
  query: {
    organization_id?: string;
    type: INCIDENT_ATTR_TYPES;
  };
  onSuccess?: (data: any) => void;
  onError?: () => void;
}

export interface IActionGetListAttrByOrg {
  type: string;
  payload: IPayloadGetListIncidentAttrByOrg;
}

// CRUD attr
export interface IPayloadActionCRUDIncidentAttrByOrg {
  data: IElementIncidentType;
  onSuccess?: () => void;
  onError?: (errorCode: string, data?: any) => void;
}

export interface IActionCRUDIncidentAttrByOrg {
  type: string;
  payload: IPayloadActionCRUDIncidentAttrByOrg;
}

// order attr
export interface IPayloadOrderIncidentAttr {
  data: IDataBodyOrder;
  onSuccess?: () => void;
  onError?: (errorCode: string, data?: any) => void;
}

export interface IActionOrderIncidentAttr {
  type: string;
  payload: IPayloadOrderIncidentAttr;
}
