import API from 'libs/axios';
import {
  IForgotPasswordFormValues,
  ILoginFormValues,
  IMyOptInsFormValues,
  IPayloadRefreshToken,
  IPayloadResendCodeTfa,
  IUpdateUserProfileFormValue,
} from 'pages/auth/interfaces';

// AUTH

export const postLogin = (payload: ILoginFormValues) => API.post('admin/login', payload);
export const postForgotPassword = (payload: IForgotPasswordFormValues) =>
  API.post('admin/forgot_password', { email: payload?.email });
export const postNewPassword = (payload: IForgotPasswordFormValues) =>
  API.post('admin/set_password', payload);
export const postResendCodeTfa = ({ session }: IPayloadResendCodeTfa) =>
  API.post('admin/resend_tfa_code', { session });
export const postRefreshToken = (payload: IPayloadRefreshToken) =>
  API.post('admin/refresh_token', payload);

// PROFILE

export const getUserProfile = () => API.get(`admin/users/profile?iv=${Date.now()}`);
export const putUpdateUserProfile = (payload: IUpdateUserProfileFormValue) =>
  API.put('admin/users/profile', payload);
export const getUserRole = () => API.get(`admin/users/permission_infor?iv=${Date.now()}`);
export const getUserOptIns = () => API.get(`admin/users/optin_infor?iv=${Date.now()}`);
export const postUpdateMyOptIns = (payload: IMyOptInsFormValues) =>
  API.post(`admin/users/optin_infor`, payload);
