import { Button, Typography } from 'antd';
import { translate } from 'libs';
import { memo } from 'react';
import { IPopupOk } from '../interfaces';

const PopupOk = ({
  title,
  description,
  icon,
  classIcon,
  isReturnLogin,
  isDeactiveForm,
  handleOk,
}: IPopupOk) => {
  const newDescription = description && Array.isArray(description) ? description : [description];

  return (
    <div className={`popup-ok ${isDeactiveForm && 'deactive'}`}>
      <div className={`popup-icon ${classIcon}`}>
        <img src={icon} alt="" />
      </div>

      <div className={`popup-info ${isDeactiveForm && 'deactive-info'}`}>
        <Typography.Text className="txt-title">{title}</Typography.Text>
      </div>

      {newDescription &&
        newDescription.map((desc, index) => (
          <div className="popup-description" key={`description-${index}`}>
            <Typography.Text className="txt-description">{desc}</Typography.Text>
          </div>
        ))}

      <Button type="primary" block size="large" className="btn-ok" onClick={handleOk}>
        {isReturnLogin ? translate('loginPage.returnToLogin') : translate('common.ok2')}
      </Button>
    </div>
  );
};

export default memo(PopupOk);
