import {
  ETypeNoticeResponse,
  IActionLoadingCountNotifications,
  IActionLoadingNotifications,
  IActionNoticeResponse,
  IActionReadNotification,
  IActionViewListNotifications,
  IPayloadLoadingCountNotifications,
  IPayloadLoadingNotifications,
  IPayloadReadNotification,
  IPayloadViewListNotifications,
} from 'pages/notifications/interfaces';

export const SET_TOTAL_NOTIFICATION = 'SET_TOTAL_NOTIFICATION';

export const LOADING_NOTIFICATIONS = 'LOADING_NOTIFICATIONS';
export const LOADING_NOTIFICATIONS_SUCCESS = 'LOADING_NOTIFICATIONS_SUCCESS';
export const LOADING_NOTIFICATIONS_FAILED = 'LOADING_NOTIFICATIONS_FAILED';

export const LOADING_COUNT_NOTIFICATIONS = 'LOADING_COUNT_NOTIFICATIONS';
export const LOADING_COUNT_NOTIFICATIONS_SUCCESS = 'LOADING_COUNT_NOTIFICATIONS_SUCCESS';
export const LOADING_COUNT_NOTIFICATIONS_FAILED = 'LOADING_COUNT_NOTIFICATIONS_FAILED';

export const VIEW_LIST_NOTIFICATIONS = 'VIEW_LIST_NOTIFICATIONS';
export const VIEW_LIST_NOTIFICATIONS_SUCCESS = 'VIEW_LIST_NOTIFICATIONS_SUCCESS';
export const VIEW_LIST_NOTIFICATIONS_FAILED = 'VIEW_LIST_NOTIFICATIONS_FAILED';

export const READ_NOTIFICATION = 'READ_NOTIFICATION';

export const VISIBLE_MODAL_NOTICE_DETAIL = 'VISIBLE_MODAL_NOTICE_DETAIL';
export const CANCEL_MODAL_NOTICE_DETAIL = 'CANCEL_MODAL_NOTICE_DETAIL';

export const VISIBLE_MODAL_SYSTEM_ALERT = 'VISIBLE_MODAL_SYSTEM_ALERT';
export const CANCEL_MODAL_SYSTEM_ALERT = 'CANCEL_MODAL_SYSTEM_ALERT';

export const VISIBLE_MODAL_NOTICE_RESPONSE = 'VISIBLE_MODAL_NOTICE_RESPONSE';
export const CANCEL_MODAL_NOTICE_RESPONSE = 'CANCEL_MODAL_NOTICE_RESPONSE';

export const loadingNotificationsAction = (
  payload: IPayloadLoadingNotifications
): IActionLoadingNotifications => ({
  type: LOADING_NOTIFICATIONS,
  payload,
});

export const loadingCountNotificationsAction = (
  payload: IPayloadLoadingCountNotifications
): IActionLoadingCountNotifications => ({
  type: LOADING_COUNT_NOTIFICATIONS,
  payload,
});

export const loadingViewListNotificationsAction = (
  payload: IPayloadViewListNotifications
): IActionViewListNotifications => ({
  type: VIEW_LIST_NOTIFICATIONS,
  payload,
});

export const readNotificationAction = (
  payload: IPayloadReadNotification
): IActionReadNotification => ({
  type: READ_NOTIFICATION,
  payload,
});

/**
 * Visible modal
 */

export const visibleNoticeDetailAction = (): { type: string } => ({
  type: VISIBLE_MODAL_NOTICE_DETAIL,
});
export const cancelNoticeDetailAction = (): { type: string } => ({
  type: CANCEL_MODAL_NOTICE_DETAIL,
});

export const visibleSystemAlertAction = (): { type: string } => ({
  type: VISIBLE_MODAL_SYSTEM_ALERT,
});
export const cancelSystemAlertAction = (): { type: string } => ({
  type: CANCEL_MODAL_SYSTEM_ALERT,
});

export const visibleNoticeResponseAction = (
  payload: ETypeNoticeResponse
): IActionNoticeResponse => ({
  type: VISIBLE_MODAL_NOTICE_RESPONSE,
  payload,
});
export const cancelNoticeResponseAction = (): { type: string } => ({
  type: CANCEL_MODAL_NOTICE_RESPONSE,
});
