/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback } from 'react';
import { Form, DatePicker, Row, Col, Dropdown, Select, Button, Typography, Table } from 'antd';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { Organizations } from 'components/common';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { apiGetListIncidentAttrByOrg, INCIDENT_ATTR_TYPES } from 'api/incident-attr';
import { IPayloadGetListIncidentAttrByOrg } from 'pages/setting/interfaces';
import { IExportBulkIncidentsFormValues, IQueryBulkIncidents } from 'pages/reports/interfaces';
import { API_URL } from 'config';
import { storage } from 'utils';
import uniqBy from 'lodash/uniqBy';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { ReloadOutlined, CloseCircleFilled } from '@ant-design/icons';
import { translate } from 'libs';
import { createBulkIncident, loadingBulkIncident } from 'actions/reports';
import { IQueryDownloadImage } from 'pages/incidents/interfaces';
import { apiDownloadImage } from 'api/incidents';
import { IPayloadCreateReportBulkIncident } from 'pages/reports/interfaces';
//
const { RangePicker } = DatePicker;
const { Option } = Select;

const BulkIncidents = () => {
  const { i18n } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const initOptionOrganization: any[] = [
    { label: translate('settingPage.adminUser.allCharge'), value: 'all' },
  ];
  const initOptionStatus: any[] = [
    { label: translate('reports.bulkIncidents.status.placeholder'), value: '' },
  ];

  const showHidden: boolean = useAppSelector(
    (state) => state.organizations.isShowHiddenOrganization
  );
  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );

  const [dataExportIncident, setDataExportIncident] = React.useState<any>([]);
  const [loadingDateExport, setLoadingDateExport] = React.useState<boolean>(false);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [totalDocs, setTotalDocs] = React.useState<number>(1);
  const [currentPage, setCurrentPage] = React.useState<number>(1);

  const [isVisibleSelectOrg, setIsVisibleSelectOrg] = React.useState<boolean>(false);
  const [optionsOrganizations, setOptionsOrganizations] =
    React.useState<any[]>(initOptionOrganization);
  const [optionsStatus, setOptionsStatus] = React.useState<any[]>(initOptionStatus);

  const loadingStatusByOrg = React.useCallback(async (organization_id: string = '') => {
    const query: IPayloadGetListIncidentAttrByOrg['query'] = {
      organization_id,
      type: INCIDENT_ATTR_TYPES.STATUS,
    };

    const getListStatus = await apiGetListIncidentAttrByOrg(query);

    if (Array.isArray(getListStatus?.data?.values)) {
      const newOptionsStatus: any[] = getListStatus.data.values.map((value: any) => {
        return {
          value: value?.name,
          label: value?.name,
        };
      });

      const uniqOptions: any[] = uniqBy(newOptionsStatus, 'value');

      setOptionsStatus([...initOptionStatus, ...uniqOptions]);
    }
  }, []);

  React.useEffect(() => {
    loadData(1);
    loadingStatusByOrg();
  }, []);

  const handleChangePage = (page) => {
    loadData(page);
  };

  const loadData = useCallback((pageNumber = 1) => {
    setLoadingDateExport(true);
    const payload = {
      query: {
        page: pageNumber,
        page_size: pageSize,
      },
      onSuccess: (data: any) => {
        setDataExportIncident(data?.data?.docs);
        setTotalDocs(data?.data?.totalDocs);
        setCurrentPage(data?.data?.page);
        setLoadingDateExport(false);
      },
      onError: () => {
        console.log('onError');
        setLoadingDateExport(false);
      },
    };
    return dispatch(loadingBulkIncident(payload));
  }, []);

  const extraRanges = (): any => {
    return {
      [translate('reports.bulkIncidents.today')]: [moment(), moment()],
      [translate('reports.bulkIncidents.yesterday')]: [
        moment().subtract(1, 'days'),
        moment().subtract(1, 'days'),
      ],
      [translate('reports.bulkIncidents.last7days')]: [moment().subtract(7, 'days'), moment()],
      [translate('reports.bulkIncidents.last30days')]: [moment().subtract(30, 'days'), moment()],
      [translate('reports.bulkIncidents.thisMonth')]: [
        moment().startOf('month'),
        moment().endOf('month'),
      ],
      [translate('reports.bulkIncidents.lastMonth')]: [
        moment().subtract(1, 'months').startOf('month'),
        moment().subtract(1, 'months').endOf('month'),
      ],
      [translate('reports.bulkIncidents.onceYear')]: [
        moment().startOf('year'),
        moment().endOf('year'),
      ],
    };
  };

  const reloadDataExport = () => {
    loadData();
  };
  const handleVisibleOrg = (visible: boolean) => {
    setIsVisibleSelectOrg(visible);
  };

  const handleChangeOrganization = (value: string) => {
    if (!value) {
      form.setFieldsValue({ org_id: '', status: '' });
    }
  };

  const handleChangeStatus = (value: string) => {
    if (!value) {
      form.setFieldsValue({ status: '' });
    }
  };

  const renderStatus = () => {
    return {
      processing: translate('bulk_incident.status.processing'),
      success: translate('bulk_incident.status.success'),
      failed: translate('bulk_incident.status.failed'),
      deleted: translate('bulk_incident.status.deleted'),
      new: translate('bulk_incident.status.new'),
    };
  };

  const renderColumns = (): any[] => {
    // const {t  } = this.props;
    const columns = [
      {
        title: translate('data_report.import_incidents.list.id'),
        dataIndex: '_id',
        width: 200,
        render: (v, record) => (
          <div>
            <div>{`${v}`}</div>
          </div>
        ),
      },
      {
        title: translate('bulk_incident.label.date_time'),
        dataIndex: 'metadata',
        width: 200,
        render: (v) => (
          <div>
            {moment(v?.from_date).format('YYYY/MM/DD')} - {moment(v?.to_date).format('YYYY/MM/DD')}
          </div>
        ),
      },
      {
        title: translate('account.management.table.column.account_id'),
        dataIndex: 'metadata',
        width: 200,
        render: (v) => <div>{v?.org_name || ''}</div>,
      },
      {
        title: translate('bulk_incident.status.title'),
        dataIndex: 'status',
        width: 200,
        render: (v) => <div>{renderStatus()[v]}</div>,
      },
      {
        title: translate('bulk_incident.original_file_name'),
        dataIndex: 'original_file_name',
        width: 200,
        render: (v, record) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a onClick={() => onDownload(record?.file_key, v)}>{v}</a>
        ),
      },
      {
        title: translate('bulk_incident.start_date'),
        dataIndex: 'start_date',
        width: 200,
        render: (v) => <div>{v ? moment(v).format('YYYY/MM/DD HH:mm') : ''}</div>,
      },
      {
        title: translate('bulk_incident.end_date'),
        dataIndex: 'end_date',
        width: 200,
        render: (v) => <div>{v ? moment(v).format('YYYY/MM/DD HH:mm') : ''}</div>,
      },
      {
        title: translate('content.management.article.column.created_at'),
        dataIndex: 'createdAt',
        width: 200,
        render: (v) => <div>{moment(v).format('YYYY/MM/DD HH:mm')}</div>,
      },
    ];
    return columns;
  };

  const onSelectedOrganization = React.useCallback(
    (selectedItem: any) => {
      handleVisibleOrg(false);
      if (selectedItem) {
        const { key } = selectedItem;
        let organizationName: string = '';
        let organizationValue: string = '';
        if (Array.isArray(permissionOrganizations)) {
          const [orgId, schoolId, gradeId] = key.split('-');
          if (orgId) {
            const findOrg: any = permissionOrganizations.find((item: any) => item._id === orgId);
            if (findOrg) {
              organizationName = findOrg.name;
              organizationValue = findOrg._id;

              if (schoolId && Array.isArray(findOrg?.children)) {
                const findSchool = findOrg.children.find((item: any) => item._id === schoolId);

                if (findSchool) {
                  organizationName = `${findOrg.name} > ${findSchool.name}`;
                  organizationValue = findSchool._id;

                  if (gradeId && Array.isArray(findSchool?.children)) {
                    const findGrade = findSchool.children.find((item: any) => item._id === gradeId);

                    if (findGrade) {
                      organizationName = `${findOrg.name} > ${findSchool.name} > ${findGrade.name}`;
                      organizationValue = findGrade._id;
                    }
                  }
                }
              }
            }
          }
        }

        setOptionsOrganizations([
          { label: organizationName, value: organizationValue },
          ...optionsOrganizations,
        ]);

        /**
         * Has value org_id, will call api list @status by @org_id
         * Default set status to - All Status - if change organization
         */

        form.setFieldsValue({ org_id: organizationValue, status: '' });
        loadingStatusByOrg(organizationValue);
      } else {
        form.setFieldsValue({ org_id: 'all', status: '' });
      }
    },
    [permissionOrganizations]
  );

  const onFinish = ({ org_id, range, status }: IExportBulkIncidentsFormValues) => {
    let fromDate: string = '';
    let toDate: string = '';
    if (Array.isArray(range)) {
      const [startDate, endDate]: Moment[] = range;

      fromDate = moment(startDate).format('YYYY/MM/DD');
      toDate = moment(endDate).format('YYYY/MM/DD');
    }

    const token: string = storage.getToken();

    const query: IQueryBulkIncidents | any = {
      lang: i18n.language,
      show_hidden: showHidden,
      from_date: fromDate,
      to_date: toDate,
      standby_token: token,
    };
    if (org_id && org_id !== 'all') {
      query.org_id = org_id;
    }

    if (status) {
      query.status = status;
    }
    const queryString: string = new URLSearchParams(query).toString();
    const urlDownload: string = `${API_URL}/admin/reports/incident?${queryString}`;
    window.open(urlDownload);
  };

  const onDownload = (file: any, originalFilename: string) => {
    if (!file) return;
    const fileType = file?.split('.')[1];
    const data: IQueryDownloadImage = {
      files: [file],
      is_download: true,
      originalFilename: `${originalFilename}.${fileType}`,
    };
    apiDownloadImage(data)
      .then((res) => {
        window.open(res.data[0].url);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFinishV2 = ({ org_id, range, status }: IExportBulkIncidentsFormValues) => {
    let fromDate: string = '';
    let toDate: string = '';
    if (Array.isArray(range)) {
      const [startDate, endDate]: Moment[] = range;

      fromDate = moment(startDate).format('YYYY/MM/DD');
      toDate = moment(endDate).format('YYYY/MM/DD');
    }

    const query: IQueryBulkIncidents | any = {
      lang: i18n.language,
      show_hidden: showHidden.toString(),
      from_date: fromDate,
      to_date: toDate,
    };
    if (org_id && org_id !== 'all') {
      query.org_id = org_id;
    }

    if (status) {
      query.status = status;
    }

    const payload: IPayloadCreateReportBulkIncident = {
      query: query,
      onSuccess: () => {
        reloadDataExport();
      },
      onError: (errorCode) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(createBulkIncident(payload));
  };

  return (
    <div className="bulk-incidents">
      <Form
        form={form}
        layout="vertical"
        requiredMark={false}
        initialValues={{ status: '' }}
        onFinish={onFinishV2}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              name="range"
              label={translate('reports.bulkIncidents.dateRange')}
              rules={[
                { required: true, message: translate('reports.bulkIncidents.dateRangeRequired') },
              ]}
            >
              <RangePicker
                className="range-picker"
                size="large"
                placeholder={[
                  translate('reports.time.placeholder'),
                  translate('reports.time.placeholder'),
                ]}
                ranges={extraRanges()}
                dropdownClassName="dropdown-range-picker range-bulk"
                format="YYYY/MM/DD"
                locale={locale}
                disabledDate={(current) => moment() <= current}
                defaultPickerValue={[
                  moment().subtract(1, 'months'),
                  moment().subtract(1, 'months'),
                ]}
                allowClear
                clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
              />
            </Form.Item>
          </Col>
          <Col span={12} />

          <Col span={12}>
            <Typography.Text id="text" className="txt-drop">
              {translate('incidents.list.org')}
            </Typography.Text>
            <Dropdown
              overlay={<Organizations onSelected={onSelectedOrganization} />}
              trigger={['click']}
              overlayClassName="dropdown-organizations"
              onVisibleChange={handleVisibleOrg}
              visible={isVisibleSelectOrg}
            >
              <Form.Item
                name="org_id"
                rules={[
                  { required: true, message: translate('reports.bulkIncidents.orgRequired') },
                ]}
              >
                <Select
                  size="large"
                  className="select-organization"
                  onClick={() => handleVisibleOrg(true)}
                  placeholder={translate('reports.bulkIncidents.allOrg')}
                  dropdownRender={() => <div />}
                  allowClear
                  onChange={handleChangeOrganization}
                  notFoundContent={translate('no_data', {
                    name: translate('list.empty.organization'),
                  })}
                >
                  {optionsOrganizations.map((org) => (
                    <Option key={org?.value} value={org.value}>
                      {org?.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Dropdown>
          </Col>
          <Col span={12} />

          <Col span={12}>
            <Form.Item name="status" label={translate('reports.bulkIncidents.status')}>
              <Select
                size="large"
                className="select-status"
                placeholder={translate('select')}
                allowClear
                onChange={handleChangeStatus}
              >
                {optionsStatus.map((status: any, index: number) => (
                  <Option key={`${status?.value}-${index}`} value={status.value}>
                    {status?.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Button type="primary" onClick={() => form.submit()} className="btn-export">
          {translate('reports.activationVolume.export')}
        </Button>
        <Button
          type="primary"
          icon={<ReloadOutlined style={{ color: 'primary' }} />}
          style={{ marginLeft: 20, marginBottom: 10 }}
          onClick={() => reloadDataExport()}
        ></Button>
      </Form>
      <Table
        columns={renderColumns()}
        style={{ marginTop: 20 }}
        dataSource={dataExportIncident}
        loading={loadingDateExport}
        pagination={{
          pageSize: pageSize,
          onChange: handleChangePage,
          total: totalDocs,
          current: currentPage,
        }}
      ></Table>
    </div>
  );
};

export default React.memo(BulkIncidents);
