import { Spin } from 'antd';

interface ISpinCenterProps {
  includePadding?: boolean;
  size?: 'large' | 'default' | 'small';
}

const SpinCenter = ({ includePadding = true, size = 'default' }: ISpinCenterProps) => {
  return (
    <div style={{ textAlign: 'center', padding: includePadding ? 150 : 0 }}>
      <Spin size={size} />
    </div>
  );
};

export default SpinCenter;
