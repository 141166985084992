import React from 'react';
import { message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { translate } from 'libs';

const CopyAccessCode = ({ text }) => {
  return (
    <React.Fragment>
      <CopyToClipboard
        style={{ cursor: 'pointer' }}
        text={text}
        onCopy={() =>
          message.success(`${translate('settingPage.ogSetup.copy.accessCode.success')}`)
        }
      >
        <button className="copy-access-code-btn">
          {translate('settingPage.ogSetup.copy.accessCode')}
        </button>
      </CopyToClipboard>
    </React.Fragment>
  );
};

export default CopyAccessCode;
