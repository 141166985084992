import { call, put, takeLatest } from '@redux-saga/core/effects';
import {
  LIMIT_MESSAGE_LOADED,
  LIMIT_OPTION_LOADING,
  LIST_LIMIT_MESSAGE_ACTION_LOADING,
  UPDATE_LIST_LIMIT_MESSAGE_ACTION_LOADING,
} from 'actions/limit-message';
import { getLimited, getSelectOption, UpdateLimited } from 'api/incident-message';
import { IActionGetListMessage, IActionUpdateLimit } from 'pages/setting/interfaces/limit-message';
import { OK } from 'utils';

function* waittingGetOption() {
  try {
    const response = yield call(getSelectOption);
    const newPayload: any[] = response?.data || [];

    if (response?.status === OK) {
      yield put({ type: LIMIT_MESSAGE_LOADED, payload: newPayload });
    }

    return newPayload;
  } catch (error) {
    console.log('🚀 ~ error', error);
  }
}

function* waittingGetListLimitMessage({ payload }: IActionGetListMessage) {
  try {
    const response = yield call(getLimited, payload.query);

    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess(response?.data, []);
    } else {
      if (payload?.onError) yield payload?.onError();
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError();
  }
}

function* waittingActionUpdate({ payload }: IActionUpdateLimit) {
  try {
    const response = yield call(UpdateLimited, payload.data);
    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess();
    } else {
      if (payload?.onError) yield payload?.onError(response?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError('');
  }
}
export default function* limitMessageSaga() {
  yield takeLatest(LIMIT_OPTION_LOADING, waittingGetOption);
  yield takeLatest(LIST_LIMIT_MESSAGE_ACTION_LOADING, waittingGetListLimitMessage);
  yield takeLatest(UPDATE_LIST_LIMIT_MESSAGE_ACTION_LOADING, waittingActionUpdate);
}
