import {
  IActionCreateSOS,
  IActionDeleteSOS,
  IActionGetSOSListByOrg,
  IActionOrderSOS,
  IActionUpdateSOS,
  IPayloadCreateSOS,
  IPayloadDeleteSOS,
  IPayloadGetSOSListByOrg,
  IPayloadOrderSOS,
  IPayloadUpdateSOS,
} from 'pages/setting/interfaces';

export const LOADDING_SOS_LIST = 'LOADDING_SOS_LIST';
export const LOADDING_SOS_LIST_SUCCESS = 'LOADDING_SOS_LIST_SUCCESS';
export const LOADDING_SOS_LIST_ERROR = 'LOADDING_SOS_LIST_ERROR';

export const CREATE_SOS = 'CREATE_SOS';
export const CREATE_SOS_SUCCESS = 'CREATE_SOS_SUCCESS';
export const CREATE_SOS_FAILED = 'CREATE_SOS_FAILED';

export const UPDATE_SOS = 'UPDATE_SOS';
export const UPDATE_SOS_SUCCESS = 'UPDATE_SOS_SUCCESS';
export const UPDATE_SOS_FAILED = 'UPDATE_SOS_FAILED';

export const DELETE_SOS = 'DELETE_SOS';
export const DELETE_SOS_SUCCESS = 'DELETE_SOS_SUCCESS';
export const DELETE_SOS_FAILED = 'DELETE_SOS_FAILED';

export const ORDER_SOS = 'ORDER_SOS';
export const ORDER_SOS_SUCCESS = 'ORDER_SOS_SUCCESS';
export const ORDER_SOS_FAILED = 'ORDER_SOS_FAILED';

export const getListSOSByOrg = (payload: IPayloadGetSOSListByOrg): IActionGetSOSListByOrg => ({
  type: LOADDING_SOS_LIST,
  payload,
});

export const createSOSAction = (payload: IPayloadCreateSOS): IActionCreateSOS => ({
  type: CREATE_SOS,
  payload,
});

export const updateSOSAction = (payload: IPayloadUpdateSOS): IActionUpdateSOS => ({
  type: UPDATE_SOS,
  payload,
});

export const deleteSOSAction = (payload: IPayloadDeleteSOS): IActionDeleteSOS => ({
  type: DELETE_SOS,
  payload,
});

export const orderSOSAction = (payload: IPayloadOrderSOS): IActionOrderSOS => ({
  type: ORDER_SOS,
  payload,
});
