import {
  CHANGE_SEARCH_USERS_SUCCESS,
  CREATE_USER,
  CREATE_USER_FAILED,
  CREATE_USER_SUCCESS,
  GET_DETAIL_USER_FAILED,
  GET_DETAIL_USER_SUCCESS,
  LOADING_USERS,
  LOADING_USERS_FAILED,
  LOADING_USERS_SUCCESS,
  SELECTED_ORGANIZATION_ID_SUCCESS,
  UPDATE_OPT_INS_USER,
  UPDATE_OPT_INS_USER_FAILED,
  UPDATE_OPT_INS_USER_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAILED,
  UPDATE_USER_SUCCESS,
  LOADING_DEACTIVE_USERS,
  LOADING_DEACTIVE_USERS_SUCCESS,
  LOADING_DEACTIVE_USERS_FAILED,
  GET_DETAIL_USER,
  GET_OPT_INS_USER,
  GET_OPT_INS_USER_SUCCESS,
  GET_OPT_INS_USER_FAILED,
  CHANGE_STATUS_USER_SUCCESS,
  UNLOCK_USER_SUCCESS,
  GET_CURRENT_USER_OPTIN_SUCCESS,
  SELECTED_ORGANIZATION_SUCCESS,
} from 'actions/users';
import { IUsersSelector } from 'pages/setting/interfaces';
import { ActionProps } from 'reducers';
import { LOGOUT_SUCCESS } from 'actions/auth';
import { LOADING_PROFILE_SUCCESS } from 'actions/auth';

const initialState: IUsersSelector = {
  users: {
    list: [],
    pagination: {
      page: 1,
    },
  },
  deactivatedUsers: {
    list: [],
    pagination: {
      page: 1,
    },
  },
  isLoading: true,
  selectedOrgId: '',
  selectedOrg: {},
  search: '',
};

export const UsersReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case LOADING_USERS:
    case CREATE_USER:
    case UPDATE_OPT_INS_USER:
    case GET_DETAIL_USER:
    case GET_OPT_INS_USER:
    case LOADING_DEACTIVE_USERS:
    case UPDATE_USER:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_USERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        users: {
          list:
            action.payload.pagination.page === 1
              ? action.payload.list
              : action.payload.pagination.page === state.users.pagination.page
              ? state.users.list
              : [...state.users.list, ...action.payload.list],
          pagination: action.payload.pagination,
        },
      };
    }
    case LOADING_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CREATE_USER_SUCCESS:
    case CREATE_USER_FAILED:
    case GET_DETAIL_USER_SUCCESS:
    case GET_DETAIL_USER_FAILED:
    case UPDATE_USER_SUCCESS:
    case UPDATE_USER_FAILED:
    case UPDATE_OPT_INS_USER_SUCCESS:
    case UPDATE_OPT_INS_USER_FAILED:
    case GET_OPT_INS_USER_SUCCESS:
    case GET_OPT_INS_USER_FAILED:
    case GET_CURRENT_USER_OPTIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case SELECTED_ORGANIZATION_ID_SUCCESS:
      return {
        ...state,
        selectedOrgId: action.payload,
      };
    case SELECTED_ORGANIZATION_SUCCESS:
      return {
        ...state,
        selectedOrg: action.payload,
      };
    case CHANGE_SEARCH_USERS_SUCCESS:
      return {
        ...state,
        search: action.payload,
      };
    case LOADING_DEACTIVE_USERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        deactivatedUsers: {
          list:
            action.payload.pagination.page === 1
              ? action.payload.list
              : action.payload.pagination.page === state.deactivatedUsers.pagination.page
              ? state.deactivatedUsers.list
              : [...state.deactivatedUsers.list, ...action.payload.list],
          pagination: action.payload.pagination,
        },
      };
    }
    case LOADING_DEACTIVE_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CHANGE_STATUS_USER_SUCCESS: {
      const newList = state.users.list.map((user) => {
        if (user._id === action.payload.user_id) {
          user.is_active = action.payload.is_active;
          if (!action.payload.is_active) {
            user.role = [];
          }
        }
        return user;
      });
      return {
        ...state,
        users: {
          ...state.users,
          list: newList,
        },
      };
    }
    case UNLOCK_USER_SUCCESS: {
      const newList = state.users.list.map((user) => {
        if (user._id === action.payload.user_id) {
          user.isBlock = false;
        }
        return user;
      });
      return {
        ...state,
        users: {
          ...state.users,
          list: newList,
        },
      };
    }
    case LOGOUT_SUCCESS:
      return { ...initialState };
    case LOADING_PROFILE_SUCCESS:
      if (!action.payload.incident_filter?.organization_name) {
        return state;
      } else {
        return {
          ...state,
          selectedOrg: {
            name: action.payload.incident_filter.organization_name,
            _id: action.payload.incident_filter.organization_id,
            role_name: action.payload.incident_filter.role_name,
          },
        };
      }
    default:
      return state;
  }
};
