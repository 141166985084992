/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './layout.scss';
import { useCallback, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Header from './components/header/Header';
import { Layout } from 'antd';
import { NotFound } from 'components/common';
import { ESettingPage, IPayloadLoadingUserRole } from 'pages/setting/interfaces';
import { IPayloadGetProfile } from 'pages/auth/interfaces';
import { getProfileAction } from 'actions/auth';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { loadingUserRoleAction } from 'actions/users';
import { EReportPage } from 'pages/reports/interfaces';
import Footer from './components/footer/Footer';
import { OS, RM, RO, OA, AA } from 'utils';
import NoticeResponse from 'components/notice-response';
import { getMessaging, getToken } from 'firebase/messaging';

import SettingPage from 'pages/setting/Setting';
import ListIncidentsPage from 'pages/incidents/ListIncidents';
import DetailIncidentsPage from 'pages/incidents/DetailIncidents';
import ProfilePage from 'pages/setting/components/profile/Profile';
import BroadcastsPage from 'pages/broadcasts/Broadcasts';
import NotificationsPage from 'pages/notifications/Notifications';
import ReportsPage from 'pages/reports/Reports';
import NoPermisisonPage from 'pages/auth/components/NoPermission';
import { updateTokenDevice } from 'api/users';
import { VAPIDKEY } from 'config';
import PageNotificationDetail from 'pages/notifications/components/PageNotificationDetail';

const PrivateLayout = () => {
  const ignorePath: string[] = [
    '/auth/two-factor',
    '/auth/new-password',
    '/admin/share-incident',
    '/admin/block-ip',
    '/admin/user-inactive',
    '/admin/no-permission',
    '/system/maintenance',
    '/admin/link-expired',
  ];

  const { pathname } = useLocation();
  const isHideContent: boolean = ignorePath.includes(pathname);
  if (isHideContent) return null;

  const dispatch = useAppDispatch();

  const role: string = useAppSelector((state) => state.auth.role);
  const isLoggedIn: boolean = useAppSelector((state) => state.auth.isLoggedIn);

  const isRMRO: boolean = React.useMemo(() => [RM, RO].includes(role), [role]);
  const isOS: boolean = React.useMemo(() => role === OS, [role]);
  const isOA: boolean = React.useMemo(() => role === OA, [role]);
  const isAA: boolean = React.useMemo(() => role === AA, [role]);

  const loadingProfile = useCallback(() => {
    const payload: IPayloadGetProfile = {};

    return dispatch(getProfileAction(payload));
  }, []);

  const loadMyRole = useCallback(() => {
    const payload: IPayloadLoadingUserRole = {
      query: {},
    };

    return dispatch(loadingUserRoleAction(payload));
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      loadMyRole();
      loadingProfile();
    }
  }, []);

  const getTokenFirebase = async () => {
    const messaging = getMessaging();
    getToken(messaging, { vapidKey: VAPIDKEY })
      .then(async (currentToken) => {
        console.log('currentToken:', currentToken);
        await updateTokenDevice({ token_device: currentToken });
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };

  const requestPermission = async () => {
    if (Notification.permission === 'granted') {
      console.log('Notification permission granted.');
      await getTokenFirebase();
    } else if (Notification.permission === 'default' || Notification.permission === 'denied') {
      Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          await getTokenFirebase();
        } else {
          console.log('Unable to get permission to notify:', permission);
        }
      });
    }
  };

  useEffect(() => {
    requestPermission();
  }, []);

  const getSettingComponent = (component, isSecurity = false) => {
    if (isRMRO) return NoPermisisonPage;
    if (isOA && isSecurity) return NoPermisisonPage;
    return component;
  };

  const getUploadComponent = (component) => {
    if (isAA || isOS) return component;
    return NoPermisisonPage;
  };

  const isNoticePage: boolean = pathname === '/admin/notifications';

  return (
    <Layout className="layout">
      <Header />
      <Layout.Content className={`layout-content ${isNoticePage && 'content-notifications'}`}>
        <Switch>
          <Route
            path="/admin/incidents/:id"
            component={isOS ? NoPermisisonPage : DetailIncidentsPage}
            exact
          />
          <Route path="/admin/notification-detail/:id" component={PageNotificationDetail} exact />
          <Route
            path="/admin/incidents"
            component={isOS ? NoPermisisonPage : ListIncidentsPage}
            exact
          />
          <Route path="/admin/broadcasts" component={isOS ? NoPermisisonPage : BroadcastsPage} />
          <Route
            path="/admin/reports"
            component={() => (
              <ReportsPage
                page={EReportPage.ACTIVATION_VOLUME}
                key={EReportPage.ACTIVATION_VOLUME}
              />
            )}
            exact
          />
          <Route
            path="/admin/reports/incident-test"
            component={() => (
              <ReportsPage
                page={EReportPage.ACTIVATION_VOLUME_TEST}
                key={EReportPage.ACTIVATION_VOLUME_TEST}
              />
            )}
            exact
          />
          <Route
            path="/admin/reports/bulk-incidents"
            component={() => (
              <ReportsPage page={EReportPage.BULK_INCIDENTS} key={EReportPage.BULK_INCIDENTS} />
            )}
            exact
          />
          <Route
            path="/admin/setting/incident-management"
            component={getSettingComponent(() => (
              <SettingPage
                key={ESettingPage.INCIDENT_MANAGEMENT}
                page={ESettingPage.INCIDENT_MANAGEMENT}
              />
            ))}
            exact
          />
          <Route
            path="/admin/setting/users"
            component={getSettingComponent(() => (
              <SettingPage
                key={ESettingPage.ADMIN_USER_SETUP}
                page={ESettingPage.ADMIN_USER_SETUP}
              />
            ))}
            exact
          />
          <Route
            path="/admin/setting/message-closed-incident-display"
            component={getSettingComponent(() => (
              <SettingPage
                key={ESettingPage.MESSAGE_CLOSE_INCIDENT_DISPLAY}
                page={ESettingPage.MESSAGE_CLOSE_INCIDENT_DISPLAY}
              />
            ))}
            exact
          />
          <Route
            path="/admin/setting/other-settings"
            component={getUploadComponent(() => (
              <SettingPage key={ESettingPage.OTHER_SETTINGS} page={ESettingPage.OTHER_SETTINGS} />
            ))}
            exact
          />
          <Route
            path="/admin/setting/security"
            component={getSettingComponent(
              () => (
                <SettingPage key={ESettingPage.SECURITY} page={ESettingPage.SECURITY} />
              ),
              true
            )}
            exact
          />
          <Route
            path="/admin/setting/incident-question"
            component={getSettingComponent(() => (
              <SettingPage
                key={ESettingPage.INCIDENT_QUESTION}
                page={ESettingPage.INCIDENT_QUESTION}
              />
            ))}
            exact
          />
          <Route
            path="/admin/setting/automated-response"
            component={getSettingComponent(() => (
              <SettingPage
                key={ESettingPage.AUTOMATED_RESPONSE}
                page={ESettingPage.AUTOMATED_RESPONSE}
              />
            ))}
            exact
          />
          <Route
            path="/admin/setting/deactivated-user"
            component={getSettingComponent(() => (
              <SettingPage
                key={ESettingPage.DEACTIVATED_USER}
                page={ESettingPage.DEACTIVATED_USER}
              />
            ))}
            exact
          />
          <Route
            path="/admin/setting/sos-setup"
            component={getSettingComponent(() => (
              <SettingPage key={ESettingPage.SOS_SETUP} page={ESettingPage.SOS_SETUP} />
            ))}
            exact
          />
          <Route
            path="/admin/setting/organizations"
            component={getSettingComponent(() => (
              <SettingPage
                key={ESettingPage.ORGANIZATION_SETUP}
                page={ESettingPage.ORGANIZATION_SETUP}
              />
            ))}
            exact
          />
          <Route path="/admin/profile" component={ProfilePage} />
          <Route path="/admin/notifications" component={NotificationsPage} />
          <Route component={NotFound} path="*" />
        </Switch>
      </Layout.Content>
      {role && <Footer />}
      <NoticeResponse />
    </Layout>
  );
};

export { PrivateLayout };
