import 'antd/dist/antd.less';
import 'font-awesome/css/font-awesome.min.css';
import store from 'app/store';
import 'config/i18n';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'libs';
import 'nprogress/nprogress.css';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import AppPrivate from './App';
import * as serviceWorker from './serviceWorker';
import { notification, message, ConfigProvider } from 'antd';
import { initializeApp, getApps } from 'firebase/app';
import { FIREBASE_CONFIG } from 'config';

notification.config({
  placement: 'topRight',
  duration: 3,
  maxCount: 1,
});

message.config({
  duration: 2,
  maxCount: 1,
  rtl: false,
});

if (getApps().length === 0) {
  initializeApp(FIREBASE_CONFIG);
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ConfigProvider autoInsertSpaceInButton={false}>
        <AppPrivate />
      </ConfigProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
