import {
  LOADING_INCIDENT_QS,
  LOADING_INCIDENT_QS_FAILED,
  LOADING_INCIDENT_QS_SUCCESS,
  DRAG_QUESTION,
  DRAG_QUESTION_SUCCESS,
  DRAG_QUESTION_FAILED,
  CREATE_QUESTION,
  CREATE_QUESTION_SUCCESS,
  CREATE_QUESTION_FAILED,
  EDIT_QUESTION,
  EDIT_QUESTION_SUCCESS,
  EDIT_QUESTION_FAILED,
  DELETE_QUESTION,
  DELETE_QUESTION_SUCCESS,
  DELETE_QUESTION_FAILED,
  CHANGE_VISIBILITY,
  CHANGE_VISIBILITY_SUCCESS,
  CHANGE_VISIBILITY_FAILED,
} from 'actions/incident-question';
import { IIncidentQuestionSelector } from 'pages/setting/interfaces';
import { ActionProps } from 'reducers';

const initialState: IIncidentQuestionSelector = {
  questions: [],
  isLoading: false,
};

export const IncidentQuestionReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case CHANGE_VISIBILITY:
    case DRAG_QUESTION:
    case CREATE_QUESTION:
    case EDIT_QUESTION:
    case DELETE_QUESTION:
    case LOADING_INCIDENT_QS:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_INCIDENT_QS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        questions: action.payload,
      };
    case CHANGE_VISIBILITY_FAILED:
    case CREATE_QUESTION_SUCCESS:
    case CREATE_QUESTION_FAILED:
    case EDIT_QUESTION_SUCCESS:
    case EDIT_QUESTION_FAILED:
    case DELETE_QUESTION_SUCCESS:
    case DELETE_QUESTION_FAILED:
    case DRAG_QUESTION_SUCCESS:
    case DRAG_QUESTION_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case LOADING_INCIDENT_QS_FAILED:
      return {
        ...state,
        isLoading: false,
        questions: [],
      };
    case CHANGE_VISIBILITY_SUCCESS:
      const { question_id, status } = action.payload;
      const newQuestions = state.questions.map((question) => {
        if (question._id === question_id) {
          return { ...question, visibility_custom: status };
        }
        return question;
      });
      return {
        ...state,
        questions: newQuestions,
        isLoading: false,
      };
    default:
      return state;
  }
};
