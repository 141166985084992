/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Form, DatePicker, Radio, Table, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  EFormatReport,
  ETimeFilterActivation,
  IPayloadLoadingReportActiveTimeline,
  IQueryLoadingReportActiveTimeline,
  ITimelineProps,
  IValuesFilterActiveTimeline,
} from 'pages/reports/interfaces';
import moment, { Moment } from 'moment';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { loadingActiveTimelineAction } from 'actions/reports';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { ETabActivation } from 'pages/reports/components/activation-volume';
import { ESelectOrganizationValues } from 'pages/setting/interfaces';
import { CloseCircleFilled } from '@ant-design/icons';
import { translate } from 'libs';

const { RangePicker } = DatePicker;
const { Text } = Typography;

const Timeline = React.forwardRef((props: ITimelineProps, ref: any) => {
  const { activeKey } = props;
  const { i18n } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const showHidden: boolean = useAppSelector(
    (state) => state.organizations.isShowHiddenOrganization
  );

  const [dataSource, setDataSource] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [typeFilter, setTypeFilter] = React.useState<ETimeFilterActivation>(
    ETimeFilterActivation.MONTH
  );
  const selectedOrgId = useAppSelector((state) => state.users.selectedOrgId);

  const loadingActiveTimeline = React.useCallback(
    ({
      to_date = '',
      from_date = '',
      format = EFormatReport.JSON,
      type = ETimeFilterActivation.MONTH,
    }: IQueryLoadingReportActiveTimeline) => {
      if (activeKey !== ETabActivation.TIMELINE) return;
      setIsLoading(true);

      const payload: IPayloadLoadingReportActiveTimeline = {
        query: {
          type,
          format,
          to_date,
          from_date,
          org_id: selectedOrgId !== ESelectOrganizationValues.ALL_IN_CHARGE ? selectedOrgId : '',
          lang: i18n.language,
          show_hidden: showHidden,
        },
        onSuccess: (data: any) => {
          if (Array.isArray(data)) {
            setDataSource(data);
          }

          setIsLoading(false);
        },
        onError: (errorCode: string) => {
          console.log('🚀 ~ errorCode', errorCode);
          setIsLoading(false);
        },
      };

      return dispatch(loadingActiveTimelineAction(payload));
    },
    [activeKey, selectedOrgId, showHidden]
  );

  const getQuery = (): IQueryLoadingReportActiveTimeline => {
    const filters: IValuesFilterActiveTimeline = form.getFieldsValue();

    let fromDate: string = '';
    let toDate: string = '';

    if (Array.isArray(filters?.range)) {
      const [startDate, endDate]: Moment[] = filters.range;

      fromDate = moment(startDate).format('YYYY/MM/DD');
      toDate = moment(endDate).format('YYYY/MM/DD');
    }

    const query: IQueryLoadingReportActiveTimeline = {
      type: filters.type,
      to_date: toDate,
      from_date: fromDate,
      format: EFormatReport.JSON,
    };

    return query;
  };

  const getQueryAndLoadData = () => {
    const query: IQueryLoadingReportActiveTimeline = getQuery();

    loadingActiveTimeline(query);
  };

  React.useEffect(() => {
    return () => {
      setDataSource([]);
    };
  }, []);

  React.useEffect(() => {
    if (activeKey === ETabActivation.TIMELINE) {
      getQueryAndLoadData();
    }
  }, [activeKey]);

  React.useEffect(() => {
    getQueryAndLoadData();
  }, [selectedOrgId, showHidden]);

  const extraRanges = (): any => {
    const thisYear: number = moment().year();
    const thisMonth: number = moment().month();

    return {
      [translate('reports.activationVolume.last3Month')]: [
        moment().subtract(3, 'months'),
        moment(),
      ],
      [translate('reports.activationVolume.last6Month')]: [
        moment().subtract(6, 'months'),
        moment(),
      ],
      [translate('reports.activationVolume.thisAcademic')]: [
        moment(`${thisMonth >= 4 ? thisYear : thisYear - 1} /04/01`, 'YYYY/MM/DD'),
        moment(),
      ],
    };
  };

  React.useImperativeHandle(ref, () => ({
    getFilterTimelineValues: () => {
      const query: IQueryLoadingReportActiveTimeline = getQuery();

      query.lang = i18n.language;
      query.show_hidden = showHidden;
      query.org_id = selectedOrgId !== ESelectOrganizationValues.ALL_IN_CHARGE ? selectedOrgId : '';

      return query;
    },
  }));

  const onChangeTypeFilter = (event: any) => {
    const { value } = event.target;
    setTypeFilter(value as ETimeFilterActivation);

    onSubmitFilter();
  };

  const onFinishFilter = (values: IValuesFilterActiveTimeline) => {
    getQueryAndLoadData();
  };

  const onSubmitFilter = () => {
    form.submit();
  };

  const columns: any[] = [
    {
      title: translate('reports.activationVolume.timeline'),
      dataIndex: 'timeline',
      width: 460,
      render: (_: any, record: any) => {
        const isMonth: boolean = typeFilter === ETimeFilterActivation.MONTH;

        let renderDate: string | null = record?.date;

        if (isMonth && renderDate) {
          const [startDate]: string[] = record.date.split('-');
          const splitedDate = startDate?.split('/');
          renderDate = translate('reports.activationVolume.monthFormat', {
            year: splitedDate[0] || '',
            month: splitedDate[1] || '',
          });
        }

        return (
          <Space direction="vertical" className="space-timeline" size={0}>
            <Text className="date-month">{renderDate}</Text>
            {isMonth && <Text className="range-date">{record?.date}</Text>}
          </Space>
        );
      },
    },
    {
      title: `${translate('reports.activationVolume.total')}`,
      dataIndex: 'total',
      width: 200,
      align: 'right',
      render: (_: any, record: any) => record?.web + record?.mobile,
    },
    {
      title: translate('reports.activationVolume.fromWeb'),
      dataIndex: 'web',
      width: 200,
      align: 'right',
    },
    {
      title: `${translate('reports.activationVolume.fromApp')}`,
      dataIndex: 'mobile',
      width: 200,
      align: 'right',
    },
  ];

  return (
    <div className="timeline">
      <Form
        form={form}
        initialValues={{ type: ETimeFilterActivation.MONTH }}
        onFinish={onFinishFilter}
      >
        <div className="timeline-filter">
          <Form.Item name="range">
            <RangePicker
              className="range-picker"
              size="large"
              placeholder={[
                translate('reports.time.placeholder'),
                translate('reports.time.placeholder'),
              ]}
              ranges={extraRanges()}
              dropdownClassName="dropdown-range-picker"
              onChange={onSubmitFilter}
              format="YYYY/MM/DD"
              // disabledDate={(current) => moment() <= current}
              defaultPickerValue={[moment().subtract(1, 'months'), moment().subtract(1, 'months')]}
              locale={locale}
              allowClear
              clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
            />
          </Form.Item>
          <Form.Item name="type">
            <Radio.Group size="large" className="select-time" onChange={onChangeTypeFilter}>
              <Radio.Button value={ETimeFilterActivation.MONTH}>
                {translate('reports.activationVolume.month')}
              </Radio.Button>
              <Radio.Button value={ETimeFilterActivation.WEEK}>
                {translate('reports.activationVolume.week')}
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </div>
      </Form>

      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={false}
        scroll={{ y: 'calc(100vh - 220px)' }}
        rowKey="date"
        className="timeline-table"
        onRow={(_: any, index: any) => ({
          className: index % 2 !== 0 ? 'row-odd' : '',
          onClick: () => {},
        })}
        locale={{
          emptyText: translate('no_data', { name: translate('list.empty.timeline_report') }),
        }}
        loading={isLoading}
        summary={(pageData: any): React.ReactNode => {
          if (pageData.length === 0) return null;

          let totalWeb: number = 0;
          let totalApp: number = 0;

          pageData.forEach(({ web = 0, mobile = 0 }) => {
            totalWeb += web;
            totalApp += mobile;
          });

          return (
            <Table.Summary.Row className="row-summary">
              <Table.Summary.Cell index={1} className="cell-summary-total">
                {translate('reports.activationVolume.totalNumber')}
              </Table.Summary.Cell>
              <Table.Summary.Cell index={2} className="cell-summary">
                <Text>{totalWeb + totalApp}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={4} className="cell-summary">
                <Text>{totalWeb}</Text>
              </Table.Summary.Cell>
              <Table.Summary.Cell index={3} className="cell-summary">
                <Text>{totalApp}</Text>
              </Table.Summary.Cell>
            </Table.Summary.Row>
          );
        }}
      />
    </div>
  );
});

export default React.memo(Timeline);
