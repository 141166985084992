import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware, { END } from 'redux-saga';
import rootReducer from 'reducers';
import { applyMiddleware, compose, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import rootSaga from 'sagas';
import { composeWithDevTools } from 'redux-devtools-extension';

const sagaMiddleware = createSagaMiddleware();
export const history = createBrowserHistory();

const pReducer = rootReducer(history);
let store: any = null;

if (process.env.NODE_ENV === 'development') {
  store = createStore(
    pReducer,
    composeWithDevTools(applyMiddleware(routerMiddleware(history)), applyMiddleware(sagaMiddleware))
  );
} else {
  store = createStore(
    pReducer,
    compose(applyMiddleware(routerMiddleware(history)), applyMiddleware(sagaMiddleware))
  );
}

store.runSaga = sagaMiddleware.run;
store.close = () => store.dispatch(END);
sagaMiddleware.run(rootSaga);
export default store;
export type AppDispatch = typeof store.dispatch;
