import {
  LOADING_HOLIDAY,
  LOADING_HOLIDAY_FAILED,
  LOADING_HOLIDAY_SUCCESS,
  CREATE_HOLIDAY,
  CREATE_HOLIDAY_SUCCESS,
  CREATE_HOLIDAY_FAILED,
  EDIT_HOLIDAY,
  EDIT_HOLIDAY_SUCCESS,
  EDIT_HOLIDAY_FAILED,
  DELETE_HOLIDAY,
  DELETE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_FAILED,
  LOADING_AUTOMATED_RESPONSE,
  CHANGE_AUTOMATED_RESPONSE,
  LOADING_AUTOMATED_RESPONSE_SUCCESS,
  LOADING_AUTOMATED_RESPONSE_FAILED,
  CHANGE_AUTOMATED_RESPONSE_SUCCESS,
  CHANGE_AUTOMATED_RESPONSE_FAILED,
} from 'actions/automated-response';
import { IAutomatedResponseSelector } from 'pages/setting/interfaces';
import { ActionProps } from 'reducers';

const initialState: IAutomatedResponseSelector = {
  holidays: [],
  isLoading: false,
};

export const AutomatedResponseReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case CREATE_HOLIDAY:
    case EDIT_HOLIDAY:
    case DELETE_HOLIDAY:
    case LOADING_HOLIDAY:
    case LOADING_AUTOMATED_RESPONSE:
    case CHANGE_AUTOMATED_RESPONSE:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_HOLIDAY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        holidays: action.payload,
      };
    case CREATE_HOLIDAY_SUCCESS:
    case CREATE_HOLIDAY_FAILED:
    case LOADING_AUTOMATED_RESPONSE_SUCCESS:
    case LOADING_AUTOMATED_RESPONSE_FAILED:
    case CHANGE_AUTOMATED_RESPONSE_SUCCESS:
    case CHANGE_AUTOMATED_RESPONSE_FAILED:
    case EDIT_HOLIDAY_SUCCESS:
    case EDIT_HOLIDAY_FAILED:
    case DELETE_HOLIDAY_SUCCESS:
    case DELETE_HOLIDAY_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case LOADING_HOLIDAY_FAILED:
      return {
        ...state,
        isLoading: false,
        holidays: [],
      };
    default:
      return state;
  }
};
