import {
  IPayloadLogin,
  IActionLogin,
  IPayloadForgotPassword,
  IActionForgotPassword,
  IActionResendCodeTfa,
  IPayloadResendCodeTfa,
  IPayloadRefreshToken,
  IActionRefreshToken,
  IPayloadGetProfile,
  IPayLoadUpdateUserProfile,
  IActionUpdateUserProfile,
  IPayloadGetUserRole,
  IPayloadGetOptIns,
  IPayloadUpdateMyOptIns,
  IActionUpdateMyOptIns,
} from 'pages/auth/interfaces';

export const LOGGING_IN = 'LOGGING_IN';
export const LOGGING_SUCCESS = 'LOGGING_SUCCESS';
export const LOGGING_ERROR = 'LOGGING_ERROR';
export const VERIFY_TFA = 'VERIFY_TFA';

export const LOGGING_OUT = 'LOGGING_OUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const FG_PASSWORD = 'FG_PASSWORD';
export const FG_PASSWORD_SUCCESS = 'FG_PASSWORD_SUCCESS';
export const FG_PASSWORD_ERROR = 'FG_PASSWORD_ERROR';

export const NEW_PASSWORD = 'NEW_PASSWORD';
export const NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS';
export const NEW_PASSWORD_ERROR = 'NEW_PASSWORD_ERROR';

export const RESEND_CODE_TFA = 'RESEND_CODE_TFA';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const LOADING_PROFILE = 'LOADING_PROFILE';
export const LOADING_PROFILE_SUCCESS = 'LOADING_PROFILE_SUCCESS';
export const LOADING_PROFILE_ERROR = 'LOADING_PROFILE_ERROR';

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';

export const LOADING_MY_ROLE = 'LOADING_MY_ROLE';
export const LOADING_USER_OPT_INS = 'LOADING_USER_OPT_INS';

export const UPDATE_MY_OPT_INS = 'UPDATE_MY_OPT_INS';
export const UPDATE_MY_OPT_INS_SUCCESS = 'UPDATE_MY_OPT_INS_SUCCESS';
export const UPDATE_MY_OPT_INS_FAILED = 'UPDATE_MY_OPT_INS_FAILED';

export const UPDATE_PERMISSION_ORG = 'UPDATE_PERMISSION_ORG';
export const SHOW_HIDDEN_PERMISSION_ORG = 'SHOW_HIDDEN_PERMISSION_ORG';

// AUTH

export const loginAction = (payload: IPayloadLogin): IActionLogin => ({
  type: LOGGING_IN,
  payload,
});

export const logoutAction = () => ({
  type: LOGGING_OUT,
});

export const forgotPasswordAction = (payload: IPayloadForgotPassword): IActionForgotPassword => ({
  type: FG_PASSWORD,
  payload,
});

export const newPasswordAction = (payload: IPayloadForgotPassword): IActionForgotPassword => ({
  type: NEW_PASSWORD,
  payload,
});

export const resendCodeTfaAction = (payload: IPayloadResendCodeTfa): IActionResendCodeTfa => ({
  type: RESEND_CODE_TFA,
  payload,
});

export const refreshTokenAction = (payload: IPayloadRefreshToken): IActionRefreshToken => ({
  type: REFRESH_TOKEN,
  payload,
});

// PROFILE

export const getProfileAction = (payload: IPayloadGetProfile) => ({
  type: LOADING_PROFILE,
  payload,
});

export const updateProfileAction = (
  payload: IPayLoadUpdateUserProfile
): IActionUpdateUserProfile => ({
  type: UPDATE_PROFILE,
  payload,
});

export const getUserRoleAction = (payload: IPayloadGetUserRole) => ({
  type: LOADING_MY_ROLE,
  payload,
});

export const getUserOptInsAction = (payload: IPayloadGetOptIns) => ({
  type: LOADING_USER_OPT_INS,
  payload,
});

export const updateMyOptInsAction = (payload: IPayloadUpdateMyOptIns): IActionUpdateMyOptIns => ({
  type: UPDATE_MY_OPT_INS,
  payload,
});
