/* eslint-disable react-hooks/exhaustive-deps */
import { Modal, Button, Typography, Input, Cascader } from 'antd';
import { ModalConfirm } from 'components/modal-confirm';
import { translate } from 'libs';
import { ETypeOrganization, IMoveOGProps } from 'pages/setting/interfaces';
import React, { Fragment, memo, useEffect, useState } from 'react';

const MoveOG = ({
  isVisible,
  onCancel,
  optionsCascaderOG,
  handleMove,
  selectedItem,
  selectedItems,
}: IMoveOGProps) => {
  const [OGValue, setOGValue] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (Array.isArray(selectedItems) && selectedItems.length > 0) {
      const newInputValue = selectedItems
        .map((e) => e?.name)
        .filter((e) => !!e)
        .join(', ');

      const [k1, k2] = selectedItems[0].key.split('-');

      const newOgValue = [k1, `${k1}-${k2}`];
      setOGValue(newOgValue);

      setInputValue(newInputValue);
    } else {
      setInputValue(selectedItem?.title);
    }
  }, [selectedItems, selectedItem]);

  useEffect(() => {
    if (selectedItem) {
      const { key } = selectedItem;

      const [k1, k2] = key.split('-');

      const newOgValue = [k1, `${k1}-${k2}`];
      setOGValue(newOgValue);
      setInputValue(selectedItem?.title);
    }
  }, [selectedItem]);

  const handleChangeOG = (value: any) => {
    setOGValue(value);
  };

  const handleFinishMove = () => {
    return handleMove(OGValue, () => null);
  };

  const handleStructure = () => {
    const newStructure: any[] = optionsCascaderOG.map((organization) => {
      const newSchools = organization.children.map((school: any) => ({
        ...school,
        disabled: school?.type === ETypeOrganization.SINGLE || school?.is_active === false,
      }));

      return {
        ...organization,
        children: newSchools,
      };
    });

    return newStructure;
  };

  const memoOptions: any[] = React.useMemo(() => handleStructure(), [optionsCascaderOG]);

  return (
    <Fragment>
      <ModalConfirm
        textConfirmBtn={translate('settingPage.ogSetup.modal.title.button.move')}
        visible={!!openModal}
        handleCancel={() => {
          setOpenModal(false);
        }}
        textConfirm={translate('settingPage.ogSetup.modal.content.move')}
        title={translate('settingPage.ogSetup.modal.title.move')}
        handleOk={() => {
          setOpenModal(false);
          handleFinishMove();
        }}
      />
      <Modal
        maskClosable={false}
        title={translate('settingPage.ogSetup.moveOg')}
        centered
        visible={isVisible}
        onCancel={onCancel}
        footer={[
          <Button
            key="large"
            type="primary"
            className="btn-ok"
            size="large"
            onClick={() => setOpenModal(true)}
          >
            {translate('settingPage.ogSetup.move')}
          </Button>,
          <Button key="close" className="btn-close" size="large" onClick={onCancel}>
            {translate('common.popup.closeBtn')}
          </Button>,
        ]}
        className="md-move-og"
      >
        <Typography.Text className="lb-form">
          {translate('settingPage.ogSetup.ogName')}
        </Typography.Text>
        <Input size="large" value={inputValue} disabled className="input-og-name" />

        <div className="move-to">
          <Typography.Text className="lb-form">
            {translate('settingPage.ogSetup.moveTo')}
          </Typography.Text>
          <Cascader
            options={memoOptions}
            size="large"
            displayRender={(label) => label.join(' > ')}
            allowClear={false}
            className="cas-og"
            value={OGValue}
            onChange={handleChangeOG}
            dropdownRender={(menus) => <div className="cas-og-menu">{menus}</div>}
          />
        </div>
      </Modal>
    </Fragment>
  );
};

export default memo(MoveOG);
