import API from 'libs/axios';
import { IQueryLoadingNotifications } from 'pages/notifications/interfaces';
import { queryString } from 'utils';

const PATH: string = 'admin/notification';

export const getNotifications = (query: IQueryLoadingNotifications) => {
  const params: any = {};

  Object.entries(query).forEach(([key, value]: any) => {
    if (value) params[key] = value;
  });
  params['iv'] = Date.now();
  const qs: string = queryString(params);

  return API.get(`${PATH}/list?${qs}`);
};

export const getCountNotice = () => API.get(`${PATH}/count?iv=${Date.now()}`);
export const getViewListNotice = () => API.get(`${PATH}/view-list`);
export const readNotificationApi = (data) => API.post(`${PATH}/read-notification`, data);
export const getDetailNotificationApi = (data) => API.get(`${PATH}/detail`, { params: data });
