import { translate } from 'libs';

export interface IListIncidentsProps { }

export enum EFilterStatusIncidents {
  ALL = 'ALL',
  INPROGRESS = 'INPROGRESS',
  PENDING = 'PENDING',
  CLOSED = 'CLOSED',
}
export enum EFilterResponseStatusIncidents {
  ALL = 'ALL',
  UNREAD = 'UNREAD',
  READ_UNANSWERED = 'UNANSWERED',
  READ_ANSWERED = 'ANSWERED',
}

export enum ANONYMOUS_END_CHAT_STATUS {
  ALL = 'ALL',
  processing = 'processing',
  answered = 'answered',
  cancel = 'cancel',
}

export interface IFiltersIncidentsProps {
  onFilter: (values: IFiltersIncidentsFormValues) => void;
  handleVisibleCreate: () => void;
  onFilterCols: (colName: string, isChecked: boolean) => void;
  columns: any[];
}

export interface IFiltersIncidentsFormValues {
  search: string;
  status: string;
  responseStatus: string;
  assigned: boolean;
  anonymous_end_chat_status: string;
}

export interface ICreateIncidentsProps {
  isVisible: boolean;
  onCancel: () => void;
}

// upload file - url upload
export interface IPayloadUrlUpload {
  organization_id: string;
  files: string[];
}

// loading incidents
export interface IQueryLoadingIncidents {
  organization_id: string;
  assigned_to_me: boolean;
  status: string;
  response_status: string;
  search: string;
  page_size: number;
  page_number: number;
  show_hidden: boolean;
  is_message_unread?: number;

  // for optional when loading at list notification
  new_incident?: boolean;
  sort_by?: string;
  sort_order?: number;
}
export interface IPayloadLoadingIncidents {
  query: IQueryLoadingIncidents;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;

  ignoreDispatchReducer?: boolean;
}
export interface IActionLoadingIncidents {
  type: string;
  payload: IPayloadLoadingIncidents;
}

// create incident api
export interface IFile {
  original_name: string;
  fileKey: string;
}
export interface ICreateIncidentsFormValues {
  org_id: string;
  description: string;
  files: IFile[];
  questions: any[];
}
export interface IPayloadCreateIncidents {
  values: ICreateIncidentsFormValues;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface ICreateIncidentsAction {
  type: string;
  payload: IPayloadCreateIncidents;
}

// total info incidents
export interface ITotalInfo {
  totalNew: number;
  totalPending: number;
  totalUnreadMessage: number;
}
export interface IQueryLoadingTotalInfoIncidents {
  organization_id: string;
  show_hidden: boolean;
}
export interface IPayloadLoadingTotalInfoIncidents {
  query: IQueryLoadingTotalInfoIncidents;
  onSuccess?: (data: any) => void;
  onError?: (errorCode: string) => void;
}
export interface IActionLoadingTotalInfoIncidents {
  type: string;
  payload: IPayloadLoadingTotalInfoIncidents;
}

export const incidentCols = () => [
  { key: 'sequence', label: translate('incidents.list.incident') },
  { key: 'sms', label: translate('incidents.list.msgs') },
  { key: 'status', label: translate('incidents.list.status') },
  { key: 'description', label: translate('incidents.list.description') },
  { key: 'organization', label: translate('incidents.list.org') },
  { key: 'updatedAt', label: translate('incidents.list.updated') },
  { key: 'submitted', label: translate('incidents.list.submitted') },
  { key: 'priority', label: translate('incidents.list.priority') },
  { key: 'type', label: translate('incidents.detail.formDetail.label.type') },
  { key: 'happendedAt', label: translate('incidents.detail.formDetail.label.date') },
  { key: 'assignees', label: translate('incidents.detail.share.assignee') },
  { key: 'labels', label: translate('incidents.detail.share.label') },
  { key: 'targets', label: translate('incidents.detail.share.targets') },
  { key: 'perpetrators', label: translate('incidents.detail.share.perpetrators') },
  { key: 'actionTakens', label: translate('incidents.detail.share.taken') },
  { key: 'location', label: translate('incidents.detail.share.location') },
  { key: 'is_survey_answered', label: translate('incidents.list.column.survey_status') },
];
