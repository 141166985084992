import React, { useEffect, useState } from 'react';
import { get } from 'lodash';
import { translate } from 'libs';
import { Tooltip } from 'antd';
import { apiPreviewBroadcast } from 'api/incidents';
import { IFile } from 'pages/incidents/interfaces';
import { showError } from 'components/standby-notice';
import './page-noti-detail.scss';
import { getDetailNotificationApi } from 'api/notifications';
import { useParams } from 'react-router-dom';

function PageNotificationDetail() {
  const [selectedItem, setselectedItem] = useState(null);
  const { id }: any = useParams();

  useEffect(() => {
    if (id) {
      getDetailNotificationApi({ notification_id: id }).then((res) => {
        setselectedItem(res.data);
      });
    }
  }, [id]);

  const listFile = get(selectedItem, 'file_url') || [];
  const url = get(selectedItem, 'source_url');

  const handleOpenLink = (url) => {
    if (!url) return null;
    if (
      url.startsWith('https') ||
      url.startsWith('Https') ||
      url.startsWith('http') ||
      url.startsWith('Http')
    ) {
      return window.open(url, '_blank');
    } else {
      return window.open(`https://${url}`, '_blank');
    }
  };

  const onPreviewImage = (file: IFile) => {
    const params: any = { files: [file?.fileKey] };

    apiPreviewBroadcast(params)
      .then((res: any) => {
        window.open(res?.data?.[0]?.url);
      })
      .catch(() => {
        showError(translate('error.common'));
      });
  };

  console.log(selectedItem);

  return (
    <div className="page-notification-detail">
      <div className="message-title">
        <span style={{ whiteSpace: 'break-spaces', width: '100%' }}>
          {get(
            selectedItem,
            'data.cms_content_management_id.description',
            get(selectedItem, 'title', get(selectedItem, 'subject'))
          )}
        </span>
      </div>
      <div className="message-alert">
        <span style={{ whiteSpace: 'break-spaces', width: '100%' }}>
          {get(
            selectedItem,
            'data.cms_content_management_id.description',
            get(selectedItem, 'content', get(selectedItem, 'description'))
          )}
        </span>
      </div>
      {url ? (
        <span style={{ color: '#2f80ed', cursor: 'pointer' }} onClick={() => handleOpenLink(url)}>
          {url}
        </span>
      ) : null}
      {listFile.length > 0 &&
        listFile.map((file: any) => (
          <div
            key={file.original_name}
            className="message-files"
            onClick={() => onPreviewImage(file)}
          >
            <img src="/images/attach_content_detail.png" width="15" height="15" alt="" />
            {file?.original_name.length <= 60 ? (
              <div className="text-message">{file?.original_name}</div>
            ) : (
              <Tooltip title={file?.original_name}>
                <div className="text-message">{file?.original_name}</div>
              </Tooltip>
            )}
          </div>
        ))}
    </div>
  );
}

export default PageNotificationDetail;
