/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Dropdown, Menu, Space } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';
import './reports.scss';
import {
  EFormatReport,
  EReportPage,
  IQueryLoadingReportActiveTimeline,
  IQueryLoadingReportActiveUsers,
  IReportPageProps,
} from './interfaces';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectedOrgAction, selectedOrgIDAction } from 'actions/users';
import {
  ESelectOrganizationValues,
  IPayloadSelectedOrganizationID,
} from 'pages/setting/interfaces';
import { AA, OS, storage } from 'utils';
import ActivationVolume from './components/activation-volume';
import BulkIncidents from './components/bulk-incidents';
import ActivationVolumeTest from './components/activation-volume-test';

import { Organizations } from 'components/common';
import { API_URL } from 'config';
import { isEmpty } from 'lodash';
import { translate } from 'libs';

const menus = [
  {
    label: 'report',
    children: [
      { label: 'activationVolume', path: '/admin/reports' },
      { label: 'activationVolumeTest', path: '/admin/reports/incident-test' },
      { label: 'bulkIncidents', path: '/admin/reports/bulk-incidents' },
    ],
  },
];

const Reports = ({ page }: IReportPageProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [selectedMenu, setSelectedMenu] = React.useState<string[]>([page]);

  const [isVisibleSelectOrg, setIsVisibleSelectOrg] = React.useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = React.useState<any>(null);
  const role: string = useAppSelector((state) => state.auth.role);
  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );

  const isAAOS: boolean = React.useMemo(() => [AA, OS].includes(role), [role]);
  const activationRef: any = React.useRef();
  const myOrg = useAppSelector((state) => state.users.selectedOrg);

  const initSelectOrganization = React.useCallback(() => {
    /**
     * Loading Org first
     * check @isOA - loading first org of OA
     */
    if (!isEmpty(myOrg)) {
      if (isEmpty(myOrg.name)) {
        onSelectedOrganization('');
        return;
      }
      onSelectedOrganization(myOrg);
      return;
    }
  }, [permissionOrganizations]);

  React.useEffect(() => {
    initSelectOrganization();
  }, [permissionOrganizations]);

  React.useEffect(() => {
    return () => {
      dispatch(selectedOrgIDAction({ selectedOrgId: '' }));
    };
  }, []);

  const handleVisibleOrg = (visible: boolean) => {
    setIsVisibleSelectOrg(visible);
  };

  const onSelectedOrganization = React.useCallback((selectedItem: any) => {
    handleVisibleOrg(false);
    const payload: IPayloadSelectedOrganizationID = {
      selectedOrgId: selectedItem?._id || ESelectOrganizationValues.ALL_IN_CHARGE,
    };
    if (isEmpty(selectedItem)) {
      setSelectedOrg(selectedItem);
      dispatch(selectedOrgIDAction(payload));
      dispatch(
        selectedOrgAction({
          selectedOrg: { name: '' },
        })
      );
      return;
    } else {
      const { props, title, access_codes, ...newOptionOrg } = selectedItem;
      setSelectedOrg(newOptionOrg);
      const payloadOrgAction: any = {
        selectedOrg: newOptionOrg || ESelectOrganizationValues.ALL_IN_CHARGE,
      };
      dispatch(selectedOrgAction(payloadOrgAction));
      dispatch(selectedOrgIDAction(payload));
      return;
    }
  }, []);

  const handleChangeMenu = ({ selectedKeys }: any) => {
    setSelectedMenu(selectedKeys);
  };

  const onClickMenuItem = (path: string) => {
    history.push(path);
  };

  const handleExportTestIncident = () => {
    const filtersActiveUsersValues: IQueryLoadingReportActiveUsers =
      activationRef?.current?.getFilterActiveUsersValues?.();

    const filtersActiveTimelineValues: IQueryLoadingReportActiveTimeline =
      activationRef?.current?.getFilterTimelineValues?.();

    const token: string = storage.getToken();

    if (filtersActiveTimelineValues) {
      /**
       * Export data at Activation Timeline
       */
      const query: any = {
        ...filtersActiveTimelineValues,
        format: EFormatReport.XLS,
        standby_token: token,
      };

      const queryString: string = new URLSearchParams(query).toString();
      const urlDownload: string = `${API_URL}/admin/reports/incident_test/timeline_users?${queryString}`;
      window.open(urlDownload);
    }

    if (filtersActiveUsersValues) {
      /**
       * Export data at Activation By Organization
       */

      const query: any = {
        ...filtersActiveUsersValues,
        format: EFormatReport.XLS,
        standby_token: token,
      };

      const queryString: string = new URLSearchParams(query).toString();
      const urlDownload: string = `${API_URL}/admin/reports/incident_test/active_users?${queryString}`;
      window.open(urlDownload);
    }
  };

  const handleExport = () => {
    const filtersActiveUsersValues: IQueryLoadingReportActiveUsers =
      activationRef?.current?.getFilterActiveUsersValues?.();

    const filtersActiveTimelineValues: IQueryLoadingReportActiveTimeline =
      activationRef?.current?.getFilterTimelineValues?.();

    const token: string = storage.getToken();

    if (filtersActiveTimelineValues) {
      /**
       * Export data at Activation Timeline
       */
      const query: any = {
        ...filtersActiveTimelineValues,
        format: EFormatReport.XLS,
        standby_token: token,
      };

      const queryString: string = new URLSearchParams(query).toString();
      const urlDownload: string = `${API_URL}/admin/reports/timeline_users?${queryString}`;
      window.open(urlDownload);
    }

    if (filtersActiveUsersValues) {
      /**
       * Export data at Activation By Organization
       */

      const query: any = {
        ...filtersActiveUsersValues,
        format: EFormatReport.XLS,
        standby_token: token,
      };

      const queryString: string = new URLSearchParams(query).toString();
      const urlDownload: string = `${API_URL}/admin/reports/active_users?${queryString}`;
      window.open(urlDownload);
    }
  };

  const renderExtraHeader = () => {
    switch (page) {
      case EReportPage.ACTIVATION_VOLUME:
        return (
          <Space size={12}>
            <Button type="primary" size="large" className="btn-export" onClick={handleExport}>
              {translate('reports.activationVolume.export')}
            </Button>
            <Dropdown
              overlay={<Organizations onSelected={onSelectedOrganization} />}
              trigger={['click']}
              overlayClassName="dropdown-organizations"
              onVisibleChange={handleVisibleOrg}
              visible={isVisibleSelectOrg}
            >
              <Button
                size="large"
                className={`btn-account-og ${!isAAOS && 'is-oa'}`}
                icon={
                  <span className={`icon-right ${!isAAOS && 'is-oa'}`}>
                    <i className="fa fa-chevron-down" />
                  </span>
                }
              >
                {selectedOrg
                  ? selectedOrg?.name
                  : !isAAOS
                  ? translate('settingPage.adminUser.allCharge')
                  : permissionOrganizations?.[0]?.name}
              </Button>
            </Dropdown>
          </Space>
        );
      case EReportPage.ACTIVATION_VOLUME_TEST:
        return (
          <Space size={12}>
            <Button
              type="primary"
              size="large"
              className="btn-export"
              onClick={handleExportTestIncident}
            >
              {translate('reports.activationVolume.export')}
            </Button>
            <Dropdown
              overlay={<Organizations onSelected={onSelectedOrganization} />}
              trigger={['click']}
              overlayClassName="dropdown-organizations"
              onVisibleChange={handleVisibleOrg}
              visible={isVisibleSelectOrg}
            >
              <Button
                size="large"
                className={`btn-account-og ${!isAAOS && 'is-oa'}`}
                icon={
                  <span className={`icon-right ${!isAAOS && 'is-oa'}`}>
                    <i className="fa fa-chevron-down" />
                  </span>
                }
              >
                {selectedOrg
                  ? selectedOrg?.name
                  : !isAAOS
                  ? translate('settingPage.adminUser.allCharge')
                  : permissionOrganizations?.[0]?.name}
              </Button>
            </Dropdown>
          </Space>
        );
      default:
        break;
    }
  };

  const renderPage = () => {
    switch (page) {
      case EReportPage.ACTIVATION_VOLUME:
        return <ActivationVolume ref={activationRef} />;
      case EReportPage.ACTIVATION_VOLUME_TEST:
        return <ActivationVolumeTest ref={activationRef} />;
      case EReportPage.BULK_INCIDENTS:
        return <BulkIncidents />;
      default:
        break;
    }
  };

  return (
    <div className="reports-page">
      <Menu
        theme="light"
        mode="inline"
        className="menu-report"
        selectedKeys={selectedMenu}
        onSelect={handleChangeMenu}
      >
        {menus.map(({ label, children }) => (
          <Menu.ItemGroup key={label} title={translate(`reports.menu.${label}`)}>
            {children.map(({ label: lbChild, path }) => (
              <Menu.Item key={lbChild} onClick={() => onClickMenuItem(path)}>
                {translate(`reports.menu.${lbChild}`)}
              </Menu.Item>
            ))}
          </Menu.ItemGroup>
        ))}
      </Menu>
      <div className="body-report-page">
        <div className="header-report">
          <div className="header-item">{renderExtraHeader()}</div>
        </div>
        <div>{renderPage()}</div>
      </div>
    </div>
  );
};

export default React.memo(Reports);
