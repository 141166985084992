import { Modal, Button, Typography } from 'antd';
import { translate } from 'libs';
import { IModalHideAndDeleteProps } from 'pages/setting/interfaces';
import React, { memo } from 'react';

const ModalHideAndDeleteProps = ({
  isVisible,
  onCancel,
  isDelete = false,
  isHide = false,
  isError = false,
  isLoading = false,
  isUnHide = false,
  onConfirm,
  errorCode = '',
}: IModalHideAndDeleteProps) => {
  const renderTitle = () => {
    if (isDelete) {
      return translate(`settingPage.ogSetup.${isError ? 'canNotDeleteOg' : 'deleteOg'}`);
    }

    if (isUnHide) {
      return translate('settingPage.ogSetup.unhideOg');
    }

    if (isHide) {
      return translate(`settingPage.ogSetup.${isError ? 'canNotHideOg' : 'hideOg'}`);
    }

    return '';
  };

  const renderText = () => {
    if (isDelete) {
      return (
        <Typography.Text className="txt-info">
          {translate(`settingPage.ogSetup.${isError ? 'deleteOgFailed' : 'sureDeleteOg'}`)}
        </Typography.Text>
      );
    }

    if (isUnHide) {
      return (
        <Typography.Text className="txt-info">
          {translate(`settingPage.ogSetup.sureUnhideOg`)}
        </Typography.Text>
      );
    }

    if (isHide) {
      return (
        <Typography.Text className="txt-info break-line-localize">
          {isError
            ? translate(`errors.code.${errorCode}`)
            : translate('settingPage.ogSetup.hideOgInfo')}
        </Typography.Text>
      );
    }

    return null;
  };

  return (
    <Modal
      maskClosable={false}
      title={renderTitle()}
      centered
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        (isHide || !isError) && (
          <Button
            key="1"
            type="primary"
            danger={isDelete || isHide}
            className="btn-delete"
            size="large"
            onClick={onConfirm}
            loading={isLoading}
          >
            {translate(
              `${
                isError && isHide
                  ? 'common.accept'
                  : `settingPage.ogSetup.${isDelete ? 'deleteOg' : isUnHide ? 'unhideOg' : 'hide'}`
              }`
            )}
          </Button>
        ),
        <Button
          key="2"
          className="btn-close"
          type={!isHide && isError ? 'primary' : 'default'}
          size="large"
          onClick={onCancel}
        >
          {translate(!isHide && isError ? 'common.accept' : 'common.close')}
        </Button>,
      ]}
      className="md-hide-delete"
    >
      {renderText()}
      <div className="space" />
    </Modal>
  );
};

export default memo(ModalHideAndDeleteProps);
