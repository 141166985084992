import { Button, Modal, Space, Typography } from 'antd';
import { translate } from 'libs';
import { IUnLockUserProps } from 'pages/setting/interfaces';
import React from 'react';

const UnLockUser = ({ isVisible, onCancel, isLoading, onUnLock }: IUnLockUserProps) => {
  return (
    <Modal
      title={translate('settingPage.adminUser.unlockUser')}
      centered
      maskClosable={false}
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button
          key="large"
          type="primary"
          className="btn-save"
          size="large"
          loading={isLoading}
          onClick={onUnLock}
        >
          {translate('settingPage.adminUser.unlock')}
        </Button>,
        <Button key="cancel" className="btn-cancel" size="large" onClick={onCancel}>
          {translate('settingPage.adminUser.unlockUser.cancelBtn')}
        </Button>,
      ]}
      className="modal md-unlock-user"
    >
      <Space direction="vertical" size={20} className="space-txt-unlock">
        <Typography.Text className="txt-unlock">
          {translate('settingPage.adminUser.unlockSure')}
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default React.memo(UnLockUser);
