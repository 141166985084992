/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Space, Spin, Tooltip } from 'antd';
import CRUD from './CRUD';
import { ModalConfirm } from 'components/modal-confirm';
import { useAppDispatch } from 'app/hooks';
import { IPayloadActionCRUDIP, IPayloadGetListIP } from 'pages/setting/interfaces/security';
import { getIpByIdAction, curdIpAction } from 'actions/security';
import { showError, showSuccess } from 'components/standby-notice';
import InfiniteScroll from 'react-infinite-scroll-component';
import { translate } from 'libs';

export interface CRUDTYPE {
  action?: string;
  ip?: string;
  indexUpdate?: number | null;
  organization_id?: string;
}
const ListIP = ({ name, selectedOrgId }) => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [data, setData] = useState<CRUDTYPE>({
    action: '',
    ip: '',
    indexUpdate: null,
    organization_id: '',
  });
  const [showModalDelete, setShowModalDelete] = useState<boolean>(false);
  const [valueEdit, setvalueEdit] = useState<string>('');
  const dispatch = useAppDispatch();
  const handleModalOk = (dataForm) => {
    setLoading(true);
    handleCancelModal();
    const payload: IPayloadActionCRUDIP = {
      data: dataForm,
      onSuccess: () => {
        setLoading(false);
        showSuccess(
          translate(`settingPage.security.${data.action === 'ADD' ? 'add' : 'edit'}Ip.success`)
        );
        setTimeout(() => {
          getListIp();
        }, 0);
      },
      onError: () => {
        // TODO
        showError(translate('settingPage.security.deleteIp.self_delete_error'));
        setLoading(false);
      },
    };
    dispatch(curdIpAction(payload));
  };
  const getListIp = () => {
    setLoading(true);
    const payload: IPayloadGetListIP = {
      query: {
        organization_id: selectedOrgId,
      },
      onSuccess: (data) => {
        setList(data);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    };
    dispatch(getIpByIdAction(payload));
  };

  useEffect(() => {
    getListIp();
    setData({ ...data, organization_id: selectedOrgId });
  }, [selectedOrgId]);

  const handleClickEdit = (ele, index) => {
    setvalueEdit(ele);
    setData({ ...data, action: 'UPDATE', indexUpdate: index });
    setShowModal(true);
  };

  const handleClickDelete = (ele) => {
    setData({ ...data, action: 'REMOVE', ip: ele });
    setShowModalDelete(true);
  };

  const deleteApi = () => {
    setLoading(true);
    handleCancelModal();

    const payload: IPayloadActionCRUDIP = {
      data: data,
      onSuccess: () => {
        setLoading(false);
        showSuccess(translate(`settingPage.security.deleteIp.success`));
        setTimeout(() => {
          getListIp();
        }, 0);
      },
      onError: (error_code, data) => {
        // TODO
        showError(translate(`settingPage.security.deleteIp.self_delete_error`));
        setLoading(false);
      },
    };
    dispatch(curdIpAction(payload));
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };

  const handleCreateNew = () => {
    setvalueEdit('');
    setData({ ...data, action: 'ADD' });
    setShowModal(true);
  };

  return (
    <Spin spinning={loading} size="small">
      <div className="list-security">
        <div className="header-list">
          <div className="title">{translate('settingPage.security.ipList.ip')}</div>
          <div className="add">
            <Button
              type="primary"
              className="btn-add"
              icon={<PlusOutlined />}
              onClick={handleCreateNew}
            >
              {translate('settingPage.security.ip.addIPBtn')}
            </Button>
          </div>
        </div>
        <div className="body" style={{ paddingLeft: 24 }}>
          <InfiniteScroll
            dataLength={list.length}
            next={() => { }}
            hasMore={false}
            loader={<Spin />}
            scrollableTarget="scrollableDiv"
            inverse
            className="inifinite-scroll"
            style={{ maxHeight: 248 }}
          >
            {list.map((ele, index) => (
              <div className="item" key={index}>
                <div className="left">
                  <div className="name">{ele}</div>
                </div>
                <div className="right">
                  <Space size={24}>
                    <Tooltip placement="topLeft" title={translate('setting.tooltip.editIcon')}>
                      <img
                        alt=""
                        src="/images/ic-edit.png"
                        className="ic-edit"
                        onClick={() => handleClickEdit(ele, index)}
                      />
                    </Tooltip>
                    <Tooltip placement="topLeft" title={translate('setting.tooltip.deleteIcon')}>
                      <img
                        alt=""
                        src="/images/ic-delete.png"
                        className="ic-delete"
                        onClick={() => handleClickDelete(ele)}
                      />
                    </Tooltip>
                  </Space>
                </div>
              </div>
            ))}
          </InfiniteScroll>
        </div>
      </div>

      <CRUD
        isOpen={showModal}
        data={data}
        valueEdit={valueEdit}
        handleCancel={handleCancelModal}
        handleOk={handleModalOk}
      />
      <ModalConfirm
        isDeleted
        visible={showModalDelete}
        handleOk={() => {
          deleteApi();
          setShowModalDelete(false);
        }}
        handleCancel={() => {
          setShowModalDelete(false);
        }}
        title={`${translate('settingPage.security.deleteIp.title')}`}
        textConfirm={`${translate('settingPage.security.deleteIp.body')}`}
        textConfirmBtn={translate(`settingPage.security.deleteIp.delete`)}
      />
    </Spin>
  );
};

export default ListIP;
