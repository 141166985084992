import {
  IActionGetListMessage,
  IActionUpdateLimit,
  IPayloadActionUpdateLimit,
  IPayloadGetListMessage,
} from 'pages/setting/interfaces/limit-message';
export const LIMIT_MESSAGE_LOADED = 'LIMIT_MESSAGE_LOADED';
export const LIMIT_OPTION_LOADING = 'LIMIT_OPTION_LOADING';
export const LIST_LIMIT_MESSAGE_ACTION_LOADING = 'LIST_LIMIT_MESSAGE_ACTION_LOADING';
export const UPDATE_LIST_LIMIT_MESSAGE_ACTION_LOADING = 'UPDATE_LIST_LIMIT_MESSAGE_ACTION_LOADING';

//get limit message

export const getLimitOptionAction = () => ({
  type: LIMIT_OPTION_LOADING,
});

export const getLimitMessageAction = (payload: IPayloadGetListMessage): IActionGetListMessage => ({
  type: LIST_LIMIT_MESSAGE_ACTION_LOADING,
  payload,
});

export const updateLimitMessageAction = (
  payload: IPayloadActionUpdateLimit
): IActionUpdateLimit => ({
  type: UPDATE_LIST_LIMIT_MESSAGE_ACTION_LOADING,
  payload,
});
