import {
  CREATE_BROADCAST,
  CREATE_BROADCAST_ERROR,
  CREATE_BROADCAST_SUCCESS,
  DELETE_BROADCAST,
  DELETE_BROADCAST_ERROR,
  DELETE_BROADCAST_SUCCESS,
  LOADING_BROADCAST_LIST,
  LOADING_BROADCAST_LIST_ERROR,
  LOADING_BROADCAST_LIST_SUCCESS,
  RESEND_BROADCAST,
  RESEND_BROADCAST_ERROR,
  RESEND_BROADCAST_SUCCESS,
  UPDATE_BROADCAST,
  UPDATE_BROADCAST_ERROR,
  UPDATE_BROADCAST_SUCCESS,
  UPDATE_PUBLIC_BROADCAST,
  UPDATE_PUBLIC_BROADCAST_SUCCESS,
  UPDATE_PUBLIC_BROADCAST_ERROR,
} from 'actions/broadcast';
import { notification } from 'antd';
import {
  getBroadcastbyOrg,
  postCreateBroadcast,
  postDeleteBroadcast,
  postResendBroadcast,
  postUpdateBroadcast,
  updatePublicBroadcast,
} from 'api/broadcast';
import { history, translate } from 'libs';
import {
  IActionDeleteBroadcast,
  IActionResendBroadcast,
  IActionUpdateBroadcast,
} from 'pages/broadcasts/interfaces/detail-broadcast';
import {
  IActionCreateBroadcast,
  IActionGetBroadcastByOrg,
} from 'pages/setting/interfaces/broadcast';
import { call, put, takeLatest } from 'redux-saga/effects';
import { FORBIDDEN, OK, SCHEDULE_AFTER_NOW } from 'utils';

function* getBroadcast({ payload }: IActionGetBroadcastByOrg) {
  try {
    const get = yield call(getBroadcastbyOrg, payload.query);
    if (get?.status === OK) {
      const { data } = get;
      yield put({ type: LOADING_BROADCAST_LIST_SUCCESS, payload: data });
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      yield put({ type: LOADING_BROADCAST_LIST_ERROR });
      if (payload?.onError) yield payload.onError(get?.error_code);

      if (payload?.onError) yield payload.onError(get?.error_code);

      let message: string = translate('error.common');

      switch (get?.error_code) {
        case FORBIDDEN:
          return history.push({
            pathname: '/admin/no-permission',
          });
        default:
          break;
      }
      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: LOADING_BROADCAST_LIST_ERROR });
  }
}

function* createBroadcast({ payload }: IActionCreateBroadcast) {
  try {
    const create = yield call(postCreateBroadcast, payload.values);
    if (create?.status === OK) {
      yield put({ type: CREATE_BROADCAST_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: CREATE_BROADCAST_ERROR });
      if (payload?.onError) yield payload.onError(create?.error_code);

      let message: string = translate('error.common');

      switch (create?.error_code) {
        case SCHEDULE_AFTER_NOW:
          message = translate('broadcast.create.scheduleAfterNow');
          break;
        default:
          break;
      }
      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: CREATE_BROADCAST_ERROR });
  }
}

function* updateBroadcast({ payload }: IActionUpdateBroadcast) {
  try {
    const update = yield call(postUpdateBroadcast, payload.values);

    if (update?.status === OK) {
      yield put({ type: UPDATE_BROADCAST_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: UPDATE_BROADCAST_ERROR });
      if (payload?.onError) yield payload.onError(update?.error_code);

      let message: string = translate('error.common');

      switch (update?.error_code) {
        case SCHEDULE_AFTER_NOW:
          message = translate('broadcast.create.scheduleAfterNow');
          break;
        default:
          break;
      }
      yield notification.error({ message });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: UPDATE_BROADCAST_ERROR });
  }
}

function* resendBroadcast({ payload }: IActionResendBroadcast) {
  try {
    const resend = yield call(postResendBroadcast, payload.id);

    if (resend?.status === OK) {
      yield put({ type: RESEND_BROADCAST_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: RESEND_BROADCAST_ERROR });
      if (payload?.onError) yield payload.onError(resend?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: RESEND_BROADCAST_ERROR });
  }
}

function* deleteBroadcast({ payload }: IActionDeleteBroadcast) {
  try {
    const del = yield call(postDeleteBroadcast, payload.values);

    if (del?.status === OK) {
      yield put({ type: DELETE_BROADCAST_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: DELETE_BROADCAST_ERROR });
      if (payload?.onError) yield payload.onError(del?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: DELETE_BROADCAST_ERROR });
  }
}

function* updatePublicBroadcastSaga({ payload }: any) {
  try {
    const res = yield call(updatePublicBroadcast, payload);
    if (res?.status === OK) {
      yield put({ type: UPDATE_PUBLIC_BROADCAST_SUCCESS, payload });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: UPDATE_PUBLIC_BROADCAST_ERROR });
      if (payload?.onError) yield payload.onError(res?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: UPDATE_PUBLIC_BROADCAST_ERROR });
  }
}

export default function* broadcastSaga() {
  yield takeLatest(LOADING_BROADCAST_LIST, getBroadcast);
  yield takeLatest(CREATE_BROADCAST, createBroadcast);
  yield takeLatest(UPDATE_BROADCAST, updateBroadcast);
  yield takeLatest(DELETE_BROADCAST, deleteBroadcast);
  yield takeLatest(RESEND_BROADCAST, resendBroadcast);
  yield takeLatest(UPDATE_PUBLIC_BROADCAST, updatePublicBroadcastSaga);
}
