/* eslint-disable react-hooks/exhaustive-deps */
import { ESelectOrganizationValues } from 'pages/setting/interfaces';
import React, { useEffect, useRef, useState } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { AA, OS, RO } from 'utils';
import type { AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<any> = useSelector;

export const usePrevious = (value: any) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const useReadOnly = (): boolean => {
  const role: string = useAppSelector((state) => state.auth.role);
  if (!role || [RO].includes(role)) return true;

  return false;
};

// start ===================
export const useMyOrganizationID = (): string => {
  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );
  const role: string = useAppSelector((state) => state.auth.role);
  const selectedOrgId: string = useAppSelector((state) => state.users.selectedOrgId);

  const rootOrgId: string = React.useMemo(
    () => permissionOrganizations?.[0]?._id,
    [permissionOrganizations]
  );

  const myOrganizationID: string = React.useMemo(() => {
    if ([AA, OS].includes(role) && !selectedOrgId) {
      return rootOrgId;
    }

    if (selectedOrgId) {
      if (selectedOrgId === ESelectOrganizationValues.ALL_IN_CHARGE) {
        return '';
      }

      return selectedOrgId;
    }

    let firstSchool: any = permissionOrganizations?.[0]?.children?.[0]; // init default first school

    for (const organization of permissionOrganizations) {
      if (Array.isArray(organization?.role)) {
        const checkRoleOrganization: string | null = organization?.role?.[0]?.role_name;

        if (!!checkRoleOrganization) {
          // firstSchool = firstSchool;
          break;
        } else {
          /**
           * Have not role at root organization, check role in school
           */
          if (Array.isArray(organization?.childrens)) {
            for (const school of organization.childrens) {
              if (Array.isArray(school?.role)) {
                const checkRoleSchool: string | null = school?.role?.[0]?.role_name;

                if (!!checkRoleSchool) {
                  firstSchool = school;
                  break;
                } else {
                  /**
                   * Have not role at root school, check role in grade
                   */
                  if (Array.isArray(school?.childrens)) {
                    for (const grade of school.childrens) {
                      if (Array.isArray(grade?.role)) {
                        const checkRoleGrade: string | null = grade?.role?.[0]?.role_name;

                        if (!!checkRoleGrade) {
                          firstSchool = grade;
                          break;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return firstSchool?._id;
  }, [selectedOrgId, role, rootOrgId, permissionOrganizations]);
  return myOrganizationID;
};

export const useComponentVisible = (initialIsVisible) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref: any = useRef(null);

  const handleHideDropdown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      setIsComponentVisible(false);
    }
  };

  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleHideDropdown, true);
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('keydown', handleHideDropdown, true);
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
};
