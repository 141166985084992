import { Button, Typography } from 'antd';
import Wrapper from './Wrapper';
import { useAppDispatch } from 'app/hooks';
import { logoutAction } from 'actions/auth';
import { translate } from 'libs';

const NoPermisisonPage = () => {
  const dispatch = useAppDispatch();

  const handleOk = () => dispatch(logoutAction());

  return (
    <Wrapper>
      <div className="lock-block">
        <div>
          <div>
            <div className="block-ip-icon">
              <img src="/images/ic-no-permission.png" alt="No Permission" />
            </div>
            <div>
              <Typography.Text className="txt-title">
                {translate('loginPage.noPermissAlert')}
              </Typography.Text>
            </div>
            <div className="description">
              <Typography.Text className="txt-description">
                {translate('loginPage.noPermissDescription')}
              </Typography.Text>
            </div>
            <Button type="primary" block size="large" className="btn-ok" onClick={handleOk}>
              {translate('common.accept')}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default NoPermisisonPage;
