import React from 'react';
import { useEffect } from 'react';
import { Radio, Space } from 'antd';
import { Button, Form, Input, Modal } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { IPayloadUpdateSOS, IUpdateSOSFormValues } from 'pages/setting/interfaces';
import { showError, showSuccess } from 'components/standby-notice';
import { updateSOSAction } from 'actions/sos';
import trim from 'lodash/trim';
import ConfirmButton from 'components/common/ConfirmButton';
import { translate } from 'libs';

const ModalFormEdit = ({ visible, onCancel, record, loadingListSOS }) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const isLoading: boolean = useAppSelector((state) => state.sos.isLoading);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue(record);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onFinish = (values: IUpdateSOSFormValues) => {
    const payload: IPayloadUpdateSOS = {
      query: {
        sosId: record._id,
      },
      values: {
        ...values,
        title: trim(values.title),
        value: trim(values.value),
      },
      onSuccess: () => {
        loadingListSOS();
        onCancel();
        showSuccess(translate('settingPage.sosSetUp.edit.message.success'));
      },
      onError: () => {
        onCancel();
        showError(translate('settingPage.sosSetUp.edit.message.error'));
      },
    };

    return dispatch(updateSOSAction(payload));
  };

  const handleChangeType = (event: any) => {
    const { value } = event.target;

    let typeValue: string = '';

    if (value === record?.type) {
      typeValue = record?.value;
    }

    form.setFieldsValue({ value: typeValue });
  };

  const isValidHttpUrl = (rule, value = '', callback) => {
    let url;

    if (value.trim()) {
      try {
        url = new URL(value);
      } catch (_) {
        return Promise.reject(new Error(translate('settingPage.sosSetUp.form.require.url')));
      }
      if (url.protocol !== 'http:' && url.protocol !== 'https:') {
        return Promise.reject(new Error(translate('settingPage.sosSetUp.form.require.url')));
      }
    }

    return Promise.resolve();
  };

  return (
    <Modal
      maskClosable={false}
      title={translate('settingPage.sosSetUp.editSos')}
      centered
      visible={visible}
      onCancel={onCancel}
      width={625}
      className="modal md-form-sos"
      footer={[
        <ConfirmButton
          className="btn-save"
          buttonTitle={translate('settingPage.sosSetUp.saveBtn')}
          onClick={() => form.submit()}
          form={form}
          keyName="create"
          size="large"
          isLoading={isLoading}
        />,
        <Button key="close" onClick={onCancel} className="btn-close" size="large">
          {translate('common.cancelBtn')}
        </Button>,
      ]}
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        requiredMark={false}
        form={form}
        initialValues={{
          type: 'URL',
        }}
      >
        <Form.Item
          className="title"
          label={translate('settingPage.sosSetUp.edit.nameSos')}
          name="title"
          rules={[
            {
              required: true,
              whitespace: true,
              message: translate('settingPage.sosSetUp.form.require.nameSOS'),
            },
          ]}
        >
          <Input
            size="large"
            placeholder={translate('settingPage.sosSetUp.edit.nameSos')}
            className="input-name"
            maxLength={70}
          />
        </Form.Item>

        <Form.Item name="type">
          <Radio.Group value={record?.type} className="radio-sos" onChange={handleChangeType}>
            <Space direction="vertical" size={20} className="space-radio-sos">
              <Radio value="PHONE" className="cb-values">
                <Form.Item noStyle shouldUpdate={(p, n) => p.type !== n.type}>
                  {({ getFieldValue }) => {
                    const getType: String = getFieldValue('type');
                    return (
                      <Form.Item
                        label={translate('settingPage.sosSetUp.edit.phone')}
                        className="radio-title"
                        name={getType !== 'PHONE' ? '' : 'value'}
                        rules={[
                          {
                            required: getType === 'PHONE',
                            message: translate('settingPage.sosSetUp.form.require.callNumber'),
                          },
                          {
                            pattern: new RegExp(/^[0-9]+$/),
                            message: translate('settingPage.myInfo.phoneFormat'),
                          },
                        ]}
                      >
                        <Input
                          disabled={getType !== 'PHONE'}
                          size="large"
                          className="input-value"
                          placeholder={translate('settingPage.sosSetUp.edit.phone')}
                          maxLength={12}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Radio>
              <Radio value="URL" className="cb-values">
                <Form.Item noStyle shouldUpdate={(p, n) => p.type !== n.type}>
                  {({ getFieldValue }) => {
                    const getType: String = getFieldValue('type');
                    return (
                      <Form.Item
                        label={translate('settingPage.sosSetUp.edit.url')}
                        name={getType !== 'URL' ? '' : 'value'}
                        className="radio-title"
                        rules={[
                          {
                            required: getType === 'URL',
                            whitespace: getType === 'URL',
                            // pattern: new RegExp(
                            //   '^(https?:\\/\\/)?' + // protocol
                            //     '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                            //     '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                            //     '(\\:\\d+)?(\\/[-a-z\\d#%_.~+]*)*' + // port and path
                            //     '(\\?[;&a-z\\d#%_.~+=-]*)?' + // query string
                            //     '(\\#[-a-z\\d_]*)?$',
                            //   'i'
                            // ),
                            message: translate('settingPage.sosSetUp.form.require.url'),
                          },
                          { validator: isValidHttpUrl },
                        ]}
                      >
                        <Input
                          disabled={getType !== 'URL'}
                          size="large"
                          className="input-value"
                          placeholder={translate('settingPage.sosSetUp.edit.url')}
                          maxLength={256}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(ModalFormEdit);
