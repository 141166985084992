/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Form, Input, Dropdown, Select, Space, Typography, Spin } from 'antd';
import { postUrlUpload } from 'api/incidents';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Organizations } from 'components/common';
import {
  ICreateIncidentsFormValues,
  ICreateIncidentsProps,
  IFile,
  IPayloadCreateIncidents,
  IPayloadUrlUpload,
} from 'pages/incidents/interfaces';
import React from 'react';
import axios from 'axios';
import { showError, showSuccess } from 'components/standby-notice';
import { createIncidentsAction } from 'actions/incidents';
import nProgress from 'nprogress';
import { useHistory } from 'react-router-dom';
import trim from 'lodash/trim';
import { getFileType } from 'utils/common';
import Translate, { translate } from 'libs';

const { Option } = Select;

const CreateIncidents = ({ isVisible, onCancel }: ICreateIncidentsProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const inputFileRef: any = React.useRef();

  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );
  const isLoading: boolean = useAppSelector((state) => state.incidents.isLoading);

  const [isVisibleSelectOrg, setIsVisibleSelectOrg] = React.useState<boolean>(false);
  const [optionsOrganizations, setOptionsOrganizations] = React.useState<any[]>([]);
  const [isUploadingFile, setIsUploadingFile] = React.useState<boolean>(false);
  const [files, setFiles] = React.useState<any[]>([]);

  React.useEffect(() => {
    if (isVisible) {
      form.setFieldsValue({ org_id: undefined, description: '' });
    }

    return () => {
      if (isVisible) {
        setFiles([]);
      }
    };
  }, [isVisible]);

  const handleVisibleOrg = (visible: boolean) => {
    setIsVisibleSelectOrg(visible);
  };

  const onSelectedOrganization = React.useCallback(
    (selectedItem: any) => {
      handleVisibleOrg(false);

      if (selectedItem) {
        const { key } = selectedItem;
        let organizationName: string = '';
        let organizationValue: string = '';

        if (Array.isArray(permissionOrganizations)) {
          const [orgId, schoolId, gradeId] = key.split('-');

          if (orgId) {
            const findOrg: any = permissionOrganizations.find((item: any) => item._id === orgId);
            if (findOrg) {
              organizationName = findOrg.name;
              organizationValue = findOrg._id;

              if (schoolId && Array.isArray(findOrg?.children)) {
                const findSchool = findOrg.children.find((item: any) => item._id === schoolId);

                if (findSchool) {
                  organizationName = `${findOrg.name} > ${findSchool.name}`;
                  organizationValue = findSchool._id;

                  if (gradeId && Array.isArray(findSchool?.children)) {
                    const findGrade = findSchool.children.find((item: any) => item._id === gradeId);

                    if (findGrade) {
                      organizationName = `${findOrg.name} > ${findSchool.name} > ${findGrade.name}`;
                      organizationValue = findGrade._id;
                    }
                  }
                }
              }
            }
          }
        }

        setOptionsOrganizations([{ label: organizationName, value: organizationValue }]);

        form.setFieldsValue({ org_id: organizationValue });
      }
    },
    [permissionOrganizations]
  );

  const onFinish = ({ org_id, description }: ICreateIncidentsFormValues) => {
    const newFiles: IFile[] = files
      .map((file: any) => {
        delete file?.type;
        delete file?.url;

        return file;
      })
      .filter((file: IFile | null) => !!file);

    const payload: IPayloadCreateIncidents = {
      values: {
        org_id,
        description: trim(description),
        files: newFiles,
        questions: [],
      },
      onSuccess: (data: any) => {
        onCancel();
        showSuccess(translate('incidents.create.success'));
        history.push(`/admin/incidents/${data?.incident_id}`);
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(createIncidentsAction(payload));
  };

  const handleUploadFile = (fileList: any[]) => {
    let arrayKey: any = [];

    if (fileList?.length > 0) {
      Object.keys(fileList).forEach(function (key) {
        arrayKey = arrayKey.concat(fileList[key]);
      });
      arrayKey = arrayKey.map((e) => {
        return {
          name: encodeURIComponent(e.name),
          type: e.type,
          size: e.size,
          file: e,
          fileExtension: e.name.split('.').pop(),
        };
      });
    }
    customRequest(arrayKey);
  };

  const customRequest = async (fileList: any[]) => {
    const myOrgId: string = form.getFieldValue('org_id');

    if (!myOrgId) {
      return form.setFields([
        { name: 'org_id', errors: [translate('incidents.create.orgRequired')] },
      ]);
    }

    setIsUploadingFile(true);

    const isAllowed: string[] = [
      'image/jpeg',
      'image/png',
      'audio/mpeg',
      'video/mp4',
      'application/pdf',
      'MOV',
      'mov',
      'image/heic',
      'heic',
      'video/quicktime',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    // file heic can not have type(e.type)
    const newArr = fileList.filter((e) => e.type || e.fileExtension);

    const fileType: boolean[] = newArr?.map((e) =>
      isAllowed.includes(e?.type.toLowerCase() || e?.fileExtension.toLowerCase())
    );
    const isFileTobig = fileList?.find((e) => e.size > 100 * 1024 * 1024);

    if (fileList.length > 10) {
      setIsUploadingFile(false);
      inputFileRef.current.value = '';
      return showError(translate('fileToMany'));
    }

    if (fileType.includes(false)) {
      setIsUploadingFile(false);
      inputFileRef.current.value = '';
      return showError(translate('fileToSmall'));
    }

    if (isFileTobig) {
      setIsUploadingFile(false);
      inputFileRef.current.value = '';
      return showError(translate('fileToLarge', { size: 100 }));
    }
    const isFileToSmall = fileList?.find((e) => e.size <= 0);

    if (isFileToSmall) {
      setIsUploadingFile(false);
      inputFileRef.current.value = '';
      return showError(translate('fileToSmall'));
    }

    try {
      let jsonKey: any = {};
      for (let i = 0; i < fileList.length; i++) {
        jsonKey[fileList[i].name] = {
          file: fileList[i].file,
          type:
            getFileType(fileList[i].name) === 'heic' || getFileType(fileList[i].name) === 'HEIC'
              ? 'heic'
              : fileList[i].type,
        };
      }
      const payload: IPayloadUrlUpload = {
        organization_id: myOrgId,
        files: [...fileList].map((eel) => eel.name),
      };

      setIsUploadingFile(true);
      const { data } = await postUrlUpload(payload);

      // re-map file with url upload
      const dataRemap = (data || []).map((ele) => {
        return {
          url: ele.url,
          ...jsonKey[ele.original_name],
          fileKey: ele.fileKey,
          original_name: ele.original_name,
        };
      });

      Promise.all(
        dataRemap.map((item: any) => {
          const config = {
            headers: {
              'Content-Type': item.type,
              'Access-Control-Allow-Origin': '*',
            },
          };

          return axios.put(item.url, item.file, config);
        })
      )
        .then(() => {
          inputFileRef.current.value = '';

          const newFiles: any[] = dataRemap.map((item: any) => {
            const fileType: any = jsonKey[item.original_name]?.type;

            return { ...item, type: fileType };
          });

          setFiles([...files, ...newFiles]);
        })
        .finally(() => {
          nProgress.done();
          setIsUploadingFile(false);
        });
    } catch (error) {
      inputFileRef.current.value = '';
      console.log(error);
      setIsUploadingFile(false);
    } finally {
      // setIsUploadingFile(false);
    }
  };

  const handleClickUpload = () => {
    const valueOrg = form.getFieldValue('org_id');
    if (!valueOrg) {
      form.validateFields();
    } else {
      inputFileRef.current?.click?.();
    }
  };

  const renderIconFileType = (fileKey) => {
    let text = '';
    text = fileKey?.split('.').pop().toUpperCase();

    return (
      <div className="wrap-fileType">
        <span>{text}</span>
      </div>
    );
  };

  const itemUploadRender = (file: any) => {
    return (
      <Space key={file.fileKey} className="space-file" size={10}>
        {renderIconFileType(file.fileKey)}
        <Typography.Text className="file-name">
          {decodeURIComponent(file.original_name)}
        </Typography.Text>
        <img
          alt=""
          src="/images/ic-cancel.png"
          className="ic-cancel"
          onClick={() => handleRemove(file)}
        />
      </Space>
    );
  };

  const handleRemove = (file: any) => {
    const newFiles: any[] = files.filter((item: any) => file.fileKey !== item.fileKey);

    setFiles(newFiles);
  };

  const handlePreventDefault = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleDragEnter = (event: any) => {
    event.preventDefault();
    event.stopPropagation();

    const element = document.querySelector('#form-upload-file');
    if (element) {
      element.className = 'preview-file-upload';
    }
  };

  const handleDragLeave = (event: any) => {
    event.preventDefault();

    if (event.currentTarget.contains(event.target) && event.target.id !== 'form-upload-file') {
      return;
    }

    const element = document.querySelector('#form-upload-file');
    if (element) {
      element.className = 'preview-file-upload preview-file-upload-hidden';
    }
  };

  const handleDropFile = (event: any) => {
    event.preventDefault();
    handleUploadFile(event.dataTransfer.files);

    const element = document.querySelector('#form-upload-file');
    if (element) {
      element.className = 'preview-file-upload preview-file-upload-hidden';
    }
  };

  return (
    <Modal
      title={translate('incidents.create.title')}
      centered
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button
          key="large"
          type="primary"
          onClick={() => form.submit()}
          className="btn-save"
          size="large"
          loading={isLoading}
          disabled={isUploadingFile}
        >
          {translate('incidents.create.create_btn')}
        </Button>,
        <Button
          key="cancel"
          className="btn-cancel"
          size="large"
          onClick={onCancel}
          disabled={isUploadingFile}
        >
          {translate('incidents.create.cancel_btn')}
        </Button>,
      ]}
      className="modal md-create-incident"
      width={800}
      destroyOnClose
      maskClosable={false}
    >
      <Spin spinning={isUploadingFile}>
        <div
          onDragStart={handlePreventDefault}
          onDragOver={handlePreventDefault}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDropFile}
        >
          <div id="form-upload-file" className="preview-file-upload preview-file-upload-hidden" />

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            requiredMark={false}
            scrollToFirstError
          >
            <div className="list-org">
              <Typography.Text className="customize-required-class">
                {translate('incidents.list.org')}
              </Typography.Text>
              <Dropdown
                overlay={
                  <Organizations
                    onSelected={onSelectedOrganization}
                    selectGradeOnly
                    isHideAmInCharge
                    isVisibleShowHidden={false}
                  />
                }
                trigger={['click']}
                overlayClassName="dropdown-organizations"
                onVisibleChange={handleVisibleOrg}
                visible={isVisibleSelectOrg}
              >
                <Form.Item
                  name="org_id"
                  rules={[{ required: true, message: translate('incidents.create.orgRequired') }]}
                >
                  <Select
                    size="large"
                    className="select-organization"
                    onClick={() => handleVisibleOrg(!isVisibleSelectOrg)}
                    placeholder={translate('incidents.create.select_org')}
                    dropdownRender={() => <div />}
                    notFoundContent={translate('no_data', {
                      name: translate('list.empty.organization'),
                    })}
                  >
                    {optionsOrganizations.map((org) => (
                      <Option key={org?.value} value={org.value}>
                        {org?.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Dropdown>
            </div>

            <Form.Item
              name="description"
              label={translate('incidents.create.reportMessage')}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: translate('incidents.create.reportMessageRequired'),
                },
              ]}
            >
              <Input.TextArea
                autoSize={{ minRows: 4, maxRows: 5 }}
                placeholder={translate('incidents.create.reportMessage.placeholder')}
                className="input-report"
                maxLength={12000}
              />
            </Form.Item>
          </Form>

          <div className="upload-incidents">
            <img id="img" alt="" src="/images/ic-upload.png" />
            <Translate
              i18nKey="common.browse2"
              components={{
                blue: (
                  <span
                    onClick={handleClickUpload}
                    style={{ display: 'inline-block', color: 'blue', textDecoration: 'underline' }}
                  />
                ),
              }}
            />
            <input
              onChange={(event: any) => handleUploadFile(event.target.files)}
              multiple
              type="file"
              style={{ display: 'none' }}
              ref={inputFileRef}
            />
          </div>

          <Space direction="vertical" size={16} className="all-files">
            {files.map((file: any) => itemUploadRender(file))}
          </Space>
        </div>
      </Spin>
    </Modal>
  );
};

export default React.memo(CreateIncidents);
