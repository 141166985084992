export enum EActionBroadcast {
  ADD = 'ADD',
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
}

export enum formatSchedule {
  FORMAT = 'YYYY/MM/DD HH:mm',
}

export interface IBroadcastListProps {
  isVisibleCreate: boolean;
  onCancelCreate: () => void;
}
