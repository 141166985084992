import {
  LOADING_COUNT_NOTIFICATIONS,
  LOADING_NOTIFICATIONS,
  VIEW_LIST_NOTIFICATIONS,
  READ_NOTIFICATION,
} from 'actions/notifications';
import {
  getCountNotice,
  getNotifications,
  getViewListNotice,
  readNotificationApi,
} from 'api/notifications';
import {
  IActionLoadingCountNotifications,
  IActionLoadingNotifications,
  IActionReadNotification,
  IActionViewListNotifications,
} from 'pages/notifications/interfaces';
import { call, takeLatest } from 'redux-saga/effects';
import { OK } from 'utils';

function* loadingNotifications({ payload }: IActionLoadingNotifications) {
  try {
    const get = yield call(getNotifications, payload.query);

    if (get?.status === OK) {
      const { data } = get;
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      if (payload?.onError) yield payload.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload.onError('');
  }
}

function* loadingCountNotifications({ payload }: IActionLoadingCountNotifications) {
  try {
    const get = yield call(getCountNotice);

    if (get?.status === OK) {
      const { data } = get;
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      if (payload?.onError) yield payload.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload.onError('');
  }
}

function* viewListNotifications({ payload }: IActionViewListNotifications) {
  try {
    const get = yield call(getViewListNotice);

    if (get?.status === OK) {
      const { data } = get;
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      if (payload?.onError) yield payload.onError(get?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload.onError('');
  }
}

function* readNotification({ payload }: IActionReadNotification) {
  try {
    const response = yield call(readNotificationApi, payload.data);

    if (response?.status === OK) {
      const { data } = response;
      if (payload?.onSuccess) yield payload.onSuccess(data);
    } else {
      if (payload?.onError) yield payload.onError(response?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload.onError('');
  }
}

export default function* notificationsSaga() {
  yield takeLatest(LOADING_NOTIFICATIONS, loadingNotifications);
  yield takeLatest(LOADING_COUNT_NOTIFICATIONS, loadingCountNotifications);
  yield takeLatest(VIEW_LIST_NOTIFICATIONS, viewListNotifications);
  yield takeLatest(READ_NOTIFICATION, readNotification);
}
