import {
  CREATE_BROADCAST,
  CREATE_BROADCAST_ERROR,
  CREATE_BROADCAST_SUCCESS,
  DELETE_BROADCAST,
  DELETE_BROADCAST_ERROR,
  DELETE_BROADCAST_SUCCESS,
  LOADING_BROADCAST_LIST,
  LOADING_BROADCAST_LIST_ERROR,
  LOADING_BROADCAST_LIST_SUCCESS,
  RESEND_BROADCAST,
  RESEND_BROADCAST_ERROR,
  RESEND_BROADCAST_SUCCESS,
  UPDATE_BROADCAST,
  UPDATE_BROADCAST_ERROR,
  UPDATE_BROADCAST_SUCCESS,
  UPDATE_PUBLIC_BROADCAST_SUCCESS,
} from 'actions/broadcast';
import { IBroadcastSelector } from 'pages/setting/interfaces/broadcast';
import { ActionProps } from 'reducers';

const initialState: IBroadcastSelector = {
  list: [],
  pagination: {
    page: 1,
  },
  isLoading: false,
};

export const BroadcastReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case LOADING_BROADCAST_LIST:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_BROADCAST:
    case UPDATE_BROADCAST:
    case RESEND_BROADCAST:
    case DELETE_BROADCAST:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_BROADCAST_LIST_SUCCESS: {
      const newList =
        action.payload.pagination.page === 1
          ? action.payload.list
          : action.payload.pagination.page === state.pagination.page
          ? state.list
          : [...state.list, ...action.payload.list];

      return {
        ...state,
        isLoading: false,
        list: newList,
        pagination: action.payload.pagination,
      };
    }
    case LOADING_BROADCAST_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        broadcasts: [],
      };
    case RESEND_BROADCAST_SUCCESS:
    case RESEND_BROADCAST_ERROR:
    case UPDATE_BROADCAST_SUCCESS:
    case UPDATE_BROADCAST_ERROR:
    case DELETE_BROADCAST_SUCCESS:
    case DELETE_BROADCAST_ERROR:
    case CREATE_BROADCAST_SUCCESS:
    case CREATE_BROADCAST_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case UPDATE_PUBLIC_BROADCAST_SUCCESS: {
      const newList = state.list.map((broadcast) => {
        if (broadcast._id === action.payload.id) {
          broadcast.is_public = action.payload.is_public;
        }
        return broadcast;
      });
      return {
        ...state,
        list: newList,
      };
    }
    default:
      return state;
  }
};
