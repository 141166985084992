/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { readNotificationAction } from 'actions/notifications';
import { Button, Modal, Typography, Tooltip } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { IPayloadReadNotification, ISystemAlertProps } from '../interfaces';
import { apiPreviewBroadcast } from 'api/incidents';
import { IFile } from 'pages/incidents/interfaces';
import { showError } from 'components/standby-notice';
import { get } from 'lodash';
import { translate } from 'libs';

const SystemAlert = ({ isVisible, onCancel, selectedItem }: ISystemAlertProps) => {
  const dispatch = useAppDispatch();

  const readMessage = () => {
    const payload: IPayloadReadNotification = {
      data: {
        notification_id: selectedItem?._id,
      },
    };
    return dispatch(readNotificationAction(payload));
  };

  const onPreviewImage = (file: IFile) => {
    const params: any = { files: [file?.fileKey] };

    apiPreviewBroadcast(params)
      .then((res: any) => {
        window.open(res?.data?.[0]?.url);
      })
      .catch(() => {
        showError(translate('error.common'));
      });
  };

  useEffect(() => {
    readMessage();
  }, []);

  const renderTitle = () => {
    return <div>{get(selectedItem, 'data.cms_content_management_id.title')}</div>;
  };

  const handleOpenLink = (url) => {
    if (!url) return null;
    if (
      url.startsWith('https') ||
      url.startsWith('Https') ||
      url.startsWith('http') ||
      url.startsWith('Http')
    ) {
      return window.open(url, '_blank');
    } else {
      return window.open(`https://${url}`, '_blank');
    }
  };
  const renderContentType = () => {
    if (!selectedItem) return null;
    const listFile = get(selectedItem, 'data.cms_content_management_id.file_url') || [];
    const url = get(selectedItem, 'data.cms_content_management_id.source_url');

    return (
      <React.Fragment>
        <Typography.Text className="message-alert">
          <span style={{ whiteSpace: 'break-spaces', width: '100%' }}>
            {get(
              selectedItem,
              'data.cms_content_management_id.description',
              get(selectedItem, 'data.cms_content_management_id.content')
            )}
          </span>
        </Typography.Text>
        {url ? (
          <span style={{ color: '#2f80ed', cursor: 'pointer' }} onClick={() => handleOpenLink(url)}>
            {url}
          </span>
        ) : null}
        {listFile.length > 0 &&
          listFile.map((file) => (
            <div
              key={file.original_name}
              className="message-files"
              onClick={() => onPreviewImage(file)}
            >
              <img src="/images/attach_content_detail.png" width="15" height="15" alt="" />
              {file?.original_name.length <= 60 ? (
                <div className="text-message">{file?.original_name}</div>
              ) : (
                <Tooltip title={file?.original_name}>
                  <div className="text-message">{file?.original_name}</div>
                </Tooltip>
              )}
            </div>
          ))}
      </React.Fragment>
    );
  };

  return (
    <Modal
      className="modal modal-system-alert"
      title={renderTitle()}
      maskClosable={false}
      visible={isVisible}
      onCancel={onCancel}
      centered
      width={800}
      footer={[
        <Button key="cancel" className="btn-close" size="large" onClick={onCancel}>
          {translate('common.popup.closeBtn')}
        </Button>,
      ]}
    >
      {renderContentType()}
    </Modal>
  );
};

export default React.memo(SystemAlert);
