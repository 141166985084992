import {
  CREATE_SOS,
  CREATE_SOS_FAILED,
  CREATE_SOS_SUCCESS,
  DELETE_SOS,
  DELETE_SOS_FAILED,
  DELETE_SOS_SUCCESS,
  LOADDING_SOS_LIST,
  LOADDING_SOS_LIST_ERROR,
  LOADDING_SOS_LIST_SUCCESS,
  ORDER_SOS,
  ORDER_SOS_FAILED,
  ORDER_SOS_SUCCESS,
  UPDATE_SOS,
  UPDATE_SOS_FAILED,
  UPDATE_SOS_SUCCESS,
} from 'actions/sos';
import { deleteSOS, getSOSListByOrg, orderSOS, postCreateSOS, putUpdateSOS } from 'api/sos';
import {
  IActionCreateSOS,
  IActionDeleteSOS,
  IActionGetSOSListByOrg,
  IActionOrderSOS,
  IActionUpdateSOS,
} from 'pages/setting/interfaces';
import { call, put, takeLatest } from 'redux-saga/effects';
import { OK } from 'utils';

function* getListSOS({ payload }: IActionGetSOSListByOrg) {
  try {
    const listSOS = yield call(getSOSListByOrg, payload.query);

    if (listSOS?.status === OK) {
      const { data } = listSOS;

      yield put({ type: LOADDING_SOS_LIST_SUCCESS, payload: Array.isArray(data) ? data : [] });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      yield put({ type: LOADDING_SOS_LIST_ERROR });
      if (payload?.onError) yield payload.onError(listSOS?.error_code);
    }
  } catch (error) {
    yield put({ type: LOADDING_SOS_LIST_ERROR });
    console.log(`error`, error);
  }
}

function* createSOS({ payload }: IActionCreateSOS) {
  try {
    const create = yield call(postCreateSOS, payload.values);
    if (create?.status === OK) {
      yield put({ type: CREATE_SOS_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      if (payload?.onError) yield payload.onError(create?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: CREATE_SOS_FAILED });
  }
}

function* updateSOS({ payload }: IActionUpdateSOS) {
  try {
    const update = yield call(putUpdateSOS, payload.values, payload?.query);
    if (update?.status === OK) {
      yield put({ type: UPDATE_SOS_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      if (payload?.onError) yield payload.onError(update?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: UPDATE_SOS_FAILED });
  }
}

function* delSOS({ payload }: IActionDeleteSOS) {
  try {
    const del = yield call(deleteSOS, payload?.query);
    if (del?.status === OK) {
      yield put({ type: DELETE_SOS_SUCCESS });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      if (payload?.onError) yield payload.onError(del?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: DELETE_SOS_FAILED });
  }
}

function* sortSOS({ payload }: IActionOrderSOS) {
  try {
    const res = yield call(orderSOS, payload);
    const { data: list } = res;

    if (res?.status === OK) {
      yield put({ type: ORDER_SOS_SUCCESS, payload: list });
      if (payload?.onSuccess) yield payload.onSuccess();
    } else {
      if (payload?.onError) yield payload.onError(res?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    yield put({ type: ORDER_SOS_FAILED });
  }
}

export default function* sosSaga() {
  yield takeLatest(LOADDING_SOS_LIST, getListSOS);
  yield takeLatest(CREATE_SOS, createSOS);
  yield takeLatest(UPDATE_SOS, updateSOS);
  yield takeLatest(DELETE_SOS, delSOS);
  yield takeLatest(ORDER_SOS, sortSOS);
}
