import {
  IActionChangeStatusUser,
  IActionCreateUser,
  IActionGetDetailUser,
  IActionGetOptInsUser,
  IActionLoadingDeactiveUsers,
  IActionLoadingUserRole,
  IActionLoadingUsers,
  IActionReactiveUsers,
  IActionSearchUsers,
  IActionSelectedOrganization,
  IActionSelectedOrganizationID,
  IActionUnlockUser,
  IActionUpdateOptInsUser,
  IActionUpdateUser,
  IPayloadChangeStatusUser,
  IPayloadCreateUser,
  IPayloadGetDetailUser,
  IPayloadGetOptInsUser,
  IPayloadLoadingDeactiveUsers,
  IPayloadLoadingUserRole,
  IPayloadLoadingUsers,
  IPayloadReactiveUsers,
  IPayloadSearchUsers,
  IPayloadSelectedOrganization,
  IPayloadSelectedOrganizationID,
  IPayloadUnlockUser,
  IPayloadUpdateOptInsUser,
  IPayloadUpdateUser,
} from 'pages/setting/interfaces';

export const LOADING_USERS = 'LOADING_USERS';
export const LOADING_USERS_SUCCESS = 'LOADING_USERS_SUCCESS';
export const LOADING_USERS_FAILED = 'LOADING_USERS_FAILED';

export const LOADING_DEACTIVE_USERS = 'LOADING_DEACTIVE_USERS';
export const LOADING_DEACTIVE_USERS_SUCCESS = 'LOADING_DEACTIVE_USERS_SUCCESS';
export const LOADING_DEACTIVE_USERS_FAILED = 'LOADING_DEACTIVE_USERS_FAILED';

export const REACTIVE_USERS = 'REACTIVE_USERS';
export const REACTIVE_USERS_SUCCESS = 'REACTIVE_USERS_SUCCESS';
export const REACTIVE_USERS_FAILED = 'REACTIVE_USERS_FAILED';

export const SELECTED_ORGANIZATION = 'SELECTED_ORGANIZATION';
export const SELECTED_ORGANIZATION_ID = 'SELECTED_ORGANIZATION_ID';
export const SELECTED_ORGANIZATION_ID_SUCCESS = 'SELECTED_ORGANIZATION_ID_SUCCESS';
export const SELECTED_ORGANIZATION_SUCCESS = 'SELECTED_ORGANIZATION_SUCCESS';

export const CHANGE_SEARCH_USERS = 'CHANGE_SEARCH_USERS';
export const CHANGE_SEARCH_USERS_SUCCESS = 'CHANGE_SEARCH_USERS_SUCCESS';

export const LOADING_USER_ROLE = 'LOADING_USER_ROLE';
export const LOADING_USER_ROLE_SUCCESS = 'LOADING_USER_ROLE_SUCCESS';
export const LOADING_USER_ROLE_FAILED = 'LOADING_USER_ROLE_FAILED';

export const CREATE_USER = 'CREATE_USER';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const GET_DETAIL_USER = 'GET_DETAIL_USER';
export const GET_DETAIL_USER_SUCCESS = 'GET_DETAIL_USER_SUCCESS';
export const GET_DETAIL_USER_FAILED = 'GET_DETAIL_USER_FAILED';

export const GET_OPT_INS_USER = 'GET_OPT_INS_USER';
export const GET_OPT_INS_USER_SUCCESS = 'GET_OPT_INS_USER_SUCCESS';
export const GET_OPT_INS_USER_FAILED = 'GET_OPT_INS_USER_FAILED';

export const UPDATE_OPT_INS_USER = 'UPDATE_OPT_INS_USER';
export const UPDATE_OPT_INS_USER_SUCCESS = 'UPDATE_OPT_INS_USER_SUCCESS';
export const UPDATE_OPT_INS_USER_FAILED = 'UPDATE_OPT_INS_USER_FAILED';

export const CHANGE_STATUS_USER = 'CHANGE_STATUS_USER';
export const CHANGE_STATUS_USER_SUCCESS = 'CHANGE_STATUS_USER_SUCCESS';
export const UNLOCK_USER = 'UNLOCK_USER';
export const UNLOCK_USER_SUCCESS = 'UNLOCK_USER_SUCCESS';

export const GET_CURRENT_USER_OPTIN_SUCCESS = 'GET_CURRENT_USER_OPTIN_SUCCESS';

export const loadingUsersAction = (payload: IPayloadLoadingUsers): IActionLoadingUsers => ({
  type: LOADING_USERS,
  payload,
});

export const selectedOrgIDAction = (
  payload: IPayloadSelectedOrganizationID
): IActionSelectedOrganizationID => {
  return {
    type: SELECTED_ORGANIZATION_ID,
    payload,
  };
};

export const selectedOrgAction = (
  payload: IPayloadSelectedOrganization
): IActionSelectedOrganization => {
  return {
    type: SELECTED_ORGANIZATION,
    payload,
  };
};

export const changeSearchUsersAction = (payload: IPayloadSearchUsers): IActionSearchUsers => ({
  type: CHANGE_SEARCH_USERS,
  payload,
});

export const loadingUserRoleAction = (
  payload: IPayloadLoadingUserRole
): IActionLoadingUserRole => ({
  type: LOADING_USER_ROLE,
  payload,
});

export const createUserAction = (payload: IPayloadCreateUser): IActionCreateUser => ({
  type: CREATE_USER,
  payload,
});

export const updateUserAction = (payload: IPayloadUpdateUser): IActionUpdateUser => ({
  type: UPDATE_USER,
  payload,
});

export const getDetailUserAction = (payload: IPayloadGetDetailUser): IActionGetDetailUser => ({
  type: GET_DETAIL_USER,
  payload,
});

export const changeStatusUserAction = (
  payload: IPayloadChangeStatusUser
): IActionChangeStatusUser => ({
  type: CHANGE_STATUS_USER,
  payload,
});

export const getOptInsUserAction = (payload: IPayloadGetOptInsUser): IActionGetOptInsUser => ({
  type: GET_OPT_INS_USER,
  payload,
});

export const updateOptInsUserAction = (
  payload: IPayloadUpdateOptInsUser
): IActionUpdateOptInsUser => ({
  type: UPDATE_OPT_INS_USER,
  payload,
});

export const unlockUserAction = (payload: IPayloadUnlockUser): IActionUnlockUser => ({
  type: UNLOCK_USER,
  payload,
});

// DEACTIVE USERS
export const loadingDeactiveUsersAction = (
  payload: IPayloadLoadingDeactiveUsers
): IActionLoadingDeactiveUsers => ({
  type: LOADING_DEACTIVE_USERS,
  payload,
});

export const reactiveUsersAction = (payload: IPayloadReactiveUsers): IActionReactiveUsers => ({
  type: REACTIVE_USERS,
  payload,
});
