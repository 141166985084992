import {
  IActionChangeAutomatedResponse,
  IActionCreateHoliday,
  IActionDeleteHoliday,
  IActionGetAutomatedResponse,
  IActionLoadingHoliday,
  IActionEditHoliday,
  IPayloadChangeAutomatedResponse,
  IPayloadCreateHoliday,
  IPayloadDeleteHoliday,
  IPayloadGetAutomatedResponse,
  IPayloadLoadingHoliday,
  IPayloadEditHoliday,
} from 'pages/setting/interfaces';

export const LOADING_HOLIDAY = 'LOADING_HOLIDAY';
export const LOADING_HOLIDAY_SUCCESS = 'LOADING_HOLIDAY_SUCCESS';
export const LOADING_HOLIDAY_FAILED = 'LOADING_HOLIDAY_FAILED';

export const CREATE_HOLIDAY = 'CREATE_HOLIDAY';
export const CREATE_HOLIDAY_SUCCESS = 'CREATE_HOLIDAY_SUCCESS';
export const CREATE_HOLIDAY_FAILED = 'CREATE_HOLIDAY_FAILED';

export const EDIT_HOLIDAY = 'EDIT_HOLIDAY';
export const EDIT_HOLIDAY_SUCCESS = 'EDIT_HOLIDAY_SUCCESS';
export const EDIT_HOLIDAY_FAILED = 'EDIT_HOLIDAY_FAILED';

export const DELETE_HOLIDAY = 'DELETE_HOLIDAY';
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS';
export const DELETE_HOLIDAY_FAILED = 'DELETE_HOLIDAY_FAILED';

export const LOADING_AUTOMATED_RESPONSE = 'LOADING_AUTOMATED_RESPONSE';
export const LOADING_AUTOMATED_RESPONSE_SUCCESS = 'LOADING_AUTOMATED_RESPONSE_SUCCESS';
export const LOADING_AUTOMATED_RESPONSE_FAILED = 'LOADING_AUTOMATED_RESPONSE_FAILED';

export const CHANGE_AUTOMATED_RESPONSE = 'CHANGE_AUTOMATED_RESPONSE';
export const CHANGE_AUTOMATED_RESPONSE_SUCCESS = 'CHANGE_AUTOMATED_RESPONSE_SUCCESS';
export const CHANGE_AUTOMATED_RESPONSE_FAILED = 'CHANGE_AUTOMATED_RESPONSE_FAILED';
export const CHANGE_ON_OFF_RESPONSE = 'CHANGE_ON_OFF_RESPONSE';

export const loadingAutomatedResponseAction = (
  payload: IPayloadGetAutomatedResponse
): IActionGetAutomatedResponse => ({
  type: LOADING_AUTOMATED_RESPONSE,
  payload,
});

export const changeAutomatedResponseAction = (
  payload: IPayloadChangeAutomatedResponse
): IActionChangeAutomatedResponse => ({
  type: CHANGE_AUTOMATED_RESPONSE,
  payload,
});

export const changeOnOffResponseAction = (
  payload: IPayloadChangeAutomatedResponse
): IActionChangeAutomatedResponse => ({
  type: CHANGE_ON_OFF_RESPONSE,
  payload,
});

export const loadingHolidayAction = (payload: IPayloadLoadingHoliday): IActionLoadingHoliday => ({
  type: LOADING_HOLIDAY,
  payload,
});

export const createHolidayAction = (payload: IPayloadCreateHoliday): IActionCreateHoliday => ({
  type: CREATE_HOLIDAY,
  payload,
});

export const editHolidayAction = (payload: IPayloadEditHoliday): IActionEditHoliday => ({
  type: EDIT_HOLIDAY,
  payload,
});

export const deleteHolidayAction = (payload: IPayloadDeleteHoliday): IActionDeleteHoliday => ({
  type: DELETE_HOLIDAY,
  payload,
});
