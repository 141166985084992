const NoColumnsIcon = (props) => {
  return (
    <svg
      width="160"
      height="152"
      viewBox="0 0 160 152"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M67.797 151.228C105.24 151.228 135.594 145.556 135.594 138.56C135.594 131.564 105.24 125.892 67.797 125.892C30.3537 125.892 0 131.564 0 138.56C0 145.556 30.3537 151.228 67.797 151.228Z"
        fill="#F5F5F7"
        fillOpacity="0.8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M122.034 101.344L98.1086 71.899C96.9606 70.513 95.2826 69.674 93.5156 69.674H42.0756C40.3096 69.674 38.6316 70.513 37.4836 71.899L13.5596 101.344V116.727H122.035V101.344H122.034Z"
        fill="#AEB8C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M33.8301 31.67H101.763C102.824 31.67 103.841 32.0914 104.592 32.8416C105.342 33.5917 105.763 34.6091 105.763 35.67V129.014C105.763 130.075 105.342 131.092 104.592 131.842C103.841 132.593 102.824 133.014 101.763 133.014H33.8301C32.7692 133.014 31.7518 132.593 31.0017 131.842C30.2515 131.092 29.8301 130.075 29.8301 129.014V35.67C29.8301 34.6091 30.2515 33.5917 31.0017 32.8416C31.7518 32.0914 32.7692 31.67 33.8301 31.67V31.67Z"
        fill="#F5F5F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.6776 41.623H92.9146C93.445 41.623 93.9537 41.8337 94.3288 42.2088C94.7039 42.5839 94.9146 43.0926 94.9146 43.623V68.58C94.9146 69.1105 94.7039 69.6192 94.3288 69.9942C93.9537 70.3693 93.445 70.58 92.9146 70.58H42.6776C42.1472 70.58 41.6385 70.3693 41.2634 69.9942C40.8883 69.6192 40.6776 69.1105 40.6776 68.58V43.623C40.6776 43.0926 40.8883 42.5839 41.2634 42.2088C41.6385 41.8337 42.1472 41.623 42.6776 41.623ZM42.9396 81.437H92.6526C93.2525 81.437 93.8279 81.6753 94.2521 82.0995C94.6763 82.5237 94.9146 83.0991 94.9146 83.699C94.9146 84.2989 94.6763 84.8743 94.2521 85.2985C93.8279 85.7227 93.2525 85.961 92.6526 85.961H42.9396C42.3397 85.961 41.7643 85.7227 41.3401 85.2985C40.9159 84.8743 40.6776 84.2989 40.6776 83.699C40.6776 83.0991 40.9159 82.5237 41.3401 82.0995C41.7643 81.6753 42.3397 81.437 42.9396 81.437ZM42.9396 93.2H92.6526C93.2526 93.2 93.8281 93.4384 94.2524 93.8627C94.6767 94.287 94.9151 94.8625 94.9151 95.4625C94.9151 96.0626 94.6767 96.638 94.2524 97.0623C93.8281 97.4866 93.2526 97.725 92.6526 97.725H42.9396C42.3395 97.725 41.7641 97.4866 41.3398 97.0623C40.9155 96.638 40.6771 96.0626 40.6771 95.4625C40.6771 94.8625 40.9155 94.287 41.3398 93.8627C41.7641 93.4384 42.3395 93.2 42.9396 93.2ZM121.813 136.702C121.038 139.773 118.316 142.062 115.078 142.062H20.5146C17.2766 142.062 14.5546 139.772 13.7806 136.702C13.633 136.117 13.5584 135.516 13.5586 134.912V101.345H39.8766C42.7836 101.345 45.1266 103.793 45.1266 106.765V106.805C45.1266 109.776 47.4966 112.175 50.4036 112.175H85.1886C88.0956 112.175 90.4656 109.754 90.4656 106.782V106.77C90.4656 103.798 92.8086 101.344 95.7156 101.344H122.034V134.913C122.034 135.53 121.957 136.129 121.813 136.702Z"
        fill="#DCE0E6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M125.121 33.292L118.291 35.942C118.115 36.0102 117.924 36.0275 117.739 35.992C117.554 35.9564 117.383 35.8693 117.246 35.7408C117.108 35.6123 117.01 35.4476 116.962 35.2656C116.913 35.0836 116.918 34.8917 116.974 34.712L118.911 28.505C116.322 25.561 114.802 21.971 114.802 18.097C114.802 8.102 124.92 0 137.402 0C149.881 0 160 8.102 160 18.097C160 28.092 149.882 36.194 137.401 36.194C132.873 36.194 128.657 35.128 125.121 33.292Z"
        fill="#DCE0E6"
      />
      <path
        d="M146.304 21.365C147.878 21.365 149.153 20.1047 149.153 18.55C149.153 16.9953 147.878 15.735 146.304 15.735C144.731 15.735 143.455 16.9953 143.455 18.55C143.455 20.1047 144.731 21.365 146.304 21.365Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M131.348 21.013H125.65L128.548 16.087L131.348 21.013ZM134.909 16.087H139.894V21.013H134.909V16.087Z"
        fill="white"
      />
    </svg>
  );
};

export { NoColumnsIcon };
