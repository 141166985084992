import { Button, Typography } from 'antd';
import Wrapper from './Wrapper';
import { useAppDispatch } from 'app/hooks';
import { logoutAction } from 'actions/auth';
import { translate } from 'libs';

const UserInactivePage = () => {
  const dispatch = useAppDispatch();

  const handleOk = () => dispatch(logoutAction());

  return (
    <Wrapper>
      <div className="lock-block">
        <div>
          <div>
            <div className="user-inactive-icon">
              <img src="/images/ic-lock.png" alt="User Inactive" />
            </div>
            <div style={{ marginBottom: 24 }}>
              <Typography.Text className="txt-title">
                {translate('loginPage.accountDeactive')}
              </Typography.Text>
            </div>
            <Button type="primary" block size="large" className="btn-ok" onClick={handleOk}>
              {translate('loginPage.returnToLogin')}
            </Button>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default UserInactivePage;
