import { useEffect } from 'react';

function useTriggerOutside(ref: any, callback: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (
        event.target?.className === 'filter-cols-icon' ||
        event.target?.parentElement?.className === 'filter-cols-icon'
      ) {
        return;
      }
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export default useTriggerOutside;
