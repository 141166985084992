import { call, takeLatest } from '@redux-saga/core/effects';
import {
  LIST_IP_CRUD_ACTION_LOADING,
  LIST_IP_LOADING,
  LIST_STATUS_SECURITY_ACTION_LOADING,
  UPDATE_STATUS_SECURITY_ACTION_LOADING,
} from 'actions/security';
import { apiChangeStatus, apiCRUDIP, apiGetIpById, apiGetStatusSecurity } from 'api/security';
import {
  IActionCRUDIP,
  IActionGetIp,
  IActionUpdateStatus,
} from 'pages/setting/interfaces/security';
import { OK } from 'utils';

function* waittinggetListSECURITY({ payload }: IActionGetIp) {
  try {
    const response = yield call(apiGetStatusSecurity, payload.query);
    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess(response.data);
    } else {
      if (payload?.onError) yield payload?.onError();
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError();
  }
}

function* waittinggetListIP({ payload }: IActionGetIp) {
  try {
    const response = yield call(apiGetIpById, payload.query);
    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess(response.data);
    } else {
      if (payload?.onError) yield payload?.onError();
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError();
  }
}

function* waittingActionCRUDListIp({ payload }: IActionCRUDIP) {
  try {
    const response = yield call(apiCRUDIP, payload.data);
    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess();
    } else {
      if (payload?.onError) yield payload?.onError(response?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError('');
  }
}

function* waittingActionChangeStatus({ payload }: IActionUpdateStatus) {
  try {
    const response = yield call(apiChangeStatus, payload.data);
    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess();
    } else {
      if (payload?.onError) yield payload?.onError(response?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError('');
  }
}
export default function* securitySaga() {
  yield takeLatest(LIST_IP_LOADING, waittinggetListIP);
  yield takeLatest(LIST_IP_CRUD_ACTION_LOADING, waittingActionCRUDListIp);
  yield takeLatest(LIST_STATUS_SECURITY_ACTION_LOADING, waittinggetListSECURITY);
  yield takeLatest(UPDATE_STATUS_SECURITY_ACTION_LOADING, waittingActionChangeStatus);
}
