import React from 'react';
import { Button, DatePicker, Form, Space, Switch, Typography } from 'antd';
import moment, { Moment } from 'moment';
import { IFreezeIncidentFormValues } from 'pages/setting/interfaces';
import { useReadOnly } from 'app/hooks';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { CloseCircleFilled } from '@ant-design/icons';
import { translate } from 'libs';

const { Text } = Typography;

const FreezeIncident = () => {
  const [form] = Form.useForm();
  const isJustView: boolean = useReadOnly();

  const disabledFromDate = (date: Moment) => {
    const endDate = form.getFieldValue('to');

    if (endDate) {
      return date.isAfter(new Date(endDate));
    }

    return false;
  };

  const disabledToDate = (date: Moment) => {
    const startDate = form.getFieldValue('from');

    if (startDate) {
      const addOneDay: any = moment(startDate).add(1, 'days');
      return date.isBefore(new Date(addOneDay));
    }

    return false;
  };

  const onFinish = (values: IFreezeIncidentFormValues) => {
    console.log('🚀 ~ values', values);
  };

  return (
    <div className="freeze-incident">
      <Form
        layout="vertical"
        requiredMark={false}
        form={form}
        initialValues={{
          freeze: false,
        }}
        onFinish={onFinish}
      >
        <Form.Item className="form-freeze">
          <Form.Item name="freeze" valuePropName="checked">
            <Switch size="small" disabled={isJustView} />
          </Form.Item>
          <div className="wrap-txt">
            <Text>{translate('settingPage.freezeIncidentReporting')}</Text>
          </div>
        </Form.Item>

        <Form.Item noStyle shouldUpdate={(p, n) => p.freeze !== n.freeze}>
          {({ getFieldValue }) => {
            const isFreeze: boolean = getFieldValue('freeze');

            if (!isFreeze) return null;

            return (
              <div className="wrap-form">
                <Space size={24} className="space-form-input">
                  <Form.Item
                    name="from"
                    label={translate('settingPage.freezeIncidentReporting.from')}
                    rules={[
                      {
                        required: true,
                        message: translate('settingPage.freezeIncidentReporting.fromRequired'),
                      },
                    ]}
                  >
                    <DatePicker
                      allowClear
                      clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
                      size="large"
                      format="YYYY/MM/DD"
                      placeholder="yyyy/mm/dd"
                      disabledDate={disabledFromDate}
                      showToday={false}
                      locale={locale}
                    />
                  </Form.Item>
                  <Form.Item
                    name="to"
                    label={translate('settingPage.freezeIncidentReporting.to')}
                    rules={[
                      {
                        required: true,
                        message: translate('settingPage.freezeIncidentReporting.toRequired'),
                      },
                    ]}
                  >
                    <DatePicker
                      allowClear
                      clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
                      size="large"
                      format="YYYY/MM/DD"
                      placeholder="yyyy/mm/dd"
                      disabledDate={disabledToDate}
                      showToday={false}
                    />
                  </Form.Item>
                </Space>

                <Form.Item noStyle shouldUpdate={(p, n) => p.from !== n.from || p.to !== n.to}>
                  {({ getFieldValue }) => {
                    const startDate = getFieldValue('from');
                    const endDate = getFieldValue('to');

                    const isDisabled: boolean = !startDate || !endDate;

                    return (
                      <Button
                        htmlType="submit"
                        type="primary"
                        size="large"
                        className="btn-apply"
                        disabled={isJustView || isDisabled}
                      >
                        {translate('apply')}
                      </Button>
                    );
                  }}
                </Form.Item>
              </div>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
};

export default React.memo(FreezeIncident);
