/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Modal, Space, Form, Input, Row, Col, DatePicker, Radio } from 'antd';
import { ERepeatHoliday, IFormHolidayProps } from 'pages/setting/interfaces';
import React from 'react';
import moment, { Moment } from 'moment';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { CloseCircleFilled } from '@ant-design/icons';
import ConfirmButton from 'components/common/ConfirmButton';
import { translate } from 'libs';

const FormHoliday = ({
  isVisible,
  onCancel,
  isLoading,
  onFinish,
  selectedItem,
}: IFormHolidayProps) => {
  const [form] = Form.useForm();

  const isEdited: boolean = React.useMemo(() => !!selectedItem, [selectedItem]);

  const getHoliday = React.useCallback(() => {
    form.setFieldsValue({
      ...selectedItem,
      start_date: moment(selectedItem?.start_date),
      end_date: moment(selectedItem?.end_date),
    });
  }, [selectedItem]);

  React.useEffect(() => {
    if (selectedItem && isVisible) {
      getHoliday();
    } else {
      if (isVisible) form.resetFields();
    }

    return () => {
      if (selectedItem && isVisible) {
        form.resetFields();
      }
    };
  }, [selectedItem, isVisible]);

  // const disabledStartDate = (date: Moment) => {
  //   const endDate = form.getFieldValue('end_date');

  //   if (date.isBefore(moment(new Date()))) return true;

  //   if (endDate) {
  //     const addOneMin: any = moment(endDate).add(0, 'minutes');
  //     return date.isAfter(new Date(addOneMin));
  //   }

  //   return false;
  // };

  const disabledEndDate = (date: Moment) => {
    const startDate = form.getFieldValue('start_date');

    if (startDate) {
      const addOneMin: any = moment(startDate).subtract(0, 'minutes');
      return date.isBefore(new Date(addOneMin));
    }

    return false;
  };

  return (
    <Modal
      title={translate(`settingPage.automatedResponse.${isEdited ? 'edit' : 'create'}Holiday`)}
      centered
      maskClosable={false}
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <ConfirmButton
          className="btn-save"
          buttonTitle={
            isEdited
              ? translate('settingPage.automatedResponse.editHoliday.save')
              : translate('settingPage.automatedResponse.createBtn')
          }
          onClick={() => form.submit()}
          form={form}
          keyName="large"
          size="large"
          isLoading={isLoading}
        />,
        <Button key="cancel" className="btn-cancel" size="large" onClick={onCancel}>
          {translate('settingPage.automatedResponse.editHoliday.cancel')}
        </Button>,
      ]}
      className="modal md-form-holiday"
    >
      <Form
        onFinish={onFinish}
        form={form}
        requiredMark={false}
        layout="vertical"
        initialValues={{ type: ERepeatHoliday.NONE }}
        scrollToFirstError
      >
        <Form.Item
          name="title"
          label={translate('settingPage.automatedResponse.holidayName')}
          rules={[
            {
              required: true,
              whitespace: true,
              message: translate('settingPage.automatedResponse.holidayNameRequired'),
            },
          ]}
        >
          <Input
            size="large"
            placeholder={translate('settingPage.automatedResponse.inputName')}
            className="input-name"
            maxLength={256}
          />
        </Form.Item>

        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name="start_date"
              rules={[
                {
                  required: true,
                  message: translate('settingPage.automatedResponse.startDateRequired'),
                },
              ]}
              label={translate('settingPage.automatedResponse.startDate')}
            >
              <DatePicker
                format="YYYY/MM/DD HH:mm"
                placeholder={translate('settingPage.automatedResponse.input.placeholder')}
                size="large"
                showTime
                showHour
                showMinute
                showSecond={false}
                showNow={false}
                // disabledDate={disabledStartDate}
                locale={locale}
                allowClear
                clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="end_date"
              rules={[
                {
                  required: true,
                  message: translate('settingPage.automatedResponse.endDateRequired'),
                },
              ]}
              label={translate('settingPage.automatedResponse.endDate')}
            >
              <DatePicker
                format="YYYY/MM/DD HH:mm"
                placeholder={translate('settingPage.automatedResponse.input.placeholder')}
                size="large"
                showTime
                showHour
                showMinute
                showSecond={false}
                showNow={false}
                disabledDate={disabledEndDate}
                locale={locale}
                allowClear
                clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="type">
          <Radio.Group className="radio-repeat">
            <Space direction="vertical" size={16}>
              <Radio value={ERepeatHoliday.NONE}>
                {translate('settingPage.automatedResponse.repeatNone')}
              </Radio>
              <Radio value={ERepeatHoliday.YEARLY}>
                {translate('settingPage.automatedResponse.repeatYearly')}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default React.memo(FormHoliday);
