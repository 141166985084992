import { Button, Modal, Space, Typography } from 'antd';
import React from 'react';
import { useAppSelector } from 'app/hooks';
import { get } from 'lodash';
import { translate } from 'libs';

interface ModalProps {
  handleDelete: () => void;
  handleCancel: () => void;
  isVisible: boolean;
  modalTitle: string;
  deleteButtonTitle: string;
  confirmText?: string;
  isLoading?: boolean;
}

const ConfirmDeleteModal = (props: ModalProps) => {
  const {
    isVisible,
    handleCancel,
    handleDelete,
    modalTitle,
    deleteButtonTitle,
    confirmText,
    isLoading,
  } = props;
  const myOrg = useAppSelector((state) => state.users.selectedOrg);

  return (
    <Modal
      title={modalTitle}
      maskClosable={false}
      centered
      visible={isVisible}
      onCancel={handleCancel}
      footer={[
        <Button
          key="large"
          type="primary"
          className="btn-save"
          size="large"
          danger
          onClick={handleDelete}
          loading={isLoading}
        >
          {deleteButtonTitle}
        </Button>,
        <Button key="cancel" className="btn-cancel" size="large" onClick={handleCancel}>
          {translate('common.cancelBtn')}
        </Button>,
      ]}
      className="modal md-unlock-user"
    >
      <Space direction="vertical" size={20} className="space-txt-unlock">
        <Typography.Text className="txt-unlock">
          {get(myOrg, 'childrens.length', '')
            ? translate('modal.confirmEdit.confirmText')
            : confirmText}
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default React.memo(ConfirmDeleteModal);
