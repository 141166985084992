import { getProfileAction, getUserRoleAction, loginAction, logoutAction } from 'actions/auth';
import { LOADING_USER_ROLE_SUCCESS } from 'actions/users';
import { Button, Form, Input, Space, Typography } from 'antd';
import { userProfile } from 'App';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { showError } from 'components/standby-notice';
import { translate } from 'libs';
import { memo, useState, Fragment, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  ACCOUNT_DEACTIVE,
  BLOCK_60MINS,
  EMAIL_NOT_EXIST,
  EMAIL_PASSWORD_WRONG,
  IP_NOT_ALLOWED,
  isEmail,
  PASSWORD_WRONG,
  INACTIVE_ORG,
} from 'utils';
import Lock60Mins from './components/Lock60Mins';
import PopupOk from './components/PopupOk';
import Wrapper from './components/Wrapper';
import { ILoginFormValues, IPayloadLogin, EPopUpTypeLogin, IPayloadGetUserRole } from './interfaces';

const LoginPage = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const [isShowLocked, setIsShowLocked] = useState<boolean>(false);
  const [popUpType, setPopUpType] = useState<EPopUpTypeLogin | string>('');
  const [timeLocked, setTimeLocked] = useState<number>(0);

  const isLoggedIn: boolean = useAppSelector((state) => state.auth.isLoggedIn);
  const isLoading: boolean = useAppSelector((state) => state.auth.isLoading);

  const returnToLogin = () => {
    setPopUpType('');
    setIsShowLocked(false);
    form.resetFields();
  };

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/');
    }
  }, [history, isLoggedIn]);

  const onLoginError = (errorCode: string, data: any) => {
    switch (errorCode) {
      case PASSWORD_WRONG:
      case EMAIL_PASSWORD_WRONG:
        form.setFields([
          {
            name: 'password',
            errors: [translate('loginPage.incorrectPw')],
          },
        ]);
        if (data?.org_setting_login_lockout) {
          setIsShowLocked(true);
        } else {
          setIsShowLocked(false);
        }
        break;
      case EMAIL_NOT_EXIST:
        form.setFields([
          {
            name: 'email',
            errors: [translate('loginPage.emailNotExist')],
          },
        ]);
        break;
      case IP_NOT_ALLOWED:
        setPopUpType(EPopUpTypeLogin.BLOCK_IP);
        break;
      case ACCOUNT_DEACTIVE:
        setPopUpType(EPopUpTypeLogin.DEACTIVE);
        break;
      case BLOCK_60MINS:
        setPopUpType(EPopUpTypeLogin.LOCK);
        setTimeLocked(data.time);
        break;
      default:
        if (errorCode !== INACTIVE_ORG) {
          showError(translate(`errors.code.${errorCode}`));
        }
        break;
    }
  };

  const onFinish = (values: ILoginFormValues) => {
    const params: IPayloadLogin = {
      values,
      onSuccess: () => {
        loadingUserRole();
        dispatch(
          getProfileAction({
            onSuccess: (res: any) => {
              userProfile.profile = res.root_org_id;
            },
          })
        );
      },
      onError: (errorCode: string, data: any) => onLoginError(errorCode, data),
    };

    return dispatch(loginAction(params));
  };

  const loadingUserRole = useCallback(() => {
    const payload: IPayloadGetUserRole = {
      onSuccess: (data: any) => {
        let roles: any = [];
        if (Array.isArray(data)) {
          data.map((organization: any) => {
            if (organization?._id === data?.[0]._id) {
              /**
               * Just pick first root
               */
              let newSchools: any = [];

              roles = [organization?.role?.[0]?.role_name];

              if (organization.childrens?.length) {
                newSchools = organization.childrens.map((school: any) => {
                  let newGrades: any = [];

                  // newActiveKey.push(school._id);
                  roles = [...roles, school?.role?.[0]?.role_name];
                  if (school.childrens?.length) {
                    newGrades = school.childrens.map((grade: any) => {
                      roles = [...roles, grade?.role?.[0]?.role_name];
                      return {
                        ...grade,
                        isGrade: true,
                        title: grade?.name,
                        key: `${organization?._id}-${school?._id}-${grade?._id}`,
                      };
                    });
                  }

                  return {
                    ...school,
                    title: school?.name,
                    children: newGrades,
                    key: `${organization?._id}-${school?._id}`,
                  };
                });
              }

              return {
                ...organization,
                title: organization?.name,
                children: newSchools,
                key: organization?._id,
                disableCheckbox: true,
              };
            }

            return null;
          })
            .filter((e) => !!e);
          const highestRole = roles.sort()[0];
          dispatch({ type: LOADING_USER_ROLE_SUCCESS, payload: { highestRole } });
        }
      },
      onError: (e) => {
        if (e === 'ADU10002') {
          dispatch(logoutAction());
          history.push("/admin/no-permission")
        }
      }
    };
    return dispatch(getUserRoleAction(payload));
  }, [dispatch, history]);

  const renderPopUp = () => {
    switch (popUpType) {
      case EPopUpTypeLogin.LOCK: {
        return <Lock60Mins returnToLogin={returnToLogin} mins={timeLocked} />;
      }
      case EPopUpTypeLogin.BLOCK_IP: {
        return (
          <PopupOk
            icon="/images/ic-no-permission.png"
            title={translate('loginPage.ipNotAcceptTitle')}
            description={translate('loginPage.ipNotAcceptDescription')}
            handleOk={returnToLogin}
          />
        );
      }
      case EPopUpTypeLogin.DEACTIVE: {
        return (
          <PopupOk
            icon="/images/ic-lock.png"
            title={translate('loginPage.accountDeactive')}
            handleOk={returnToLogin}
            isDeactiveForm
            isReturnLogin
          />
        );
      }
      default:
        break;
    }
  };

  return (
    <Wrapper>
      {!popUpType ? (
        <Fragment>
          <div className="logo">
            <img src="/images/logo.png" alt="Stand By" />
          </div>

          <Form onFinish={onFinish} layout="vertical" form={form} scrollToFirstError>
            <Space direction="vertical" className="space-login">
              <Form.Item
                label={translate('loginPage.login.email')}
                name="email"
                rules={[
                  { required: true, message: translate('loginPage.emailRequired') },
                  () => ({
                    validator(_rule, value) {
                      if (!value) return Promise.reject();

                      const checkEmail: boolean = isEmail(value);

                      if (checkEmail) {
                        return Promise.resolve();
                      }

                      return Promise.reject(new Error(translate('loginPage.emailFormat')));
                    },
                  }),
                ]}
              >
                <Input
                  size="large"
                  placeholder={translate('loginPage.emailPlaceholder')}
                  allowClear
                  className="input-email"
                  maxLength={256}
                  autoComplete="off"
                />
              </Form.Item>

              <div>
                <Form.Item>
                  <Form.Item
                    label={translate('loginPage.password')}
                    name="password"
                    rules={[{ required: true, message: translate('loginPage.passwordRequired') }]}
                  >
                    <Input.Password
                      size="large"
                      placeholder={translate('loginPage.passwordPlaceholder')}
                      className="input-password"
                      allowClear
                      maxLength={16}
                      autoComplete="off"
                    />
                  </Form.Item>
                  <Link to="/auth/forgot-password">
                    <Button className="login-form-forgot" type="link">
                      {translate('loginPage.forgotPassword')}
                    </Button>
                  </Link>
                </Form.Item>

                {isShowLocked && (
                  <div className="txt-locked">
                    <Typography.Text type="danger">
                      {translate('loginPage.lock60mins')}
                    </Typography.Text>
                  </div>
                )}

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    block
                    size="large"
                    className={EPopUpTypeLogin.LOCK ? 'btn-lock' : 'btn-login'}
                    loading={isLoading}
                  >
                    {translate('loginPage.login')}
                  </Button>
                </Form.Item>
              </div>
            </Space>
          </Form>
        </Fragment>
      ) : (
        renderPopUp()
      )}
    </Wrapper>
  );
};

export default memo(LoginPage);
