import { userProfile } from 'App';
import i18n from 'config/i18n';
import { Trans } from 'react-i18next';

const typeBuild = {
  ENTERPRISE: 2,
  EDUCATIONAL: 1,
  MEDICAL: 3,
};

export function translate(key: string, values?: any, businessType?: string) {
  if (businessType) {
    return i18n.t(`type${businessType}.${key}`, values);
  }
  let { business_type } = userProfile.profile;
  let type = typeBuild[business_type];
  if (!type) {
    type = 1;
  }
  return i18n.t(`type${type}.${key}`, values);
}

const Translate = (props: any) => {
  const { i18nKey } = props;
  let { business_type } = userProfile.profile;
  let type = typeBuild[business_type];
  if (!type) {
    type = 1;
  }
  return <Trans {...props} i18nKey={`type${type}.${i18nKey}`} />;
};

export * from './axios';
export * from './history';
export default Translate;
