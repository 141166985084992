import React from 'react';
import './index.scss';
import SettingUpload from './components/upload';
import SettingServey from './components/survey';

const OtherSettings = () => {
  return (
    <>
      <SettingUpload />
      <SettingServey />
    </>
  );
};

export default OtherSettings;
