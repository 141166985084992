import { INCIDENATATTR_FAMILY_LOADED } from 'actions/incidentAttr';
import { ActionProps } from 'reducers';

const initialState = {
  listAttrs: [],
};

const IncidentAttrReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case INCIDENATATTR_FAMILY_LOADED:
      return { ...state, listAttrs: action.payload };
    default:
      return state;
  }
};

export { IncidentAttrReducer };
