import Axios, { AxiosRequestConfig } from 'axios';
import { notification } from 'antd';

import { API_URL, MAINTENANCE_API } from '../config';
import { storage } from '../utils';
import nProgress from 'nprogress';
import store from 'app/store';
import { refreshTokenAction, logoutAction } from 'actions/auth';
import { IPayloadRefreshToken } from 'pages/auth/interfaces';
import { history, translate } from 'libs';
import {
  ACCOUNT_DEACTIVE,
  FORBIDDEN,
  IP_NOT_ALLOWED,
  LINK_EXPIRED,
  SYSTEM_MAINTENANCE,
  NOT_SHOW_ERROR_CODE,
  INACTIVE_ORG,
  DELETED_ORG1,
  DELETED_ORG2,
} from 'utils/common';
import { get } from 'lodash';

const TIMEOUT: number = 40000; // 40s

nProgress.configure({
  minimum: 0.8,
  easing: 'ease',
  speed: 500,
  showSpinner: false,
});

const API = Axios.create({
  baseURL: API_URL,
  timeout: TIMEOUT,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Max-Age': 6000000,
    'Cache-Control': 'no-cache, no-store, must-revalidate',
  },
});

const maintenanceApi = Axios.create({
  baseURL: MAINTENANCE_API,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// for request
API.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    nProgress.start();
    const token = storage.getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    //config.params['iv'] = Date.now();
    return config;
  },
  (error) => {
    nProgress.done();
    return Promise.reject(error);
  }
);

// for response
API.interceptors.response.use(
  (response) => {
    nProgress.done();
    return response.data;
  },
  async (error) => {
    nProgress.done();
    try {
      if (error?.response?.status === 401) {
        const refreshToken: string = storage.getRefreshToken();
        if (!refreshToken) {
          return store.dispatch(logoutAction());
        }
        const payload: IPayloadRefreshToken = {
          refresh_token: refreshToken,
        };
        return store.dispatch(refreshTokenAction(payload));
      }
      if (error?.response?.status === 400) {
        if (error?.response?.data?.error_code === ACCOUNT_DEACTIVE) {
          history.push({
            pathname: '/admin/user-inactive',
          });
          return Promise.reject(error?.response);
        }
        if (error?.response?.data?.error_code === LINK_EXPIRED) {
          history.push({
            pathname: '/admin/link-expired',
          });
          return Promise.reject(error?.response);
        }

        if (error?.response?.data?.error_code === INACTIVE_ORG) {
          notification.error({
            message: translate(`errors.code.${error?.response?.data?.error_code}`),
          });
          return Promise.reject(error?.response);
        }
        throw error;
      }
      if (error?.response?.status === 403) {
        if (
          error?.response?.data?.error_code === ACCOUNT_DEACTIVE ||
          error?.response?.data?.error_code === DELETED_ORG1 ||
          error?.response?.data?.error_code === DELETED_ORG2
        ) {
          history.push({
            pathname: '/admin/user-inactive',
          });
          return Promise.reject(error?.response);
        }

        if (error?.response?.data?.error_code === IP_NOT_ALLOWED) {
          history.push({
            pathname: '/admin/block-ip',
          });
          return Promise.reject(error?.response);
        }
        if (error?.response?.data?.error_code === FORBIDDEN) {
          history.push({
            pathname: '/admin/no-permission',
          });
          return Promise.reject(error?.response);
        }
        throw error;
      }
      if (error?.response?.status === 503) {
        if (error?.response?.data?.error_code === SYSTEM_MAINTENANCE) {
          history.push({
            pathname: '/system/maintenance',
            search: 'maintaince=1',
          });
          return Promise.reject(error?.response);
        }
      }
      const isMaintaince = await maintenanceApi.get('');
      if (get(isMaintaince, 'isMaintenance')) {
        history.push({
          pathname: '/system/maintenance',
          search: 'maintaince=1',
        });
        return Promise.reject(error?.response);
      }
      throw error;
    } catch {
      if (!error || !error.response) {
        notification.error({
          message: translate('error.network.fail'),
        });
        return Promise.reject(error?.response);
      }
      if (error.response.status === 500) {
        notification.error({
          message: translate('error.common'),
        });
        return Promise.reject(error?.response);
      }
      showError(error?.response);
      return error?.response.data;
      // TODO: throw error to handle in sagas
      // return Promise.reject(error?.response);
    }
  }
);

const showError = (response: any) => {
  if (response?.status === 400 || response?.status === 401) return;
  if (NOT_SHOW_ERROR_CODE.includes(response?.data?.errorCode)) return;
  // const haveErrorMessage = i18n.exists(`errors.code.${errorCode}`);
  // if (haveErrorMessage) {
  notification.error({
    message: translate(`errors.code.${response?.data?.errorCode}`),
  });
  // }
};
export default API;
