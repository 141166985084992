import { Button, Modal, Space, Typography } from 'antd';
import { translate } from 'libs';
import { IReactivateProps } from 'pages/setting/interfaces';
import React from 'react';

const Reactivate = ({
  isVisible,
  onCancel,
  isLoading,
  onReactivate,
  numberUsers,
}: IReactivateProps) => {
  return (
    <Modal
      title={translate('settingPage.reactiveUser.title')}
      centered
      maskClosable={false}
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button
          key="large"
          type="primary"
          className="btn-save"
          size="large"
          loading={isLoading}
          onClick={onReactivate}
        >
          {translate('settingPage.deactivatedUser.reactivate')}
        </Button>,
        <Button key="cancel" className="btn-cancel" size="large" onClick={onCancel}>
          {translate('common.cancelBtn')}
        </Button>,
      ]}
      className="modal md-reactivate"
    >
      <Space direction="vertical" size={20} className="space-txt-reactivate">
        <Typography.Text className="txt-reactivate">
          {numberUsers > 1
            ? translate('settingPage.deactivatedUser.sureReactivateWithNumber', { numberUsers })
            : translate('settingPage.deactivatedUser.sureReactivateWithNumber')}
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default React.memo(Reactivate);
