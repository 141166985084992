import { useEffect, useState } from 'react';
import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Space, Spin, Tooltip } from 'antd';
import { INCIDENT_ATTR_TYPES } from 'api/incident-attr';
import lodash from 'lodash';
import {
  IElementIncidentType,
  IPayloadActionCRUDIncidentAttrByOrg,
  IPayloadGetListIncidentAttrByOrg,
  IPayloadOrderIncidentAttr,
} from 'pages/setting/interfaces';
import CRUD from './CRUD';
import FlagIcon from '@material-ui/icons/Flag';
import { useAppDispatch, useMyOrganizationID, useAppSelector } from 'app/hooks';
import {
  actionCRUDListIncidentAttr,
  getListIncidentAttrByOrg,
  ordersIncidentAttr,
} from 'actions/incidentAttr';
import { showSuccess, showError } from 'components/standby-notice';
import ConfirmDeleteModal from 'components/common/ConfirmDeleteModal';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';
import { ModalConfirm } from 'components/modal-confirm';
import { translate } from 'libs';

export const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'pointer', marginRight: 20 }} />
));

const ListIncidentManagement = ({ name = '', type, activeTab }) => {
  const dispatch = useAppDispatch();

  const [list, setList] = useState<IElementIncidentType[]>([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [selected, setSelected] = useState<IElementIncidentType | null>(null);
  const [selectedDelete, setSelectedDelete] = useState<IElementIncidentType | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [payloadOrder, setPayloadOrder] = useState<any>({});
  const myOrg = useAppSelector((state) => state.users.selectedOrg);

  const myOrgId: string = useMyOrganizationID();
  const handleModalOk = (data) => {
    setLoading(true);

    handleCancelModal();

    const payload: IPayloadActionCRUDIncidentAttrByOrg = {
      data,
      onSuccess: () => {
        setLoading(false);
        showSuccess(
          translate(
            `settingPage.incidentManagement.${
              data.action === 'ADD' ? 'add' : 'edit'
            }${type}.success`
          )
        );
        setTimeout(() => {
          getListAttr();
        }, 0);
      },
      onError: (error_code, data) => {
        if (error_code) {
          showError(translate(`errors.code.${error_code}`));
        }
        setLoading(false);
      },
    };

    return dispatch(actionCRUDListIncidentAttr(payload));
  };

  const getListAttr = () => {
    setLoading(true);
    const payload: IPayloadGetListIncidentAttrByOrg = {
      query: {
        organization_id: myOrgId,
        type,
      },
      onSuccess: (data = {}) => {
        const newList: any[] = lodash.get(data, 'values', []);
        setList(newList);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    };

    dispatch(getListIncidentAttrByOrg(payload));
  };

  useEffect(() => {
    if (activeTab !== type) {
      setList([]);
      return;
    }
    console.log('useEffect');
    setList([]);
    getListAttr();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [myOrgId, activeTab]);

  const handleClickEdit = (ele: IElementIncidentType) => {
    if (!ele || !ele.editable) return;
    setSelected(ele);
    setShowModal(true);
  };

  const handleClickDelete = (ele: IElementIncidentType) => {
    if (!ele || !ele.editable) return;
    setShowModalDelete(true);
    setSelectedDelete(ele);
  };

  const deleteApi = () => {
    if (!selectedDelete) return;
    setLoading(true);
    const payload: IPayloadActionCRUDIncidentAttrByOrg = {
      data: {
        action: 'REMOVE',
        type: selectedDelete?.type,
        id: selectedDelete?._id,
        organization_id: selectedDelete.org_id,
      },
      onSuccess: () => {
        setLoading(false);
        showSuccess(translate(`settingPage.incidentManagement.delete${type}.success`));
        setTimeout(() => {
          getListAttr();
        }, 0);
      },
      onError: (error_code, data) => {
        console.log(error_code);
        setLoading(false);
        showError(translate(`error.common`));
      },
    };
    dispatch(actionCRUDListIncidentAttr(payload));
  };

  const handleCancelModal = () => {
    setSelected(null);
    setShowModal(false);
  };

  const handleCreateNew = () => {
    setSelected(null);
    setShowModal(true);
  };

  const SortableItem = SortableElement(({ value }) => {
    const ele = value;
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
          height: 48,
          boxShadow: 'inset 0px -1px 0px rgba(144, 147, 153, 0.2)',
        }}
      >
        <div className="left">
          <DragHandle />
          {type === INCIDENT_ATTR_TYPES.PRIORITY ? (
            <div>
              <FlagIcon style={{ color: ele.color, marginRight: 10 }} />
              <span>{ele.name}</span>
            </div>
          ) : (
            ele.name
          )}
        </div>
        <div>
          {ele.editable ? (
            <Space size={24}>
              <Tooltip placement="topLeft" title={translate('setting.tooltip.editIcon')}>
                <img
                  alt=""
                  src="/images/ic-edit.png"
                  className="ic-edit"
                  onClick={() => handleClickEdit(ele)}
                />
              </Tooltip>

              <Tooltip placement="topLeft" title={translate('setting.tooltip.deleteIcon')}>
                <img
                  alt=""
                  src="/images/ic-delete.png"
                  className="ic-delete"
                  onClick={() => handleClickDelete(ele)}
                />
              </Tooltip>
            </Space>
          ) : null}
        </div>
      </div>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([...list], oldIndex, newIndex).filter((el) => !!el);
      // setList(newData);
      const payload: IPayloadOrderIncidentAttr = {
        data: {
          type,
          orders: newData.map((ele) => ele._id),
          organization_id: myOrgId,
        },
        onSuccess: () => {
          setLoading(false);
          showSuccess(translate(`settingPage.incidentManagement.move${type}.success`));
          getListAttr();
        },
        onError: (errorCode: string, data?: any) => {
          // TODO
          setLoading(false);
          showError(translate('error.common'));
        },
      };
      setPayloadOrder(payload);
      if (!myOrg.childrens || !myOrg.childrens.length) {
        dispatch(ordersIncidentAttr(payload));
        return;
      }
      setIsModalOpen(true);
    }
  };

  const SortableList = SortableContainer(({ children }) => {
    return <ul className="wrap-list">{children}</ul>;
  });

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleConfirm = () => {
    setLoading(true);
    dispatch(ordersIncidentAttr(payloadOrder));
    setIsModalOpen(false);
  };

  return (
    <Spin spinning={loading} size="small">
      <div className="list-incident-management">
        <div className="header-list">
          <div className="title">{name}</div>
          <div className="add">
            <Button
              type="primary"
              icon={<PlusOutlined />}
              onClick={handleCreateNew}
              className="btn-add"
            >
              {translate(`settingPage.incidentManagement.${type}.add`)}
            </Button>
          </div>
        </div>
        <div className="body">
          {list.length ? (
            <SortableList onSortEnd={onSortEnd} useDragHandle>
              {list.map((value, index) => (
                <SortableItem key={`${value._id}`} index={index} value={value} />
              ))}
            </SortableList>
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {translate('no_data', { name })}
            </div>
          )}
        </div>
      </div>

      <CRUD
        isOpen={showModal}
        type={type}
        dataSelected={selected}
        name={name}
        handleCancel={handleCancelModal}
        handleOk={handleModalOk}
      />
      <ConfirmDeleteModal
        isVisible={showModalDelete}
        handleDelete={() => {
          deleteApi();
          setShowModalDelete(false);
        }}
        handleCancel={() => {
          setShowModalDelete(false);
        }}
        modalTitle={translate(`settingPage.incidentManagement.delete${type}.title`)}
        confirmText={translate(`settingPage.incidentManagement.delete${type}.body`)}
        deleteButtonTitle={translate(`settingPage.incidentManagement.delete${type}.delete`)}
        isLoading={loading}
      />
      <ModalConfirm
        textConfirmBtn={translate('settingMenu.message.saveBtn')}
        visible={isModalOpen}
        handleCancel={handleCancel}
        textConfirm={translate('modal.confirmEdit.confirmText')}
        title={translate('modal.confirmEdit.title')}
        handleOk={handleConfirm}
      />
    </Spin>
  );
};

export default ListIncidentManagement;
