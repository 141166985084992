import {
  IActionDeleteBroadcast,
  IActionResendBroadcast,
  IActionUpdateBroadcast,
  IPayloadDeleteBroadcast,
  IPayloadResendBroadcast,
  IPayloadUpdateBroadcast,
} from 'pages/broadcasts/interfaces/detail-broadcast';
import {
  IActionCreateBroadcast,
  IActionGetBroadcastByOrg,
  IPayloadCreateBroadcast,
  IPayloadGetBroadcastByOrg,
} from 'pages/setting/interfaces/broadcast';

export const LOADING_BROADCAST_LIST = 'LOADING_BROADCAST_LIST';
export const LOADING_BROADCAST_LIST_SUCCESS = 'LOADING_BROADCAST_LIST_SUCCESS';
export const LOADING_BROADCAST_LIST_ERROR = 'LOADING_BROADCAST_LIST_ERROR';

export const CREATE_BROADCAST = 'CREATE_BROADCAST';
export const CREATE_BROADCAST_SUCCESS = 'CREATE_BROADCAST_SUCCESS';
export const CREATE_BROADCAST_ERROR = 'CREATE_BROADCAST_ERROR';

export const UPDATE_BROADCAST = 'UPDATE_BROADCAST';
export const UPDATE_BROADCAST_SUCCESS = 'UPDATE_BROADCAST_SUCCESS';
export const UPDATE_BROADCAST_ERROR = 'UPDATE_BROADCAST_ERROR';

export const DELETE_BROADCAST = 'DELETE_BROADCAST';
export const DELETE_BROADCAST_SUCCESS = 'DELETE_BROADCAST_SUCCESS';
export const DELETE_BROADCAST_ERROR = 'DELETE_BROADCAST_ERROR';

export const RESEND_BROADCAST = 'RESEND_BROADCAST';
export const RESEND_BROADCAST_SUCCESS = 'RESEND_BROADCAST_SUCCESS';
export const RESEND_BROADCAST_ERROR = 'RESEND_BROADCAST_ERROR';

export const UPDATE_PUBLIC_BROADCAST = 'UPDATE_PUBLIC_BROADCAST';
export const UPDATE_PUBLIC_BROADCAST_SUCCESS = 'UPDATE_PUBLIC_BROADCAST_SUCCESS';
export const UPDATE_PUBLIC_BROADCAST_ERROR = 'UPDATE_PUBLIC_BROADCAST_ERROR';

export const getBroadcastByOrgAction = (
  payload: IPayloadGetBroadcastByOrg
): IActionGetBroadcastByOrg => ({
  type: LOADING_BROADCAST_LIST,
  payload,
});

export const createBroadcastAction = (
  payload: IPayloadCreateBroadcast
): IActionCreateBroadcast => ({
  type: CREATE_BROADCAST,
  payload,
});

export const updateBroadcastAction = (
  payload: IPayloadUpdateBroadcast
): IActionUpdateBroadcast => ({
  type: UPDATE_BROADCAST,
  payload,
});

export const deleteBroadcastAction = (
  payload: IPayloadDeleteBroadcast
): IActionDeleteBroadcast => ({
  type: DELETE_BROADCAST,
  payload,
});

export const resendBroadcastAction = (
  payload: IPayloadResendBroadcast
): IActionResendBroadcast => ({
  type: RESEND_BROADCAST,
  payload,
});

export const updatePublicBroadcast = (payload) => ({
  type: UPDATE_PUBLIC_BROADCAST,
  payload,
});
