import { all } from 'redux-saga/effects';
import loginSaga from './auth';
import organizationSaga from './organization';
import incidentAttrSaga from './incidentAttr';
import usersSaga from './users';
import incidentQuestionSaga from './incident-question';
import securitySaga from './security';
import limitMessageSaga from './limit-message';
import automatedResponseSaga from './automated-response';
import sosSaga from './sos';
import incidentsSaga from './incidents';
import broadcastSaga from './broadcast';
import reportsSaga from './reports';
import notificationsSaga from './notifications';

export default function* rootSaga() {
  yield all([
    loginSaga(),
    organizationSaga(),
    usersSaga(),
    incidentAttrSaga(),
    incidentQuestionSaga(),
    securitySaga(),
    limitMessageSaga(),
    automatedResponseSaga(),
    sosSaga(),
    incidentsSaga(),
    broadcastSaga(),
    reportsSaga(),
    notificationsSaga(),
  ]);
}
