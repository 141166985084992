import { Modal, Button, Typography } from 'antd';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { translate } from 'libs';

interface IProps {
  title?: string;
  visible: boolean;
  handleOk?: () => void;
  handleCancel: () => void;
  textConfirm?: string;
  contentText?: string;
  textConfirmBtn?: string;
  isDeleted?: boolean;
  width?: number;
  loading?: boolean;
  t: any;
}

class ModalConfirm extends Component<IProps> {
  shouldComponentUpdate(nProps: IProps) {
    const { visible } = this.props;
    return visible !== nProps.visible;
  }

  render() {
    const {
      title = '',
      visible,
      handleOk,
      handleCancel,
      contentText,
      textConfirm,
      isDeleted = false,
      loading = false,
      textConfirmBtn,
      width = 800,
      t,
    } = this.props;
    return (
      <Modal
        title={title || t('modal.title.header.default')}
        visible={visible}
        maskClosable={false}
        onCancel={handleCancel}
        centered
        footer={[
          textConfirmBtn && (
            <Button
              danger={isDeleted}
              className="btn-save"
              size="large"
              key="confirm"
              type="primary"
              onClick={handleOk}
              loading={loading}
            >
              {textConfirmBtn}
            </Button>
          ),
          <Button key="cancel" className="btn-cancel" size="large" onClick={handleCancel}>
            {translate('common.cancelBtn')}
          </Button>,
        ]}
        className="modal modal-confirm"
        width={width}
        destroyOnClose
      >
        <p style={{ width: '100%' }}>
          {textConfirm && (
            <Typography.Text className="txt-confirm break-line-localize">
              {textConfirm}
            </Typography.Text>
          )}
          {contentText && (
            <Typography.Text className="txt-confirm break-line-localize">
              {contentText}
            </Typography.Text>
          )}
        </p>
      </Modal>
    );
  }
}

export default withTranslation()(ModalConfirm);
