import { Button, Form, Input, notification, Space, Typography } from 'antd';
import { memo, useEffect, useState } from 'react';
import { ITwoFactorFormValues, IPayloadLogin, IPayloadResendCodeTfa } from '../interfaces';
import Wrapper from './Wrapper';
import { useHistory, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { getProfileAction, loginAction, resendCodeTfaAction } from 'actions/auth';
import { SESSION_INVALID, TFA_EXPIRED, TFA_INVALID } from 'utils';
import { LeftOutlined } from '@ant-design/icons/lib/icons';
import { translate } from 'libs';

const TwoFactorAuthenticate = () => {
  const [isLoadingResendCode, setIsLoadingResendCode] = useState<boolean>(false);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { state }: any = useLocation();

  const isLoggedIn: boolean = useAppSelector((state) => state.auth.isLoggedIn);
  const isLoading: boolean = useAppSelector((state) => state.auth.isLoading);

  const [form] = Form.useForm();

  useEffect(() => {
    if (!state || isLoggedIn) {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onVerifyError = (errorCode: string) => {
    switch (errorCode) {
      case TFA_INVALID:
        form.setFields([
          {
            name: 'code',
            errors: [translate('tfaPage.codeInvalid')],
          },
        ]);
        break;
      case TFA_EXPIRED:
        form.setFields([
          {
            name: 'code',
            errors: [translate('tfaPage.codeExpired')],
          },
        ]);
        break;
      case SESSION_INVALID:
        form.setFields([
          {
            name: 'code',
            errors: [translate('tfaPage.sessionInvalid')],
          },
        ]);

        setTimeout(() => {
          history.push('/auth/login');
        }, 2000);

        break;
      default:
        break;
    }
  };

  const onFinish = ({ code }: ITwoFactorFormValues) => {
    const params: IPayloadLogin = {
      values: {
        email: state?.email,
        password: state?.password,

        challenger_name: state?.challenger_name,
        session: state?.session,
        challenger_answer: code,
      },
      onSuccess: () => {
        dispatch(getProfileAction({}));
      },
      onError: (errorCode: string) => onVerifyError(errorCode),
    };

    dispatch(loginAction(params));
  };

  const handleResendCode = () => {
    setIsLoadingResendCode(true);
    const params: IPayloadResendCodeTfa = {
      session: state?.session,
      onSuccess: () => setIsLoadingResendCode(false),
      onError: (errorCode: string) => handleErrorResendCode(errorCode),
    };
    dispatch(resendCodeTfaAction(params));
  };

  const handleErrorResendCode = (errorCode: string) => {
    setIsLoadingResendCode(false);
    switch (errorCode) {
      case SESSION_INVALID:
        notification.error({ message: translate('tfaPage.sessionInvalid') });

        setTimeout(() => {
          history.push('/auth/login');
        }, 2000);

        break;
      default:
        break;
    }
  };

  return (
    <Wrapper>
      <div className="lock-block">
        <Button style={{}} onClick={() => history.goBack()}>
          <LeftOutlined />
          {translate('button.Back.title')}
        </Button>

        <div className="two-fac-icon">
          <img src="/images/ic-two-factor.png" alt="Two Factor" />
        </div>

        <Typography.Text className="txt-title">{translate('tfaPage.title')}</Typography.Text>

        <div className="description">
          <Typography.Text className="txt-description">
            {translate('tfaPage.description')}
          </Typography.Text>
        </div>

        <Form
          onFinish={onFinish}
          layout="vertical"
          form={form}
          scrollToFirstError
          requiredMark={false}
          className="form-tow-factor"
        >
          <Space size={0} className="space-code-form">
            <Form.Item
              label={translate('tfaPage.code')}
              rules={[
                { required: true, message: translate('tfaPage.codeRequired') },
                { pattern: new RegExp(/^[0-9]*$/), message: translate('tfaPage.codeNotValid') },
              ]}
              name="code"
            >
              <Input
                placeholder={translate('tfaPage.codePlaceholder')}
                className="input-code-tfa"
                maxLength={6}
                allowClear
              />
            </Form.Item>
            <Button type="primary" htmlType="submit" className="btn-verify" loading={isLoading}>
              {translate('tfaPage.verify')}
            </Button>
          </Space>
        </Form>

        <div className="description">
          <Typography.Text className="txt-description">
            {translate('tfaPage.takeMinutes')}
            <Button
              type="link"
              onClick={handleResendCode}
              loading={isLoadingResendCode}
              style={{ marginLeft: 0 }}
            >
              {translate('tfaPage.resendNewCode')}
            </Button>
          </Typography.Text>
        </div>
      </div>
    </Wrapper>
  );
};

export default memo(TwoFactorAuthenticate);
