import { AA, OS } from 'utils';

export const filterOrgsByName = (role: string, orgTree: any, filterString: string) => {
  if (!orgTree || !filterString) {
    return orgTree;
  }
  const isRootOrgActive = role === AA || role === OS;
  if (isRootOrgActive && orgTree.name.toLowerCase().includes(filterString)) return orgTree;
  const result = {
    ...orgTree,
    children: orgTree.children
      .filter(
        (org) =>
          org.name.toLowerCase().includes(filterString) ||
          org.children.filter((child) => child.name.toLowerCase().includes(filterString)).length > 0
      )
      .map((level1Org) => ({
        ...level1Org,
        children: level1Org.children.filter(
          (level2Org) =>
            level2Org.name.toLowerCase().includes(filterString) ||
            level1Org.name.toLowerCase().includes(filterString)
        ),
      })),
  };
  if (result.children.length === 0) {
    return null;
  }
  return result;
};
