/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import {
  DownOutlined,
  InfoCircleFilled,
  RightOutlined,
  StepBackwardOutlined,
} from '@ant-design/icons';
import { Button, Modal, Typography, Table, Checkbox, Space, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  EOptIns,
  ICheckOptIns,
  IOptInsProps,
  IPayloadGetDetailUser,
  IPayloadGetOptInsUser,
} from 'pages/setting/interfaces';
import { OA } from 'utils';
import { getDetailUserAction, getOptInsUserAction } from 'actions/users';
import { getUserOptInsAction } from 'actions/auth';
import { get } from 'lodash';
import { translate } from 'libs';

const OptIns = ({
  isVisible,
  onCancel,
  selectedItem,
  organizations,
  onFinish,
  handleCheckOptIns,
  checkOptIns,
  handleInitOptIns,
  isEditMyprofile,
}: IOptInsProps) => {
  const dispatch = useAppDispatch();

  const isLoading: boolean = useAppSelector((state) => state.users.isLoading);
  const role: string = useAppSelector((state) => state.auth.role);

  const profile: any = useAppSelector((state) => state.auth.profile);
  const isOA: boolean = React.useMemo(() => role === OA, [role]);
  const rootOrgId: string = React.useMemo(() => organizations?.[0]?._id, [organizations]);
  const isHaveRoleRoot: boolean = React.useMemo(
    () => organizations?.[0]?.role?.length > 0,
    [organizations]
  );

  const [isLoadingPage, setLoadingPage] = React.useState(false);
  const [orgRoles, setOrgRoles] = React.useState<any>([]);

  const getUser = React.useCallback(
    (userId: string, callback) => {
      const payload: IPayloadGetDetailUser = {
        query: {
          user_id: userId,
        },
        onSuccess: (data: any) => {
          const orgs = get(data, 'organizations', []);
          mapListOrgId(orgs);
          if (callback) callback(userId);
        },
        onError: () => {
          onCancel();
        },
      };

      return dispatch(getDetailUserAction(payload));
    },
    [selectedItem]
  );

  const mapListOrgId = (orgs: any[]) => {
    const ids: any = [];

    function loop(org, isPushIdOrgRoot = true) {
      if (isPushIdOrgRoot) {
        ids.push(org['_id']);
      }
      if (org['childrens'] && org['childrens'].length > 0) {
        org['childrens'].forEach((ele) => {
          loop(ele);
        });
      }
    }

    orgs.forEach((org: any) => {
      const isRoot = org?.role.length > 0;
      loop(org, isRoot);
    });
    setOrgRoles(ids);
  };

  const getOptIns = React.useCallback(
    (userId: string) => {
      const isUserLogin: boolean = userId === profile?._id;

      const onHandle = {
        onSuccess: (data: any) => {
          handleInitOptIns(data?.organizations);
          setLoadingPage(false);
        },
        onError: () => {
          onCancel();
        },
      };

      const payload: IPayloadGetOptInsUser = {
        query: {
          user_id: userId,
        },
        ...onHandle,
      };

      if (isUserLogin) {
        return dispatch(getUserOptInsAction(onHandle));
      }

      return dispatch(getOptInsUserAction(payload));
    },
    [selectedItem]
  );

  React.useEffect(() => {
    if (isEditMyprofile) {
      setLoadingPage(true);
      getOptIns(selectedItem?._id);
      return;
    }
    if (selectedItem && isVisible && !isEditMyprofile) {
      setLoadingPage(true);
      getUser(selectedItem?._id, () => getOptIns(selectedItem?._id));
    }
  }, [selectedItem, isVisible]);

  const renderTitleTable = (title: string) => {
    return (
      <Space>
        <Typography.Text>{translate(`settingPage.adminUser.${title}`)}</Typography.Text>
        <Tooltip title={translate(`settingPage.adminUser.${title}.description`)}>
          <InfoCircleFilled className="ic-info" />
        </Tooltip>
      </Space>
    );
  };

  const handleCheck = (event: any, type: EOptIns, orgId: string) => {
    const { checked } = event.target;
    const isCheckParent: boolean = orgId === rootOrgId;

    handleCheckOptIns(checked, type, orgId, isCheckParent);
  };

  const getValueChecked = React.useCallback(
    (id: string, type: EOptIns): boolean => {
      const isChecked: boolean = checkOptIns.some(
        (item: ICheckOptIns) => item.organization_id === id && item.type === type
      );

      return isChecked;
    },
    [checkOptIns]
  );

  const getValueDisabled = React.useCallback(
    (id: string, type: EOptIns): boolean => {
      if (isOA && id === rootOrgId) return true;

      const isRootChecked: boolean = checkOptIns.some(
        (item: ICheckOptIns) => item.organization_id === rootOrgId && item.type === type
      );

      if (id !== rootOrgId && isRootChecked) return true;

      const isDisabled: boolean = checkOptIns.some(
        (item: ICheckOptIns) => item.organization_id === id && item.type === type && item.disabled
      );
      return isDisabled;
    },
    [checkOptIns, rootOrgId, isOA]
  );

  const columns: any[] = [
    {
      title: translate('settingPage.ogSetup.ogName'),
      dataIndex: 'name',
      key: 'name',
      width: 430,
      ellipsis: {
        showTitle: false,
      },
      render: (name: string) => {
        return (
          <Tooltip placement="bottomLeft" title={name}>
            <Typography.Text>{name}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: renderTitleTable('incidentEmails'),
      dataIndex: 'incidentEmails',
      key: 'incidentEmails',
      render: (_: any, record: any) => {
        if (isOA && record?._id === rootOrgId) return null;

        if (!isHaveRoleRoot && record?.isOrg && record?.role?.length === 0) return null;

        if (!isHaveRoleRoot && record?.isSchool && record?.role?.length === 0) return null;

        if (!orgRoles.includes(record?._id) && !isEditMyprofile)
          return <Checkbox disabled={true} />;

        return (
          <Checkbox
            onChange={(event: any) => handleCheck(event, EOptIns.INCIDENT_MAIL, record?._id)}
            checked={getValueChecked(record?._id, EOptIns.INCIDENT_MAIL)}
            disabled={getValueDisabled(record?._id, EOptIns.INCIDENT_MAIL)}
          />
        );
      },
      align: 'center',
    },
    {
      title: renderTitleTable('incidentSms'),
      dataIndex: 'incidentSms',
      key: 'incidentSms',
      render: (_: any, record: any) => {
        if (isOA && record?._id === rootOrgId) return null;

        if (!isHaveRoleRoot && record?.isOrg && record?.role?.length === 0) return null;

        if (!isHaveRoleRoot && record?.isSchool && record?.role?.length === 0) return null;

        if (!orgRoles.includes(record?._id) && !isEditMyprofile)
          return <Checkbox disabled={true} />;

        return (
          <Checkbox
            onChange={(event: any) => handleCheck(event, EOptIns.INCIDENT_SMS, record?._id)}
            checked={getValueChecked(record?._id, EOptIns.INCIDENT_SMS)}
            disabled={getValueDisabled(record?._id, EOptIns.INCIDENT_SMS)}
          />
        );
      },
      align: 'center',
    },
    {
      title: renderTitleTable('messengerEmail'),
      dataIndex: 'messengerEmail',
      key: 'messengerEmail',
      render: (_: any, record: any) => {
        if (isOA && record?._id === rootOrgId) return null;

        if (!isHaveRoleRoot && record?.isOrg && record?.role?.length === 0) return null;

        if (!isHaveRoleRoot && record?.isSchool && record?.role?.length === 0) return null;

        if (!orgRoles.includes(record?._id) && !isEditMyprofile)
          return <Checkbox disabled={true} />;

        return (
          <Checkbox
            onChange={(event: any) => handleCheck(event, EOptIns.MESSAGE_MAIL, record?._id)}
            checked={getValueChecked(record?._id, EOptIns.MESSAGE_MAIL)}
            disabled={getValueDisabled(record?._id, EOptIns.MESSAGE_MAIL)}
          />
        );
      },
      align: 'center',
    },
    {
      title: renderTitleTable('messengerSms'),
      dataIndex: 'messengerSms',
      key: 'messengerSms',
      render: (_: any, record: any) => {
        if (isOA && record?._id === rootOrgId) return null;

        if (!isHaveRoleRoot && record?.isOrg && record?.role?.length === 0) return null;

        if (!isHaveRoleRoot && record?.isSchool && record?.role?.length === 0) return null;

        if (!orgRoles.includes(record?._id) && !isEditMyprofile)
          return <Checkbox disabled={true} />;

        return (
          <Checkbox
            onChange={(event: any) => handleCheck(event, EOptIns.MESSAGE_SMS, record?._id)}
            checked={getValueChecked(record?._id, EOptIns.MESSAGE_SMS)}
            disabled={getValueDisabled(record?._id, EOptIns.MESSAGE_SMS)}
          />
        );
      },
      align: 'center',
    },
  ];

  return (
    <Modal
      destroyOnClose
      title={translate('settingPage.adminUser.optInPre')}
      centered
      visible={isVisible}
      onCancel={onCancel}
      maskClosable={false}
      footer={[
        <Button
          key="large"
          type="primary"
          className="btn-save"
          size="large"
          onClick={onFinish}
          loading={isLoading}
        >
          {translate('settingPage.adminUser.optInPre.saveBtn')}
        </Button>,
        <Button key="cancel" className="btn-cancel" size="large" onClick={onCancel}>
          {translate('settingPage.adminUser.optInPre.cancelBtn')}
        </Button>,
      ]}
      className="modal md-opt-in"
    >
      <div className="wrap-txt-info">
        <Typography.Text className="txt-info">
          {translate('settingPage.adminUser.optInfo')}
        </Typography.Text>
      </div>

      <Table
        columns={columns}
        dataSource={organizations}
        className="board"
        rowKey="_id"
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => {
            if (Array.isArray(record?.children) && record.children.length > 0) {
              return expanded ? (
                <DownOutlined className="ic-expand" onClick={(e) => onExpand(record, e)} />
              ) : (
                <RightOutlined className="ic-expand" onClick={(e) => onExpand(record, e)} />
              );
            }

            return <StepBackwardOutlined className="fake-expand" />;
          },
          defaultExpandAllRows: true,
        }}
        pagination={false}
        loading={isLoadingPage}
        locale={{ emptyText: translate('no_data', { name: translate('settingPage.optIn.title') }) }}
      />
    </Modal>
  );
};

export default React.memo(OptIns);
