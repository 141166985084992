/* eslint-disable react-hooks/exhaustive-deps */
import {
  changeShowHiddenOrganizationAction,
  loadingOrganizationAction,
} from 'actions/organization';
import { selectedOrgAction, selectedOrgIDAction } from 'actions/users';
import { Menu, Layout, Typography, Space, Switch, Button, Dropdown } from 'antd';
import { useAppDispatch, useAppSelector, useReadOnly } from 'app/hooks';
import { Organizations } from 'components/common';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { OA, AA, OS, MANAGE_ROLES } from 'utils';
import AdminUserSetup from './components/admin-user/AdminUserSetup';
import OrganizationSetup from './components/organization/OrganizationSetupV2';
import IncidentQuestion from './components/incident-question/IncidentQuestion';
import FreezeIncident from './components/freeze-incident/FreezeIncident';
import DeactivatedUser from './components/deactived-user/ListDeactivatedUser';
import AutomatedResponse from './components/automated-response/AutomatedResponse';
import Profile from './components/profile/Profile';
import IncidentManagement from './components/incident-management';
import IncidentMessage from './components/incident-message';
import Security from './components/security';
import SOSSetup from './components/sos-setup';
import {
  ISettingPageProps,
  ESettingPage,
  IPayloadLoadingOrganization,
  IPayloadSelectedOrganizationID,
  ESelectOrganizationValues,
} from './interfaces';
import './setting.scss';
import { isEmpty } from 'lodash';
import { translate } from 'libs';
import OtherSettings from './components/other-settings';

const { Header, Sider, Content } = Layout;

const menus = [
  {
    label: 'profile',
    children: [
      { label: 'ogSetup', path: '/admin/setting/organizations' },
      { label: 'adminUserSetup', path: '/admin/setting/users' },
    ],
  },
  {
    label: 'admin',
    children: [
      { label: 'incidentManagement', path: '/admin/setting/incident-management' },
      { label: 'security', path: '/admin/setting/security' },
    ],
  },
  {
    label: 'app',
    children: [
      { label: 'sosSetup', path: '/admin/setting/sos-setup' },
      { label: 'incidentQuestion', path: '/admin/setting/incident-question' },
      { label: 'automatedResponse', path: '/admin/setting/automated-response' },
      // { label: 'freezeIncidentReporting', path: '/admin/setting/freeze-incident-reporting' },
      {
        label: 'messageClosedIncidentDisplay',
        path: '/admin/setting/message-closed-incident-display',
      },
      { label: 'deactivatedUser', path: '/admin/setting/deactivated-user' },
      { label: 'otherSettings', path: '/admin/setting/other-settings' },
    ],
  },
];

const Setting = ({ page }: ISettingPageProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const keySearch: string = useAppSelector((state) => state.organizations.keySearch);
  const isLoading: boolean = useAppSelector((state) => state.organizations.isLoading);
  const isShowHiddenOrganization: boolean = useAppSelector(
    (state) => state.organizations.isShowHiddenOrganization
  );
  const role: string = useAppSelector((state) => state.auth.role);
  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );

  const isOA: boolean = React.useMemo(() => role === OA, [role]);
  const isAAOS: boolean = React.useMemo(() => [AA, OS].includes(role), [role]);

  const isJustView: boolean = useReadOnly();
  const [isCollapsed] = useState<boolean>(false);
  const [selectedMenu, setSelectedMenu] = useState<string[]>([page]);
  const [isVisibleSelectOrg, setIsVisibleSelectOrg] = useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = useState<any>(null);
  const myOrg = useAppSelector((state) => state.users.selectedOrg);

  useEffect(() => {
    if (page !== ESettingPage.ORGANIZATION_SETUP) {
      initSelectOrganization();
    }
  }, [permissionOrganizations]);

  const initSelectOrganization = React.useCallback(() => {
    /**
     * Loading Org first
     * check @isOA - loading first org of OA
     */
    if (isAAOS && page === ESettingPage.SECURITY) {
      onSelectedOrganization(permissionOrganizations?.[0]);
      return;
    }
    if (isAAOS && page === ESettingPage.OTHER_SETTINGS) {
      onSelectedOrganization(permissionOrganizations?.[0]);
      return;
    }
    if (MANAGE_ROLES.includes(myOrg.role_name)) {
      onSelectedOrganization(myOrg);
      return;
    }
    if (isAAOS) {
      onSelectedOrganization(permissionOrganizations?.[0]);
      return;
    }
    let firstOrgOA: any = permissionOrganizations?.[0]?.children?.[0]; // init default first school
    if (Array.isArray(permissionOrganizations)) {
      for (const organization of permissionOrganizations) {
        const checkRoleOrganization: string | null = organization?.role?.[0]?.role_name;
        if (!!checkRoleOrganization) {
          // firstOrgOA = firstOrgOA;
          break;
        } else {
          /**
           * Have not role at root organization, check role in school
           */
          if (Array.isArray(organization?.childrens)) {
            for (const school of organization.childrens) {
              if (Array.isArray(school?.role)) {
                const checkRoleSchool: string | null = school?.role?.[0]?.role_name;
                if (checkRoleSchool === OA) {
                  firstOrgOA = school;
                  break;
                } else {
                  /**
                   * Have not role at root school, check role in grade
                   */
                  if (Array.isArray(school?.childrens)) {
                    for (const grade of school.childrens) {
                      if (Array.isArray(grade?.role)) {
                        const checkRoleGrade: string | null = grade?.role?.[0]?.role_name;
                        if (checkRoleGrade === OA) {
                          firstOrgOA = grade;
                          break;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    if (page === ESettingPage.ADMIN_USER_SETUP || page === ESettingPage.DEACTIVATED_USER) {
      onSelectedOrganization(null);
      return;
    }
    onSelectedOrganization(firstOrgOA);
    return;
  }, [permissionOrganizations, page]);

  const handleChangeMenu = ({ selectedKeys }: any) => {
    setSelectedMenu(selectedKeys);
  };

  const onClickMenuItem = (path: string) => {
    history.push(path);
  };

  const handleVisibleOrg = (visible: boolean) => {
    setIsVisibleSelectOrg(visible);
  };

  const renderPage = () => {
    switch (page) {
      case ESettingPage.ORGANIZATION_SETUP:
        return <OrganizationSetup />;
      case ESettingPage.ADMIN_SETUP:
        return <Profile />;
      case ESettingPage.ADMIN_USER_SETUP:
        return <AdminUserSetup />;
      case ESettingPage.INCIDENT_MANAGEMENT:
        return <IncidentManagement />;
      case ESettingPage.MESSAGE_CLOSE_INCIDENT_DISPLAY:
        return <IncidentMessage />;
      case ESettingPage.OTHER_SETTINGS:
        return <OtherSettings />;
      case ESettingPage.SECURITY:
        return <Security />;
      case ESettingPage.INCIDENT_QUESTION:
        return <IncidentQuestion selectedOrg={selectedOrg} />;
      case ESettingPage.AUTOMATED_RESPONSE:
        return <AutomatedResponse />;
      case ESettingPage.FREEZE_INCIDENT_REPORTING:
        return <FreezeIncident />;
      case ESettingPage.DEACTIVATED_USER:
        return <DeactivatedUser />;
      case ESettingPage.SOS_SETUP:
        return <SOSSetup />;
      default:
        break;
    }
  };

  const checkVisibleSelectOrganization = (): boolean => {
    if (page === ESettingPage.SECURITY) return false;
    if (page === ESettingPage.OTHER_SETTINGS) return false;
    return isVisibleSelectOrg;
  };

  const renderExtraHeader = () => {
    switch (page) {
      case ESettingPage.ORGANIZATION_SETUP:
        return (
          <Space className="show-hidden-og" align="center">
            <Switch
              size="small"
              onChange={handleChangeHideShow}
              checked={isShowHiddenOrganization}
              loading={isLoading}
              className="switch-hidden tiny-switch-common"
            />
            <Typography.Text className="txt-show-hidden">
              {translate('settingPage.ogSetup.showHidden')}
            </Typography.Text>
          </Space>
        );
      case ESettingPage.MESSAGE_CLOSE_INCIDENT_DISPLAY:
      case ESettingPage.OTHER_SETTINGS:
      case ESettingPage.SOS_SETUP:
      case ESettingPage.DEACTIVATED_USER:
      case ESettingPage.AUTOMATED_RESPONSE:
      case ESettingPage.FREEZE_INCIDENT_REPORTING:
      case ESettingPage.SECURITY:
      case ESettingPage.INCIDENT_MANAGEMENT:
      case ESettingPage.INCIDENT_QUESTION:
      case ESettingPage.ADMIN_USER_SETUP: {
        const checkDisabledAmInCharge = (): boolean => {
          if ([ESettingPage.ADMIN_USER_SETUP, ESettingPage.DEACTIVATED_USER].includes(page))
            return false;

          return true;
        };

        return (
          <Dropdown
            overlay={
              <Organizations
                onSelected={onSelectedOrganization}
                isDisabledAmInCharge={checkDisabledAmInCharge()}
                isManage
              />
            }
            trigger={['click']}
            overlayClassName="dropdown-organizations"
            onVisibleChange={handleVisibleOrg}
            visible={checkVisibleSelectOrganization()}
          >
            <Button
              size="large"
              className={`btn-account-og ${(isOA || isJustView) && 'is-oa'}`}
              icon={
                <span className={`icon-right ${(isOA || isJustView) && 'is-oa'}`}>
                  <i className="fa fa-chevron-down" />
                </span>
              }
            >
              {selectedOrg
                ? selectedOrg?.name
                : isOA || isJustView
                ? translate('settingPage.adminUser.allCharge')
                : permissionOrganizations?.[0]?.name}
            </Button>
          </Dropdown>
        );
      }
      default:
        break;
    }
  };

  const onSelectedOrganization = useCallback((selectedItem: any) => {
    handleVisibleOrg(false);
    const payload: IPayloadSelectedOrganizationID = {
      selectedOrgId: selectedItem?._id || ESelectOrganizationValues.ALL_IN_CHARGE,
      onSuccess: () => {},
    };
    if (isEmpty(selectedItem)) {
      setSelectedOrg(selectedItem);
      dispatch(selectedOrgIDAction(payload));
      dispatch(
        selectedOrgAction({
          selectedOrg: { name: '' },
        })
      );
      return;
    } else {
      const { props, title, access_codes, ...newOptionOrg } = selectedItem;
      setSelectedOrg(newOptionOrg);
      const payloadOrgAction: any = {
        selectedOrg: newOptionOrg || ESelectOrganizationValues.ALL_IN_CHARGE,
      };
      dispatch(selectedOrgAction(payloadOrgAction));
      dispatch(selectedOrgIDAction(payload));
    }
    return;
  }, []);

  const loadingOrganizations = useCallback(
    (checked: boolean, name: string, callback?: () => void) => {
      const payload: IPayloadLoadingOrganization = {
        query: {
          show_hidden: checked,
          name,
        },
        onError: (errorCode: string) => {
          console.log('🚀 ~ errorCode', errorCode);
          dispatch(changeShowHiddenOrganizationAction(checked));
        },
        onSuccess: () => {
          if (callback) {
            callback();
          }
          dispatch(changeShowHiddenOrganizationAction(checked));
        },
      };
      return dispatch(loadingOrganizationAction(payload));
    },
    [keySearch]
  );

  const handleChangeHideShow = (checked: boolean) => {
    loadingOrganizations(checked, keySearch);
  };

  const disableMenu = (label_menu: string) => {
    if (label_menu === ESettingPage.SECURITY && isOA) {
      return true;
    }

    if (label_menu === ESettingPage.OTHER_SETTINGS && isOA) {
      return true;
    }

    return false;
  };

  return (
    <div className="setting">
      <Layout className="menu-layout">
        <Sider
          className="layout-menu-left"
          trigger={null}
          collapsible
          collapsed={isCollapsed}
          theme="light"
        >
          <div className="logo" />
          <Menu
            theme="light"
            mode="inline"
            className="menu-setting"
            selectedKeys={selectedMenu}
            onSelect={handleChangeMenu}
          >
            {menus.map(({ label, children }, index) => (
              <Menu.ItemGroup key={label} title={translate(`settingMenu.${label}`)}>
                {children.map(({ label: lbChild, path }) => (
                  <Menu.Item
                    key={lbChild}
                    onClick={() => onClickMenuItem(path)}
                    disabled={disableMenu(lbChild)} // disable page Security with role OA
                  >
                    {translate(`settingMenu.${lbChild}`)}
                  </Menu.Item>
                ))}
                {index < menus.length - 1 ? <Menu.Divider /> : null}
              </Menu.ItemGroup>
            ))}
          </Menu>
        </Sider>
        <Layout className="site-layout ">
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div className="content-setting-page">
              <Header className="header-setting" style={{ padding: 0 }}>
                <div className="header-item">
                  <div />
                  {renderExtraHeader()}
                </div>
              </Header>
              <Content>
                {page === ESettingPage.ORGANIZATION_SETUP ||
                MANAGE_ROLES.includes(myOrg.role_name) ||
                !myOrg.name
                  ? renderPage()
                  : null}
              </Content>
            </div>
          </div>
        </Layout>
      </Layout>
    </div>
  );
};

export default memo(Setting);
