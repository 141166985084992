/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import './styles/common.scss';
import { Redirect, Route, useHistory, useLocation, useParams } from 'react-router-dom';
import { PrivateRouter } from 'components/common';
import { PrivateLayout } from 'components/Layout';
import LoginPage from 'pages/auth/LoginPage';
import TwoFactorPage from 'pages/auth/components/TwoFactorAuthenticate';
import ForgotPasswordPage from 'pages/auth/components/ForgotPassword';
import BlockIpPage from 'pages/auth/components/Block-Ip';
import { useAppSelector } from 'app/hooks';
import ShareIncident from 'components/share-incident';
import { OS, updateLanguage } from 'utils';
import { ELanguage, IPayloadGetUserRole } from 'pages/auth/interfaces';
import UserInactivePage from 'pages/auth/components/UserInactive';
import NoPermisisonPage from 'pages/auth/components/NoPermission';
import LinkExpiredPage from 'pages/auth/components/LinkExpired';
import { Fragment } from 'react';
import 'moment/locale/ja';
import SpinCenter from 'components/spin-center';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';
import { translate } from 'libs';
import { useDispatch } from 'react-redux';
import { getProfileAction, getUserRoleAction, logoutAction } from 'actions/auth';
import { LOADING_USER_ROLE_SUCCESS } from 'actions/users';
import SystemMaintemancePage from 'pages/auth/components/SystemMaintenance';

let userProfile: any = {
  profile: {},
};

const App = () => {
  const role: string = useAppSelector((state) => state.auth.role);
  const isLoggedIn: boolean = useAppSelector((state) => state.auth.isLoggedIn);
  const profile: any = useAppSelector((state) => state.auth.profile);
  const history = useHistory();
  const { search } = useLocation();
  const queryString = new URLSearchParams(search);

  const isOS: boolean = React.useMemo(() => role === OS, [role]);

  const myLanguage: ELanguage = ELanguage.JAPANESE;

  const { ready } = useTranslation();
  const dispatch = useDispatch();

  const loadingUserRole = useCallback(() => {
    const payload: IPayloadGetUserRole = {
      onSuccess: (data: any) => {
        let roles: any = [];
        if (Array.isArray(data)) {
          data
            .map((organization: any) => {
              if (organization?._id === data?.[0]._id) {
                /**
                 * Just pick first root
                 */
                let newSchools: any = [];

                roles = [organization?.role?.[0]?.role_name];

                if (organization.childrens?.length) {
                  newSchools = organization.childrens.map((school: any) => {
                    let newGrades: any = [];

                    // newActiveKey.push(school._id);
                    roles = [...roles, school?.role?.[0]?.role_name];
                    if (school.childrens?.length) {
                      newGrades = school.childrens.map((grade: any) => {
                        roles = [...roles, grade?.role?.[0]?.role_name];
                        return {
                          ...grade,
                          isGrade: true,
                          title: grade?.name,
                          key: `${organization?._id}-${school?._id}-${grade?._id}`,
                        };
                      });
                    }

                    return {
                      ...school,
                      title: school?.name,
                      children: newGrades,
                      key: `${organization?._id}-${school?._id}`,
                    };
                  });
                }

                return {
                  ...organization,
                  title: organization?.name,
                  children: newSchools,
                  key: organization?._id,
                  disableCheckbox: true,
                };
              }

              return null;
            })
            .filter((e) => !!e);
          const highestRole = roles.sort()[0];
          dispatch({ type: LOADING_USER_ROLE_SUCCESS, payload: { highestRole } });
        }
      },
      onError: (e) => {
        if (e === 'ADU10002') {
          dispatch(logoutAction());
          history.push('/admin/no-permission');
        }
      },
    };
    return dispatch(getUserRoleAction(payload));
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(
        getProfileAction({
          onSuccess: (res: any) => {
            userProfile.profile = res.root_org_id;
          },
        })
      );
      loadingUserRole();
    }
    window.addEventListener(
      'resize',
      (event) => {
        const width = window.outerWidth;
        document
          .querySelector('meta[name=viewport]')
          ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
      },
      true
    );
    return () => {
      window.removeEventListener(
        'resize',
        (event) => {
          const width = window.outerWidth;
          document
            .querySelector('meta[name=viewport]')
            ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
        },
        true
      );
    };
  }, []);

  useEffect(() => {
    window.addEventListener(
      'load',
      (event) => {
        const width = window.outerWidth;
        document
          .querySelector('meta[name=viewport]')
          ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
      },
      true
    );
    return () => {
      window.removeEventListener(
        'load',
        (event) => {
          const width = window.outerWidth;
          document
            .querySelector('meta[name=viewport]')
            ?.setAttribute('content', `width=1366, initial-scale=${width / 1366}`);
        },
        true
      );
    };
  }, []);

  const onMessErrConnect = () => {
    return notification.error({
      message: translate(`error.network.fail`),
    });
  };

  useEffect(() => {
    window.addEventListener('online', () => {
      window.location.reload();
    });
    window.addEventListener('offline', () => {
      onMessErrConnect();
    });

    return () => {
      window.removeEventListener('online', () => ({}));
      window.removeEventListener('offline', () => ({}));
    };
  }, []);

  React.useEffect(() => {
    updateLanguage(myLanguage);
  }, [myLanguage]);

  const redirectTo = (): string => {
    if (isLoggedIn && role) {
      if (isOS) {
        return (window.location.href = '/admin/setting/organizations');
      }
      return '/admin/incidents';
    }
    return '/auth/login';
  };

  if (queryString.has('maintaince')) {
    return <SystemMaintemancePage />;
  }

  if (!ready || (!profile._id && isLoggedIn) || (isLoggedIn && !role)) return <SpinCenter />;
  return (
    <Fragment>
      <Route path="/auth/login" component={LoginPage} />
      <Route path="/auth/two-factor" component={TwoFactorPage} exact strict />
      <Route path="/auth/forgot-password" component={ForgotPasswordPage} />
      <Route
        exact
        strict
        path="/auth/new-password"
        component={() => <ForgotPasswordPage isCreateNewPassword />}
      />
      <Route path="/admin/share-incident" component={ShareIncident} />
      <Route path="/admin/block-ip" component={BlockIpPage} />
      <Route path="/admin/user-inactive" component={UserInactivePage} />
      <Route path="/admin/no-permission" component={NoPermisisonPage} />
      <Route path="/admin/link-expired" component={LinkExpiredPage} />
      <Route path="/system/maintenance" component={SystemMaintemancePage} />
      <PrivateRouter>
        <PrivateLayout />
      </PrivateRouter>
      <Route exact path="/" render={() => <Redirect to={redirectTo()} />} />
    </Fragment>
  );
};

export { userProfile };

export default App;
