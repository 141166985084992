import { UPDATE_LIST_INCIDENTS_COLUMNS } from 'actions/incidents';
import { IIncidentsFilters } from 'pages/incidents/interfaces';
import { ActionProps } from 'reducers';
import { LOADING_PROFILE_SUCCESS } from 'actions/auth';
import { UPDATE_INCIDENT_FILTER } from 'actions/incidents';

const initialState: IIncidentsFilters = {
  columns: null,
  organization_id: '',
  organization_name: '',
  search: '',
  status: '',
  response_status: '',
  assigned_to_me: false,
  show_hidden: false,
  sort_by: '',
  sort_order: 1,
};

export const IncidentsFiltersReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case UPDATE_LIST_INCIDENTS_COLUMNS:
      return {
        ...state,
        columns: action.payload.columns,
      };
    case LOADING_PROFILE_SUCCESS:
    case UPDATE_INCIDENT_FILTER:
      return {
        ...state,
        ...action.payload.incident_filter,
      };
    default:
      return state;
  }
};
