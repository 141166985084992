/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Button, Dropdown, Layout, Space, Typography } from 'antd';
import { Organizations } from 'components/common';
import { useAppDispatch, useAppSelector, useMyOrganizationID } from 'app/hooks';
import { AA, OS } from 'utils';
import { selectedOrgIDAction, selectedOrgAction } from 'actions/users';
import {
  ESelectOrganizationValues,
  IPayloadSelectedOrganizationID,
} from 'pages/setting/interfaces';
import { IPayloadLoadingTotalInfoIncidents, ITotalInfo } from 'pages/incidents/interfaces';
import { loadingTotalInfoIncidentsAction, LOAD_TOTAL_INFO_SUCCESS } from 'actions/incidents';
import { isEmpty } from 'lodash';
import { translate } from 'libs';
const { Header } = Layout;
const { Text } = Typography;

const Headers = () => {
  const dispatch = useAppDispatch();

  const role: string = useAppSelector((state) => state.auth.role);

  const showHidden: boolean = useAppSelector(
    (state) => state.organizations.isShowHiddenOrganization
  );
  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );

  const isAAOS: boolean = React.useMemo(() => [AA, OS].includes(role), [role]);
  const myOrgId: string = useMyOrganizationID();
  const myOrg = useAppSelector((state) => state.users.selectedOrg);
  const firstLoadRef = React.useRef<boolean>(true);
  const [isVisibleSelectOrg, setIsVisibleSelectOrg] = React.useState<boolean>(false);
  const [selectedOrg, setSelectedOrg] = React.useState<any>(null);
  const [totalInfo, setTotalInfo] = React.useState<ITotalInfo>({
    totalNew: 0,
    totalPending: 0,
    totalUnreadMessage: 0,
  });

  React.useEffect(() => {
    initSelectOrganization();
  }, [permissionOrganizations]);
  React.useEffect(() => {
    /**
     * First load total info
     */
    loadingTotalInfo(true);
  }, [myOrgId]);

  React.useEffect(() => {
    if (myOrgId !== undefined && !firstLoadRef.current) {
      loadingTotalInfo();
    }
  }, [showHidden, myOrgId]);

  const loadingTotalInfo = React.useCallback(
    (firstLoad = false) => {
      const payload: IPayloadLoadingTotalInfoIncidents = {
        query: {
          organization_id: firstLoad && !isAAOS ? '' : myOrgId,
          show_hidden: showHidden,
        },
        onSuccess: (data: ITotalInfo) => {
          setTotalInfo(data);
          firstLoadRef.current = false;
          dispatch({ type: LOAD_TOTAL_INFO_SUCCESS, payload: data });
        },
        onError: (errorCode: string) => {
          console.log('🚀 ~ errorCode', errorCode);
        },
      };
      if (payload.query.organization_id === undefined) return;

      return dispatch(loadingTotalInfoIncidentsAction(payload));
    },
    [showHidden, myOrgId]
  );

  const initSelectOrganization = React.useCallback(() => {
    /**
     * Loading Org first
     * check @isOA - loading first org of OA
     */
    if (!isEmpty(myOrg)) {
      if (isEmpty(myOrg.name)) {
        onSelectedOrganization('');
        return;
      }
      onSelectedOrganization(myOrg);
      return;
    }
  }, [permissionOrganizations]);

  const handleVisibleOrg = (visible: boolean) => {
    setIsVisibleSelectOrg(visible);
  };

  const onSelectedOrganization = React.useCallback((selectedItem: any) => {
    handleVisibleOrg(false);
    const payload: IPayloadSelectedOrganizationID = {
      selectedOrgId: selectedItem?._id || ESelectOrganizationValues.ALL_IN_CHARGE,
    };
    if (isEmpty(selectedItem)) {
      setSelectedOrg(selectedItem);
      dispatch(selectedOrgIDAction(payload));
      dispatch(
        selectedOrgAction({
          selectedOrg: { name: '' },
        })
      );
      return;
    } else {
      const { props, title, access_codes, children, ...newOptionOrg } = selectedItem;
      setSelectedOrg(newOptionOrg);
      const payloadOrgAction: any = {
        selectedOrg: newOptionOrg || ESelectOrganizationValues.ALL_IN_CHARGE,
      };
      dispatch(selectedOrgAction(payloadOrgAction));
      dispatch(selectedOrgIDAction(payload));
    }
    return;
  }, []);

  return (
    <Header className="header-incidents">
      <div style={{ width: '15%' }} />
      <div className="wrap-info-incident" style={{ width: '65%' }}>
        <Space className="wrap-badge-incident" size={48}>
          <Space className="space-badge">
            <Text className="badge-txt">
              {translate('incidents.list.newIncident', { number: totalInfo?.totalNew })}
            </Text>
          </Space>
          <Space className="space-badge">
            <Text className="badge-txt">
              {translate('incidents.list.unreadMessenger', {
                number: totalInfo?.totalUnreadMessage,
              })}
            </Text>
          </Space>
        </Space>
      </div>
      <div className="wrap-dropdown-org">
        <Dropdown
          overlay={<Organizations onSelected={onSelectedOrganization} />}
          trigger={['click']}
          overlayClassName="dropdown-organizations"
          onVisibleChange={handleVisibleOrg}
          visible={isVisibleSelectOrg}
        >
          <Button
            size="large"
            className={`btn-account-og ${!isAAOS && 'is-oa'}`}
            icon={
              <span className={`icon-right ${!isAAOS && 'is-oa'}`}>
                <i className="fa fa-chevron-down" />
              </span>
            }
          >
            {selectedOrg
              ? selectedOrg?.name
              : !isAAOS
              ? translate('settingPage.adminUser.allCharge')
              : permissionOrganizations?.[0]?.name}
          </Button>
        </Dropdown>
      </div>
    </Header>
  );
};

export default React.memo(Headers);
