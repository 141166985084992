import { ActionProps } from 'reducers';
import {
  LOGGING_ERROR,
  LOGGING_IN,
  LOGGING_SUCCESS,
  LOGOUT_SUCCESS,
  FG_PASSWORD,
  FG_PASSWORD_SUCCESS,
  FG_PASSWORD_ERROR,
  NEW_PASSWORD,
  NEW_PASSWORD_SUCCESS,
  NEW_PASSWORD_ERROR,
  VERIFY_TFA,
  LOADING_PROFILE,
  LOADING_PROFILE_SUCCESS,
  LOADING_PROFILE_ERROR,
  UPDATE_MY_OPT_INS,
  UPDATE_MY_OPT_INS_SUCCESS,
  UPDATE_MY_OPT_INS_FAILED,
  UPDATE_PERMISSION_ORG,
  SHOW_HIDDEN_PERMISSION_ORG,
} from 'actions/auth';
import { IAuthSelector } from 'pages/auth/interfaces';
import {
  LOADING_USER_ROLE_FAILED,
  LOADING_USER_ROLE_SUCCESS,
  UPDATE_OPT_INS_USER_SUCCESS,
} from 'actions/users';
import { storage as localStorage } from 'utils';

const initialState: IAuthSelector = {
  isLoggedIn: !!localStorage.getToken(),
  profile: {},
  isLoading: false,
  role: '',
  permissionOrganizations: [],
  isLoadingPermission: true,
  showHidden: false,
  roles: [],
};

const AuthReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case FG_PASSWORD:
    case NEW_PASSWORD:
    case LOGGING_IN:
    case UPDATE_MY_OPT_INS:
    case LOADING_PROFILE:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profile: action.payload,
      };
    case LOGGING_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        isLoading: false,
      };
    case FG_PASSWORD_ERROR:
    case FG_PASSWORD_SUCCESS:
    case NEW_PASSWORD_ERROR:
    case NEW_PASSWORD_SUCCESS:
    case VERIFY_TFA:
    case LOGGING_ERROR:
    case UPDATE_MY_OPT_INS_SUCCESS:
    case UPDATE_MY_OPT_INS_FAILED:
    case UPDATE_OPT_INS_USER_SUCCESS:
    case LOADING_PROFILE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState,
        isLoggedIn: false,
      };
    case LOADING_USER_ROLE_SUCCESS: {
      return {
        ...state,
        role: action.payload.highestRole,
        roles: action.payload.roles ? action.payload.roles : state.roles,
      };
    }
    case LOADING_USER_ROLE_FAILED:
      return {
        ...state,
        role: '',
        permissionOrganizations: [],
      };
    case UPDATE_PERMISSION_ORG:
      return {
        ...state,
        isLoadingPermission: false,
        permissionOrganizations: action.payload,
      };
    case SHOW_HIDDEN_PERMISSION_ORG:
      return {
        ...state,
        showHidden: action.payload,
      };
    default:
      return state;
  }
};

const pAuthReducer = AuthReducer;

export default pAuthReducer;
