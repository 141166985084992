import { logoutAction } from 'actions/auth';
import { cancelNoticeResponseAction } from 'actions/notifications';
import { Button, Modal, Typography } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import SpinCenter from 'components/spin-center';
import { translate } from 'libs';
import { ETypeNoticeResponse } from 'pages/notifications/interfaces';
import React from 'react';

const { Text } = Typography;

const NoticeResponse = () => {
  const dispatch = useAppDispatch();

  const typeNoticeResponse: ETypeNoticeResponse = useAppSelector(
    (state) => state.notifications.typeNoticeResponse
  );

  const isVisble: boolean = React.useMemo(
    () => typeNoticeResponse !== ETypeNoticeResponse.NONE,
    [typeNoticeResponse]
  );

  React.useEffect(() => {
    // dispatch(visibleNoticeResponseAction(ETypeNoticeResponse.EXPIRED));

    return () => {
      dispatch(cancelNoticeResponseAction());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOk = () => {
    if (typeNoticeResponse === ETypeNoticeResponse.DEACTIVE) {
      dispatch(logoutAction());
    }

    dispatch(cancelNoticeResponseAction());
  };

  const renderIcon = (): string => {
    let src: string = '';

    switch (typeNoticeResponse) {
      case ETypeNoticeResponse.NO_PERMISSION:
        src = '/images/ic-no-permission.png';
        break;
      case ETypeNoticeResponse.EXPIRED:
        src = '/images/ic-link-expired.png';
        break;
      case ETypeNoticeResponse.MAINTANCE:
        src = '/images/ic-maintain.png';
        break;
      case ETypeNoticeResponse.DEACTIVE:
        src = '/images/ic-lock.png';
        break;
      default:
        break;
    }

    return src;
  };

  const renderTitle = (): string => {
    let title: string = '';

    switch (typeNoticeResponse) {
      case ETypeNoticeResponse.NO_PERMISSION:
        title = translate('loginPage.noPermissAlert');
        break;
      case ETypeNoticeResponse.EXPIRED:
        title = translate('loginPage.linkExpiredAlert');
        break;
      case ETypeNoticeResponse.MAINTANCE:
        title = translate('loginPage.maintainAlert');
        break;
      case ETypeNoticeResponse.DEACTIVE:
        title = translate('loginPage.accountDeactive');
        break;
      default:
        break;
    }

    return title;
  };

  const renderDescription = (): React.ReactNode => {
    const allDescriptions: string[] = [];

    switch (typeNoticeResponse) {
      case ETypeNoticeResponse.NO_PERMISSION:
        allDescriptions.push(translate('loginPage.noPermissDescription'));
        break;
      case ETypeNoticeResponse.EXPIRED:
        allDescriptions.push(
          translate('loginPage.linkExpiredDescription1'),
          translate('loginPage.linkExpiredDescription2')
        );
        break;

      default:
        break;
    }

    const mapAllDescriptions = allDescriptions.map((description: string, index: number) => (
      <Text className="txt-description" key={`description-${index}`}>
        {description}
      </Text>
    ));

    return mapAllDescriptions;
  };

  const renderTitleButton = (): string => {
    let title: string = translate('common.ok');

    switch (typeNoticeResponse) {
      case ETypeNoticeResponse.DEACTIVE:
        title = translate('loginPage.returnToLogin');
        break;
      default:
        break;
    }

    return title;
  };

  return (
    <Modal
      centered
      visible={isVisble}
      width={567}
      footer={null}
      maskClosable={false}
      closable={false}
      className="modal modal-notice-response"
    >
      {isVisble ? (
        <div className="popup-response">
          <div className="popup-icon">
            <img src={renderIcon()} alt="" />
          </div>

          <div className="popup-info">
            <Text className="txt-title">{renderTitle()}</Text>
          </div>

          <div className="popup-description">{renderDescription()}</div>

          <Button type="primary" block className="btn-ok" onClick={handleOk}>
            {renderTitleButton()}
          </Button>
        </div>
      ) : (
        <SpinCenter />
      )}
    </Modal>
  );
};

export default React.memo(NoticeResponse);
