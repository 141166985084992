import { SECURITY_LOADED } from 'actions/security';
import { ActionProps } from 'reducers';

const initialState = {
  listSecurity: [],
};

const SecurityReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case SECURITY_LOADED:
      return { ...state, listSecurity: action.payload };
    default:
      return state;
  }
};

export { SecurityReducer };
