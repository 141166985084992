import React, { useState } from 'react';
import { Button, Input, Tooltip } from 'antd';
import { IChangeNameOrg } from 'pages/setting/interfaces';
import { useAppSelector, useComponentVisible } from 'app/hooks';
import { OA } from 'utils';
import { translate } from 'libs';

const maxNamOrg = 70;
interface IProps {
  org: any;
  callback: (value: IChangeNameOrg) => void;
}

const InputName = ({ org, callback }: IProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(true);
  const [isEditable, setEditable] = useState<boolean>(org?.is_editing);
  const [value, setValue] = useState<string>(org?.name);
  const isRoot = !org.parent_id;
  const userRole: string = useAppSelector((state) => state.auth.role);
  const isOA: boolean = React.useMemo(() => userRole === OA, [userRole]);

  const renderClassnamByRole = () => {
    if (isOA && isRoot) {
      return 'edit-hidden';
    }

    return 'edit-show';
  };

  return (
    <div className={`item ${isRoot ? 'text-org-root' : 'text-org-child'}`} ref={ref}>
      {!isEditable || !isComponentVisible ? (
        <span className="text-org-name-trancate">{org?.name}</span>
      ) : null}
      {isEditable && isComponentVisible ? (
        <div className="wrap-button">
          <div>
            <Input
              className="input-edit"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              maxLength={maxNamOrg}
            />
          </div>
          <div>
            <Button
              type="primary"
              onClick={() => {
                if (!value && !value.trim()) {
                  setValue(org?.name);
                  setIsComponentVisible(false);
                  return setEditable(false);
                }

                callback({
                  org_id: org?._id,
                  new_name: value,
                  old_name: org?.name,
                });
                setEditable(false);
              }}
            >
              {translate('settingPage.ogSetup.saveBtn')}
            </Button>
          </div>
          <div>
            <Button
              onClick={() => {
                setValue(org?.name);
                setEditable(false);
                setIsComponentVisible(false);
              }}
            >
              {translate('common.cancelBtn')}
            </Button>
          </div>
        </div>
      ) : (
        <div className={`${renderClassnamByRole()}`} onClick={() => setEditable(true)}>
          <Tooltip title={translate('setting.tooltip.editIcon')}>
            <img
              onClick={() => {
                setEditable(true);
                setValue(org?.name);
                setIsComponentVisible(true);
              }}
              src={`/images/ic-edit.png`}
              width="20"
              alt=""
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default InputName;
