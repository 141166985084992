import {
  IActionChangeShowHiddenOrganization,
  IActionCheckAccessCodeOrganization,
  IActionCreateOrganization,
  IActionDetailOrganization,
  IActionExpandOrganization,
  IActionLoadingOrganiztion,
  IActionSearchOrganization,
  IActionStructureOrganization,
  IActionUpdateOrganization,
  IHideMultiOrganizationAction,
  IMultiActionOrganization,
  IPayloadCheckAccessCode,
  IPayloadCreateOrganization,
  IPayloadDetailOrganization,
  IPayloadGetCreateTestIncident,
  IPayloadGetSettingServey,
  IPayloadGetSettingUpload,
  IPayloadHideMultiOrganization,
  IPayloadLoadingOrganization,
  IPayloadMultiActionOrganization,
  IPayloadStructureOrganization,
  IPayloadUpdateOrganization,
  IPayloadUpdateSettingUpload,
} from 'pages/setting/interfaces';

export const LOADING_ORGANIZATION = 'LOADING_ORGANIZATION';
export const LOADING_ORGANIZATION_SUCCESS = 'LOADING_ORGANIZATION_SUCCESS';
export const LOADING_ORGANIZATION_FAILED = 'LOADING_ORGANIZATION_FAILED';

export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_FAILED = 'CREATE_ORGANIZATION_FAILED';

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS';
export const UPDATE_ORGANIZATION_FAILED = 'UPDATE_ORGANIZATION_FAILED';

export const GET_DETAIL_ORGANIZATION = 'GET_DETAIL_ORGANIZATION';
export const GET_DETAIL_ORGANIZATION_SUCCESS = 'GET_DETAIL_ORGANIZATION_SUCCESS';
export const GET_DETAIL_ORGANIZATION_FAILED = 'GET_DETAIL_ORGANIZATION_FAILED';

export const MULTI_ACTION_ORGANIZATION = 'MULTI_ACTION_ORGANIZATION';
export const CHECK_ACCESS_CODE = 'CHECK_ACCESS_CODE';
export const CHANGE_SHOW_HIDDEN_ORGANIZATION = 'CHANGE_SHOW_HIDDEN_ORGANIZATION';
export const SEARCH_ORGANIZATION = 'SEARCH_ORGANIZATION';
export const EXPAND_ORGANIZATION = 'EXPAND_ORGANIZATION';

export const HIDE_MULTI_ORGANIZATION = 'HIDE_MULTI_ORGANIZATION';
export const HIDE_MULTI_ORGANIZATION_SUCCESS = 'HIDE_MULTI_ORGANIZATION_SUCCESS';
export const UPDATE_DATE_ROOT_ORG = 'UPDATE_DATE_ROOT_ORG';
export const HIDE_MULTI_ORGANIZATION_FAILED = 'HIDE_MULTI_ORGANIZATION_FAILED';

export const STRUCTURE_ORGANIZATION = 'STRUCTURE_ORGANIZATION';
export const STRUCTURE_ORGANIZATION_SUCCESS = 'STRUCTURE_ORGANIZATION_SUCCESS';
export const STRUCTURE_ORGANIZATION_FAILED = 'STRUCTURE_ORGANIZATION_FAILED';
export const UPDATE_SETTING_UPLOAD = 'UPDATE_SETTING_UPLOAD';
export const UPDATE_SETTING_SERVEY = 'UPDATE_SETTING_SERVEY';
export const GET_SETTING_UPLOAD = 'GET_SETTING_UPLOAD';
export const GET_SETTING_SERVEY = 'GET_SETTING_SERVEY';
export const GET_CREATE_TEST_INCIDENT = 'GET_CREATE_TEST_INCIDENT';

export const updateSettingUploadAction = (
  payload: IPayloadUpdateSettingUpload
): IActionLoadingOrganiztion => ({
  type: UPDATE_SETTING_UPLOAD,
  payload,
});

export const updateSettingServeyAction = (
  payload: IPayloadUpdateSettingUpload
): IActionLoadingOrganiztion => ({
  type: UPDATE_SETTING_SERVEY,
  payload,
});

export const getSettingUploadAction = (
  payload: IPayloadGetSettingUpload
): IActionLoadingOrganiztion => ({
  type: GET_SETTING_UPLOAD,
  payload,
});

export const getSettingServeyAction = (
  payload: IPayloadGetSettingServey
): IActionLoadingOrganiztion => ({
  type: GET_SETTING_SERVEY,
  payload,
});

export const getCreateTestIncidentAction = (
  payload: IPayloadGetCreateTestIncident
): IActionLoadingOrganiztion => ({
  type: GET_CREATE_TEST_INCIDENT,
  payload,
});

export const loadingOrganizationAction = (
  payload: IPayloadLoadingOrganization
): IActionLoadingOrganiztion => ({
  type: LOADING_ORGANIZATION,
  payload,
});

export const createOrganizationAction = (
  payload: IPayloadCreateOrganization
): IActionCreateOrganization => ({
  type: CREATE_ORGANIZATION,
  payload,
});

export const updateOrganizationAction = (
  payload: IPayloadUpdateOrganization
): IActionUpdateOrganization => ({
  type: UPDATE_ORGANIZATION,
  payload,
});

export const checkAccessCodeOrganizationAction = (
  payload: IPayloadCheckAccessCode
): IActionCheckAccessCodeOrganization => ({
  type: CHECK_ACCESS_CODE,
  payload,
});

export const getDetailOrganizationAction = (
  payload: IPayloadDetailOrganization
): IActionDetailOrganization => ({
  type: GET_DETAIL_ORGANIZATION,
  payload,
});

export const multiActionOrganization = (
  payload: IPayloadMultiActionOrganization
): IMultiActionOrganization => ({
  type: MULTI_ACTION_ORGANIZATION,
  payload,
});

export const changeShowHiddenOrganizationAction = (
  payload: boolean
): IActionChangeShowHiddenOrganization => ({
  type: CHANGE_SHOW_HIDDEN_ORGANIZATION,
  payload,
});

export const expandOrganizationAction = (payload: boolean): IActionExpandOrganization => ({
  type: EXPAND_ORGANIZATION,
  payload,
});

export const changeSearchOrganizationAction = (payload: string): IActionSearchOrganization => ({
  type: SEARCH_ORGANIZATION,
  payload,
});

export const hiddenMultiOrganizationAction = (
  payload: IPayloadHideMultiOrganization
): IHideMultiOrganizationAction => ({
  type: HIDE_MULTI_ORGANIZATION,
  payload,
});

export const structureOrganizationAction = (
  payload: IPayloadStructureOrganization
): IActionStructureOrganization => ({
  type: STRUCTURE_ORGANIZATION,
  payload,
});
