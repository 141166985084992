import { Modal, Button, Input, Typography, Space } from 'antd';
import { translate } from 'libs';
import { IGenQrCodeProps } from 'pages/setting/interfaces';
import React, { memo } from 'react';
import QRCode from 'react-qr-code';

const GenQrCode = ({ isVisible, onCancel, code }: IGenQrCodeProps) => {
  const handleDownload = () => {
    console.log('🚀 ~ handleDownload');
  };

  return (
    <Modal
      maskClosable={false}
      title={translate('settingPage.ogSetup.qrAccessCode')}
      centered
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button
          key="large"
          type="primary"
          className="btn-download"
          size="large"
          onClick={handleDownload}
        >
          {translate('settingPage.ogSetup.downLarge')}
        </Button>,
        <Button
          key="regular"
          type="primary"
          className="btn-download"
          size="large"
          onClick={handleDownload}
        >
          {translate('settingPage.ogSetup.downRegular')}
        </Button>,
      ]}
      className="md-gen-qr-code"
    >
      <Typography.Text className="lb-form">
        {translate('settingPage.ogSetup.accessCode')}
      </Typography.Text>
      <Input size="large" value={code} disabled className="input-access-code" />

      <div className="contain-qr-code">
        <QRCode value={code} size={128} />
        <Space size={22} direction="vertical">
          <Typography.Text className="txt-description-qr-code">
            {translate('settingPage.ogSetup.qrSizeLarge')}
          </Typography.Text>
          <Typography.Text className="txt-description-qr-code">
            {translate('settingPage.ogSetup.qrSizeRegular')}
          </Typography.Text>
        </Space>
      </div>
    </Modal>
  );
};

export default memo(GenQrCode);
