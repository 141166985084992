import { ELanguage } from 'pages/auth/interfaces';

const storagePrefix: string = 'standby_';

const storage = {
  getToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
  },
  getRefreshToken: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}refresh_token`) as string);
  },
  setRefreshToken: (refreshToken: string) => {
    window.localStorage.setItem(`${storagePrefix}refresh_token`, JSON.stringify(refreshToken));
  },
  clearRefreshToken: () => {
    window.localStorage.removeItem(`${storagePrefix}refresh_token`);
  },
  getLocale: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}lang`) as string);
  },
  setLocale: (lang: ELanguage) => {
    window.localStorage.setItem(`${storagePrefix}lang`, JSON.stringify(lang));
  },
  clearAll: () => {
    window.localStorage.clear();
  },
};

export { storage };
