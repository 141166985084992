import {
  VISIBLE_MODAL_NOTICE_DETAIL,
  CANCEL_MODAL_NOTICE_DETAIL,
  VISIBLE_MODAL_SYSTEM_ALERT,
  CANCEL_MODAL_SYSTEM_ALERT,
  VISIBLE_MODAL_NOTICE_RESPONSE,
  CANCEL_MODAL_NOTICE_RESPONSE,
  SET_TOTAL_NOTIFICATION,
} from 'actions/notifications';
import { ETypeNoticeResponse, INotificationSelector } from 'pages/notifications/interfaces';
import { ActionProps } from 'reducers';

const initialState: INotificationSelector = {
  isVisibleNoticeDetail: false,
  isVisibleSystemAlert: false,
  typeNoticeResponse: ETypeNoticeResponse.NONE,
  totalNotification: 0,
};

export const NotificationsReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case SET_TOTAL_NOTIFICATION:
      return {
        ...state,
        totalNotification: action.payload.total,
      };
    case VISIBLE_MODAL_NOTICE_DETAIL:
      return {
        ...state,
        isVisibleNoticeDetail: true,
      };
    case CANCEL_MODAL_NOTICE_DETAIL:
      return {
        ...state,
        isVisibleNoticeDetail: false,
      };
    case VISIBLE_MODAL_SYSTEM_ALERT:
      return {
        ...state,
        isVisibleSystemAlert: true,
      };
    case CANCEL_MODAL_SYSTEM_ALERT:
      return {
        ...state,
        isVisibleSystemAlert: false,
      };
    case VISIBLE_MODAL_NOTICE_RESPONSE:
      return {
        ...state,
        typeNoticeResponse: action.payload as ETypeNoticeResponse,
      };
    case CANCEL_MODAL_NOTICE_RESPONSE:
      return {
        ...state,
        typeNoticeResponse: ETypeNoticeResponse.NONE,
      };
    default:
      return state;
  }
};
