/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './notifications.scss';
import { Typography } from 'antd';
import ListNotification from 'pages/notifications/components/ListNotification';
import {
  disconnectSocket,
  initialSocketNotice,
  onReceiveNotice,
  onReceiveNoticeIncidentAssign,
  onStartMaintaince,
} from 'libs/socket';
import { storage } from 'utils';
import { useAppSelector } from 'app/hooks';
import { useHistory } from 'react-router';
import { translate } from 'libs';

const Notifications = () => {
  const history = useHistory();
  const [isReload, setIsReload] = React.useState<boolean>(false);
  const admin_user_id: string = useAppSelector((state) => state.auth?.profile?._id);

  const onCancelPopUp = () => {};

  const initialNoticeSocket = () =>
    new Promise<void>((resolve) => {
      const token: string = storage.getToken();

      if (token) {
        initialSocketNotice(token);
      }
      resolve();
    });

  React.useEffect(() => {
    if (admin_user_id) {
      initialNoticeSocket().then(() => {
        onReceiveNotice((msg: any) => {
          if (msg) {
            setIsReload((pre) => !pre);
          }
        });
        onReceiveNoticeIncidentAssign((msg: any) => {
          if (msg) {
            setIsReload((pre) => !pre);
          }
        });
        onStartMaintaince((msg: any) => {
          return history.push({
            pathname: '/system/maintenance',
            search: 'maintaince=1',
          });
        });
      });
    }

    return () => {
      disconnectSocket();
    };
  }, [admin_user_id]);

  return (
    <div className="notifications">
      <div className="header-notifications">
        <Typography.Text className="txt-notifications">
          {translate('notifications.popUp.title')}
        </Typography.Text>
      </div>
      <div className="all-notifications">
        <ListNotification
          onCancelPopUp={onCancelPopUp}
          isReload={isReload}
          isPageNotification={true}
        />
      </div>
    </div>
  );
};

export default React.memo(Notifications);
