/* eslint-disable react-hooks/exhaustive-deps */
import { Tabs } from 'antd';
import ListIncidentManagement from './components/List';
import { INCIDENT_ATTR_TYPES } from 'api/incident-attr';
import { useAppDispatch } from 'app/hooks';
import { getIncidentAttrFamily } from 'actions/incidentAttr';
import { useEffect, useState } from 'react';
import { translate } from 'libs';

const { TabPane } = Tabs;

const IncidentManagement = () => {
  const dispatch = useAppDispatch();
  const [activeTab, setActiveTab] = useState(INCIDENT_ATTR_TYPES.TYPE);
  const list = [
    {
      label: translate('settingPage.incidentManagement.tabs.incident_type'),
      type: INCIDENT_ATTR_TYPES.TYPE,
    },
    {
      label: translate('settingPage.incidentManagement.tabs.incident_label'),
      type: INCIDENT_ATTR_TYPES.LABEL,
    },
    {
      label: translate('settingPage.incidentManagement.tabs.incident_action_taken'),
      type: INCIDENT_ATTR_TYPES.ACTION,
    },
    {
      label: translate('settingPage.incidentManagement.tabs.incident_status'),
      type: INCIDENT_ATTR_TYPES.STATUS,
    },
    {
      label: translate('settingPage.incidentManagement.tabs.incident_priority'),
      type: INCIDENT_ATTR_TYPES.PRIORITY,
    },
  ];

  useEffect(() => {
    dispatch(getIncidentAttrFamily());
  }, []);

  const handleChangeTab = (key) => setActiveTab(key);

  return (
    <div className="incident-management">
      <Tabs defaultActiveKey="1" onChange={handleChangeTab}>
        {list.map((ele, index) => (
          <TabPane tab={ele.label} key={ele.type}>
            <ListIncidentManagement name={ele.label} type={ele.type} activeTab={activeTab} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default IncidentManagement;
