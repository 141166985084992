/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { DownOutlined, InfoCircleFilled, RightOutlined } from '@ant-design/icons';
import { Button, Typography, Table, Space, Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
  EOptIns,
  IAllSchool,
  ICheckOptIns,
  IHandleIdOrg,
  IMyOptInsProps,
} from 'pages/setting/interfaces';
import { loadingUserRoleAction } from 'actions/users';
import OptIns from '../../admin-user/components/OptIns';
import groupBy from 'lodash/groupBy';
import { IPayloadGetOptIns, IPayloadUpdateMyOptIns } from 'pages/auth/interfaces';
import { getUserOptInsAction, updateMyOptInsAction } from 'actions/auth';
import { translate } from 'libs';

let scrollYNow: any = undefined;

const MyOptIns = (props: IMyOptInsProps) => {
  const [isLoadingTable, setLoadingTable] = useState<boolean>(false);
  const profile: any = useAppSelector((state) => state.auth.profile);
  const permissionOrganizations: any[] = useAppSelector(
    (state) => state.auth.permissionOrganizations
  );

  const [isVisibleOptIns, setIsVisibleOptIns] = React.useState<boolean>(false);
  const [checkOptIns, setCheckOptIns] = React.useState<ICheckOptIns[]>([]);
  const [checkOptInsShow, setCheckOptInsShow] = React.useState<ICheckOptIns[]>([]);
  const [activeKey, setActiveKey] = useState<string[]>([]);

  const rootOrgId: string = permissionOrganizations?.[0]?._id;
  const dispatch = useAppDispatch();

  const getMyOptIns = React.useCallback(() => {
    const payload: IPayloadGetOptIns = {
      onSuccess: (data: any) => {
        handleInitOptIns(data?.organizations);
      },
      onError: () => {
        handleCancelOptIns();
      },
    };
    return dispatch(getUserOptInsAction(payload));
  }, []);

  React.useEffect(() => {
    getMyOptIns();
    return dispatch(loadingUserRoleAction({ query: {} }));
  }, []);

  const handleVisibleOptIns = () => {
    setIsVisibleOptIns(true);
    scrollYNow = window.scrollY;
  };

  const handleLoadingTable = (val: boolean) => {
    setLoadingTable(val);
  };

  const handleCancelOptIns = () => {
    setLoadingTable(true);
    setIsVisibleOptIns(false);
    window.onscroll = () => {
      window.scrollTo({ top: scrollYNow });
      window.onscroll = null;
    };
  };

  const handleIdOrg: IHandleIdOrg = React.useMemo(() => {
    const newOrganizations = [...permissionOrganizations];

    const allIdInOrg: string[] = []; // except root org id
    const allSchool: IAllSchool[] = [];

    newOrganizations.forEach((org: any) => {
      if (org?._id === rootOrgId && Array.isArray(org?.children)) {
        org.children.forEach((school: any) => {
          allIdInOrg.push(school?._id);

          const gradeIdsInSchool: string[] = [];

          if (Array.isArray(school?.children)) {
            school.children.forEach((grade: any) => {
              allIdInOrg.push(grade?._id);
              gradeIdsInSchool.push(grade?._id);
            });
          }
          allSchool.push({ _id: school?._id, children: gradeIdsInSchool });
        });
      }
    });

    return {
      allIdInOrg,
      allSchool,
    };
  }, [permissionOrganizations, rootOrgId]);

  const onFinishOptIns = () => {
    const { allSchool }: IHandleIdOrg = handleIdOrg;
    const newCheckOptIns: ICheckOptIns[] = [...checkOptIns];

    const idsIM: string[] = [];
    const idsIS: string[] = [];
    const idsMM: string[] = [];
    const idsMS: string[] = [];

    const groupByType: any = groupBy(newCheckOptIns, 'type');

    const pushToCase = (type: EOptIns, id: string) => {
      switch (type) {
        case EOptIns.INCIDENT_MAIL:
          idsIM.push(id);
          break;
        case EOptIns.INCIDENT_SMS:
          idsIS.push(id);
          break;
        case EOptIns.MESSAGE_MAIL:
          idsMM.push(id);
          break;
        case EOptIns.MESSAGE_SMS:
          idsMS.push(id);
          break;
        default:
          break;
      }
    };

    if (Object.keys(groupByType).length > 0) {
      Object.entries(groupByType).forEach((keyValue: any) => {
        const type: EOptIns = keyValue[0];
        const checkOptInsOfType: ICheckOptIns[] = keyValue[1];

        /**
         * Check root to be check
         */

        const isCheckRoot: boolean = checkOptInsOfType.some(
          (item: ICheckOptIns) => item.organization_id === rootOrgId && item.type === type
        );

        if (isCheckRoot) {
          pushToCase(type, rootOrgId);
        } else {
          /**
           * School to be checked
           */

          allSchool.forEach((school: IAllSchool) => {
            const indexSchool: number = checkOptInsOfType.findIndex(
              (item: ICheckOptIns) =>
                school?._id === item.organization_id && item.type === type && !item.disabled
            );

            if (indexSchool >= 0) {
              pushToCase(type, school._id);
            } else {
              /**
               * Grade to be checked
               */
              if (Array.isArray(school?.children)) {
                school.children.forEach((gradeId: string) => {
                  const indexGrade: number = checkOptInsOfType.findIndex(
                    (item: ICheckOptIns) =>
                      gradeId === item.organization_id && item.type === type && !item.disabled
                  );

                  if (indexGrade >= 0) {
                    pushToCase(type, gradeId);
                  }
                });
              }
            }
          });
        }
      });
    }

    const payload: IPayloadUpdateMyOptIns = {
      values: {
        optin_data: [
          {
            type: EOptIns.INCIDENT_MAIL,
            organization_ids: idsIM,
          },
          {
            type: EOptIns.INCIDENT_SMS,
            organization_ids: idsIS,
          },
          {
            type: EOptIns.MESSAGE_MAIL,
            organization_ids: idsMM,
          },
          {
            type: EOptIns.MESSAGE_SMS,
            organization_ids: idsMS,
          },
        ],
      },
      onSuccess: () => {
        handleCancelOptIns();
        getMyOptIns();
      },
    };

    return dispatch(updateMyOptInsAction(payload));
  };

  /**
   * Handle Check Logic Opt Ins
   */

  const handleInitOptIns = (optInsUserData: any[]) => {
    const initChecked: ICheckOptIns[] = [];
    const { allIdInOrg, allSchool }: IHandleIdOrg = handleIdOrg;

    if (Array.isArray(optInsUserData)) {
      optInsUserData.forEach((organization: any) => {
        if (Array.isArray(organization?.opt_in)) {
          // Check Opt Ins in root folder
          organization.opt_in.forEach((orgOpt: any) => {
            // Exist Opt Ins in root folder
            const orgType: EOptIns = orgOpt?.type;

            initChecked.push({
              organization_id: orgOpt?.org_id,
              type: orgType,
              disabled: false,
            });

            /**
             * Check school and grade with same type
             * type @EOptIns
             */
            allIdInOrg.forEach((id: string) => {
              initChecked.push({
                organization_id: id,
                type: orgType,
                disabled: true,
              });
            });
          });
        }

        /**
         * Check Opt Ins in School folder
         */

        if (Array.isArray(organization?.childrens)) {
          organization.childrens.forEach((school: any) => {
            if (Array.isArray(school?.opt_in)) {
              school.opt_in.forEach((schoolOpt: any) => {
                // Exist Opt Ins School folder
                const schoolType: EOptIns = schoolOpt?.type;

                initChecked.push({
                  organization_id: schoolOpt?.org_id,
                  type: schoolType,
                  disabled: false,
                });

                /**
                 * Check grade with same type
                 * type @EOptIns
                 */
                const findSchool: IAllSchool | undefined = allSchool.find(
                  (s: IAllSchool) => s?._id === school?._id
                );

                if (findSchool && Array.isArray(findSchool?.children)) {
                  findSchool.children.forEach((gradeId: string) => {
                    initChecked.push({
                      organization_id: gradeId,
                      type: schoolType,
                      disabled: true,
                    });
                  });
                }
              });
            }

            /**
             * Check Opt Ins Grade folder
             */
            if (Array.isArray(school?.childrens)) {
              school.childrens.forEach((grade: any) => {
                if (Array.isArray(grade?.opt_in)) {
                  grade.opt_in.forEach((gradeOpt: any) => {
                    // Exist Opt Ins grade folder
                    const gradeType: EOptIns = gradeOpt?.type;

                    initChecked.push({
                      organization_id: gradeOpt?.org_id,
                      type: gradeType,
                      disabled: false,
                    });
                  });
                }
              });
            }
          });
        }
      });
    }

    setCheckOptIns(initChecked);
    setCheckOptInsShow(initChecked);
  };

  const handleCheckOptIns = (
    checked: boolean,
    type: EOptIns,
    orgId: string,
    isCheckParent = false
  ) => {
    const newCheckOptIns: ICheckOptIns[] = [...checkOptIns];

    const index: number = newCheckOptIns.findIndex(
      (item: ICheckOptIns) => item.type === type && item.organization_id === orgId
    );

    const { allIdInOrg, allSchool }: IHandleIdOrg = handleIdOrg;

    const isCheckSchool: boolean = allSchool.some((item: IAllSchool) => item?._id === orgId);

    if (checked) {
      if (index < 0) {
        newCheckOptIns.push({ organization_id: orgId, type, disabled: false });
      }

      if (isCheckSchool) {
        const findSchool: IAllSchool | undefined = allSchool.find(
          (school: IAllSchool) => school?._id === orgId
        );

        if (findSchool) {
          findSchool.children.forEach((gradeId: string) => {
            const findIndexGrade: number = newCheckOptIns.findIndex(
              (item: ICheckOptIns) => item.organization_id === gradeId && item.type === type
            );
            if (findIndexGrade >= 0) {
              // exist
              const gradeItem = newCheckOptIns[findIndexGrade];

              newCheckOptIns.splice(findIndexGrade, 1, {
                ...gradeItem,
                type,
                disabled: true,
              });
            } else {
              newCheckOptIns.push({
                organization_id: gradeId,
                type,
                disabled: true,
              });
            }
          });
        }
      }

      if (isCheckParent) {
        // checked true and check root true
        allIdInOrg.forEach((id: string) => {
          const indexCheck: number = newCheckOptIns.findIndex(
            (item: ICheckOptIns) => item.organization_id === id && item.type === type
          );

          if (indexCheck < 0) {
            newCheckOptIns.push({ organization_id: id, type, disabled: true });
          }
        });
      }
    } else {
      if (index >= 0) {
        newCheckOptIns.splice(index, 1);
      }

      if (isCheckSchool) {
        const findSchool: IAllSchool | undefined = allSchool.find(
          (school: IAllSchool) => school?._id === orgId
        );

        if (findSchool) {
          findSchool.children.forEach((gradeId: string) => {
            const findIndexGrade: number = newCheckOptIns.findIndex(
              (item: ICheckOptIns) => item.organization_id === gradeId && item.type === type
            );

            newCheckOptIns.splice(findIndexGrade, 1);
          });
        }
      }

      if (isCheckParent) {
        /**
         * Uncheck root and enable checkbox school
         */

        allIdInOrg.forEach((id: string) => {
          const indexCheck: number = newCheckOptIns.findIndex(
            (item: ICheckOptIns) => item.organization_id === id && item.type === type
          );

          if (indexCheck >= 0) {
            newCheckOptIns.splice(indexCheck, 1);
          }
        });
      }
    }

    setCheckOptIns(newCheckOptIns);
  };

  const renderTitleTable = (title: string) => {
    return (
      <Space>
        <Typography.Text>{translate(`settingPage.adminUser.${title}`)}</Typography.Text>
        <Tooltip title={translate(`settingPage.adminUser.${title}.description`)}>
          <InfoCircleFilled className="ic-info" />
        </Tooltip>
      </Space>
    );
  };

  const iconChecked = (id: string, type: EOptIns) => {
    const isChecked: boolean = checkOptInsShow.some(
      (item: ICheckOptIns) => item.organization_id === id && item.type === type
    );

    if (isChecked) return <img src="/images/ic-ticked.png" alt="" />;

    return null;
  };

  const columns: any[] = [
    {
      title: translate('settingPage.ogSetup.ogName'),
      dataIndex: 'name',
      key: 'name',
      width: 430,
      ellipsis: {
        showTitle: false,
      },
      render: (name: string) => {
        return (
          <Tooltip placement="bottomLeft" title={name}>
            <Typography.Text style={{ marginLeft: 15 }}>
              {name.length > 40 ? name.substring(0, 40) + '...' : name}
            </Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: renderTitleTable('incidentEmails'),
      dataIndex: 'incidentEmails',
      key: 'incidentEmails',
      width: 120,
      render: (_: any, record: any) => iconChecked(record?._id, EOptIns.INCIDENT_MAIL),
      align: 'center',
    },
    {
      title: renderTitleTable('incidentSms'),
      dataIndex: 'incidentSms',
      key: 'incidentSms',
      width: 140,
      render: (_: any, record: any) => iconChecked(record?._id, EOptIns.INCIDENT_SMS),
      align: 'center',
    },
    {
      title: renderTitleTable('messengerEmail'),
      dataIndex: 'messengerEmail',
      key: 'messengerEmail',
      width: 140,
      render: (_: any, record: any) => iconChecked(record?._id, EOptIns.MESSAGE_MAIL),
      align: 'center',
    },
    {
      title: renderTitleTable('messengerSms'),
      dataIndex: 'messengerSms',
      key: 'messengerSms',
      width: 160,
      render: (_: any, record: any) => iconChecked(record?._id, EOptIns.MESSAGE_SMS),
      align: 'center',
    },
  ];

  useEffect(() => {
    let newActiveKey: string[] = [];

    [...permissionOrganizations].forEach((item: any) => {
      newActiveKey.push(item?._id);

      if (Array.isArray(item?.children)) {
        item.children.forEach((school: any) => {
          newActiveKey.push(school?._id);
        });
      }
    });

    setActiveKey(newActiveKey);
  }, [permissionOrganizations]);

  const onExpandKey = (expanded: boolean, keySelected: string) => {
    const newActiveKey: string[] = [...activeKey];

    const indexKey: number = newActiveKey.findIndex((key: string) => key === keySelected);

    if (expanded) {
      if (indexKey < 0) {
        newActiveKey.push(keySelected);
      }
    } else {
      if (indexKey >= 0) {
        newActiveKey.splice(indexKey, 1);
      }
    }

    setActiveKey(newActiveKey);
  };

  return (
    <div className="my-opt-ins">
      <Button className="btn-edit" type="primary" size="large" onClick={handleVisibleOptIns}>
        {translate('settingPage.adminUser.edit')}
      </Button>

      <Table
        columns={columns}
        dataSource={permissionOrganizations}
        className="opt-in-board"
        rowKey="_id"
        expandable={{
          expandIcon: ({ expanded, onExpand, record }) => {
            if (Array.isArray(record?.children) && record.children.length > 0) {
              return expanded ? (
                <DownOutlined className="ic-expand" onClick={(e) => onExpand(record, e)} />
              ) : (
                <RightOutlined className="ic-expand" onClick={(e) => onExpand(record, e)} />
              );
            }

            return null;
          },
          expandedRowKeys: activeKey,
          onExpand: (expanded: boolean, record: any) => onExpandKey(expanded, record._id),
        }}
        pagination={false}
        locale={{ emptyText: translate('no_data', { name: translate('settingPage.optIn.title') }) }}
      />

      {/* Modal Opt Ins */}
      <OptIns
        isVisible={isVisibleOptIns}
        onCancel={handleCancelOptIns}
        selectedItem={profile}
        organizations={permissionOrganizations}
        onFinish={onFinishOptIns}
        handleCheckOptIns={handleCheckOptIns}
        checkOptIns={checkOptIns}
        handleInitOptIns={handleInitOptIns}
        isLoadingTable={isLoadingTable}
        handleLoadingTable={handleLoadingTable}
        isEditMyprofile
      />
    </div>
  );
};

export default MyOptIns;
