import { Modal, Button, Typography } from 'antd';
import { translate } from 'libs';

interface IProps {
  title?: string;
  visible: boolean;
  handleOk?: () => void;
  handleCancel: () => void;
  textConfirm?: string;
  contentText?: string;
  textConfirmBtn?: string;
  textCancelBtn?: string;
  isDeleted?: boolean;
  width?: number;
  loading?: boolean;
}

const ModalConfirm = ({
  title = '',
  visible,
  handleOk,
  handleCancel,
  contentText,
  textConfirm,
  isDeleted = false,
  loading = false,
  textConfirmBtn,
  textCancelBtn,
  width = 800,
}: IProps) => {
  return (
    <Modal
      title={title || translate('modal.title.header.default')}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      centered
      footer={[
        textConfirmBtn && (
          <Button
            danger={isDeleted}
            className="btn-save"
            size="large"
            key="confirm"
            type="primary"
            onClick={handleOk}
            loading={loading}
          >
            {textConfirmBtn}
          </Button>
        ),
        <Button key="cancel" className="btn-cancel" size="large" onClick={handleCancel}>
          {textCancelBtn ? textCancelBtn : translate('common.cancelBtn')}
        </Button>,
      ]}
      className="modal modal-confirm"
      width={width}
    >
      <p style={{ width: '100%' }}>
        {textConfirm && (
          <Typography.Text className="txt-confirm break-line-localize">
            {textConfirm}
          </Typography.Text>
        )}
        {contentText && (
          <Typography.Text className="txt-confirm break-line-localize">
            {contentText}
          </Typography.Text>
        )}
      </p>
    </Modal>
  );
};

export { ModalConfirm };
