import { Tabs } from 'antd';
import React from 'react';
import Timeline from './components/Timeline';
import ActiveByOrg from './components/ActiveByOrg';
import { IActiveVolumeProps } from 'pages/reports/interfaces';
import { translate } from 'libs';

const { TabPane } = Tabs;

export enum ETabActivation {
  TIMELINE = 'TIMELINE',
  ORGANIZATION = 'ORGANIZATION',
}

const ActivationVolumeTest = React.forwardRef((_props: IActiveVolumeProps, ref: any) => {
  const timelineRef: any = React.useRef();
  const activeOrgRef: any = React.useRef();
  const [activeKey, setActiveKey] = React.useState<ETabActivation>(ETabActivation.TIMELINE);

  React.useImperativeHandle(ref, () => ({
    getFilterTimelineValues: () =>
      activeKey === ETabActivation.TIMELINE && timelineRef?.current?.getFilterTimelineValues?.(),
    getFilterActiveUsersValues: () =>
      activeKey === ETabActivation.ORGANIZATION &&
      activeOrgRef?.current?.getFilterActiveUsersValues?.(),
  }));

  const handleChangeTab = (key: string) => setActiveKey(key as ETabActivation);

  return (
    <div className="activity-volume">
      <Tabs className="activation-volume-tabs" onChange={handleChangeTab} activeKey={activeKey}>
        <TabPane tab={translate('incident.test.report.tab_1')} key={ETabActivation.TIMELINE}>
          <Timeline ref={timelineRef} activeKey={activeKey} />
        </TabPane>
        <TabPane tab={translate('incident.test.report.tab_2')} key={ETabActivation.ORGANIZATION}>
          <ActiveByOrg ref={activeOrgRef} activeKey={activeKey} />
        </TabPane>
      </Tabs>
    </div>
  );
});

export default React.memo(ActivationVolumeTest);
