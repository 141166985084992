import {
  CREATE_INCIDENTS,
  CREATE_INCIDENTS_FAILED,
  CREATE_INCIDENTS_SUCCESS,
  CREATE_NOTE_INCIDENTS,
  CREATE_NOTE_INCIDENTS_FAILED,
  CREATE_NOTE_INCIDENTS_SUCCESS,
  LOADING_ACTIVITYLOG_FAILED,
  LOADING_ACTIVITYLOG_SUCCESS,
  LOADING_INCIDENTS,
  LOADING_ACTIVITYLOG,
  LOADING_INCIDENTS_FAILED,
  LOADING_INCIDENTS_SUCCESS,
  LOADING_NOTE_INCIDENTS,
  LOADING_NOTE_INCIDENTS_FAILED,
  LOADING_NOTE_INCIDENTS_SUCCESS,
  DEACTIVE_USER,
  DEACTIVE_USER_SUCCESS,
  DEACTIVE_USER_FAILED,
  UPDATE_INCIDENTS_FAILED,
  UPDATE_INCIDENTS_SUCCESS,
  UPDATE_INCIDENTS,
  LOADING_CHAT_INCIDENTS,
  LOADING_CHAT_INCIDENTS_SUCCESS,
  LOADING_CHAT_INCIDENTS_FAILED,
  APPEND_MESSAGE_INCIDENT_SUCCESS,
  RESET_CHAT,
  CHANGE_CHAT_STATUS,
  LOADING_ALL_INCIDENTS_ATTR,
  LOADING_ALL_INCIDENTS_ATTR_SUCCESS,
  LOADING_ALL_INCIDENTS_ATTR_FAILED,
  LOAD_TOTAL_INFO_SUCCESS,
  UNMOUT_INCIDENTS_LIST,
  DELETE_ATTRS_INCIDENTS_BY_ORG,
} from 'actions/incidents';
import { IIncidentsSelector, EFilterResponseStatusIncidents } from 'pages/incidents/interfaces';
import { ActionProps } from 'reducers';
import { uniqBy } from 'lodash';

const initialState: IIncidentsSelector = {
  list: [],
  pagination: {
    page: 1,
  },
  chats: [],
  isLoading: false,
  statusChats: '',
  listFilterStatus: [],
};

export const IncidentsReducer = (state = initialState, action: ActionProps) => {
  switch (action.type) {
    case UNMOUT_INCIDENTS_LIST:
      return initialState;
    case LOADING_INCIDENTS:
      return {
        ...state,
        isLoading: true,
      };
    case CREATE_NOTE_INCIDENTS:
    case LOADING_NOTE_INCIDENTS:
    case CREATE_INCIDENTS:
    case LOADING_ACTIVITYLOG:
    case DEACTIVE_USER:
    case UPDATE_INCIDENTS:
    case DELETE_ATTRS_INCIDENTS_BY_ORG:
    case LOADING_CHAT_INCIDENTS:
    case LOADING_ALL_INCIDENTS_ATTR:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_INCIDENTS_SUCCESS: {
      let newList =
        action.payload.pagination.page === 1
          ? action.payload.list
          : action.payload.pagination.page === state.pagination.page
          ? state.list
          : [...state.list, ...action.payload.list];
      newList = uniqBy(newList, '_id');
      return {
        ...state,
        isLoading: false,
        list: newList,
        pagination: action.payload.pagination,
      };
    }
    case LOADING_INCIDENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        list: [],
      };
    case LOADING_CHAT_INCIDENTS_SUCCESS: {
      let newResponseStatus = state.statusChats;
      if (action.payload.length) {
        const lastMessage = action.payload[0];
        if (!lastMessage.is_admin) {
          newResponseStatus = lastMessage.admin_read
            ? EFilterResponseStatusIncidents.READ_UNANSWERED
            : EFilterResponseStatusIncidents.UNREAD;
        }
        if (lastMessage.is_admin) {
          if (lastMessage.sender_id) {
            newResponseStatus = EFilterResponseStatusIncidents.READ_ANSWERED;
          } else {
            const lastAnonymousMessage = action.payload[1];
            newResponseStatus = lastAnonymousMessage?.admin_read
              ? EFilterResponseStatusIncidents.READ_UNANSWERED
              : EFilterResponseStatusIncidents.UNREAD;
          }
        }
      }
      return {
        ...state,
        isLoading: false,
        chats: action.payload,
        statusChats: newResponseStatus,
      };
    }
    case APPEND_MESSAGE_INCIDENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chats: [action.payload, ...state.chats],
      };
    case LOADING_CHAT_INCIDENTS_FAILED:
    case RESET_CHAT:
      return {
        ...state,
        isLoading: false,
        chats: [],
        statusChats: '',
      };

    case CREATE_NOTE_INCIDENTS_SUCCESS:
    case CREATE_NOTE_INCIDENTS_FAILED:
    case LOADING_NOTE_INCIDENTS_SUCCESS:
    case LOADING_NOTE_INCIDENTS_FAILED:
    case CREATE_INCIDENTS_SUCCESS:
    case CREATE_INCIDENTS_FAILED:
    case LOADING_ACTIVITYLOG_SUCCESS:
    case LOADING_ACTIVITYLOG_FAILED:
    case LOADING_ALL_INCIDENTS_ATTR_SUCCESS:
    case LOADING_ALL_INCIDENTS_ATTR_FAILED:
    case DEACTIVE_USER_SUCCESS:
    case DEACTIVE_USER_FAILED:
    case UPDATE_INCIDENTS_SUCCESS:
    case UPDATE_INCIDENTS_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case CHANGE_CHAT_STATUS:
      return {
        ...state,
        statusChats: action.payload,
      };
    case LOAD_TOTAL_INFO_SUCCESS:
      return {
        ...state,
        listFilterStatus: action.payload.listStatus,
      };

    default:
      return state;
  }
};
