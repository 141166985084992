import { Button, Modal, Space, Typography } from 'antd';
import { translate } from 'libs';
import { IDeactiveUserProps } from 'pages/setting/interfaces';
import React from 'react';

const DeactiveUser = ({
  isVisible,
  onCancel,
  isLoading,
  onDeactive,
  onActive,
  actionHandleChange,
}: IDeactiveUserProps) => {
  const onClickAction = () => {
    if (actionHandleChange === 'active') {
      return onActive();
    }
    return onDeactive();
  };

  return (
    <Modal
      title={
        actionHandleChange === 'active'
          ? translate('settingPage.adminUser.reactiveUser')
          : translate('settingPage.adminUser.deactiveUser')
      }
      centered
      maskClosable={false}
      visible={isVisible}
      onCancel={onCancel}
      footer={[
        <Button
          key="large"
          type="primary"
          className="btn-save"
          size="large"
          danger
          loading={isLoading}
          onClick={onClickAction}
        >
          {actionHandleChange === 'active'
            ? translate('settingPage.deactiveUser.activeBtn')
            : translate('settingPage.deactiveUser.deactiveBtn')}
        </Button>,
        <Button key="cancel" className="btn-cancel" size="large" onClick={onCancel}>
          {translate('settingPage.deactiveUser.cancelBtn')}
        </Button>,
      ]}
      className="modal md-deactive-user"
    >
      <Space direction="vertical" size={20} className="space-txt-deactive">
        <Typography.Text className="txt-deactive break-line-localize">
          {actionHandleChange === 'active'
            ? translate('settingPage.adminUser.activeInfo')
            : translate('settingPage.adminUser.deactiveInfo')}
        </Typography.Text>
      </Space>
    </Modal>
  );
};

export default React.memo(DeactiveUser);
