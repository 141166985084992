/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Space, Tooltip, Typography, Button } from 'antd';
import './styles/list-incidents.scss';
import Headers from './components/list-incidents/Headers';
import Filters from './components/list-incidents/Filters';
import { IFiltersIncidentsFormValues } from './interfaces';
import moment from 'moment';
import CreateIncidents from './components/list-incidents/CreateIncidents';
import { useAppSelector } from 'app/hooks';
import {
  loadingIncidentsAction,
  UNMOUT_INCIDENTS_LIST,
  updateIncidentFilterAction,
} from 'actions/incidents';
import { useHistory } from 'react-router';
import { FlagFilled } from '@ant-design/icons';
import { trim, get, has } from 'lodash';
import { renderImageResponseStatus } from './helpers';
import InfinityLoadTable from 'components/common/InfinityLoadTable';
import { isEmpty } from 'lodash';
import { translate } from 'libs';
import { useDispatch } from 'react-redux';
import ReactDragListView from 'react-drag-listview';
import { NoColumnsIcon, FilterIcon } from './components/list-incidents/images';
import { MenuOutlined } from '@ant-design/icons';

import { getSettingServeyAction } from 'actions/organization';
import { IPayloadGetSettingServey } from 'pages/setting/interfaces';
const ListIncidents = () => {
  const history = useHistory();

  const showHidden: boolean = useAppSelector(
    (state) => state.organizations.isShowHiddenOrganization
  );
  const myOrg = useAppSelector((state) => state.users.selectedOrg);
  const data_org_root = useAppSelector((state) => state.organizations.root_org_data);
  const { list, isLoading } = useAppSelector((state) => state.incidents);
  const [isVisibleCreate, setIsVisibleCreate] = React.useState<boolean>(false);

  const filtersRef: any = React.useRef();
  const [query, setQuery] = React.useState<any>(null);
  const [trigger, setTrigger] = React.useState(0);

  const [sortType, setSortType] = React.useState<string>('');
  const [ascOrder, setAscOrder] = React.useState<boolean>(true);
  const [movingCol, setMovingCol] = React.useState<string>('');
  const filters = useAppSelector((state) => state.incidentsFilters);
  const auth = useAppSelector((state) => state.auth);
  const [surveyStatus, setSurveyStatus] = React.useState<boolean>(true);
  const dispatch = useDispatch();

  const renderColTitle = (title: string, sort: string) => {
    return (
      <div className="table-header">
        <span
          className="dragHandler"
          onClick={(e) => {
            e.stopPropagation();
          }}
          onMouseOver={(e) => {
            setMovingCol(title);
          }}
          onMouseLeave={(e) => {
            setMovingCol('');
          }}
        >
          <>
            {movingCol === title && <MenuOutlined style={{ marginRight: '5px' }} />}
            {title}
          </>
        </span>
        <span
          className={sortType === sort ? (ascOrder === true ? 'upIcon' : 'downIcon') : 'normalIcon'}
        >
          {}
        </span>
      </div>
    );
  };

  const totalCols = new Map([
    [
      'sequence',
      {
        label: 'sequence',
        title: renderColTitle(translate('incidents.list.incident'), 'sequence'),
        dataIndex: 'sequence',
        width: 120,
        showSorterTooltip: false,
        ellipsis: {
          showTitle: false,
        },
        sorter: (a: any, b: any) => a.sequence - b.sequence,
        render: (sequence: number) => (
          <Tooltip placement="bottomLeft" title={sequence}>
            <Typography.Text className="txt-incident">{sequence}</Typography.Text>
          </Tooltip>
        ),
        // fixed: 'left',
      },
    ],
    [
      'sms',
      {
        label: 'sms',
        title: renderColTitle(translate('incidents.list.msgs'), 'totalMessage'),
        dataIndex: 'totalMessage',
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.response_status.localeCompare(b.response_status),
        width: 120,
        render: (totalMessage: number, record: any) => {
          const { src, tooltip } = renderImageResponseStatus(record?.response_status, totalMessage);
          return (
            <Space>
              {src && (
                <Tooltip placement="bottomLeft" title={translate(`incidents.list.${tooltip}`)}>
                  <img alt="" src={src} />
                </Tooltip>
              )}
              <Typography.Text>{totalMessage || 0}</Typography.Text>
            </Space>
          );
        },
      },
    ],
    [
      'status',
      {
        label: 'status',
        title: renderColTitle(translate('incidents.list.status'), 'status'),
        dataIndex: 'status',
        width: 146,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.status?.name.localeCompare(b.status?.name),
        render: (status: any) => {
          return (
            <Tooltip placement="bottomLeft" title={status?.name}>
              <Typography.Text>{status?.name}</Typography.Text>
            </Tooltip>
          );
        },
      },
    ],
    [
      'description',
      {
        label: 'description',
        title: renderColTitle(translate('incidents.list.description'), 'description'),
        dataIndex: 'description',
        width: 250,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.description.localeCompare(b.description),
        render: (record: string) => {
          return (
            <Tooltip
              placement="bottomLeft"
              title={
                <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{record}</span>
              }
            >
              <span className="text-ellipsis">{record}</span>
            </Tooltip>
          );
        },
      },
    ],
    [
      'organization',
      {
        label: 'organization',
        title: renderColTitle(translate('incidents.list.org'), 'organization'),
        dataIndex: 'organization',
        width: 300,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.org_id?.name.localeCompare(b.org_id?.name),
        render: (_: any, record: any) => {
          const orgName: string = record?.org_id?.name;
          return (
            <Tooltip placement="bottomLeft" title={orgName}>
              {orgName?.length < 60 ? orgName : orgName.substring(0, 32) + `...`}
            </Tooltip>
          );
        },
      },
    ],
    [
      'updatedAt',
      {
        label: 'updatedAt',
        title: renderColTitle(translate('incidents.list.updated'), 'updatedAt'),
        dataIndex: 'updatedAt',
        width: 125,
        sorter: (a: any, b: any) => moment(a.updatedAt).unix() - moment(b.updatedAt).unix(),
        showSorterTooltip: false,
        ellipsis: {
          showTitle: false,
        },
        render: (record: string) => {
          const text: string = moment(record).format('YYYY/MM/DD');
          return (
            <Tooltip placement="bottomLeft" title={text}>
              {text}
            </Tooltip>
          );
        },
      },
    ],
    [
      'submitted',
      {
        label: 'submitted',
        title: renderColTitle(translate('incidents.list.submitted'), 'createdAt'),
        dataIndex: 'createdAt',
        width: 125,
        sorter: (a: any, b: any) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
        showSorterTooltip: false,
        ellipsis: {
          showTitle: false,
        },
        render: (record: string) => {
          const text: string = moment(record).format('YYYY/MM/DD');
          return (
            <Tooltip placement="bottomLeft" title={text}>
              {text}
            </Tooltip>
          );
        },
      },
    ],
    [
      'priority',
      {
        label: 'priority',
        title: renderColTitle(translate('incidents.list.priority'), 'priority'),
        dataIndex: 'priority',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => (a.priority?.name || '').localeCompare(b.priority?.name || ''),
        render: (priority: any) => {
          const { name, color } = priority;
          return (
            <>
              {
                <Tooltip placement="bottomLeft" title={priority?.name}>
                  {!isEmpty(name) && !isEmpty(color) ? (
                    <FlagFilled style={{ color: priority?.color }} />
                  ) : (
                    <div></div>
                  )}
                </Tooltip>
              }
            </>
          );
        },
      },
    ],
    [
      'type',
      {
        label: 'type',
        title: renderColTitle(translate('incidents.detail.formDetail.label.type'), 'type'),
        dataIndex: 'type',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => (a.type?.name || '').localeCompare(b.type?.name || ''),
        render: (type: any) => {
          if (!type) return null;
          const { name } = type;
          return (
            <>
              {
                <Tooltip placement="bottomLeft" title={type?.name}>
                  {name}
                </Tooltip>
              }
            </>
          );
        },
      },
    ],
    [
      'happendedAt',
      {
        label: 'happendedAt',
        title: renderColTitle(translate('incidents.detail.formDetail.label.date'), 'happened_at'),
        dataIndex: 'happened_at',
        width: 120,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) =>
          moment(a.happened_at || new Date()).unix() - moment(b.happened_at || new Date()).unix(),
        render: (happened_at: string) => {
          if (!happened_at) return null;
          const text = moment(happened_at).format('YYYY/MM/DD');

          return (
            <Tooltip
              placement="bottomLeft"
              title={
                <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{text}</span>
              }
            >
              <span className="text-ellipsis">{text}</span>
            </Tooltip>
          );
        },
      },
    ],
    [
      'assignees',
      {
        label: 'assignees',
        title: renderColTitle(translate('incidents.detail.share.assignee'), 'assignees'),
        dataIndex: 'assignees',
        width: 120,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          const aAssignees = a.assignees.map((t: any) => t.full_name).join('');
          const bAssignees = b.assignees.map((t: any) => t.full_name).join('');
          return aAssignees.localeCompare(bAssignees);
        },
        render: (assignees: any[]) => {
          if (!assignees.length) return null;
          const text = assignees.map((t) => t.full_name).join(', ');
          return (
            <Tooltip
              placement="bottomLeft"
              title={
                <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{text}</span>
              }
            >
              <span className="text-ellipsis"> {assignees.map((t) => t.full_name).join(', ')}</span>
            </Tooltip>
          );
        },
      },
    ],
    [
      'labels',
      {
        label: 'labels',
        title: renderColTitle(translate('incidents.detail.share.label'), 'labels'),
        dataIndex: 'labels',
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          const aLabels = a.labels.map((t: any) => t.name).join('');
          const bLabels = b.labels.map((t: any) => t.name).join('');
          return aLabels.localeCompare(bLabels);
        },
        render: (labels: any[]) => {
          if (!labels.length) return null;
          const text = labels.map((t) => t.name).join(', ');
          return (
            <Tooltip
              placement="bottomLeft"
              title={
                <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{text}</span>
              }
            >
              <span className="text-ellipsis"> {labels.map((t) => t.name).join(', ')}</span>
            </Tooltip>
          );
        },
      },
    ],
    [
      'targets',
      {
        label: 'targets',
        title: renderColTitle(translate('incidents.detail.share.targets'), 'targets'),
        dataIndex: 'targets',
        width: 320,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.targets.join('').localeCompare(b.targets.join('')),
        render: (targets: string[]) => {
          if (!targets.length) return null;
          return (
            <Tooltip
              placement="bottomLeft"
              title={
                <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  {targets.join(', ')}
                </span>
              }
            >
              <span className="text-ellipsis">{targets.join(', ')}</span>
            </Tooltip>
          );
        },
      },
    ],
    [
      'perpetrators',
      {
        label: 'perpetrators',
        title: renderColTitle(translate('incidents.detail.share.perpetrators'), 'perpetrators'),
        dataIndex: 'perpetrators',
        width: 200,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => a.perpetrators.join('').localeCompare(b.perpetrators.join('')),
        render: (perpetrators: string[]) => {
          if (!perpetrators.length) return null;
          return (
            <Tooltip
              placement="bottomLeft"
              title={
                <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
                  {perpetrators.join(', ')}
                </span>
              }
            >
              <span className="text-ellipsis">{perpetrators.join(', ')}</span>
            </Tooltip>
          );
        },
      },
    ],
    [
      'actionTakens',
      {
        label: 'actionTakens',
        title: renderColTitle(translate('incidents.detail.share.taken'), 'actions_taken'),
        dataIndex: 'actions_taken',
        width: 350,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          const aActions = a.actions_taken.map((t: any) => t.name).join('');
          const bActions = b.actions_taken.map((t: any) => t.name).join('');
          return aActions.localeCompare(bActions);
        },

        render: (taken: any[]) => {
          if (!taken.length) return null;
          const text = taken.map((t) => t.name).join(', ');
          return (
            <Tooltip
              placement="bottomLeft"
              title={
                <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{text}</span>
              }
            >
              <span className="text-ellipsis"> {text}</span>
            </Tooltip>
          );
        },
      },
    ],
    [
      'location',
      {
        label: 'location',
        title: renderColTitle(translate('incidents.detail.share.location'), 'location'),
        dataIndex: 'location',
        width: 180,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => (a.location || '').localeCompare(b.location || ''),
        render: (location: string) => {
          if (!location) return null;
          return (
            <Tooltip
              placement="bottomLeft"
              title={
                <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{location}</span>
              }
            >
              <span className="text-ellipsis">{location}</span>
            </Tooltip>
          );
        },
      },
    ],
    [
      'is_survey_answered',
      {
        label: 'is_survey_answered',
        title: renderColTitle(
          translate('incidents.list.column.survey_status'),
          'is_survey_answered'
        ),
        dataIndex: 'is_survey_answered',
        width: 180,
        ellipsis: {
          showTitle: false,
        },
        showSorterTooltip: false,
        sorter: (a: any, b: any) => {
          const t_1 = translate('incident.list.column.anonymouse_end_chat_answered');
          const t_2 = translate('incident.list.column.anonymouse_end_chat_not_answered');
          const t_3 = translate('incident.list.column.anonymouse_end_chat_off');
          return (a.is_survey_answered ? t_1 : !surveyStatus ? t_3 : t_2 || '').localeCompare(
            b.is_survey_answered ? t_1 : !surveyStatus ? t_3 : t_2 || ''
          );
        },
        render: (is_survey_answered: boolean) => {
          const t_1 = translate('incident.list.column.anonymouse_end_chat_answered');
          const t_2 = translate('incident.list.column.anonymouse_end_chat_not_answered');
          const t_3 = translate('incident.list.column.anonymouse_end_chat_off');
          let txt_show = t_2;
          if (is_survey_answered === true) {
            txt_show = t_1;
          } else if (!surveyStatus) {
            txt_show = t_3;
          }
          return (
            <Tooltip
              placement="bottomLeft"
              title={
                <span style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>{txt_show}</span>
              }
            >
              <span
                className={`text-ellipsis ${is_survey_answered === true ? 'text-bold-600' : ''}`}
              >
                {txt_show}
              </span>
            </Tooltip>
          );
        },
      },
    ],
  ]);

  const [columns, setColumns] = React.useState<any>(
    Array.isArray(filters.columns)
      ? filters.columns.map((c) => totalCols.get(c)).filter((e) => e)
      : Array.from(totalCols.values()).filter((e, index) => index <= 7)
  );

  React.useEffect(() => {
    if (isEmpty(auth.profile)) return;
    const filtersValues: IFiltersIncidentsFormValues = filtersRef.current.getFilterValues();
    const query = {
      organization_id: myOrg._id,
      search: trim(filtersValues.search),
      status: filtersValues.status,
      response_status: filtersValues.responseStatus,
      assigned_to_me: filtersValues.assigned,
      show_hidden: showHidden,
      sort_by: '',
      sort_order: -1,
    };
    setQuery(query);
    dispatch(
      updateIncidentFilterAction({
        incident_filter: {
          ...query,
          organization_name: myOrg?.name,
          role_name: myOrg?.role_name,
          columns: columns.map((c) => c.label),
        },
      })
    );
  }, [myOrg, showHidden, trigger]);

  React.useEffect(() => {
    const payload: IPayloadGetSettingServey = {
      onSuccess: (dataOrg) => {
        if (dataOrg) {
          let new_data_org = { ...dataOrg };
          if (!has(dataOrg, 'is_allow_servey')) {
            new_data_org['is_allow_servey'] = false;
          }
          setSurveyStatus(new_data_org.is_allow_servey);
        }
      },
      onError: () => {},
    };
    return () => {
      dispatch(getSettingServeyAction(payload));
    };
  }, [dispatch, myOrg, showHidden, trigger]);

  React.useEffect(() => {
    const moving = totalCols.get(movingCol);
    const newColumns = columns
      .filter((e) => e)
      .map((c) => {
        if (c.label === movingCol) {
          return moving;
        }
        return totalCols.get(c.label);
      });
    setColumns(newColumns);
  }, [movingCol, ascOrder, sortType]);

  React.useEffect(() => {
    return () => {
      dispatch({ type: UNMOUT_INCIDENTS_LIST });
    };
  }, []);

  const handleVisibleCreate = () => {
    setIsVisibleCreate(true);
  };

  const handleCancelVisibleCreate = () => {
    setIsVisibleCreate(false);
  };

  const onFilter = () => {
    setTrigger((pre) => pre + 1);
  };

  const onRow = (item: any, index: any) => {
    const isUnread = get(item, 'status.code', '') === 1;
    let className = index % 2 === 0 ? 'row-odd' : '';
    className = isUnread ? `${className} row-bold` : className;
    return {
      className: className,
      onClick: () => history.push(`/admin/incidents/${item._id}`),
    };
  };

  const handleClickSorter = (sorter: any) => {
    const newAscOrder = sorter.order === 'ascend';
    const type = sorter.field;
    if (sorter.order) {
      setSortType(type);
      setAscOrder(newAscOrder);
    } else {
      setSortType('');
      setAscOrder(newAscOrder);
    }
    if (type !== 'sequence' && type !== 'updatedAt' && type !== 'createdAt') return;

    const sortOrder = newAscOrder ? 1 : -1;
    setQuery({ ...query, sort_by: type, sort_order: sortOrder });
  };

  const updateColumns = (newColumns: any[]) => {
    setColumns(newColumns);
    dispatch(
      updateIncidentFilterAction({
        incident_filter: {
          ...filters,
          columns: newColumns.map((c) => c.label),
        },
      })
    );
  };
  const onDragEnd = (fromIndex, toIndex) => {
    const newColumns = [...columns];
    const item = newColumns.splice(fromIndex, 1)[0];
    newColumns.splice(toIndex, 0, item);
    updateColumns(newColumns);
  };

  const onFilterCols = (colName: string, isChecked: boolean) => {
    let newColumns: any = [];
    if (isChecked) {
      const newColumnIndex = Array.from(totalCols.values()).findIndex(
        (col: any) => col.label === colName
      );
      if (newColumnIndex < 0) return;
      newColumns = [...columns];
      newColumns.splice(newColumnIndex, 0, Array.from(totalCols.values())[newColumnIndex]);
      setColumns(newColumns);
    } else {
      newColumns = columns.filter((col: any) => {
        return col.label !== colName;
      });
    }
    setColumns(newColumns);
  };

  return (
    <div className="list-incidents">
      <Headers />
      <Filters
        onFilter={onFilter}
        handleVisibleCreate={handleVisibleCreate}
        ref={filtersRef}
        onFilterCols={onFilterCols}
        columns={columns}
      />
      {filters.columns?.length > 0 && (
        <ReactDragListView.DragColumn
          onDragEnd={onDragEnd}
          nodeSelector="th"
          handleSelector=".dragHandler"
          ignoreSelector="react-resizable-handle"
          lineClassName="drag-line"
        >
          <InfinityLoadTable
            columns={(filters?.columns || [])
              .map((c: string) => {
                if (!get(data_org_root, 'is_allow_servey', false)) {
                  if (!['anonymous_end_chat_status', 'is_end_chat'].includes(c)) {
                    return totalCols.get(c);
                  }
                  return null;
                } else {
                  return totalCols.get(c);
                }
              })
              .filter((e) => e)}
            dataSource={list}
            className="table-incident"
            onRow={onRow}
            scroll={{ x: 800, y: 'calc(100vh - 290px)' }}
            query={query}
            loadDataAction={loadingIncidentsAction}
            isLoading={isLoading}
            dataType={translate('list.empty.incident')}
            onchangeSorter={handleClickSorter}
          />
        </ReactDragListView.DragColumn>
      )}
      {filters.columns?.length === 0 && (
        <div className="no-columns-message">
          <NoColumnsIcon />
          <span style={{ fontWeight: '500', fontSize: '21px', margin: '5px 0px 5px -20px' }}>
            {translate('incidents.list.nocolumn.des1')}
          </span>
          <span style={{ marginBottom: '10px' }}> {translate('incidents.list.nocolumn.des2')}</span>
          <Button
            size="large"
            type="primary"
            className="btn-filter-columns"
            onClick={() => {
              filtersRef.current.showFilterCols();
            }}
            icon={<FilterIcon color="white" fillOpacity={'0.9'} />}
          >
            {translate('incidents.list.nocolumn.button')}
          </Button>
        </div>
      )}
      <CreateIncidents isVisible={isVisibleCreate} onCancel={handleCancelVisibleCreate} />
    </div>
  );
};

export default React.memo(ListIncidents);
