/* eslint-disable react-hooks/exhaustive-deps */
import { DownOutlined, SearchOutlined } from '@ant-design/icons';
import { Card, Input, Switch, Typography, Tree, Space, Button, Form, Tooltip, Spin } from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { ETypeOrganization } from 'pages/setting/interfaces';
import React, { useCallback, useEffect, useState } from 'react';
import { AA, OS, OA } from 'utils';
import trim from 'lodash/trim';
import { filterOrgsByName } from './helpers';
import { translate } from 'libs';
import {
  changeShowHiddenOrganizationAction,
} from 'actions/organization';
import { loadingUserRoleAction } from 'actions/users';
interface IOrganizationsProps {
  onSelected: (selectecItem: any) => void;
  isVisibleShowHidden?: boolean;
  isHideAmInCharge?: boolean;
  isDisabledAmInCharge?: boolean;
  isManage?: boolean;
  selectGradeOnly?: boolean;
}

const Organizations = ({
  onSelected,
  isVisibleShowHidden = true,
  isHideAmInCharge = false,
  isDisabledAmInCharge = false,
  isManage = false,
  selectGradeOnly = false,
}: IOrganizationsProps) => {
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();

  const role: string = useAppSelector((state) => state.auth.role);
  const permissionOrganizations: any[] = useAppSelector((state) => state.auth.permissionOrganizations);
  const showHidden: boolean = useAppSelector(
    (state) => state.organizations.isShowHiddenOrganization
  );
  const isAAOS: boolean = React.useMemo(() => [AA, OS].includes(role), [role]);
  const isOA: boolean = React.useMemo(() => role === OA, [role]);
  const [searchString, setSearchString] = React.useState<string>('');
  const [expandedKeys, setExpandedKeys] = React.useState<React.Key[]>([]);

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    loadingOrganizations(showHidden);
  }, [])

  React.useEffect(() => {
    if (Array.isArray(permissionOrganizations)) {
      handleSetExpandKeys();
    }
  }, [permissionOrganizations]);

  const handleShowTreeData = () => {
    const newTreeData: any[] = permissionOrganizations.map((organization: any) => {
      let newSchools: any[] = [];
      const isRootRole = !!organization?.role?.[0]?.role_name;
      if (organization?.children?.length) {
        newSchools =
          showHidden && isVisibleShowHidden
            ? organization.children
            : organization.children.filter((school: any) => school?.is_active);

        if (isManage && isOA) {
          newSchools = newSchools.filter((school: any) => {
            const schoolRole: string = school?.role?.[0]?.role_name;
            const isGradeOA: boolean = school.children.some(
              (grade: any) => grade?.role?.[0]?.role_name === OA
            );
            return isGradeOA || schoolRole === OA;
          });
        }

        newSchools = newSchools.map((school: any) => {
          let newGrades: any = [];
          const isHiddenSchool: boolean = !school?.is_active;

          /**
           * School has role OA, will be show all grade of this school @isSchoolOA
           */

          const isSchoolOA: boolean = school?.role?.[0]?.role_name === OA;
          const isSchoolActive = isRootRole || school?.role?.[0]?.role_name;

          if (school.children?.length) {
            newGrades =
              showHidden && isVisibleShowHidden
                ? school.children
                : school.children.filter((grade: any) => grade?.is_active);

            if (isManage && isOA) {
              newGrades = newGrades.filter((grade: any) => {
                const gradeRole: string = grade?.role?.[0]?.role_name;
                return isSchoolOA || gradeRole === OA;
              });
            }

            newGrades = newGrades.map((grade: any) => {
              const isHiddenGrade: boolean = !grade?.is_active;

              return {
                ...grade,
                isGrade: true,
                title: (
                  <Tooltip title={<span style={{ whiteSpace: 'pre-wrap' }}>{grade?.title}</span>}>
                    <div className={`tree-header-grade ${isHiddenGrade && 'hidden'}`}>
                      <Space direction="vertical" size={0}>
                        <Typography.Text className="txt-grade" style={{ whiteSpace: 'pre' }}>{grade?.title}</Typography.Text>
                        {isHiddenGrade && (
                          <Typography.Text className="txt-hidden" style={{ color: "#FF6969" }}>
                            {translate('settingPage.ogSetup.hiddenOg')}
                          </Typography.Text>
                        )}
                      </Space>
                    </div>
                  </Tooltip>
                ),
              };
            });
          }

          const isSingleLayer: boolean = school?.type === ETypeOrganization.SINGLE;

          return {
            ...school,
            isSchool: true,
            title: (
              <Tooltip title={<span style={{ whiteSpace: 'pre-wrap' }}>{school?.title}</span>}>
                <div
                  className={`tree-header-school ${isHiddenSchool && 'hidden'} ${(!isSchoolActive || selectGradeOnly) && !isSingleLayer && 'is-role-grade'
                    }`}
                >
                  <Space direction="vertical" size={0}>
                    <Typography.Text className="txt-school" style={{ whiteSpace: 'pre' }}>{school?.title}</Typography.Text>
                    {isHiddenSchool && (
                      <Typography.Text className="txt-hidden" style={{ color: "#FF6969" }}>
                        {translate('settingPage.ogSetup.hiddenOg')}
                      </Typography.Text>
                    )}
                  </Space>
                </div>
              </Tooltip>
            ),
            children: newGrades,
            disabled: (!isSchoolActive || selectGradeOnly) && !isSingleLayer,
          };
        });
      }

      return {
        ...organization,
        isOrganization: true,
        title: (
          <Tooltip title={<span style={{ whiteSpace: 'pre-wrap' }}>{organization?.name}</span>}>
            <div
              className={`tree-header-og ${!isRootRole && 'is-oa'} ${(selectGradeOnly || !isRootRole) && 'is-role-grade'
                }`}
            >
              <Typography.Text className="txt-og" style={{ whiteSpace: 'pre' }}>{organization?.name}</Typography.Text>
            </div>
          </Tooltip>
        ),
        children: newSchools,
        disabled: !isRootRole || selectGradeOnly,
      };
    });
    setLoading(false)
    return newTreeData
      .map((tree) => filterOrgsByName(role, tree, trim(searchString.toLowerCase())))
      .filter(Boolean);
  };

  const memoTreeData: any[] = React.useMemo(() => {
    return handleShowTreeData()
  }, [permissionOrganizations, showHidden, searchString]);

  const onSelect = (selectedKeys: React.Key[], info: any) => {
    onSelected(info.node);
  };

  const handleImCharge = () => {
    onSelected(null);
  };

  const handleSetExpandKeys = () => {
    let newExpandKeys: string[] = [];
    [...permissionOrganizations].forEach((item: any) => {
      newExpandKeys.push(item?.key);

      if (Array.isArray(item?.children)) {
        item.children.forEach((school: any) => {
          newExpandKeys.push(school?.key);

          if (Array.isArray(school?.children)) {
            school.children.forEach((grade: any) => {
              newExpandKeys.push(grade?.key);
            });
          }
        });
      }
    });

    setExpandedKeys(newExpandKeys);
  };

  const onExpand = (expandedKeysValue: React.Key[]) => {
    setExpandedKeys(expandedKeysValue);
  };

  const handleChangeSearch = (event: any) => {
    const { value } = event.target;
    setSearchString(value);
  };

  const loadingOrganizations = useCallback(
    (checked: boolean) => {
      const payload: any = {
        query: {
          show_hidden: checked,
        },
        onError: (errorCode: string) => {
          dispatch(changeShowHiddenOrganizationAction(checked));
        },
        onSuccess: () => {
          dispatch(changeShowHiddenOrganizationAction(checked));
        },
      };
      return dispatch(loadingUserRoleAction(payload));
    },
    []
  );

  const handleChangeHideShow = (checked: boolean) => {
    loadingOrganizations(checked);
  };

  return (
    <Card className="organizations">
      <Form form={form} initialValues={{ show_hidden: showHidden, search: '' }}>
        {isVisibleShowHidden && (
          <div className="show-hidden">
            <Typography.Text className="txt">
              {translate('settingPage.ogSetup.showHidden')}
            </Typography.Text>
            <Form.Item name="show_hidden" noStyle valuePropName="checked">
              <Switch
                onChange={(value: boolean) => {
                  handleChangeHideShow(value);
                }}
                className="switch-hidden"
              />
            </Form.Item>
          </div>
        )}

        <div className="wrap-input">
          <Form.Item name="search" noStyle>
            <Input
              placeholder={translate('settingPage.ogSetup.searchPlaceholder')}
              prefix={<SearchOutlined />}
              className="search-input"
              size="large"
              allowClear
              maxLength={256}
              onChange={handleChangeSearch}
            />
          </Form.Item>
        </div>
      </Form>
      {!isAAOS && !isHideAmInCharge && (
        <Button
          type="link"
          className="txt-oa"
          onClick={handleImCharge}
          disabled={isDisabledAmInCharge}
        >
          {translate('settingPage.adminUser.allCharge')}
        </Button>
      )}

      <Spin spinning={loading}>
        {memoTreeData.length > 0 ? (
          <Tree
            switcherIcon={<DownOutlined />}
            className="tree-organizations"
            treeData={memoTreeData}
            onSelect={onSelect}
            expandedKeys={expandedKeys}
            onExpand={onExpand}
          />
        ) : (
          loading ? <div style={{ height: 200 }} /> : <div style={{ display: 'flex', justifyContent: 'center', marginTop: 15 }}>
            {translate('no_data', { name: translate('list.empty.organization') })}
          </div>
        )}
      </Spin>
    </Card>
  );
};

export default React.memo(Organizations);
