/* eslint-disable react-hooks/exhaustive-deps */
import { DownOutlined, RightOutlined, StepBackwardOutlined } from '@ant-design/icons';
import { getUserRoleAction } from 'actions/auth';
import { Checkbox, Table, Tooltip, Typography } from 'antd';
import { useAppDispatch } from 'app/hooks';
import { IPayloadGetUserRole } from 'pages/auth/interfaces';
import { memo, useCallback, useEffect, useState } from 'react';
import { AA, OA, OS, RM, RO } from 'utils';
import { LOADING_USER_ROLE_SUCCESS } from 'actions/users';
import { loadingUserRoleAction } from 'actions/users';
import { translate } from 'libs';

const MyRole = () => {
  const dispatch = useAppDispatch();

  const [activeKey, setActiveKey] = useState<string[]>([]);
  const [userRole, setUserRole] = useState<any[]>([]);

  const loadingUserRole = useCallback(() => {
    const payload: IPayloadGetUserRole = {
      onSuccess: (data: any) => {
        let newUserRole: any[] = [];
        // const newActiveKey: string[] = [];
        let roles: any = [];
        if (Array.isArray(data)) {
          newUserRole = data
            .map((organization: any) => {
              if (organization?._id === data?.[0]._id) {
                /**
                 * Just pick first root
                 */
                let newSchools: any = [];

                roles = [organization?.role?.[0]?.role_name];

                if (organization.childrens?.length) {
                  newSchools = organization.childrens.map((school: any) => {
                    let newGrades: any = [];

                    // newActiveKey.push(school._id);
                    roles = [...roles, school?.role?.[0]?.role_name];
                    if (school.childrens?.length) {
                      newGrades = school.childrens.map((grade: any) => {
                        roles = [...roles, grade?.role?.[0]?.role_name];
                        return {
                          ...grade,
                          isGrade: true,
                          title: grade?.name,
                          key: `${organization?._id}-${school?._id}-${grade?._id}`,
                        };
                      });
                    }

                    return {
                      ...school,
                      title: school?.name,
                      children: newGrades,
                      key: `${organization?._id}-${school?._id}`,
                    };
                  });
                }

                return {
                  ...organization,
                  title: organization?.name,
                  children: newSchools,
                  key: organization?._id,
                  disableCheckbox: true,
                };
              }

              return null;
            })
            .filter((e) => !!e);
          const highestRole = roles.sort()[0];
          dispatch({ type: LOADING_USER_ROLE_SUCCESS, payload: { highestRole } });
          setUserRole(newUserRole);
          // setActiveKey(newActiveKey);
        }
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };
    return dispatch(getUserRoleAction(payload));
  }, []);

  useEffect(() => {
    loadingUserRole();
    return dispatch(loadingUserRoleAction({ query: {} }));
  }, []);

  const onChangExpand = (event: any) => {
    const { checked = false } = event.target;

    let newActiveKey: string[] = [];

    if (checked) {
      [...userRole].forEach((item: any) => {
        newActiveKey.push(item?._id);

        if (Array.isArray(item?.children)) {
          item.children.forEach((school: any) => {
            newActiveKey.push(school?._id);
          });
        }
      });
    }

    setActiveKey(newActiveKey);
  };

  const onExpandKey = (expanded: boolean, keySelected: string) => {
    const newActiveKey: string[] = [...activeKey];

    const indexKey: number = newActiveKey.findIndex((key: string) => key === keySelected);

    if (expanded) {
      if (indexKey < 0) {
        newActiveKey.push(keySelected);
      }
    } else {
      if (indexKey >= 0) {
        newActiveKey.splice(indexKey, 1);
      }
    }

    setActiveKey(newActiveKey);
  };

  const renderRoleName = (role: string) => {
    switch (role) {
      case AA:
        return translate('settingPage.adminUser.AA');
      case OS:
        return translate('settingPage.adminUser.OS');
      case OA:
        return translate('settingPage.adminUser.OA');
      case RM:
        return translate('settingPage.adminUser.RM');
      case RO:
        return translate('settingPage.adminUser.RO');
      default:
        break;
    }
  };

  const columns: any[] = [
    {
      title: translate('settingPage.ogSetup.ogName'),
      dataIndex: 'name',
      key: 'name',
      width: 483,
      ellipsis: {
        showTitle: false,
      },
      render: (name: string) => {
        return (
          <Tooltip placement="bottomLeft" title={name}>
            <Typography.Text>{name}</Typography.Text>
          </Tooltip>
        );
      },
    },
    {
      title: () => {
        return (
          <Checkbox onClick={onChangExpand} className="cb-expand">
            {translate('settingPage.myRole.expandOg')}
          </Checkbox>
        );
      },
      dataIndex: 'checkbox',
      key: 'checkbox',
      width: 419,
      align: 'center',
    },
    {
      title: translate('settingPage.myRole.role'),
      dataIndex: 'role',
      key: 'role',
      width: 432,
      render: (record: any) => Array.isArray(record) && renderRoleName(record?.[0]?.role_name),
    },
  ];

  return (
    <div className="my-role">
      {userRole.length > 0 && (
        <Table
          columns={columns}
          dataSource={userRole}
          className="board"
          rowKey="_id"
          expandable={{
            expandIcon: ({ expanded, onExpand, record }) => {
              if (Array.isArray(record?.children) && record.children.length > 0) {
                return expanded ? (
                  <DownOutlined className="ic-expand" onClick={(e) => onExpand(record, e)} />
                ) : (
                  <RightOutlined className="ic-expand" onClick={(e) => onExpand(record, e)} />
                );
              }

              return <StepBackwardOutlined className="fake-expand" />;
            },
            expandedRowKeys: activeKey,
            onExpand: (expanded: boolean, record: any) => onExpandKey(expanded, record._id),
          }}
          pagination={false}
          locale={{ emptyText: translate('no_data', { name: translate('list.empty.permission') }) }}
        />
      )}
    </div>
  );
};

export default memo(MyRole);
