/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { Button, Modal, Form, Radio, Space, Typography, Input, Switch, Table, Tooltip } from 'antd';
import {
  EQuestionType,
  IFormIncidentQsProps,
  IPayloadChangeVisibilityIncidentQuestion,
} from 'pages/setting/interfaces';
import { RO, RM, AA, OS } from 'utils';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { arrayMoveImmutable } from 'array-move';
import { DragHandle, SortableContainers, SortableItem } from '../IncidentQuestion';
import { changeVisibilityIncidentQuestionAction } from 'actions/incident-question';
import trim from 'lodash/trim';
import { MenuOutlined } from '@ant-design/icons';
import { ETypeOrganization } from 'pages/setting/interfaces';
import { get } from 'lodash';
import ConfirmButton from 'components/common/ConfirmButton';
import { translate } from 'libs';

const dataAnswerDefault: any[] = [
  {
    index: 0,
    answer: 'A',
  },
  {
    index: 1,
    answer: 'B',
  },
  {
    index: 2,
    answer: 'C',
  },
  {
    index: 3,
    answer: 'D',
  },
  {
    index: 4,
    answer: 'E',
  },
];

const dataAnswerRating: any[] = [
  {
    index: 0,
    answer: '★',
  },
  {
    index: 1,
    answer: '★★',
  },
  {
    index: 2,
    answer: '★★★',
  },
  {
    index: 3,
    answer: '★★★★',
  },
  {
    index: 4,
    answer: '★★★★★',
  },
];

const FormIncidentQs = React.forwardRef(
  (
    {
      isVisible,
      onCancel,
      isLoading,
      onFinish,
      formRef,
      selectedItem,
      loadingQuestions,
      isDisabled,
      selectedOrg,
    }: IFormIncidentQsProps,
    ref: any
  ) => {
    const [form] = Form.useForm();
    const dispatch = useAppDispatch();

    const userRole: string = useAppSelector((state) => state.auth.role);
    const isAAOS: boolean = React.useMemo(() => [AA, OS].includes(userRole), [userRole]);
    const permissionOrganizations: any[] = useAppSelector(
      (state) => state.auth.permissionOrganizations
    );

    const isGrade: boolean = selectedOrg?.isGrade || selectedOrg?.type === ETypeOrganization.SINGLE;
    const inputRef: any = React.useRef();
    const [dataAnswers, setDataAnswers] = React.useState<any[]>(dataAnswerDefault);
    const isEdited: boolean = React.useMemo(() => !!selectedItem, [selectedItem]);
    const [valueInput, setValueInput] = useState<string>('');

    const getQuestion = React.useCallback(() => {
      form.setFieldsValue({
        ...selectedItem?.question_detail,
        visibility: selectedItem?.visibility_custom,
      });

      if (Array.isArray(selectedItem?.question_detail?.answer_value)) {
        const { answer_value } = selectedItem.question_detail;

        const newDataAnswers = answer_value.map((answer: string, index: number) => ({
          index,
          answer,
        }));

        setDataAnswers(newDataAnswers);
      }
    }, [selectedItem]);

    React.useEffect(() => {
      setValueInput('');
      if (selectedItem && isVisible) {
        getQuestion();
      } else {
        if (isVisible) {
          form.resetFields();
        }
      }

      return () => {
        if (selectedItem && isVisible) {
          form.resetFields();
        }
      };
    }, [selectedItem, isVisible]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
      if (oldIndex !== newIndex) {
        const newData = arrayMoveImmutable([].concat(...dataAnswers), oldIndex, newIndex).filter(
          (el) => !!el
        );
        setDataAnswers(newData);
      }
    };

    const DraggableContainer = (props: any) => (
      <SortableContainers
        useDragHandle
        helperClass="option-dragging"
        onSortEnd={onSortEnd}
        {...props}
      />
    );

    const DraggableBodyRow = ({ className, style, ...restProps }) => {
      // function findIndex base on Table rowKey props and should always be a right array index
      const index = dataAnswers.findIndex((x) => x.index === restProps['data-row-key']);
      return <SortableItem index={index} {...restProps} />;
    };

    const handleDeleteAnswer = (id: string) => {
      if (isDisabled) return;

      const newDataAnswers = [...dataAnswers];

      const findIndex: number = newDataAnswers.findIndex((e: any) => e?.index === id);
      if (findIndex >= 0) {
        newDataAnswers.splice(findIndex, 1);
      }

      setDataAnswers(newDataAnswers);
    };

    const handleChangeVisibility = (checked: boolean) => {
      form.setFieldsValue({ visibility: !checked });

      const payload: IPayloadChangeVisibilityIncidentQuestion = {
        values: {
          status: checked,
          question_id: selectedItem?._id,
        },
        onSuccess: () => {
          form.setFieldsValue({ visibility: checked });
          loadingQuestions();
        },
      };

      return dispatch(changeVisibilityIncidentQuestionAction(payload));
    };

    const columns = [
      {
        dataIndex: 'sort',
        width: 30,
        className: 'drag-visible',
        render: () =>
          !isDisabled ? (
            <Tooltip placement="topLeft" title={translate('setting.tooltip.dragIcon')}>
              <div>
                <DragHandle />
              </div>
            </Tooltip>
          ) : (
            <MenuOutlined style={{ cursor: 'not-allowed', color: '#999' }} />
          ),
      },
      {
        dataIndex: 'answer',
        width: 540,
        render: (record: string) => (
          <Input className="input-answer" size="large" disabled value={record} />
        ),
      },
      {
        dataIndex: 'delete',
        render: (_: any, record: any) => (
          <img
            alt=""
            src="/images/ic-delete.png"
            onClick={() => handleDeleteAnswer(record?.index)}
            className="ic-delete"
          />
        ),
      },
    ];

    React.useImperativeHandle(ref, () => ({
      getDataAnswers: () => {
        const newDataAnswers: string[] = [...dataAnswers]
          .map((e: any) => e?.answer)
          .filter((e: any) => !!e);

        return newDataAnswers;
      },
    }));

    const disabledRequired = () => {
      if (isDisabled) return true;
      if (!isEdited) return false;
      if (isAAOS && !selectedOrg) return false;
      if (
        userRole !== RO &&
        userRole !== RM &&
        selectedOrg?._id === get(selectedItem, 'question_detail.org_id')
      )
        return false;
      return true;
    };

    const handleChangeQuestionType = (event: any) => {
      const { value } = event.target;

      if (value === EQuestionType.RATING) {
        setDataAnswers(dataAnswerRating);
      } else {
        setDataAnswers(dataAnswerDefault);
      }
    };

    const renderOwner = () => {
      return selectedOrg?.name || permissionOrganizations?.[0]?.name;
    };

    const checkDisabledSubmit = (): boolean => {
      if (isVisible) {
        const questionType: EQuestionType = form.getFieldValue('question_type');

        const listMustHaveAnswer: EQuestionType[] = [
          EQuestionType.CHECKBOXLIST,
          EQuestionType.RATING,
          EQuestionType.DROPDOWN,
        ];

        if (listMustHaveAnswer.includes(questionType) && dataAnswers.length === 0) return true;
      }

      return false;
    };

    return (
      <Modal
        maskClosable={false}
        title={translate(`settingPage.incidentQuestion.${isEdited ? 'edit' : 'add'}IQs`)}
        centered
        visible={isVisible}
        onCancel={onCancel}
        footer={[
          !isDisabled && (
            <ConfirmButton
              className="btn-save"
              buttonTitle={
                isEdited
                  ? translate('settingPage.incidentQuestion.editIQs.save')
                  : translate('settingPage.incidentQuestion.createBtn')
              }
              onClick={() => formRef.current?.submit()}
              form={form}
              keyName="large"
              size="large"
              isLoading={isLoading}
              disabled={checkDisabledSubmit()}
            />
          ),
          <Button key="cancel" className="btn-cancel" size="large" onClick={onCancel}>
            {translate('common.cancelBtn')}
          </Button>,
        ]}
        className="modal md-form-incident-question"
      >
        <Form
          onFinish={onFinish}
          layout="vertical"
          scrollToFirstError
          ref={formRef}
          form={form}
          autoComplete="off"
          initialValues={{
            is_required: false,
            question_type: EQuestionType.TEXTBOX,
            editable: false,
            visibility: false,
            question_name: '',
          }}
        >
          {!isGrade && (
            <div className="wrap-type">
              <div className="wrap-txt-setting">
                <Typography.Text>
                  {translate('settingPage.incidentQuestion.settingOwner', {
                    organization: renderOwner(),
                  })}
                </Typography.Text>
              </div>
              <Form.Item name="editable">
                <Radio.Group className="radio-type" disabled={isDisabled}>
                  <Space direction="vertical" size={0}>
                    <Radio value={false}>
                      {translate('settingPage.incidentQuestion.allowUse')}
                    </Radio>
                    <Radio value={true}>{translate('settingPage.incidentQuestion.allowSub')}</Radio>
                  </Space>
                </Radio.Group>
              </Form.Item>
            </div>
          )}

          <Form.Item
            name="question_name"
            label={translate('settingPage.incidentQuestion.question')}
            rules={[
              {
                required: true,
                whitespace: true,
                message: translate('settingPage.incidentQuestion.questionRequired'),
              },
            ]}
          >
            <Input.TextArea
              autoSize={{ maxRows: 5, minRows: 3 }}
              placeholder={translate('settingPage.incidentQuestion.questionPlaceholder')}
              className="input-question"
              maxLength={256}
              disabled={isDisabled}
            />
          </Form.Item>

          <Form.Item className="form-required">
            <Form.Item name="is_required" valuePropName="checked">
              <Switch
                className="switch-required tiny-switch-common"
                size="small"
                disabled={disabledRequired()}
              />
            </Form.Item>
            <div className="wrap-txt">
              <Typography.Text>
                {translate('settingPage.incidentQuestion.requireInfo')}
              </Typography.Text>
            </div>
          </Form.Item>

          {isEdited && (
            <Form.Item className="form-visibility">
              <div className="wrap-txt">
                <Typography.Text>
                  {translate('settingPage.incidentQuestion.visibility')}
                </Typography.Text>
              </div>
              <Form.Item name="visibility" valuePropName="checked">
                <Switch
                  className="switch-visibility  tiny-switch-common"
                  size="small"
                  onChange={handleChangeVisibility}
                  loading={isLoading}
                  disabled={isDisabled}
                />
              </Form.Item>
            </Form.Item>
          )}

          <div className="answer-block">
            <Form.Item
              name="question_type"
              label={translate('default.question.type')}
              className="answer-type"
              rules={[{ required: true }]}
            >
              <Radio.Group
                className="radio-answer-type"
                onChange={handleChangeQuestionType}
                disabled={isDisabled}
              >
                <Space direction="vertical" size={1} className="space-answer-type">
                  <Radio value={EQuestionType.TEXTBOX}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-textbox.png" />
                      <Typography.Text>
                        {translate('default.question.type.textbox')}
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.DROPDOWN}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-dropdown.png" />
                      <Typography.Text>
                        {translate('default.question.type.dropdown')}
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.CHECKBOX}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-checkbox.png" />
                      <Typography.Text>
                        {translate('default.question.type.checkbox')}
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.CHECKBOXLIST}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-checkbox-checked.png" />
                      <Typography.Text>
                        {translate('default.question.type.checkboxlist')}
                      </Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.DATE}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-calendar.png" />
                      <Typography.Text>{translate('default.question.type.date')}</Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.EMAIL}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-mail.png" />
                      <Typography.Text>{translate('default.question.type.email')}</Typography.Text>
                    </Space>
                  </Radio>
                  <Radio value={EQuestionType.RATING}>
                    <Space className="space-label-answer-type">
                      <img alt="" src="/images/ic-star.png" />
                      <Typography.Text>{translate('default.question.type.rating')}</Typography.Text>
                    </Space>
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            <Form.Item noStyle shouldUpdate={(p, n) => p.question_type !== n.question_type}>
              {({ getFieldValue, setFields }) => {
                const answerType: string = getFieldValue('question_type');

                const renderContent = () => {
                  switch (answerType) {
                    case EQuestionType.TEXTBOX:
                      return (
                        <Typography.Text className="txt-answer">
                          {translate('settingPage.incidentQuestion.textboxOption')}
                        </Typography.Text>
                      );
                    case EQuestionType.CHECKBOX:
                      return (
                        <Typography.Text className="txt-answer">
                          {translate('settingPage.incidentQuestion.checkboxOption')}
                        </Typography.Text>
                      );
                    case EQuestionType.DATE:
                      return (
                        <Typography.Text className="txt-answer">
                          {translate('settingPage.incidentQuestion.dateOption')}
                        </Typography.Text>
                      );
                    case EQuestionType.EMAIL:
                      return (
                        <Typography.Text className="txt-answer">
                          {translate('settingPage.incidentQuestion.emailOption')}
                        </Typography.Text>
                      );
                    case EQuestionType.CHECKBOXLIST:
                    case EQuestionType.RATING:
                    case EQuestionType.DROPDOWN: {
                      return (
                        <div className="form-list-answers">
                          <Space className="space-input-add">
                            <Input
                              placeholder={translate('settingPage.incidentQuestion.addNewAnswer')}
                              className="input-add-new"
                              size="large"
                              ref={inputRef}
                              maxLength={256}
                              value={valueInput}
                              onChange={(e) => setValueInput(e.target.value)}
                            />
                            <Button
                              onClick={() => {
                                const trimInput: string = trim(valueInput || '');
                                if (trimInput.length > 0) {
                                  const newDataAnswers: any[] = [...dataAnswers];
                                  const index: number = new Date().valueOf();

                                  const checkExistAnswer: boolean = newDataAnswers.some(
                                    (item: any) =>
                                      item.answer.toLowerCase() === trimInput.toLowerCase()
                                  );

                                  if (checkExistAnswer) {
                                    return setFields([
                                      {
                                        name: 'extra',
                                        errors: [
                                          translate('settingPage.incidentQuestion.answerExist'),
                                        ],
                                      },
                                    ]);
                                  }

                                  setFields([{ name: 'extra', errors: [] }]);

                                  newDataAnswers.push({ index, answer: trimInput });
                                  setDataAnswers(newDataAnswers);
                                  setValueInput('');
                                }
                                return inputRef?.current?.focus?.();
                              }}
                              type="primary"
                              size="large"
                              className="btn-add"
                              disabled={isDisabled}
                            >
                              {translate('settingPage.incidentQuestion.addBtn')}
                            </Button>
                          </Space>

                          <Table
                            pagination={false}
                            dataSource={dataAnswers}
                            columns={columns}
                            rowKey="index"
                            components={{
                              body: {
                                wrapper: DraggableContainer,
                                row: DraggableBodyRow,
                              },
                            }}
                            className="table-answers"
                            scroll={{ y: 270 }}
                            locale={{
                              emptyText: translate('no_data', {
                                name: translate('settingPage.incidentQuestion.answer'),
                              }),
                            }}
                          />
                        </div>
                      );
                    }
                    default:
                      return null;
                  }
                };

                return (
                  <Form.Item
                    className="answer"
                    label={translate('settingPage.incidentQuestion.answer')}
                    name="extra"
                  >
                    <div className="wrap-answer">{renderContent()}</div>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </div>
        </Form>
      </Modal>
    );
  }
);

export default React.memo(FormIncidentQs);
