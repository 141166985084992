import React, { useEffect, useState } from 'react';
import { Switch, Spin } from 'antd';
import { translate } from 'libs';
import { useAppDispatch } from 'app/hooks';
import { IPayloadGetSettingUpload, IPayloadUpdateSettingUpload } from 'pages/setting/interfaces';
import { ModalConfirm } from 'components/modal-confirm';
import { has } from 'lodash';
import { getSettingUploadAction, updateSettingUploadAction } from 'actions/organization';

const SettingUpload = () => {
  const dispatch = useAppDispatch();
  const [org, setOrg] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [isModalConfirm, setIsModalConfirm] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(true);

  const onChange = (checked: boolean) => {
    setStatus(checked);
    setIsModalConfirm(!isModalConfirm);
  };

  const handleOnOff = () => {
    setLoading(true);
    setIsModalConfirm(!isModalConfirm);
    const payload: IPayloadUpdateSettingUpload = {
      value: status,
      onSuccess: () => {
        setOrg({ ...org, is_allow_upload: status });
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    };

    return dispatch(updateSettingUploadAction(payload));
  };

  useEffect(() => {
    const payload: IPayloadGetSettingUpload = {
      onSuccess: (dataOrg) => {
        if (dataOrg) {
          let new_data_org = { ...dataOrg };
          if (!has(dataOrg, 'is_allow_upload')) {
            new_data_org['is_allow_upload'] = true;
          }
          setOrg(new_data_org);
          setStatus(new_data_org.is_allow_upload);
        }
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    };
    return dispatch(getSettingUploadAction(payload));
  }, [dispatch]);

  if (loading)
    return (
      <div className="other-setting-loading">
        <Spin spinning={loading} size="small"></Spin>
      </div>
    );

  return (
    <>
      <div className="other-setting">
        <div className="wrap_header">
          <div className="left">
            <Switch
              checked={org?.is_allow_upload}
              onChange={onChange}
              style={org?.is_allow_upload ? { background: '#34C759' } : { background: '#FF3B30' }}
            />
          </div>
          <div className="right">
            <p className="title">{translate('setting.upload.title')}</p>
          </div>
        </div>
        <div className="wrap-text-des">
          <p style={org?.is_allow_upload ? { color: '#34C759' } : { color: '#FF3B30' }}>
            {translate(`${org?.is_allow_upload ? 'setting.upload.on' : 'setting.upload.off'}`)}
          </p>
          <p className="text-des">{translate('setting.upload.des')}</p>
        </div>
      </div>
      <ModalConfirm
        isDeleted={false}
        visible={isModalConfirm}
        handleOk={handleOnOff}
        handleCancel={() => {
          setIsModalConfirm(!isModalConfirm);
        }}
        title={translate(
          `${
            !status
              ? 'setting.upload.modal.title.on.to.off'
              : 'setting.upload.modal.title.off.to.on'
          }`
        )}
        contentText={translate(
          `${!status ? 'setting.upload.modal.des.on.to.off' : 'setting.upload.modal.des.off.to.on'}`
        )}
        textConfirmBtn={translate(
          `${
            !status
              ? 'setting.upload.modal.btn.on.to.off.ok'
              : 'setting.upload.modal.btn.off.to.on.ok'
          }`
        )}
        textCancelBtn={translate('setting.upload.modal.btn.cancel')}
      />
    </>
  );
};

export default React.memo(SettingUpload);
