import { call, put, takeLatest } from 'redux-saga/effects';
import {
  apiGetListIncidentAttrFamily,
  apiGetListIncidentAttrByOrg,
  apiIncidentAttrAction,
  apiOrdersIncidentAttr,
} from 'api/incident-attr';
import { OK } from 'utils';
import {
  INCIDENATATTR_FAMILY_LOADING,
  INCIDENATATTR_FAMILY_LOADED,
  INCIDENATATTR_LIST_LOADING,
  INCIDENATATTR_CRUD_ACTION_LOADING,
  INCIDENATATTR_ORDER_LOADING,
} from 'actions/incidentAttr';
import {
  IActionCRUDIncidentAttrByOrg,
  IActionGetListAttrByOrg,
  IActionOrderIncidentAttr,
} from 'pages/setting/interfaces';

function* waittingGetFamily() {
  try {
    const response = yield call(apiGetListIncidentAttrFamily);
    if (response?.status === OK) {
      yield put({ type: INCIDENATATTR_FAMILY_LOADED, payload: response.data || [] });
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
  }
}

function* waittinggetListAttrByOrg({ payload }: IActionGetListAttrByOrg) {
  try {
    const response = yield call(apiGetListIncidentAttrByOrg, payload.query);

    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess(response.data);
    } else {
      if (payload?.onError) yield payload?.onError();
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError();
  }
}

function* waittingActionCRUDListAttrByOrg({ payload }: IActionCRUDIncidentAttrByOrg) {
  try {
    const response = yield call(apiIncidentAttrAction, payload.data);

    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess();
    } else {
      if (payload?.onError) yield payload?.onError(response?.error_code, payload.data);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError('', payload.data);
  }
}

function* waittingOrderIncidentAttr({ payload }: IActionOrderIncidentAttr) {
  try {
    const response = yield call(apiOrdersIncidentAttr, payload.data);

    if (response?.status === OK) {
      if (payload?.onSuccess) yield payload?.onSuccess();
    } else {
      if (payload?.onError) yield payload?.onError(response?.error_code);
    }
  } catch (error) {
    console.log('🚀 ~ error', error);
    if (payload?.onError) yield payload?.onError('');
  }
}

export default function* incidentAttrSaga() {
  yield takeLatest(INCIDENATATTR_FAMILY_LOADING, waittingGetFamily);
  yield takeLatest(INCIDENATATTR_LIST_LOADING, waittinggetListAttrByOrg);
  yield takeLatest(INCIDENATATTR_CRUD_ACTION_LOADING, waittingActionCRUDListAttrByOrg);
  yield takeLatest(INCIDENATATTR_ORDER_LOADING, waittingOrderIncidentAttr);
}
