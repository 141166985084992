/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Spin, Typography } from 'antd';
import { translate } from 'libs';
import moment from 'moment';
import { IQueryLoadingActivityLogMultilanguage } from 'pages/incidents/interfaces';
import React, { useEffect } from 'react';

interface IActivityLogProps {
  listActiviLog: any[];
  isLoading: boolean;
  getActivityLog: () => void;
  incidenId: string;
}

const ActivityLog = ({
  isLoading,
  listActiviLog,
  getActivityLog,
  incidenId,
}: IActivityLogProps) => {
  useEffect(() => {
    getActivityLog();
  }, [incidenId]);

  return (
    <Spin spinning={isLoading}>
      <div className="activity">
        <div className="activity-list">
          <Space size={10} className="space">
            {listActiviLog &&
              listActiviLog?.map((itemLog: IQueryLoadingActivityLogMultilanguage, index) => {
                return (
                  <div className="activity-item" key={index}>
                    <div className="header-item">
                      <Typography.Title className="title">
                        {itemLog?.category === '1'
                          ? `${translate('incident.pdf.information.user_id')} ${
                              itemLog?.client_id || ''
                            }`
                          : `${itemLog?.admin_name || ''}`}
                      </Typography.Title>
                      <Typography.Text className="text">
                        {moment(itemLog.time).format('YYYY/MM/DD HH:mm')}
                      </Typography.Text>
                      <Typography.Text className="text">
                        {itemLog?.category === '1'
                          ? translate(`incidents.activityLog.platform.${itemLog.platform}`)
                          : ''}
                      </Typography.Text>
                    </div>
                    <Typography.Text className="activity-text">
                      {translate(`incidents.activityLog.detail.type${itemLog.category}`, {
                        ...itemLog,
                        time: moment(itemLog.time).format('YYYY/MM/DD HH:mm:ss'),
                      })}
                    </Typography.Text>
                  </div>
                );
              })}
          </Space>
        </div>
      </div>
    </Spin>
  );
};

export default React.memo(ActivityLog);
