/* eslint-disable react-hooks/exhaustive-deps */
import { CloseCircleFilled, SearchOutlined } from '@ant-design/icons';
import { loadingActiveUsersAction } from 'actions/reports';
import {
  Col,
  Row,
  Form,
  Input,
  DatePicker,
  Space,
  Typography,
  Table,
  Switch,
  Tooltip,
  Spin,
} from 'antd';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import moment, { Moment } from 'moment';
import {
  EFormatReport,
  IActiveByOrganizationProps,
  IPayloadLoadingReportActiveUsers,
  IQueryLoadingReportActiveUsers,
  IValuesFilterActiveUser,
} from 'pages/reports/interfaces';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import trim from 'lodash/trim';
import locale from 'antd/es/date-picker/locale/ja_JP';
import { ESelectOrganizationValues } from 'pages/setting/interfaces';
import { ETabActivation } from 'pages/reports/components/activation-volume';
import { translate } from 'libs';

const { Text } = Typography;

const ActiveByOrg = React.forwardRef((props: IActiveByOrganizationProps, ref: any) => {
  const { activeKey } = props;
  const { i18n } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const isLoading: boolean = useAppSelector((state) => state.reports.isLoading);

  const [isShowCompare, setIsShowCompare] = React.useState<boolean>(false);
  const [isShowHidden, setIsShowHidden] = React.useState<boolean>(false);
  const [activeUsers, setActiveUsers] = React.useState<any[]>([]);
  const [activeKeys, setActiveKeys] = React.useState<string[]>([]);
  const selectedOrgId = useAppSelector((state) => state.users.selectedOrgId);

  const [loading, setLoading] = useState(true)

  const loadingActiveUsers = React.useCallback(
    ({
      date = '',
      search = '',
      compare_date = '',
      format = EFormatReport.JSON,
    }: IQueryLoadingReportActiveUsers) => {
      if (activeKey !== ETabActivation.ORGANIZATION) return;
      const payload: IPayloadLoadingReportActiveUsers = {
        query: {
          date,
          format,
          search,
          compare_date,
          org_id: selectedOrgId !== ESelectOrganizationValues.ALL_IN_CHARGE ? selectedOrgId : '',
          lang: i18n.language,
          show_hidden: isShowHidden,
        },
        onSuccess: (data: any) => {
          setActiveUsers(data);

          const newActiveKey: string[] = [...activeKeys];

          data.forEach((org: any) => {
            newActiveKey.push(org?._id);

            if (Array.isArray(org?.children)) {
              org.children.forEach((school: any) => {
                newActiveKey.push(school?._id);

                if (Array.isArray(school?.children)) {
                  school.children.forEach((grade: any) => {
                    newActiveKey.push(grade?._id);
                  });
                }
              });
            }
          });

          const uniqKeys: string[] = uniq(newActiveKey);

          setActiveKeys(uniqKeys);
          setLoading(false)
        },
      };

      return dispatch(loadingActiveUsersAction(payload));
    },
    [activeKey, isShowHidden, selectedOrgId]
  );

  React.useEffect(() => {
    if (activeKey === ETabActivation.ORGANIZATION) {
      getQueryAndLoadData();
    }
  }, [activeKey]);

  React.useImperativeHandle(ref, () => ({
    getFilterActiveUsersValues: () => {
      const query: IQueryLoadingReportActiveUsers = getQuery();
      query.lang = i18n.language;
      query.show_hidden = isShowHidden;
      query.org_id = selectedOrgId !== ESelectOrganizationValues.ALL_IN_CHARGE ? selectedOrgId : '';

      return query;
    },
  }));

  const getQuery = (): IQueryLoadingReportActiveUsers => {
    const filters: IValuesFilterActiveUser = form.getFieldsValue();

    const query: IQueryLoadingReportActiveUsers = {
      format: EFormatReport.JSON,
      search: trim(filters?.search),
      date: filters?.date ? moment(filters.date).format('YYYY/MM/DD') : '',
      compare_date: filters?.compare_date ? moment(filters.compare_date).format('YYYY/MM/DD') : '',
    };

    return query;
  };

  const getQueryAndLoadData = () => {
    const query: IQueryLoadingReportActiveUsers = getQuery();

    loadingActiveUsers(query);
  };

  React.useEffect(() => {
    return () => {
      setIsShowCompare(false);
      setActiveKeys([]);
    };
  }, []);

  React.useEffect(() => {
    getQueryAndLoadData();
  }, [isShowHidden, selectedOrgId]);

  const handleAdd = () => {
    setIsShowCompare(true);
  };

  const handleChangeDate = (date: Moment | null) => {
    getQueryAndLoadData();
  };

  const handleChangeDateCompare = (date: Moment | null) => {
    if (!date) {
      getQueryAndLoadData();
      return setIsShowCompare(false);
    }

    getQueryAndLoadData();
  };

  const onChangeShowHidden = (value: boolean) => setIsShowHidden(value);

  const onFinishFilter = () => {
    getQueryAndLoadData();
  };

  const onSearch = () => {
    getQueryAndLoadData();
  };

  const handleChangeSearch = debounce(onSearch, 800);

  const columnsOrg: any[] = [
    {
      title: translate('reports.activationVolume.orgName'),
      dataIndex: 'name',
      width: '50%',
      ellipsis: {
        showTitle: false,
      },
      render: (name: string, record: any) => {
        const isHidden = !record.is_active && !record.isOrg;
        return {
          props: {
            style: isHidden ? { padding: '5px 16px' } : {},
          },
          children: (
            <Tooltip
              placement="bottomLeft"
              title={<span style={{ whiteSpace: 'pre-wrap' }}>{name}</span>}
            >
              <Space direction="vertical" size={0}>
                <Typography.Text className="text-ellipsis">{name}</Typography.Text>
                {isHidden && (
                  <Typography.Text
                    className="text-ellipsis"
                    style={{ color: '#FF6969', fontSize: 12 }}
                  >
                    {translate('settingPage.ogSetup.hiddenOg')}
                  </Typography.Text>
                )}
              </Space>
            </Tooltip>
          ),
        };
      },
    },
    {
      title: () => {
        return (
          <Space>
            <Switch
              size="small"
              onChange={onChangeShowHidden}
              className="switch-hidden switchs-style-ct tiny-switch-common"
            />
            <Text>{translate('reports.activationVolume.showAllOrg')}</Text>
          </Space>
        );
      },
      dataIndex: 'action',
      align: 'right',
    },
  ];

  const columnsCalculate = (isCompare = false) => {
    const columns: any[] = [
      {
        title: translate('reports.activationVolume.acumulate'),
        render: (_: any, record: any) =>
          (isCompare ? record?.compareWeb || 0 : record?.web || 0) +
          (isCompare ? record?.compareMobile || 0 : record?.mobile || 0),
        align: 'right',
      },
      {
        title: translate('reports.activationVolume.web'),
        dataIndex: 'web',
        render: (_: any, record: any) => (isCompare ? record?.compareWeb || 0 : record?.web || 0),
        align: 'right',
      },
      {
        title: translate('reports.activationVolume.app'),
        dataIndex: 'mobile',
        render: (_: any, record: any) =>
          isCompare ? record?.compareMobile || 0 : record?.mobile || 0,
        align: 'right',
      },
    ];

    return columns;
  };

  const getClassName = (item) => {
    let className = item?.isOrg ? 'row-org' : item?.isSchool ? 'row-school' : 'row-grade';
    return className;
  };

  return (
    <Spin spinning={isLoading || loading}>
      <div className="active-by-organization">
        <Form form={form} initialValues={{ date: moment(), search: '' }} onFinish={onFinishFilter}>
          <Row>
            <Col span={12}>
              <div className="head">
                <Form.Item name="search" noStyle>
                  <Input
                    placeholder={translate('reports.activationVolume.search')}
                    prefix={<SearchOutlined />}
                    className="search-input"
                    allowClear
                    onChange={handleChangeSearch}
                  />
                </Form.Item>
              </div>
              <Table
                columns={columnsOrg}
                dataSource={activeUsers}
                className="table-organization"
                rowKey="_id"
                pagination={false}
                expandable={{
                  expandIcon: () => null,
                  defaultExpandAllRows: true,
                  expandedRowKeys: activeKeys,
                }}
                onRow={(item: any) => ({
                  className: getClassName(item),
                  onClick: () => { },
                })}
                locale={{
                  emptyText: translate('no_data', { name: translate('list.empty.organization') }),
                }}
              />
            </Col>
            <Col span={6}>
              <div className="head">
                <Form.Item name="date" noStyle>
                  <DatePicker
                    format="YYYY/MM/DD"
                    allowClear
                    clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
                    showToday={false}
                    className="date-picker"
                    placeholder={translate('reports.time.placeholder')}
                    disabledDate={(current) => moment() <= current}
                    onChange={handleChangeDate}
                    locale={locale}
                  />
                </Form.Item>
              </div>
              <Table
                columns={columnsCalculate()}
                dataSource={activeUsers}
                className="table-calculate"
                rowKey="_id"
                pagination={false}
                expandable={{
                  expandIcon: () => null,
                  expandedRowKeys: activeKeys,
                }}
                onRow={(item: any) => ({
                  className: getClassName(item),
                  onClick: () => { },
                })}
                locale={{
                  emptyText: translate('no_data', {
                    name: translate('list.empty.timeline_report'),
                  }),
                }}
              />
            </Col>
            <Col span={6}>
              {!isShowCompare ? (
                <div className="view-add">
                  <Space direction="vertical" size={12} className="space-view">
                    <img
                      alt=""
                      src="/images/ic-add-large.png"
                      className="ic-add"
                      onClick={handleAdd}
                      title={translate('reports.activationVolume.addAnother')}
                    />
                    <Text className="txt-add">
                      {translate('reports.activationVolume.addAnother')}
                    </Text>
                  </Space>
                </div>
              ) : (
                <React.Fragment>
                  <div className="head last-col">
                    <Form.Item name="compare_date" noStyle>
                      <DatePicker
                        format="YYYY/MM/DD"
                        allowClear
                        clearIcon={<CloseCircleFilled style={{ color: 'red' }} />}
                        showToday={false}
                        className="date-picker"
                        placeholder={translate('reports.activationVolume.pickToCompare')}
                        disabledDate={(current) => moment() <= current}
                        onChange={handleChangeDateCompare}
                        locale={locale}
                      />
                    </Form.Item>
                  </div>
                  <Table
                    columns={columnsCalculate(true)}
                    dataSource={activeUsers}
                    className="table-calculate"
                    rowKey="_id"
                    pagination={false}
                    expandable={{
                      expandIcon: () => null,
                      expandedRowKeys: activeKeys,
                    }}
                    onRow={(item: any) => ({
                      className: getClassName(item),
                      onClick: () => { },
                    })}
                    locale={{
                      emptyText: translate('no_data', {
                        name: translate('list.empty.timeline_report'),
                      }),
                    }}
                  />
                </React.Fragment>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </Spin>
  );
});

export default React.memo(ActiveByOrg);
