/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import './popup-notifications.scss';
import { Badge, Popover, Typography } from 'antd';
import { BellFilled } from '@ant-design/icons';
import { useHistory } from 'react-router';
import ListNotification from 'pages/notifications/components/ListNotification';
import { storage } from 'utils';
import {
  disconnectSocket,
  initialSocketNotice,
  onReceiveNotice,
  onReceiveNoticeIncidentAssign,
  onStartMaintaince,
} from 'libs/socket';
import { loadingCountNotificationsAction, SET_TOTAL_NOTIFICATION } from 'actions/notifications';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { IPayloadLoadingCountNotifications } from 'pages/notifications/interfaces';
import { translate } from 'libs';
import { useSelector } from 'react-redux';

const PopUpNotifications = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [isVisiblePopUp, setVisiblePopUp] = React.useState<boolean>(false);
  const [isReload, setIsReload] = React.useState<boolean>(false);
  const totalNotice = useSelector(({ notifications }: any) => notifications.totalNotification);

  const admin_user_id: string = useAppSelector((state) => state.auth?.profile?._id);

  const handleVisibleChangePopUp = (visible: boolean) => {
    setVisiblePopUp(visible);
  };

  const handleCancelPopUp = () => {
    setVisiblePopUp(false);
  };

  const onClickSeeAll = () => {
    handleCancelPopUp();

    return history.push('/admin/notifications');
  };

  const initialNoticeSocket = () =>
    new Promise<void>((resolve) => {
      const token: string = storage.getToken();

      if (token) {
        initialSocketNotice(token);
      }
      resolve();
    });

  const loadingCountNotice = React.useCallback(() => {
    const payload: IPayloadLoadingCountNotifications = {
      onSuccess: (data: any) => {
        dispatch({
          type: SET_TOTAL_NOTIFICATION,
          payload: {
            total: data?.total_badge,
          },
        });
      },
      onError: (errorCode: string) => {
        console.log('🚀 ~ errorCode', errorCode);
      },
    };

    return dispatch(loadingCountNotificationsAction(payload));
  }, []);

  React.useEffect(() => {
    loadingCountNotice();
  }, []);

  React.useEffect(() => {
    if (admin_user_id) {
      initialNoticeSocket().then(() => {
        onReceiveNotice((msg: any) => {
          if (msg) {
            loadingCountNotice();
            setIsReload((pre) => !pre);
          }
        });
        onReceiveNoticeIncidentAssign((msg: any) => {
          if (msg) {
            loadingCountNotice();
            setIsReload((pre) => !pre);
          }
        });
        onStartMaintaince((msg: any) => {
          return history.push({
            pathname: '/system/maintenance',
          });
        });
      });
    }
    return () => {
      disconnectSocket();
    };
  }, [admin_user_id]);

  const title = (
    <div className="title-notifications">
      <Typography.Text>{translate('notifications.popUp.title')}</Typography.Text>
      <Typography.Text className="btn-see-all" onClick={onClickSeeAll}>
        {translate('notifications.popUp.seeAll')}
      </Typography.Text>
    </div>
  );

  const content = (
    <ListNotification
      onCancelPopUp={handleCancelPopUp}
      isVisiblePopUp={isVisiblePopUp}
      callback={loadingCountNotice}
      isReload={isReload}
    />
  );

  return (
    <div className="pop-up-notifications">
      <Popover
        overlayClassName="overlay-notifications"
        trigger="click"
        title={title}
        content={content}
        visible={isVisiblePopUp}
        onVisibleChange={handleVisibleChangePopUp}
      >
        <Badge count={totalNotice} overflowCount={99} className="badge-notification">
          <BellFilled className="ic-bell" type="primary" />
        </Badge>
      </Popover>
    </div>
  );
};

export default React.memo(PopUpNotifications);
