import API from 'libs/axios';
import {
  ICreateSOSFormValues,
  IPayloadDeleteSOS,
  IPayloadGetSOSListByOrg,
  IPayloadOrderSOS,
  IUpdateSOSFormValues,
} from 'pages/setting/interfaces';

const PATH: string = 'admin/organizations/sos';

export const getSOSListByOrg = (query: IPayloadGetSOSListByOrg['query']) =>
  API.get(`${PATH}/list/${query.organization_id}`);

export const postCreateSOS = (payload: ICreateSOSFormValues) => API.post(`${PATH}/create`, payload);

export const putUpdateSOS = (payload: IUpdateSOSFormValues, query: IPayloadDeleteSOS['query']) =>
  API.put(`${PATH}/update/${query.sosId}`, payload);

export const deleteSOS = (query: IPayloadDeleteSOS['query']) =>
  API.delete(`${PATH}/delete/${query.sosId}`);

export const orderSOS = (payload: IPayloadOrderSOS) => API.put(`${PATH}/order`, payload);
