/* eslint-disable react-hooks/exhaustive-deps */
import {
  Form,
  Radio,
  Space,
  Typography,
  Input,
  Dropdown,
  Button,
  Menu,
  Spin,
  InputNumber,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { useMyOrganizationID, useReadOnly } from 'app/hooks';
import { showSuccess, showError } from 'components/standby-notice';
import {
  IPayloadActionUpdateLimit,
  IPayloadGetListMessage,
} from 'pages/setting/interfaces/limit-message';
import { useDispatch } from 'react-redux';
import { updateLimitMessageAction, getLimitMessageAction } from 'actions/limit-message';
import { getSelectOption } from 'api/incident-message';
import { get } from 'lodash';
import ConfirmButton from 'components/common/ConfirmButton';
import { translate } from 'libs';

const { Title, Text } = Typography;

enum ELimitMessage {
  BY_TOTAL = 'BY_TOTAL',
  BY_DATE = 'BY_DATE',
}

export interface IUpdateLimit {
  organization_id: string;
  type: string;
  selected_option: string | null | any;
  message: string;
  value: string | null | undefined;
}

const IncidentMessage = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const isJustView: boolean = useReadOnly();

  const [listLimitMessage, setListLimitMessage] = useState<any[]>([]);

  const myOrgId: string = useMyOrganizationID();

  const [loading, setLoading] = useState(false);

  const [updateData, setUpdateData] = useState<IUpdateLimit>({
    organization_id: '',
    type: '',
    selected_option: '1',
    message: '',
    value: '',
  });

  const [valueDay, setValueDay] = useState<String>('');
  const [valueMessage, setValueMessage] = useState<String>('');

  const getListLimitMessage = (list) => {
    if (!myOrgId) return;
    setLoading(true);

    const payload: IPayloadGetListMessage = {
      query: {
        organization_id: myOrgId,
      },
      onSuccess: (data: any, optionData: any[]) => {
        if (data) {
          setUpdateData({
            ...updateData,
            type: data.type,
            selected_option: data.option,
            value: data.value,
          });

          if (data.type === null && data.value === null) {
            setUpdateData({ ...updateData, type: ELimitMessage.BY_TOTAL, value: 'Unlimited' });
            setValueMessage(translate(`settingMenu.numberMessage.typeUNLIMITED`));
          } else {
            if (data.type === ELimitMessage.BY_DATE) {
              const findItem: any = get(list, 1, []).find((e: any) => e?.value === data?.value);
              if (findItem) {
                setValueDay(translate(`settingMenu.numberDay.type${findItem?.id}`));
                setValueMessage(translate(`settingMenu.numberMessage.type${findItem?.id}`));
              } else {
                setValueDay(translate(`settingMenu.numberDay.typeUNLIMITED`));
                setValueMessage(translate(`settingMenu.numberMessage.typeUNLIMITED`));
              }
            } else {
              const findItem: any = get(list, 0, []).find((e: any) => e?.value === data?.value);
              if (findItem) {
                setValueMessage(translate(`settingMenu.numberMessage.type${findItem?.id}`));
                setValueDay(translate(`settingMenu.numberDay.type${findItem?.id}`));
              } else {
                setValueMessage(translate(`settingMenu.numberMessage.typeUNLIMITED`));
                setValueDay(translate(`settingMenu.numberDay.typeUNLIMITED`));
              }
            }
          }
        }

        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    };

    return dispatch(getLimitMessageAction(payload));
  };

  const getListOption = async () => {
    const result = await getSelectOption();

    if (result?.status?.toString() === 'OK') {
      setListLimitMessage(result?.data);
      getListLimitMessage(result?.data);
    }
  };

  useEffect(() => {
    getListOption();
  }, [myOrgId]);

  const LimitMessage = (
    <Menu className="menu-limit" style={{ width: 326 }}>
      {get(listLimitMessage, 0, []).map((item) => {
        return (
          <Menu.Item
            key={item.id}
            onClick={() => {
              setValueMessage(item.label);
              setValueMessage(translate(`settingMenu.numberMessage.type${item.id}`));
              setUpdateData({
                ...updateData,
                value: item.value,
                organization_id: myOrgId,
              });
            }}
          >
            {translate(`settingMenu.numberMessage.type${item.id}`)}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const LimitDay = (
    <Menu className="menu-limit">
      {get(listLimitMessage, 1, []).map((item) => {
        return (
          <Menu.Item
            key={item.id}
            onClick={() => {
              setValueDay(translate(`settingMenu.numberDay.type${item.id}`));
              setUpdateData({
                ...updateData,
                value: item.value,
                organization_id: myOrgId,
              });
            }}
          >
            {translate(`settingMenu.numberDay.type${item.id}`)}
          </Menu.Item>
        );
      })}
    </Menu>
  );
  const onFinish = () => {
    setLoading(true);
    const selected_option =
      updateData.value === 'Custom' ? updateData.selected_option || '1' : null;

    const payload: IPayloadActionUpdateLimit = {
      data: {
        ...updateData,
        organization_id: myOrgId,
        selected_option,
      },
      onSuccess: () => {
        setLoading(false);
        showSuccess(translate(`settingMenu.message.success2`));
        getListLimitMessage(listLimitMessage);
        setValueDay('');
        setValueMessage('');
      },
      onError: (errorCode: string) => {
        if (errorCode) {
          showError(translate(`errors.code.${errorCode}`));
        }
        setLoading(false);
      },
    };
    dispatch(updateLimitMessageAction(payload));
  };

  return (
    <Spin spinning={loading} size="small">
      <div className="incident-message">
        <Form
          layout="vertical"
          onFinish={onFinish}
          requiredMark={false}
          fields={[
            {
              name: ['type'],
              value: updateData ? updateData.type : '',
            },
          ]}
          form={form}
          scrollToFirstError
        >
          <Form.Item
            name="type"
            rules={[
              { required: true, message: `${translate('settingPage.ogSetup.ogTypeRequired')}` },
            ]}
          >
            <Radio.Group
              className="radio-og-type"
              onChange={(e) => {
                setUpdateData({
                  ...updateData,
                  type: e.target.value,
                  value: 'Unlimited',
                  selected_option: updateData.value === 'Custom' ? 1 : updateData.selected_option,
                });
                if (e.target.value === ELimitMessage.BY_TOTAL) {
                  setValueMessage(translate(`settingMenu.numberMessage.typeUNLIMITED`));
                } else {
                  setValueDay(translate(`settingMenu.numberDay.typeUNLIMITED`));
                }
              }}
            >
              <Space direction="vertical" size={32}>
                <Radio value={ELimitMessage.BY_TOTAL}>
                  <div style={{ paddingLeft: 10 }}>
                    <div>
                      <Title className="title-msg">
                        {translate('settingMenu.message.TitleMessage')}{' '}
                      </Title>
                      <Text className="txt-msg">{translate('settingMenu.message.Text')}</Text>
                    </div>
                    <div className="wrap-select_box">
                      {updateData.type === ELimitMessage.BY_TOTAL && (
                        <React.Fragment>
                          <div className="container">
                            <div className="select-item-msg">
                              <Text className="text">
                                {translate('settingMenu.message.LabelSelect')}
                              </Text>
                              <Dropdown overlay={LimitMessage} trigger={['click']}>
                                <Button>
                                  {valueMessage}
                                  <DownOutlined style={{ width: '100%', textAlign: 'end' }} />
                                </Button>
                              </Dropdown>
                            </div>
                            {valueMessage === translate(`settingMenu.numberMessage.typeCUSTOM`) ? (
                              <div className="input-item">
                                <Text className="text">
                                  {translate('settingMenu.message.LabelInput')}
                                </Text>
                                <InputNumber
                                  placeholder={translate('settingMenu.numberDay.basicUsage')}
                                  value={updateData.selected_option}
                                  onChange={(value) =>
                                    setUpdateData({ ...updateData, selected_option: value || '1' })
                                  }
                                  defaultValue="1"
                                  min="1"
                                  max="65000"
                                />
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                          {!updateData?.value ||
                          updateData?.value?.toUpperCase() === 'UNLIMITED' ? (
                            ''
                          ) : (
                            <div className="box-select-bottom">
                              <Text className="text">
                                {translate('settingMenu.message.TitleButton')}
                              </Text>
                              <Input
                                className="input-disabled"
                                placeholder={translate(
                                  `settingMenu.message.TextButton${
                                    updateData.value === 'Unlimited' ? '.unlimited' : ''
                                  }`,
                                  {
                                    number:
                                      updateData.value === 'Custom'
                                        ? parseInt(updateData.selected_option) || 1
                                        : updateData.value,
                                  }
                                )}
                                disabled
                                onChange={(e) =>
                                  setUpdateData({ ...updateData, message: e.target.value })
                                }
                              />
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </div>
                </Radio>
                <Radio value={ELimitMessage.BY_DATE}>
                  <div style={{ paddingLeft: 10 }}>
                    <div>
                      <Title className="title-msg">
                        {translate('settingMenu.numberDay.TitleDay')}{' '}
                      </Title>
                      <Text className="txt-msg">{translate('settingMenu.numberDay.Text')}</Text>
                    </div>
                    <div className="wrap-select_box">
                      {updateData.type === ELimitMessage.BY_DATE && (
                        <>
                          <div className="container">
                            <div className="select-item-msg">
                              <Text className="text">
                                {translate('settingMenu.numberDay.LabelSelect')}
                              </Text>
                              <Dropdown overlay={LimitDay} trigger={['click']}>
                                <Button>
                                  {valueDay}{' '}
                                  <DownOutlined style={{ width: '100%', textAlign: 'end' }} />
                                </Button>
                              </Dropdown>
                            </div>
                            {valueDay === translate(`settingMenu.numberDay.typeCUSTOM`) ? (
                              <div className="input-item">
                                <Text className="text">
                                  {translate('settingMenu.numberDay.LabelInput')}
                                </Text>
                                <InputNumber
                                  placeholder={translate('settingMenu.numberDay.basicUsage')}
                                  value={updateData.selected_option}
                                  onChange={(value) =>
                                    setUpdateData({ ...updateData, selected_option: value || '1' })
                                  }
                                  defaultValue="1"
                                  min="1"
                                  max="65000"
                                />
                              </div>
                            ) : null}
                          </div>
                          {!updateData.value || updateData?.value?.toUpperCase() === 'UNLIMITED' ? (
                            ''
                          ) : (
                            <div className="box-select-bottom">
                              <Text className="text">
                                {translate('settingMenu.numberDay.TitleButton')}
                              </Text>

                              <Input
                                className="input-disabled"
                                placeholder={translate(
                                  `settingMenu.numberDay.TextButton${
                                    updateData.value === 'Unlimited' ? '.unlimited' : ''
                                  }`,
                                  {
                                    number: translate('common.count_days', {
                                      number:
                                        updateData.value === 'Custom'
                                          ? parseInt(updateData.selected_option) || 1
                                          : updateData.value,
                                    }),
                                  }
                                )}
                                disabled
                                onChange={(e) =>
                                  setUpdateData({ ...updateData, message: e.target.value })
                                }
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
          {!isJustView && (
            <Form.Item className="item-btn">
              <ConfirmButton
                className="btn-edit"
                buttonTitle={translate('settingMenu.message.saveBtn')}
                onClick={() => form.submit()}
                form={form}
                size="large"
                isLoading={loading}
              />
            </Form.Item>
          )}
        </Form>
      </div>
    </Spin>
  );
};
export default IncidentMessage;
